import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";


import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import PinDropIcon from '@mui/icons-material/PinDrop';
import ListAltIcon from '@mui/icons-material/ListAlt';
import WalletIcon from '@mui/icons-material/Wallet';
import LogoutIcon from '@mui/icons-material/Logout';
import DeliveryAddress from "./DeliveryAddress";
import MyAddress from "./MyAddress";
import MyWallet from "./MyWallet";
import MyOrder from "./MyOrder";
import BeenhereIcon from '@mui/icons-material/Beenhere';
import { Height } from "@mui/icons-material";
import UserProfile from "./UserProfile";
import Person2Icon from '@mui/icons-material/Person2';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import MyWishlist from "./MyWishlist";

function MyProfile() {
  var userLogininfo = JSON.parse(localStorage.getItem("user-Logininfo"));
  if (userLogininfo === null) {
    window.location.href = '/';
  }else {
    if (userLogininfo["User Group"] !== "Employee") {
        window.location.href="/not-found";
    }

}
 
  let authToken = `${process.env.REACT_APP_BASE_AUTHKEY}`;
  let baseURL = "http://dialerpwebapis-dev-as.azurewebsites.net";//= `${process.env.REACT_APP_BASE_URL}`;
  let baseTokenKey = `${process.env.REACT_APP_BASE_KEY}`.replace(/'/g, '');
  function fun_LogOut() {
    localStorage.removeItem("user-Logininfo");
    window.location.href = '/';
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header />
      <div className="top-space">
        <Container fluid>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <div className="section-myprofile">
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                  <Row>
                    <Col xs={12} sm={12} md={4} lg={3}>
                      <div className="profile-div-left">
                        {/* <img src="../../assets/images/profile-icon.png" /> */}
                        {userLogininfo?.["ProfilePic"] !== undefined && userLogininfo?.["ProfilePic"] !== null ?
                          <img src={userLogininfo?.["ProfilePic"]}  />
                          // style={{ height: '130px', width: '165px' }}
                          : <img src="../../assets/images/profile-icon.png"/>
                        }
                        <h2>{userLogininfo?.["User Name"]}</h2>
                        <h3>{userLogininfo?.["Login Mobile"]}</h3>
                        <Nav variant="pills" className="flex-column">
                          <Nav.Item>
                            <Nav.Link eventKey="first"><Person2Icon className="left-profile-icon" />My Profile</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="second"><PinDropIcon className="left-profile-icon" />My Address</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="Third"><ListAltIcon className="left-profile-icon" />My Bookings</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="Four"><FavoriteBorderIcon className="left-profile-icon" />My Wishlist</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="Five"><WalletIcon className="left-profile-icon" />My Wallet</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="Six" onClick={fun_LogOut}><LogoutIcon className="left-profile-icon" />Logout</Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </div>
                    </Col>
                    <Col xs={12} sm={12} md={8} lg={9}>
                      <Tab.Content>
                        <Tab.Pane eventKey="first">
                          <div className="profile-div-right">
                            <UserProfile />
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                           <MyAddress />
                        </Tab.Pane>
                        <Tab.Pane eventKey="Third">
                        <MyOrder />
                        </Tab.Pane>
                        <Tab.Pane eventKey="Four">
                        <MyWishlist />
                        </Tab.Pane>
                        <Tab.Pane eventKey="Five">
                          <MyWallet />
                        </Tab.Pane>

                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>


              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />

    </div>
  );
}


export default MyProfile
