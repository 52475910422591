import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Card from "react-bootstrap/Card";
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import GradeIcon from '@mui/icons-material/Grade';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import TextField from '@mui/material/TextField';
import { Link } from "react-router-dom";
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Header from "../Header";
import Footer from "../Footer";
import axios from 'axios';
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { fetchWrapper, fetchWrapperForLogin } from '../apiUtils';
import { ProductContext } from "../context/AppContext";
function AddAddress() {
  var userLogininfo = JSON.parse(localStorage.getItem("user-Logininfo"));

  if (userLogininfo === null) {
    window.location.href = '/';
  }
  else {
    if (userLogininfo["User Group"] !== "Customer") {
      window.location.href = "/not-found";
    }

  }
  const { handleClickLoader, handleCloseLoader } = useContext(ProductContext);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [Mobile, setMobile] = useState("");
  const [Email, setEmail] = useState("");
  const [Address, setAddress] = useState("");
  const [City, setCity] = useState("");
  const [State, setState] = useState("");
  const [StateCode, setStateCode] = useState("");
  const [Country, setCountry] = useState("");
  const [Pincode, setPincode] = useState("");
  const [AddressType, setAddressType] = useState("");
  const [error, setError] = useState("");
  const [CityID, setCityID] = useState("");
  const [StateID, setStateID] = useState("");
  const [CountryID, setCountryID] = useState("");
  const [Adstatus, setAdstatus] = useState("");
  const [col, setcol] = useState([]);
  const [drop, setdrop] = useState([]);
  const [updateId, setupdateId] = useState("");
  const [BillingupdateId, setBillingupdateId] = useState("");
  const [EditView, setEditView] = useState("");
  const [AllAddress, setAllAddress] = useState([]);
  let authToken;
  if (userLogininfo !== null && userLogininfo !== "" && userLogininfo !== undefined) {
    authToken = localStorage.getItem("Auth-Token").replace(/"/g, '');

  }

  let baseURL = `${process.env.REACT_APP_BASE_URL}`;
  let baseTokenKey = `${process.env.REACT_APP_BASE_KEY}`.replace(/'/g, '');



  var GetAddressID = JSON.parse(localStorage.getItem("ADDID"));
  var GetBillingID = JSON.parse(localStorage.getItem("BillingId"));
  var get = JSON.parse(localStorage.getItem("myAddress"));

  useEffect(() => {
    setEditView(JSON.parse(localStorage.getItem("ListBillint")));
    setupdateId(JSON.parse(localStorage.getItem("ADDID")));
    setBillingupdateId(JSON.parse(localStorage.getItem("BillingId")));
    localStorage.removeItem("ADDID");
    localStorage.removeItem("BillingId");
    localStorage.removeItem("ListBillint");
    if (userLogininfo !== null && userLogininfo !== "" && userLogininfo !== undefined) {
      var fullName = [];
      fullName = userLogininfo["User Name"]?.split(" ");
      // if (fullName.length === 1) {
      //   setFirstName(fullName[0]);
      // }
      // if (fullName.length === 2) {
      //   setFirstName(fullName[0]);
      //   setLastName(fullName[1])
      // }
      // if (fullName.length === 3) {
      //   let fname = fullName[0] + " " + fullName[1]
      //   setFirstName(fname);
      //   setLastName(fullName[2])
      // }
      fun_CandidateAllAddress();
      ListBillingAddress();

      if (GetAddressID !== "" && GetAddressID !== undefined && GetAddressID != null) {
        fun_CandidateAddressList();
      }
      else {
        fun_BillingEditList();
      }

      window.scrollTo(0, 0);
    }
  }, []);
  console.log(EditView, "EditView");
  console.log("BillingupdateId", BillingupdateId);
  function fun_CandidateAllAddress() {

    handleClickLoader();
    let newusername = '{UserMobile:' + userLogininfo["Login Mobile"] + '}';
    const AllAddPara = {
      "pgSize": 100,
      "index": 1,
      "toEmailID": "designMonu@dialerp.com",
      "tabErpID": 1760,
      "filters": newusername,
      "viewFormat": "columnname",
    }
    fetch(
      baseURL + "/api/dynamic/GetRecords",
      {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(AllAddPara),
      }
    ).then((result) => {
      result.json().then((resp) => {
        handleCloseLoader();
        if (resp.hasErrors === false) {
          if (resp.response?.records !== null) {
            if (resp.response?.records.length > 0) {
              setAllAddress(resp.response);
            }
          }
        }
        else {
          alert(resp.errors);
        }
      });
    });
  }
  var min = 1;
  var max = 100;
  const MobileRegExp = new RegExp("^[0-9]{10}$");

  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  function generateString(length) {
    let result = ' ';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  function fun_AddAddress() {
    //  const randomNum = Math.floor(Math.random() * (50 - 1 + 1) + 1);
    // var rand = generateString(6)
    // const ActiveId = [];
    // const FirmName = [];
    if (FirstName === "") {
      setError("required");
      return;
    }
    // else if (LastName === "") {
    //   setError("required");
    //   return;
    // }
    else if (Mobile === "") {
      setError("required");
      return;
    }
    else if (!MobileRegExp.test(Mobile)) {
      setError('required');
      return;
    }
    else if (Email === "") {
      setError("required");
      return;
    }
    else if (!emailRegex.test(Email)) {
      setError('required');
      return;
    }

    else if (Address === "") {
      setError("required");
      return;
    }
    else if (City === "") {
      setError("required");
      return;
    }
    else if (State === "") {
      setError("required");
      return;
    }
    else if (Country === "") {
      setError("required");
      return;
    }
    else if (Pincode === "") {
      setError("required");
      return;
    }
    else if (AddressType === "") {
      setError("required");
      return;
    }
    else {
      handleClickLoader();
      var fname = "";
      if (LastName !== null && LastName !== "" && LastName !== undefined) {
        fname = FirstName + ' ' + LastName
      }
      else {
        fname = FirstName
      }
      if (updateId != null && updateId !== "" && updateId !== undefined) {
       
        const formData =
          [{
            "AddressID": updateId,
            "AddressLine1": Address,
            "AddressNickName": Email,
            "City": City,
            "CityID": CityID,
            "State": State,
            "StateID": StateID,
            "StateCode": StateCode,
            "Country": Country,
            "CountryID": CountryID,
            "AddressType": AddressType,
            "ContactName": fname,
            "ContactMobile": Mobile,
            "UserMobile": userLogininfo?.["Login Mobile"],
            "Coordinates": ["00.000000", "00.000000"],
          }
          ]
        fetch(
          baseURL + "/api/Dynamic/AddUpdateRecords?LoginType=2&tabErpID=1760",
          {
            method: "POST",
            headers: {
              "Authorization-Token": authToken,
              Accept: "application/json, text/plain",
              "Content-Type": "application/json;charset=UTF-8",
            },
            body: JSON.stringify(formData),
          }
        ).then((result) => {
          result.json().then((resp) => {
            if (resp.hasErrors === false) {
              //alert(resp.response.message)
              setupdateId("");
              GetAddressID = "";
              if (get !== "" && get != null && get !== undefined) {
                window.location.href = "/customer/my-address"
              }
              else {
                window.location.href = "/customer/view-booking/1"
              }

            }
            else {
              alert(resp.errors);
            }
          });
        });
      }
      else {
        AllAddress?.records?.map((status, i) => {
          if (status["Landmark"] === "Active") {
            const formData = [{
              "AddressID": status["AddressID"],
              "AddressLine1": status["AddressLine1"],
              "Pincode": status["Pincode"],
              "Landmark": "Deactive",
              "City": status["City"],
              "CityID": status["CityID"],
              "State": status["State"],
              "StateID": status["StateID"],
              "StateCode": status["StateCode"],
              "Country": status["Country"],
              "CountryID": status["CountryID"],
              //"Coordinates": "",
              "AddressType": status["AddressType"],
              "ContactName": status["ContactName"],
              "ContactMobile": status["ContactMobile"],
              "Coordinates": ["00.000000", "00.000000"],
              "UserMobile": userLogininfo?.["LoginMobile"],
              "AddressNickName": status["AddressNickName"],
            }]
            try {
              fetch(
                baseURL + "/api/Dynamic/AddUpdateRecords?LoginType=2&tabErpID=1760",
                {
                  method: "POST",
                  headers: {
                    "Authorization-Token": authToken,
                    Accept: "application/json, text/plain",
                    "Content-Type": "application/json;charset=UTF-8",
                  },
                  body: JSON.stringify(formData),
                }
              ).then((result) => {
                result.json().then((resp) => {
                  if (resp.hasErrors === false) {
                  }
                  else {
                    alert(resp.errors);
                    return
                  }
                });
              });
            } catch (error) {
              alert(error.message);
            }
          }
        })
        const formData =
          [{
            "AddressNickName": Email,
            "AddressLine1": Address,
            "Landmark": "Active",
            "Pincode": Pincode,
            "City": City,
            "CityID": CityID,
            "State": State,
            "StateID": StateID,
            "StateCode": StateCode,
            "Country": Country,
            "CountryID": CountryID,
            //  "Coordinates": "",
            "AddressType": AddressType,
            "ContactName": fname,
            "ContactMobile": Mobile,
            "Coordinates": ["00.000000", "00.000000"],
            "UserMobile": userLogininfo?.["Login Mobile"],

          }
          ]
        fetch(
          baseURL + "/api/Dynamic/AddUpdateRecords?LoginType=2&tabErpID=1760",
          {
            method: "POST",
            headers: {
              "Authorization-Token": authToken,
              Accept: "application/json, text/plain",
              "Content-Type": "application/json;charset=UTF-8",
            },
            body: JSON.stringify(formData),
          }
        ).then((result) => {
          result.json().then((resp) => {
            handleCloseLoader();
            if (resp.hasErrors === false) {
              if (BillingAddressList.length === 0) {
                fun_AddBillingAddresses();
              }
              if (get !== "" && get != null && get !== undefined) {
                window.location.href = "/customer/my-address"
              }
              else {
                window.location.href = "/customer/view-booking/1"
              }

            }
            else {

              alert(resp.errors);

            }
          });
        });
      }

    }
  }
  function fun_CandidateAddressList() {
    var fullName = [];
    let newusername = '{_id:{$eq :' + GetAddressID + '},}';
    const loginPra = {
      "pgSize": 100,
      "index": 1,
      "toEmailID": "designMonu@dialerp.com",
      "tabErpID": 1760,
      "filters": newusername,
      "viewFormat": "columnname",
    }
    fetch(
      baseURL + "/api/dynamic/GetRecords",
      {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(loginPra),
      }
    ).then((result) => {
      result.json().then((resp) => {

        if (resp.hasErrors === false) {
          if (resp.response?.records !== null) {
            if (resp.response?.records.length > 0) {

              try {
                if ((resp?.response?.records[0]["ContactName"]) !== "" && (resp?.response?.records[0]["ContactName"]) !== null
                  && (resp?.response?.records[0]["ContactName"]) !== undefined) {
                  fullName = ((resp?.response?.records[0]["ContactName"])?.split(" "));
                  if (fullName.length === 1) {
                    setFirstName(fullName[0]);
                  }
                  if (fullName.length === 2) {
                    setFirstName(fullName[0]);
                    setLastName(fullName[1])
                  }
                  if (fullName.length === 3) {
                    let fname = fullName[0] + " " + fullName[1]
                    setFirstName(fname);
                    setLastName(fullName[2])
                  }
                }
              }
              catch (error) {
                alert(error);
              }
              setMobile(resp.response?.records[0]["ContactMobile"])
              setEmail(resp.response?.records[0]["AddressNickName"])
              setAddress(resp.response?.records[0]["AddressLine1"])
              setCity(resp.response?.records[0]["City"])
              setState(resp.response?.records[0]["State"])
              setCountry(resp.response?.records[0]["Country"])
              setPincode(resp.response?.records[0]["Pincode"])
              setAddressType(resp.response?.records[0]["AddressType"])

            }
          }
          else {
            setAddress("")
            setCity("")
            setState("")
            setCountry("")
            setPincode("")
            setAddressType("")

          }

        }
        else {
          alert(resp.errors);
        }
      });
    });
  }

  var pincodevalue = "";
  const handleGoButtonClick = (item) => {
    if (item !== "") {
      pincodevalue = item;
      getdropdata();
    }
    else {
      pincodevalue = "";
      setCity("");
      setState("");
      setCountry("");
      setStateCode("");
    }
  };

  const getdropdata = async () => {
    let PincodeData = '{Pincode:' + pincodevalue + '}';
    const options = {
      method: "POST",
      url: baseURL + "/api/dynamic/AutoFillMapperWise?appname=web&tmpUId=40396&tmpPId=40396",
      headers: {
        Headers,
        "Authorization-Token": authToken,
      },
      data: {
        useAtlusSearch: true,
        userID: 40396,
        filters: "{tabErpID:749}",
        index: 1,
        isDownload: false,
        toEmailID: "designMonu@dialerp.com",
        tabErpID: 749,
        dataMapper: {
          "CollectionName": "FINANCECONNECT_Pincode",
          "filter": PincodeData,
          "project": "{'Pincode':'$Pincode','City':'$City','CityID':'$CityID','State':'$State','StateID':'$StateID','StateCode':'$StateCode','Country':'$Country','CountryID':'$CountryID'}",
          "suggestedText": ""
        },
      },
    };
    try {
      const response = await axios.request(options);

      if (response.data.response.records !== null) {
        setdrop(response.data);
        setCity(response.data.response.records[0]["City"]);
        setState(response.data.response.records[0]["State"])
        setCountry(response.data.response.records[0]["Country"]);
        setStateCode(response.data.response.records[0]["StateCode"])
        setCityID(response.data.response.records[0]["CityID"]);
        setStateID(response.data.response.records[0]["StateID"]);
        setCountryID(response.data.response.records[0]["CountryID"]);
      }
      else {
        setCity("");
        setState("");
        setCountry("");
        setStateCode("");
        setError("Enter valid pincode")
      }
    } catch (error) {
      console.error(error);
    }

  };
  // CityID
  // CountryID
  // StateID

  function fun_Cancel() {
    window.location.href = "/customer/view-booking/1";
  }

  const handleInputChange = (event) => {
    const value = event.target.value;
    setPincode(value);

    if (value.length === 6) {
      handleGoButtonClick(value);
      setError('');
    } else {
      setError('Enter valid pincode');
      setCity("");
      setState("");
      setCountry("");
      setStateCode("");
    }
  };
  const [BillingAddressList, setBillingAddressList] = useState([]);
  function ListBillingAddress() {
    let newusername = `{'LoginMobile':${userLogininfo["Login Mobile"]}}`;
    const AllAddPara = {
      pgSize: 100,
      index: 1,
      toEmailID: "designMonu@dialerp.com",
      tabErpID: 1452,
      filters: newusername,
      viewFormat: "columnname",
    };
    fetch(baseURL + "/api/dynamic/GetRecords", {
      method: "POST",
      headers: {
        "Authorization-Token": authToken,
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(AllAddPara),
    }).then((result) => {
      result.json().then((resp) => {
        //handleCloseLoader();
        if (resp.hasErrors === false) {
          if (resp.response?.records !== null) {
            if (resp.response?.records.length > 0) {
              setBillingAddressList(resp.response);
            }
          }
        } else {
          alert(resp.errors);
        }
      });
    });
  }
  function fun_AddBillingAddresses() {
    var fname = "";
    if (LastName !== null && LastName !== "" && LastName !== undefined) {
      fname = FirstName + ' ' + LastName
    }
    else {
      fname = FirstName
    }
    //const ra=AuthkeyToken;
    var rand = generateString(6)
    if (
      BillingupdateId !== "" &&
      BillingupdateId !== null &&
      BillingupdateId !== undefined &&
      BillingupdateId !== "0"
    ) {
      const DeleverData = [
        {
          "LedgerID": BillingupdateId,
          "LedgerName": rand.trim(),
          "ContactName": fname,
          "ContactMobile": Mobile,
          "ContactEmail": Email,
          "Address": Address,
          "AddressType": AddressType,
          "Pincode": Pincode,
          "City": City, //"CityID": 463,
          "State": State, //"StateID": 29,
          "StateCode": StateCode,
          "Country": Country, //"CountryID": 1,
          "PrimaryBeat": "India",
          "BeatID": 1
        },
      ];
      fetch(baseURL + "/api/Dynamic/AddUpdateRecords?tabErpID=1452", {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(DeleverData),
      }).then((result) => {
        result.json().then((resp) => {
          if (resp.hasErrors === false) {
            // alert("Billing Address update Successfully");
            window.location.reload();
          } else {
            alert(resp.errors);
          }
        });
      });
    } else {
      const DeleverData = [
        {
          "LedgerName": rand,
          "LedgerGroup": "Customer",
          "LedgerGroupID": 31,
          "UnderLedgerGroup": userLogininfo["Ledger Group"],
          "UnderLedgerGroupID": userLogininfo["Ledger GroupID"],
          "Status": "Active",
          "StatusID": 1,
          "ContactName": fname,
          "ContactMobile": Mobile,
          "ContactEmail": Email,
          "Address": Address,
          "AddressType": AddressType,
          "Pincode": Pincode,
          "City": City,
          "State": State,
          "StateCode": StateCode,
          "Country": Country,
          "LoginMobile": userLogininfo["Login Mobile"],
          "UserName": userLogininfo["User Name"],
          "UserID": userLogininfo["UserID"],
          "UserGroup": userLogininfo["User Group"],
          "UserGroupID": userLogininfo["User GroupID"],
          "Gender": userLogininfo["Gender"],
          // "Coordinates": ["00.000000", "00.000000"],
          "PrimaryBeat": "India",
          "BeatID": 1
        },
      ];
      fetch(baseURL + "/api/Dynamic/AddUpdateRecords?tabErpID=1452", {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(DeleverData),
      }).then((result) => {

        result.json().then((resp) => {

          if (resp.hasErrors === false) {
            window.location.reload();
          } else {
            alert(resp.errors);
          }
        });
      });
    }
  }
  function fun_BillingEditList() {
    //  const bid = route.params["BookingID"];
    let newusername = "{_id:" + GetBillingID + "}";
    var fullName = [];
    const AllAddPara = {
      pgSize: 100,
      index: 1,
      toEmailID: "designMonu@dialerp.com",
      tabErpID: 1452,
      filters: newusername,
      viewFormat: "columnname",
    };
    fetch(baseURL + "/api/dynamic/GetRecords", {
      method: "POST",
      headers: {
        "Authorization-Token": authToken,
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(AllAddPara),
    }).then((result) => {
      result.json().then((resp) => {
        //handleCloseLoader();
        if (resp.hasErrors === false) {
          if (resp.response?.records !== null) {
            if (resp.response?.records.length > 0) {
              try {
                if ((resp?.response?.records[0]["ContactName"]) !== "" && (resp?.response?.records[0]["ContactName"]) !== null
                  && (resp?.response?.records[0]["ContactName"]) !== undefined) {
                  fullName = ((resp?.response?.records[0]["ContactName"])?.split(" "));
                  if (fullName.length === 1) {
                    setFirstName(fullName[0]);
                  }
                  if (fullName.length === 2) {
                    setFirstName(fullName[0]);
                    setLastName(fullName[1])
                  }
                  if (fullName.length === 3) {
                    let fname = fullName[0] + " " + fullName[1]
                    setFirstName(fname);
                    setLastName(fullName[2])
                  }

                }
              }
              catch (error) {
                alert(error);
              }
              setMobile(resp.response?.records[0]["ContactMobile"]);
              setEmail(resp.response?.records[0]["ContactEmail"]);
              setAddress(resp.response?.records[0]["Address"]);
              setCity(resp.response?.records[0]["City"]);
              setState(resp.response?.records[0]["State"]);
              setCountry(resp.response?.records[0]["Country"]);
              setAddressType(resp.response?.records[0]["AddressType"]);
              setPincode(resp.response?.records[0]["Pincode"]);
              setCityID(resp.response?.records[0]["CityID"]);
              setStateID(resp.response?.records[0]["StateID"]);
              setCountryID(resp.response?.records[0]["CountryID"]);
            }
          }
        } else {
          alert(resp.errors);
        }
      });
    });
  }
  const handleFullNameChange = (text) => {
    // Regular expression to match alphabetic characters and spaces only
    const alphabeticWithSpaceRegex = /^[a-zA-Z\s]*$/;
    if (alphabeticWithSpaceRegex.test(text)) {
      setFirstName(text);
    }
  };

  return (

    <div>
      <div className="left-view-booking-1 add-address-section-2">

        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <h2 className="add-address-5">{updateId !== "" && updateId != null && updateId !== undefined ? "Edit Address" : BillingupdateId !== "" && BillingupdateId != null && BillingupdateId !== undefined ? "Edit Billing Address" : "Add Address"}</h2>

          </Col>
          <Col xs={12} sm={12} md={12} lg={6}>
            <TextField id="outlined-basic" label="First Name*" className={`service-input-1  ${error && FirstName === "" ? 'is-invalid' : ""}`}
              variant="outlined" value={FirstName} onChange={(e) => handleFullNameChange(e.target.value)}
            //  onChange={(e) => setFirstName(e.target.value)}
            />
            {error && FirstName === "" && <div className="service-input-12">Enter first name</div>}
          </Col>
          <Col xs={12} sm={12} md={12} lg={6}>
            <TextField id="outlined-basic" label="Last Name"
              className={`service-input-1  ${error && LastName === "" ? 'is-invalid' : ""}`} variant="outlined" value={LastName}
              onChange={(e) => setLastName(e.target.value)} />
            {error && LastName === "" &&
              <div className="service-input-12">Enter last name</div>}
          </Col>
          <Col xs={12} sm={12} md={12} lg={6}>
            <TextField id="outlined-basic" type="number" label="Mobile*" className={`service-input-1  ${error && Mobile === "" || !MobileRegExp.test(Mobile) ? 'is-invalid' : ""}`} variant="outlined" value={Mobile} onChange={(e) => setMobile(e.target.value)} onInput={(e) => {
              e.target.value = (e.target.value).toString().slice(0, 10)
            }} />
            {error && Mobile === "" && <div className="service-input-12">Enter mobile</div>}
            {error && Mobile !== '' && !MobileRegExp.test(Mobile) &&
              <div className="invalid-feedback">Please enter a valid 10 digits mobile no</div>}
          </Col>
          <Col xs={12} sm={12} md={12} lg={6}>
            <TextField id="outlined-basic" label="Email*" className={`service-input-1  ${error && (Email === "" || !emailRegex.test(Email.trim())) ? 'is-invalid' : ""}`} variant="outlined" value={Email} onChange={(e) => setEmail(e.target.value)} />
            {error && Email === "" && <div className="service-input-12">Enter email</div>}
            {error && Email.trim() !== '' && !emailRegex.test(Email.trim()) && <div className="invalid-feedback">Please enter a valid email address</div>}
          </Col>
          <Col xs={12} sm={12} md={12} lg={12}>
            <TextField id="outlined-basic" label="Address*"
              className={`service-input-1  ${error && Address === "" ? 'is-invalid' : ""}`}
              variant="outlined" value={Address} onChange={(e) => setAddress(e.target.value)} />
            {error && Address === "" && <div className="service-input-12">Enter address</div>}
          </Col>
          <Col xs={12} sm={6} md={6}>
            <FormControl className="custom-go-button-add" fullWidth>
              <TextField id="outlined-basic" label="Pincode*" maxLength={6} className={`service-input-1  ${error && Pincode === "" ? 'is-invalid' : ""}`} variant="outlined" value={Pincode} onChange={handleInputChange} onInput={(e) => {
                e.target.value = (e.target.value).toString().slice(0, 6)
              }} />
              {error && Pincode === "" && <div className="service-input-12">Enter pincode</div>}
              {error && Pincode !== "" && City === "" && <div className="service-input-12">Enter valid pincode</div>}
              {/* <Button variant="contained" className="next-button-7 custom-go-button" value={Pincode} onClick={() => { handleGoButtonClick(Pincode) }} style={{ marginTop: "17px" }}>
                    Go
                  </Button> */}
            </FormControl>
          </Col>

          <Col xs={12} sm={12} md={12} lg={6}>
            <TextField id="outlined-basic" style={{ color: "black" }} label="City" className={`service-input-1 input-background-gray-color  ${error && City === "" ? 'is-invalid' : ""}`} variant="outlined" value={City} onChange={(e) => setCity(e.target.value)} disabled />
            {/* {error && City === "" && <div className="service-input-12">Select city</div>} */}
          </Col>



          <Col xs={12} sm={12} md={12} lg={6}>
            <TextField id="outlined-basic" style={{ color: "black" }} label="State" className={`service-input-1 input-background-gray-color  ${error && State === "" ? 'is-invalid' : ""}`} variant="outlined" value={State} onChange={(e) => setState(e.target.value)} disabled />
            {/* {error && State === "" && <div className="service-input-12">Select state</div>} */}
          </Col>
          <Col xs={12} sm={12} md={12} lg={6}>
            <TextField id="outlined-basic" style={{ color: "black" }} label="Country"
              className={`service-input-1 input-background-gray-color  ${error && Country === "" ? 'is-invalid' : ""}`} variant="outlined" value={Country} onChange={(e) => setCountry(e.target.value)} disabled />
            {/* {error && Country === "" && <div className="service-input-12">Select country</div>} */}
          </Col>
          <Col xs={12} sm={12} md={12} lg={12}>
            <FormLabel id="demo-radio-buttons-group-label" className="service-input-1">
              Select Address Type
            </FormLabel>
            <FormControl className="kk-22">

              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="Home"
                name="radio-buttons-group"
                className="kk-23"

              >
                <FormControlLabel
                  value="Home"
                  style={{ color: "black" }}
                  control={<Radio />}
                  label="Home"
                  checked={AddressType === "Home"}
                  onChange={(e) => { setAddressType(e.target?.value); setError(''); }} name="Home" className={`${error && AddressType === '' ? 'is-invalid' : ''}`}
                />
                <FormControlLabel
                  value="Office" style={{ color: "black" }}
                  control={<Radio />}
                  label="Office"
                  checked={AddressType === "Office"}
                  onChange={(e) => { setAddressType(e.target?.value); setError(''); }} name="Office" className={`${error && AddressType === '' ? 'is-invalid' : ''}`}
                />
                {error && AddressType === '' && <div className="service-input-12">Select address type</div>}
              </RadioGroup>
            </FormControl>
          </Col>

          <Col xs={12} sm={12} md={12} lg={12}>
            <Stack spacing={2} direction="row" className="submit-service">
              {/* <Button onClick={fun_Cancel}>Cancel</Button> */}
              {EditView === "EditBillingAddress" && EditView != null && EditView !== ""
                ? <Button variant="contained" className="next-button-7" onClick={fun_AddBillingAddresses}>
                  {BillingupdateId !== "" && BillingupdateId != null && BillingupdateId !== undefined
                    ? "Update"
                    : "Submit"}</Button>
                :
                <Button variant="contained" className="next-button-7" onClick={fun_AddAddress}>
                  {updateId !== "" && updateId != null && updateId !== undefined
                    ? "Update"
                    : "Submit"}
                </Button>}


            </Stack>
          </Col>
        </Row>
      </div>

    </div>



  );
}

export default AddAddress
