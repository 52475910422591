/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { Link } from 'react-router-dom';
import { Container } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import { fetchApiWithLoginToken, fetchWrapper } from "../apiUtils";
import moment from "moment";
function BookingConfirmed() {
    const keysToRemove = [
        "Appointmentobj",
        "deliveryaddress",
        "CardLogin"
        ,"CartItemms"
    ];
    var userLogininfo = JSON.parse(localStorage.getItem("user-Logininfo"));
    let authToken = localStorage.getItem("Auth-Token").replace(/"/g, '');
    const BookingID = localStorage.getItem("BookingID");
    // const linkHref = `/customer/order-details/ODI2165561564GOTON${BookingID}`;
    if (userLogininfo === null) {
        window.location.href = '/';
    }
   
    useEffect(() => {
       if (BookingID === null) {
            document.getElementById("BookingIDNull").click();
        }
        keysToRemove.forEach(key => {
            localStorage.removeItem(key);
        });
        if (BookingID !== null && BookingID !== "" && BookingID !== undefined) {
        AppointmentList();
        }
      }, [BookingID]);
      const [AppointmentDate, setAppointmentDate] = useState([]);
      const AppointmentList = () => {
        //let AuthkeyTEXT =//"HJ3R64q9UOlaXkQHCArWcKLUvt3n4/7IYW99O3AuyzvHRi1DPq8xq287LwO793fgiIe6iVlFhEhfEGaiTiHb8+1lM8amEOAPnjuYQ3a7rT4F9qeF+lXqeuJC98ISlV/Iy/o3axyADk9OgaOPnuha6BET+d1/Dh9Q6JI/VspPNl/g8YugBlOYe4NJ7wDYdsZo7YGnqstf45goymigIBpw1iOVPk0B42QMeoTPmd28Vy9QxUpNato+7/Xnj5e/Ly3N"
        // console.log(token,AuthkeyTEXT);
        let newusername = "{BookingID:" + BookingID + "}";
        const checkjobsPra = {
          "pgSize": 100,
          "index": 1,
          "toEmailID": "designMonu@dialerp.com",
          "tabErpID": 1258,
          //"filters": "{'Stage':'Booking Assigned'}"
          "filters": newusername
        }
        let endpointURL = "Dynamic/GetRecords"
        try {
          fetchWrapper(checkjobsPra, endpointURL, authToken)
            .then((resp) => {
              if (resp?.errorCode === "HTTP error") {
                alert("Request not valid");
                return;
              }
              if (resp.hasErrors === false) {
                if (resp.response?.records !== null) {
                  if (resp.response?.records.length > 0) {
                  
                    setAppointmentDate(resp.response?.records[0]["Appointment Dt"]);
                  }
                }
              }
              else {
                alert("Invalid Request.");
              }
            }).catch(error => {
            });
          ;
        } catch (error) {
          alert(error.message);
        }
      }
    return (
        <>
            <Link to="/customer/my-bookings" id="BookingIDNull" hidden>Back</Link>
            {/* <div className="thankyou-page"> */}
            <section className="section-step-1 section-step-scroll">

                <Container fluid>
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                            <div className="thankyou-page-22">
                                <h2>
                                    Thank You
                                </h2>
                                <img src="../../../assets/images/success.gif" alt="success-icon" className="thankyou-img-22" />
                                <h3>Your Service Booking ID is {BookingID}</h3>
                                <p>
                                    Your Service is confirmed. You will receive an order confirmation
                                    email/ SMS shortly with ths expected Service date is {moment(AppointmentDate).format("DD MMMM YYYY")}.
                                </p>
                                <Button className="service-button">
                                    <Link to="/customer/my-bookings">View Booking Detail</Link>
                                    {/* <Link to={`/customer/order-details/${BookingID}`}>View Booking Detail</Link> */}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </section>
        </>
    );
}

export default BookingConfirmed
