import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function RefundCancellation() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [0]);
  return (
    <div>
      <Header />
      <div className="top-space refundclass">
        <Container fluid>
          <Row>
            <Col xs={12} sm={12} md={12}>
              <h2>Refund and cancellation</h2>
              <h3>Purpose of Security Deposit</h3>
              <p>
                The security deposit serves as a guarantee against any potential
                damages, wastages or failure to return the assets of the company
                which are provided to you for performing assigned tasks. The
                security deposit amount will have to be paid by you either in
                lump sum or on periodical basis as may be communicated to you,
                from time
              </p>
              <h3>Refund of Security Deposit</h3>
              <p>
                Security deposit amount will be refunded to you in case you
                decide to stop working with us or we serve you with letter of
                termination of our working arrangement. In either case, you must
                return all the assets allocated to you. In case, the assets is
                damaged, lost or not returned to us in for any reason
                whatsoever, we will deduct amount for the
                lost/damaged/non-returned from the security deposit and will
                release balance security deposit amount to you.
              </p>

              <h3>Determination of Amount</h3>

              <p>
                We will reconcile and determine the number of assets allocated
                to you with the actual use of the assets and therefore the
                balance remaining in your possession.The determination and
                finalization with respect to reconciliation and finalization of
                number of assets/amount will be done by the Company at it's sole
                discretion and shall be binding on you.
              </p>
              <h3>Refund Approval</h3>
              <p>
                Once the refund is calculated and approved, it will be paid in
                your bank account which is in the Company's record.
                It will be credited in your bank account within 7 working days.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  );
}

export default RefundCancellation;
