import React, { useState, useCallback } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ComposedChart,
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Bar,
} from "recharts";
import { Link } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SearchIcon from "@mui/icons-material/Search";
import CategoryIcon from "@mui/icons-material/Category";
import StorefrontIcon from "@mui/icons-material/Storefront";
import EmojiFlagsIcon from "@mui/icons-material/EmojiFlags";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import HomeIcon from "@mui/icons-material/Home";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MenuIcon from "@mui/icons-material/Menu";
import Offcanvas from "react-bootstrap/Offcanvas";

// Form
import TextField from "@mui/material/TextField";

import Form from "react-bootstrap/Form";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import Stack from "@mui/material/Stack";
import Badge from "@mui/material/Badge";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

function AddService() {
  const [showDiv1, setShowDiv1] = useState(true);
  var userLogininfo = JSON.parse(localStorage.getItem("user-Logininfo"));
    if (userLogininfo === null) {
        window.location.href = '/';
    }
    else {
        if (userLogininfo["User Group"] !== "Employee") {
            window.location.href="/not-found";
        }

    }

  const handleButtonClick = () => {
    // Toggle the visibility of divs
    setShowDiv1(!showDiv1);
  };

  
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose11 = () => {
    setAnchorEl(null);
  };

  const [opacity, setOpacity] = useState({
    uv: 1,
    pv: 1,
  });

  const handleMouseEnter = useCallback(
    (o) => {
      const { dataKey } = o;

      setOpacity({ ...opacity, [dataKey]: 0.5 });
    },
    [opacity, setOpacity]
  );

  const handleMouseLeave = useCallback(
    (o) => {
      const { dataKey } = o;
      setOpacity({ ...opacity, [dataKey]: 1 });
    },
    [opacity, setOpacity]
  );

  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const [age, setAge] = React.useState("");

  const handleChange2 = (event) => {
    setAge(event.target.value);
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

 
  const handleGoButtonClick = () => {
    setIsDropdownOpen(!isDropdownOpen); // Toggle the dropdown's open/close state
  };


  return (
    <div className="admin-panel-section">
      <div className="header-admin">
        <Container fluid>
          <Row>
            <Col xs={6} sm={4} md={3} lg={2} className="left-side-panel-1">
              <div className="admin-logo">
                <img src="../../assets/images/logo5.svg" width="50%" />
                <MenuIcon
                  className="admin-menu-icon"
                  onClick={handleButtonClick}
                />
                <MenuIcon
                  className="admin-menu-icon-mobile"
                  onClick={handleShow}
                />
              </div>
            </Col>
            <Col xs={6} sm={8} md={9} lg={10} className="right-side-panel-1">
              <div className="welcome-section">
                <h2>
                  <span className="welcome-section-right">
                    <SearchIcon className="icon-2" />
                    <span className="icon-space-5">
                      <Badge badgeContent={4} color="primary">
                        <NotificationsNoneIcon className="icon-5" />
                      </Badge>
                    </span>
                    <Button
                      id="basic-button"
                      className="user-button display-none-on-mobile"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                    >
                      <img
                        src="../../assets/images/profile.jpeg"
                        className="profile-image"
                      />
                      User <KeyboardArrowDownIcon />
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose11}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem onClick={handleClose11}>Profile</MenuItem>
                      <MenuItem onClick={handleClose11}>My account</MenuItem>
                      <MenuItem onClick={handleClose11}>Logout</MenuItem>
                    </Menu>
                  </span>
                </h2>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {showDiv1 ? (
        <div className="admin-body-section admin-div-1">
          <Container fluid>
            <Row>
              <Col xs={12} sm={12} md={3} lg={2}>
                <div className="left-menu">
                  <Row>
                    <Col xs={12} sm={12} md={12}>
                      <ul>
                        <li>
                          <Link to="/admin/admindashboard">
                            <DashboardIcon className="icon-11" />
                            Dashboard
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            <CategoryIcon className="icon-11" />
                            Category
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            <StorefrontIcon className="icon-11" />
                            Sub Category
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            <StorefrontIcon className="icon-11" />
                            Child Category
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            <EmojiFlagsIcon className="icon-11" />
                            Service Country
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            <TrendingDownIcon className="icon-11" />
                            Service City
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            <SubdirectoryArrowRightIcon className="icon-11" />
                            Service Area
                          </Link>
                        </li>
                        <li>
                          <Link to="/admin/addservice">
                            <SubdirectoryArrowRightIcon className="icon-11" />
                            Add Service
                          </Link>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col xs={12} sm={12} md={9} lg={10} className="right-side-section">
                <div className="add-sevice-section-2">
                  <Form className="addservice-form">
                    <Row>
                      <Col xs={12} sm={12} md={12}>
                        <h2>Add Service</h2>
                      </Col>
                      <Col xs={12} sm={6} md={4}>
                        <TextField
                          id="outlined-basic"
                          label="Name"
                          variant="outlined"
                          className="addservice-1"
                        />
                      </Col>
                      <Col xs={12} sm={6} md={4}>
                        <TextField
                          id="outlined-basic"
                          label="Mobile"
                          type="number"
                          variant="outlined"
                          className="addservice-1"
                        />
                      </Col>
                      <Col xs={12} sm={6} md={4}>
                        <TextField
                          id="outlined-basic"
                          label="Email"
                          variant="outlined"
                          className="addservice-1"
                        />
                      </Col>
                      <Col xs={12} sm={6} md={4}>
                        <TextField
                          id="outlined-basic"
                          label="Address"
                          variant="outlined"
                          className="addservice-1"
                        />
                      </Col>
                      <Col xs={12} sm={6} md={4}>
                        <FormControl className="custom-go-button-add" fullWidth>
                          <InputLabel
                            id="demo-simple-select-label"
                            variant="outlined"
                          >
                            Select Service
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={age}
                            variant="outlined"
                            label="Select Service"
                            onChange={handleChange2}
                          >
                            
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem>
                            <MenuItem value={10}>Ten</MenuItem>
                          
                          </Select>
                          <Button variant="outlined" color="primary" className="custom-go-button"   onClick={handleGoButtonClick}

                          >
          Go
        </Button>
                        </FormControl>
                      </Col>

                      <Col xs={12} sm={6} md={4}>
                        <FormControl
                          className="abc55"
                          sx={{ m: 1, width: 300 }}
                        >
                          <InputLabel id="demo-multiple-checkbox-label">
                            Multi Service
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-simple-select"
                            multiple
                            value={personName}
                            onChange={handleChange}
                            input={<OutlinedInput label="Select Service" />}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={MenuProps}
                          >
                            {names.map((name) => (
                              <MenuItem key={name} value={name}>
                                <Checkbox
                                  checked={personName.indexOf(name) > -1}
                                />
                                <ListItemText primary={name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Col>

                      <Col xs={12} sm={6} md={4}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            components={["DatePicker", "DatePicker"]}
                          >
                            <DatePicker
                              className="abc-width"
                              label={"Year"}
                              openTo="year"
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </Col>
                      <Col xs={12} sm={6} md={4}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            components={["DatePicker", "DatePicker"]}
                          >
                            <DatePicker
                              className="abc-width"
                              label={"Month"}
                              openTo="month"
                              views={["year", "month", "day"]}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </Col>
                      <Col xs={12} sm={6} md={4}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            components={[
                              "DatePicker",
                              "DatePicker",
                              "DatePicker",
                            ]}
                          >
                            <DatePicker
                              label={"Day"}
                              views={["year", "month", "day"]}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </Col>

                      {/* <div className="upload-image"></div> */}
                      <Col xs={12} sm={6} md={4}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Control
                            type="file"
                            placeholder="Upload Image"
                            className="upload-image"
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} sm={6} md={4}>
                        <img
                          src="../../assets/images/profile.jpeg"
                          className="upload-image-2"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={6} md={4}>
                        <FormControl>
                          <FormLabel id="demo-radio-buttons-group-label">
                            <b>Service</b>
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                          >
                            <FormControlLabel
                              value="female"
                              control={<Radio />}
                              label="Service 1"
                            />
                            <FormControlLabel
                              value="male"
                              control={<Radio />}
                              label="Service 2"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Col>
                      <Col xs={12} sm={6} md={4}>
                        <FormGroup>
                          <FormLabel id="demo-radio-buttons-group-label">
                            <b>Service</b>
                          </FormLabel>

                          <FormControlLabel
                            control={<Checkbox defaultChecked />}
                            label="Service 1"
                          />
                          <FormControlLabel
                            required
                            control={<Checkbox />}
                            label="Service 2"
                          />
                          <FormControlLabel
                            disabled
                            control={<Checkbox />}
                            label="Service 3"
                          />
                        </FormGroup>
                      </Col>

                      <Col xs={12} sm={6} md={12}>
                        <TextField
                          id="outlined-multiline-static"
                          label="Enter Message"
                          multiline
                          className="addservice-1"
                          rows={4}
                          defaultValue="Default Value"
                        />
                      </Col>

                      <Col xs={12} sm={6} md={12}>
                        <Stack
                          spacing={2}
                          direction="row"
                          className="submit-service"
                        >
                          <Button variant="outlined">Cancel</Button>
                          <Button variant="contained">Submit</Button>
                        </Stack>
                      </Col>
                      <Col xs={12} sm={6} md={12}>
                        <Stack
                          spacing={2}
                          direction="row"
                          className="submit-service-2"
                        >
                          <Button variant="outlined">Cancel</Button>
                          <Button variant="contained">Submit</Button>
                        </Stack>
                      </Col>
                      <Col xs={12} sm={6} md={12}>
                        <Stack
                          spacing={2}
                          direction="row"
                          className="submit-service-3"
                        >
                          <Button variant="contained">Submit</Button>
                        </Stack>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        <div className="admin-body-section admin-div-2">
          <Container fluid>
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="right-side-section">
                  <div className="left-menu-2 menu-left-div-2">
                    <ul>
                      <li>
                        <Link to="">
                          <DashboardIcon className="icon-11" />
                        </Link>
                      </li>
                      <li>
                        <Link to="">
                          <CategoryIcon className="icon-11" />
                        </Link>
                      </li>
                      <li>
                        <Link to="">
                          <StorefrontIcon className="icon-11" />{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="">
                          <StorefrontIcon className="icon-11" />
                        </Link>
                      </li>
                      <li>
                        <Link to="">
                          <EmojiFlagsIcon className="icon-11" />
                        </Link>
                      </li>
                      <li>
                        <Link to="">
                          <TrendingDownIcon className="icon-11" />
                        </Link>
                      </li>
                      <li>
                        <Link to="">
                          <SubdirectoryArrowRightIcon className="icon-11" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/admin/addservice">
                          <SubdirectoryArrowRightIcon className="icon-11" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="menu-right-div-20">
                    <div className="add-sevice-section-2">
                      <Form className="addservice-form">
                        <Row>
                          <Col xs={12} sm={12} md={12}>
                            <h2>Add Service</h2>
                          </Col>
                          <Col xs={12} sm={6} md={4}>
                            <TextField
                              id="outlined-basic"
                              label="Name"
                              variant="outlined"
                              className="addservice-1"
                            />
                          </Col>
                          <Col xs={12} sm={6} md={4}>
                            <TextField
                              id="outlined-basic"
                              label="Mobile"
                              type="number"
                              variant="outlined"
                              className="addservice-1"
                            />
                          </Col>
                          <Col xs={12} sm={6} md={4}>
                            <TextField
                              id="outlined-basic"
                              label="Email"
                              variant="outlined"
                              className="addservice-1"
                            />
                          </Col>
                          <Col xs={12} sm={6} md={4}>
                            <TextField
                              id="outlined-basic"
                              label="Address"
                              variant="outlined"
                              className="addservice-1"
                            />
                          </Col>
                          <Col xs={12} sm={6} md={4}>
                            <FormControl fullWidth>
                              <InputLabel
                                id="demo-simple-select-label"
                                variant="outlined"
                              >
                                Select Service
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={age}
                                variant="outlined"
                                label="Select Service"
                                onChange={handleChange2}
                              >
                                <MenuItem value={10}>Ten</MenuItem>
                                <MenuItem value={20}>Twenty</MenuItem>
                                <MenuItem value={30}>Thirty</MenuItem>
                              </Select>
                            </FormControl>
                          </Col>

                          <Col xs={12} sm={6} md={4}>
                            <FormControl
                              className="abc55"
                              sx={{ m: 1, width: 300 }}
                            >
                              <InputLabel id="demo-multiple-checkbox-label">
                                Multi Service
                              </InputLabel>
                              <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-simple-select"
                                multiple
                                value={personName}
                                onChange={handleChange}
                                input={<OutlinedInput label="Select Service" />}
                                renderValue={(selected) => selected.join(", ")}
                                MenuProps={MenuProps}
                              >
                                {names.map((name) => (
                                  <MenuItem key={name} value={name}>
                                    <Checkbox
                                      checked={personName.indexOf(name) > -1}
                                    />
                                    <ListItemText primary={name} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Col>

                          <Col xs={12} sm={6} md={4}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer
                                components={["DatePicker", "DatePicker"]}
                              >
                                <DatePicker
                                  className="abc-width"
                                  label={"Year"}
                                  openTo="year"
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </Col>
                          <Col xs={12} sm={6} md={4}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer
                                components={["DatePicker", "DatePicker"]}
                              >
                                <DatePicker
                                  className="abc-width"
                                  label={"Month"}
                                  openTo="month"
                                  views={["year", "month", "day"]}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </Col>
                          <Col xs={12} sm={6} md={4}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer
                                components={[
                                  "DatePicker",
                                  "DatePicker",
                                  "DatePicker",
                                ]}
                              >
                                <DatePicker
                                  label={"Day"}
                                  views={["year", "month", "day"]}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </Col>

                          {/* <div className="upload-image"></div> */}
                          <Col xs={12} sm={6} md={4}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Control
                                type="file"
                                placeholder="Upload Image"
                                className="upload-image"
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12} sm={6} md={4}>
                            <img
                              src="../../assets/images/profile.jpeg"
                              className="upload-image-2"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} sm={6} md={4}>
                            <FormControl>
                              <FormLabel id="demo-radio-buttons-group-label">
                                <b>Service</b>
                              </FormLabel>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="female"
                                name="radio-buttons-group"
                              >
                                <FormControlLabel
                                  value="female"
                                  control={<Radio />}
                                  label="Service 1"
                                />
                                <FormControlLabel
                                  value="male"
                                  control={<Radio />}
                                  label="Service 2"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Col>
                          <Col xs={12} sm={6} md={4}>
                            <FormGroup>
                              <FormLabel id="demo-radio-buttons-group-label">
                                <b>Service</b>
                              </FormLabel>

                              <FormControlLabel
                                control={<Checkbox defaultChecked />}
                                label="Service 1"
                              />
                              <FormControlLabel
                                required
                                control={<Checkbox />}
                                label="Service 2"
                              />
                              <FormControlLabel
                                disabled
                                control={<Checkbox />}
                                label="Service 3"
                              />
                            </FormGroup>
                          </Col>

                          <Col xs={12} sm={6} md={12}>
                            <TextField
                              id="outlined-multiline-static"
                              label="Enter Message"
                              multiline
                              className="addservice-1"
                              rows={4}
                              defaultValue="Default Value"
                            />
                          </Col>

                          <Col xs={12} sm={6} md={12}>
                            <Stack
                              spacing={2}
                              direction="row"
                              className="submit-service"
                            >
                              <Button variant="outlined">Cancel</Button>
                              <Button variant="contained">Submit</Button>
                            </Stack>
                          </Col>
                          <Col xs={12} sm={6} md={12}>
                            <Stack
                              spacing={2}
                              direction="row"
                              className="submit-service-2"
                            >
                              <Button variant="outlined">Cancel</Button>
                              <Button variant="contained">Submit</Button>
                            </Stack>
                          </Col>
                          <Col xs={12} sm={6} md={12}>
                            <Stack
                              spacing={2}
                              direction="row"
                              className="submit-service-3"
                            >
                              <Button variant="contained">Submit</Button>
                            </Stack>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )}

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <img src="../../assets/images/logo5.svg" width="50%" />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="left-menu-2">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <ul>
                  <li>
                    <Link to="">
                      <DashboardIcon className="icon-11" />
                      Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <CategoryIcon className="icon-11" />
                      Category
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <StorefrontIcon className="icon-11" />
                      Sub Category
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <StorefrontIcon className="icon-11" />
                      Child Category
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <EmojiFlagsIcon className="icon-11" />
                      Service Country
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <TrendingDownIcon className="icon-11" />
                      Service City
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <SubdirectoryArrowRightIcon className="icon-11" />
                      Service Area
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/addservice">
                      <SubdirectoryArrowRightIcon className="icon-11" />
                      Add Service
                    </Link>
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}
export default AddService;
