import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "react-calendar/dist/Calendar.css";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CallIcon from "@mui/icons-material/Call";
import StarRateIcon from "@mui/icons-material/StarRate";
import RepeatIcon from "@mui/icons-material/Repeat";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import moment from "moment";
import Headerinner from "../Common/Headerinner";
import SideBar from "../Common/SideBar";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import PhoneForwardedOutlinedIcon from "@mui/icons-material/PhoneForwardedOutlined";
import AssistantDirectionOutlinedIcon from "@mui/icons-material/AssistantDirectionOutlined";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
function JobHistory() {
  const [expanded, setExpanded] = React.useState(false);
  const [JobHistoryList, setJobHistory] = useState("");
  const [LoginUser, setLoginUser] = useState([]);
  const [CustomerRemark, setCustomerRemark] = useState([]);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  var userLogininfo = JSON.parse(localStorage.getItem("user-Logininfo"));
  if (userLogininfo === null) {
    window.location.href = "/";
  }
  let authToken = `${process.env.REACT_APP_BASE_AUTHKEY}`;
  let baseURL = `${process.env.REACT_APP_BASE_URL}`;
  let totalAmount = 0;
  useEffect(() => {
    fun_JobHistoryList();
    window.scrollTo(0, 0);
  }, []);
  function fun_JobHistoryList() {
    let newusername = "{ProfessionalID:" + userLogininfo["UserID"] + "}";
    if(userLogininfo["User Group"] === "Employee")
    {
      newusername = "{}";
    }

    const getparam = {
      pgSize: 100,
      filterButtons: "",
      tabErpID: 1258,
      columnID: 1258014,
      statusID: 0,
      statusName: "All",
      color: "#262626",
      index: 1,
      toEmailID: "designMonu@dialerp.com",
      useAtlusSearch: false,
      filters: newusername,
    };
    fetch(baseURL + "/api/dynamic/GetRecords", {
      method: "POST",
      headers: {
        "Authorization-Token": authToken,
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(getparam),
    }).then((result) => {
      result.json().then((resp) => {        
        if (resp.hasErrors === false) {
          if (resp.response?.records !== null) {            
            if (resp.response?.records.length > 0) {
              setJobHistory(resp.response);
              console.log(resp?.response?.records, "Jobhistory");
              // if (resp.response?.records[0]["Customer Remarks"] !=
              //   "[object Object],[object Object]" && resp.response?.records[0]["Customer Remarks"] !== "[object Object]" && resp.response?.records[0]["Customer Remarks"] != null && resp.response?.records[0]["Customer Remarks"] !== undefined && resp.response?.records[0]["Customer Remarks"] !== "") {
              //   setCustomerRemark(JSON.parse(resp.response?.records[0]["Customer Remarks"]));
              // }
            }
          }
        } else {
          alert(resp.errors);
        }
      });
    });
  }

  return (
    <div className="admin-panel-section">
      <Headerinner />
      <div className="admin-body-section admin-div-1">
        <Container fluid>
          <Row>
            <Col xs={12} sm={12} md={3} lg={2}>
              <SideBar />
            </Col>
            <br></br>

            <Col xs={12} sm={12} md={9} lg={10} className="">
              <div className="job-history-section">
                <h2>Job History</h2>

                <Row>
                  {JobHistoryList?.records?.map((status, i) => {
                    const utcDate = new Date(status["Appointment Dt"]);
                    const localDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000);
                    const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false };
                    const formattedDate1 = localDate.toLocaleString('en-US', options);
                    const formattedDate = moment(formattedDate1).format("DD MMM");
                    const formattedTime = moment(formattedDate1).format("hh:mm A");

                    if (status["Customer Remarks"] !=
                      "[object Object],[object Object]" && status["Customer Remarks"] !== "[object Object]" && status["Customer Remarks"] != null && status["Customer Remarks"] !== undefined && status["Customer Remarks"] !== "") {
                      totalAmount = JSON.parse(status["Customer Remarks"]);
                    }
                    return (
                      // <Link to={`/customer/order-details/${status["BookingID"]}`}>

                      <Col xs={12} sm={6} md={4} lg={6}>
                        {/* {CustomerRemark?.CardDetails?.map((payment, i) => {
                           
                            return ( */}
                        <div className="job-history-section-inner">
                          <h3>
                            <Link
                              to={`/common/history-details/${status["BookingID"]}`}
                            >
                              {status["Cust Name"]}
                            </Link>
                          </h3>
                          <h3>{status["Stage"] === "Cancel Service" ?
                            <p className="booking-cancel">{status["Stage"]}</p> :
                            <p className="booking-Accepted">{status["Stage"]}</p>}</h3>
                          <h3>
                            Job Value  <span> ₹{Math.round(parseFloat(totalAmount["BalAmt"]))}</span>
                          </h3>






                          {/* <h3>
                            Job Value : <span> ₹{Math.round(parseFloat(totalAmount["BalAmt"]))}</span>
                          </h3> */}

                          <h4 className="time-value">
                            <AccessTimeOutlinedIcon className="clock-icon-2" />
                            {formattedDate + ' ' + formattedTime}

                          </h4>
                        </div>
                        {/* //   );
                          // })} */}
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
export default JobHistory;
