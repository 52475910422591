import React, { useState, useEffect, useContext } from "react";
import Button from "@mui/material/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "react-calendar/dist/Calendar.css";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import moment from "moment";

import MarkEmailReadSharpIcon from "@mui/icons-material/MarkEmailReadSharp";
import CallSharpIcon from "@mui/icons-material/CallSharp";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import EditIcon from "@mui/icons-material/Edit";
import ManIcon from "@mui/icons-material/Man";
import DateRangeIcon from "@mui/icons-material/DateRange";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import ClearIcon from "@mui/icons-material/Clear";
import Form from "react-bootstrap/Form";
import dayjs from "dayjs";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import axios from "axios";
import Stack from "@mui/material/Stack";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import EditProfile from "./EditProfile";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Header from "../Header";
import Footer from "../Footer";
import FooterMobile from "../FooterMobile";
import FooterAfterLogin from "../FooterAfterLogin";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { ProductContext } from "../context/AppContext";
import { fetchWrapper } from "../apiUtils";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import WomanIcon from "@mui/icons-material/Woman";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function UserProfile() {
  const { handleCloseLoader, handleClickLoader } = useContext(ProductContext);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const LoginEdit = localStorage.getItem("LoginEdit");
  const [ContactName, setContactName] = useState("");
  const [CountryCode, setCountryCode] = useState("");
  const [Mobile, setMobile] = useState("");
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [DOB, setDOB] = React.useState(dayjs("2022-04-17"));
  const [Gender, setGender] = useState("");
  const [UserStatus, setUserStatus] = useState("");
  const [UserGroup, setUserGroup] = useState("");
  const [error, setError] = useState("");
  const [UserID, setUserID] = useState("");
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const [ProfilePic, setProfilePic] = useState(null);
  const [imgurl, setimgurl] = useState("");
  const [ProfileImg, setProfileImg] = useState(null);
  const [DayDOB, setDayDOB] = useState("");
  const [MonthDOB, setMonthDOB] = useState("");
  const [YearDOB, setYearDOB] = useState("");

  const [MonthName, setMonthName] = useState("");
  var userLogininfo = JSON.parse(localStorage.getItem("user-Logininfo"));
  if (userLogininfo === null) {
    window.location.href = "/";
  }
  let authToken = localStorage.getItem("Auth-Token").replace(/"/g, "");
  let baseURL = `${process.env.REACT_APP_BASE_URL}`;
  const [ProfileData, setProfileData] = useState([]);

  function fun_ProfileData(type) {
    handleClickLoader();
    let newusername = `{'LoginMobile':${userLogininfo["Login Mobile"]}}`;
    const loginPra = {
      pgSize: 100,
      index: 1,
      toEmailID: "designMonu@dialerp.com",
      tabErpID: 1429,
      filters: newusername,
    };
    var FullDOB = [];
    let endpointURL = "Dynamic/GetRecords";
    try {
      fetchWrapper(loginPra, endpointURL, authToken).then((resp) => {
        handleCloseLoader();
        if (resp?.errorCode === "HTTP error") {
          alert("Request not valid");
          return;
        }
        if (resp.hasErrors === false) {
          if (resp.response?.records !== null) {
            if (resp.response?.records.length > 0) {
              if (type === "Edit") {
                setContactName(resp.response?.records[0]["User Name"]);
                setCountryCode(resp.response?.records[0]["Country Code"]);
                setEmail(resp.response?.records[0]["Login Email"]);
                setUserID(resp.response?.records[0]["UserID"]);
                setGender(resp.response?.records[0]["Gender"]);
                setUserGroup(resp.response?.records[0]["User Group"]);
                setMobile(resp.response?.records[0]["Login Mobile"]);
                setUserStatus(resp.response?.records[0]["User Status"]);
                setProfilePic(resp.response?.records[0]["ProfilePic"]);
                setimgurl(resp.response?.records[0]["ProfilePic"]);

                try {
                  if (
                    resp?.response?.records[0]["DOB"] !== "" &&
                    resp?.response?.records[0]["DOB"] !== null &&
                    resp?.response?.records[0]["DOB"] !== undefined
                  ) {
                    FullDOB = moment(resp?.response?.records[0]["DOB"])
                      .format("DD-MMM-YYYY")
                      ?.split("-");
                    setDayDOB(FullDOB[0]);
                    setYearDOB(FullDOB[2]);
                    let flist = MonthValue?.filter(
                      (p) => p["Name"] === FullDOB[1]
                    );
                    setMonthDOB(flist[0]["value"]);
                    setMonthName(flist[0]["Name"]);
                  }
                } catch (error) {
                  alert(error);
                }
              } else {
                setProfileData(resp.response);
              }
            }
          }
        } else {
          alert(resp.errors);
        }
      });
    } catch (error) {
      alert(error.message);
    }
  }
  function fun_LoginEditclick(id) {
    // fun_Profile_Edit(id)
    fun_ProfileData("Edit");
    setOpenEditProfile(true);
  }

  useEffect(() => {
    fun_ProfileData("get");
    window.scrollTo(0, 0);
  }, []);

  const [openEditProfile, setOpenEditProfile] = React.useState(false);

  const handleCloseEditProfile = () => {
    setOpenEditProfile(false);
  };
  //
  function handleKeyPress(e) {
    var key = e.key;
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      e.preventDefault();
    }
  }
  const MonthValue = [
    { Name: "Jan", value: "1", fullName: "January" },
    { Name: "Feb", value: "2", fullName: "February" },
    { Name: "Mar", value: "3", fullName: "March" },
    { Name: "Apr", value: "4", fullName: "April" },
    { Name: "May", value: "5", fullName: "May" },
    { Name: "Jun", value: "6", fullName: "June" },
    { Name: "Jul", value: "7", fullName: "July" },
    { Name: "Aug", value: "8", fullName: "August" },
    { Name: "Sep", value: "9", fullName: "September" },
    { Name: "Oct", value: "10", fullName: "October" },
    { Name: "Nov", value: "11", fullName: "November" },
    { Name: "Dec", value: "12", fullName: "December" },
  ];

  function handleSubmit(e) {
    let flist = MonthValue?.filter((p) => p["value"] === e.target.value);
    setMonthDOB(flist[0]["value"]);
    setMonthName(flist[0]["Name"]);
  }
  function isValidDate(d, m, y) {
    var day = parseInt(d);
    var month = parseInt(m);
    var year = parseInt(y);

    if (isNaN(day) || isNaN(month) || isNaN(year)) {
      return false;
    }
    if (day < 1 || year < 1) return false;
    if (month > 12 || month < 1) return false;
    if (
      (month === 1 ||
        month === 3 ||
        month === 5 ||
        month === 7 ||
        month === 8 ||
        month === 10 ||
        month === 12) &&
      day > 31
    )
      return false;
    if ((month === 4 || month === 6 || month === 9 || month === 11) && day > 30)
      return false;
    if (month === 2) {
      if (
        (year % 4 === 0 && year % 100 !== 0) ||
        (year % 400 === 0 && year % 100 === 0)
      ) {
        if (day > 29) {
          return false;
        }
      } else {
        if (day > 28) return false;
      }
    }
    return true;
  }
  const MobileRegExp = new RegExp("^[0-9]{10}$");
  const yearRegExp = new RegExp("^[0-9]{4}$");
  function fun_Profile_Update() {
    var current_year = new Date().getFullYear();
    let validdateData = isValidDate(DayDOB, MonthDOB, YearDOB);
    const fullage = DayDOB + "-" + MonthName + "-" + YearDOB;
    var age = Math.floor(
      (new Date() - new Date(fullage)) / 1000 / 60 / 60 / 24 / 365.25
    );
    if (ContactName === "") {
      setError("required");
      return;
    } else if (Email === "" || Email === undefined) {
      setError("required");
      return;
    } else if (!emailRegex.test(Email)) {
      setError("required");
      return;
    } else if (CountryCode.toString() === "") {
      setError('required"');
      return;
    } else if (Mobile === "") {
      setError('required"');
      return;
    } else if (!MobileRegExp.test(Mobile)) {
      setError("required");
      return;
    } else if (DayDOB === "") {
      setError("required");
      return;
    } else if (MonthDOB === "") {
      setError("required");
      return;
    } else if (YearDOB === "") {
      setError("required");
      return;
    } else if (!yearRegExp.test(YearDOB)) {
      setError("required");
      return;
    } else if (YearDOB < 1950 || YearDOB > current_year) {
      setError("Year should be in range 1950 to current year");
      return false;
    } else if (validdateData === false) {
      setError("Please Enter Valid Day");
      return;
    } else if (age < 18) {
      setError("Enter age must be 18.");
      return;
    } else if (Gender === "") {
      setError('required"');
      return;
    } else {
      handleClickLoader();
      const formData = [
        {
          FullName: ContactName,
          CountryCode: CountryCode,
          UserName: ContactName,
          LoginMobile: parseInt(Mobile),
          UserGroup: UserGroup,
          UserStatus: UserStatus,
          rByMobile: "",
          Gender: Gender,
          cDt: "",
          UserID: UserID,
          LoginPassword: Mobile,
          DOB: DayDOB + "-" + MonthName + "-" + YearDOB,
          LoginEmail: Email,
          DOA: "",
          rByUserID: "",
          rByUser: "",
          ProfilePic: imgurl,
        },
      ];
      let endpointURL = "Dynamic/AddUpdateRecords?LoginType=2&tabErpID=1429";
      try {
        fetchWrapper(formData, endpointURL, authToken).then((resp) => {
          handleCloseLoader();
          if (resp?.errorCode === "HTTP error") {
            alert("Request not valid");
            return;
          }
          if (resp.hasErrors === false) {
            alert("Profile update successfully.");
            fun_ProfileData("get");
            handleCloseEditProfile();
          }
        });
      } catch (error) {
        alert(error.message);
      }
    }
  }
  const [file, setFile] = useState("");
  const handleFile = (e, column) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        if (img.naturalWidth === 400 && img.naturalHeight === 400) {
          ProfileImgUpload(e.target.files[0], column);
        } else {
          alert("Image dimensions must be 400x400 pixels.");
        }
      };
      img.src = event.target.result;
    };

    reader.readAsDataURL(file);
  };
  const ProfileImgUpload = (e, column) => {
    setFile(URL.createObjectURL(e));
    const formData = new FormData();
    formData.append(column, e);

    axios
      .post(
        "https://api.dialerp.com/api/api/UploadCommonFilesToAzure",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization-Token": authToken,
          },
        }
      )
      .then((response) => {
        if (response.data.hasErrors === true) {
          alert(response.data.errors);
        } else if (response.data.response !== null) {
          setimgurl(response.data.response[0].imageUrl);
        }
      })
      .catch((err) => alert(err));
  };

  return (
    <div>
      {/* Web Version */}
      <div className="web-view background-my-booking">
        <Header />
        <Link
          to="/customer/EditProfile"
          id="btnEdit"
          style={{ display: "none" }}
        ></Link>
        <div className="top-space top-margin">
          <div className="my-orders">
            <Container>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <h2>My Profile</h2>
                  <Card className="my-order-inner my-order-inner-5 service-booking-cerd-5 address-active">
                    <div className="booking-div-19 profile-edit-div">
                      {ProfileData?.records?.map((status, i) => {
                        return (
                          <Row>
                            <Col xs={12} sm={6} md={4} lg={3}>
                              {status?.["ProfilePic"] !== undefined &&
                              status?.["ProfilePic"] !== null ? (
                                <img
                                  src={status["ProfilePic"]}
                                  className="profile-pic-user"
                                />
                              ) : (
                                <img src="../../assets/images/profile-icon.png" />
                              )}

                              {/* <img src={status["Profile Pic"]} className="profile-pic-user" /> */}
                            </Col>
                            <Col xs={12} sm={6} md={8} lg={9}>
                              <h2 className="profile-edit-heading">
                                {status["User Name"]}
                              </h2>

                              {status?.["Login Email"] && (
                                <h3>
                                  <MarkEmailReadSharpIcon className="profile-add-icon-5" />{" "}
                                  {status?.["Login Email"]}{" "}
                                </h3>
                              )}

                              <h3>
                                <CallSharpIcon className="profile-add-icon-5" />{" "}
                                +{status["Country Code"]}-
                                {status["Login Mobile"]}
                              </h3>
                              {/* <h3><ManIcon className="profile-add-icon-5" />{status["Gender"]}</h3> */}
                              {/* {status?.["Gender"] && (
                                <h3>
                                  <WomanIcon className="profile-add-icon-5" />
                                  <ManIcon className="profile-add-icon-5" />
                                  {status?.["Gender"]}{" "}
                                </h3>
                              )} */}
                              {status?.["Gender"] && (
                                <h3>
                                  {status["Gender"] === "Male" ? (
                                    <ManIcon className="profile-add-icon-5" />
                                  ) : (
                                    <WomanIcon className="profile-add-icon-5" />
                                  )}
                                  {status?.["Gender"]}
                                </h3>
                              )}
                              {status?.["DOB"] && (
                                <h3>
                                  <CalendarMonthIcon className="profile-add-icon-5" />{" "}
                                  {moment(status["DOB"]).format("DD-MMM-YYYY")}{" "}
                                </h3>
                              )}
                              {/* <h3><DateRangeIcon className="profile-add-icon-5" /> {moment(status["DOB"]).format("DD-MMM-YYYY")}</h3> */}

                              <img
                                src="../../assets/images/edit3.png"
                                alt="icon"
                                onClick={() =>
                                  fun_LoginEditclick(status["Login Mobile"])
                                }
                                className="edit-pic-1"
                              />
                              {/* <img src="../../assets/images/check2.png" className="edit-pic-2" /> */}
                            </Col>
                          </Row>
                        );
                      })}
                    </div>

                    {/* <CheckBoxOutlinedIcon className="profile-status" /> */}
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <FooterAfterLogin />
      </div>
      {/* Web Version */}

      {/* Mobile Version */}
      <div className="mobile-view">
        <div className="my-orders">
          <h2 className="mobile-header-1">
            <Link to="/">
              <ArrowBackIcon className="mobile-header-1-left-icon" />
            </Link>
            My Profile
            <span className="mobile-search-icon-1">
              <SearchOutlinedIcon />
            </span>
          </h2>
          <div className="top-space-mobile-profile">
            <Container fluid>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <Card className="my-order-inner my-order-inner-5 service-booking-cerd-5 address-active">
                    <div className="booking-div-19 profile-edit-div">
                      {ProfileData?.records?.map((status, i) => {
                        return (
                          <Row>
                            <Col xs={12} sm={6} md={4} lg={3}>
                              <img
                                src={status["ProfilePic"]}
                                className="profile-pic-user profile-pic-user-22"
                              />
                            </Col>
                            <Col xs={12} sm={6} md={8} lg={9}>
                              <h2 className="profile-edit-heading">
                                {status["User Name"]}
                              </h2>
                              {status?.["Login Email"] && (
                                <h3>
                                  <MarkEmailReadSharpIcon className="profile-add-icon-5" />{" "}
                                  {status["Login Email"]}
                                </h3>
                              )}
                              <h3>
                                <CallSharpIcon className="profile-add-icon-5" />{" "}
                                +{status["Country Code"]}-
                                {status["Login Mobile"]}
                              </h3>
                              {status?.["Gender"] && (
                                <h3>
                                  <ManIcon className="profile-add-icon-5" />
                                  {status["Gender"]}
                                </h3>
                              )}
                              {status?.["DOB"] && (
                                <h3>
                                  <DateRangeIcon className="profile-add-icon-5" />{" "}
                                  {moment(status["DOB"]).format("DD-MMM-YYYY")}
                                </h3>
                              )}

                              <img
                                src="../../assets/images/edit3.png"
                                alt="icon"
                                onClick={() =>
                                  fun_LoginEditclick(status["Login Mobile"])
                                }
                                className="edit-pic-1"
                              />
                              {/* <img src="../../assets/images/check2.png" className="edit-pic-2" /> */}
                            </Col>
                          </Row>
                        );
                      })}
                    </div>

                    {/* <CheckBoxOutlinedIcon className="profile-status" /> */}
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <FooterMobile />
      </div>

      {/* Mobile Version */}
      <Dialog
        open={openEditProfile}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleCloseEditProfile}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className="section-step-1 section-step-12">
          <h2>
            Edit Profile
            <ClearIcon
              className="cross-icon"
              onClick={handleCloseEditProfile}
            />
          </h2>
          <hr />

          <div className="">
            <div className="form-section">
              <Row>
                <Col xs={12} sm={8} md={8}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Control
                      type="file"
                      placeholder="Upload Image"
                      className="upload-image-22"
                      onChange={(e) => {
                        handleFile(e, "ProfileImg");
                      }}
                      name="attachments[]"
                      accept="image/*, application/pdf"
                      id="upload"
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={4} md={4}>
                  {imgurl != null ? (
                    <img
                      src={imgurl}
                      alt="Profile Image"
                      className="upload-image-2"
                    />
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
              <div className="input-spce-bottom">
                {/* <TextField
                  id="outlined-basic"
                  className={`width-100 ${
                    error && ContactName === "" ? "is-invalid" : ""
                  }`}
                  label="Contact Name *"
                  defaultValue="ContactName"
                  value={ContactName}
                  onChange={(e) => {
                    setContactName(e.target.value);
                  }}
                  variant="outlined"
                /> */}
                <TextField
                  id="outlined-basic"
                  className={`width-100 ${error && ContactName === "" ? "is-invalid" : ""
                    }`}
                  label={<span>Contact Name<span style={{ color: 'red' }}>*</span></span>}
                  defaultValue="ContactName"
                  value={ContactName}
                  onChange={(e) => {
                    setContactName(e.target.value);
                  }}
                  variant="outlined"
                />

                {error && ContactName === "" && (
                  <div className="invalid-feedback">
                    Please enter Contact Name
                  </div>
                )}
              </div>
              <div className="input-spce-bottom">
                <TextField
                  id="outlined-basic"
                  className={`width-100 ${
                    error && (Email === "" || !emailRegex.test(Email))
                      ? "is-invalid"
                      : ""
                  }`}
                  label={<span>Email<span style={{ color: 'red' }}>*</span></span>}
                  // label="Email"
                  defaultValue="Email"
                  value={Email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  variant="outlined"
                />
                {error && Email === "" && (
                  <div className="invalid-feedback">Please enter Email</div>
                )}
                {error && Email !== "" && !emailRegex.test(Email) && (
                  <div className="invalid-feedback">
                    Please enter a valid email address
                  </div>
                )}
              </div>
              <div className="input-spce-bottom">
                <TextField
                  id="outlined-basic"
                  className={`width-100  ${
                    (error && Mobile === "") || !MobileRegExp.test(Mobile)
                      ? "is-invalid"
                      : ""
                  }`}
                 
                   label="Mobile"
                  defaultValue="Mobile"
                  value={Mobile}
                  variant="outlined"
                  disabled
                />

                {error && Mobile === "" && (
                  <div className="invalid-feedback">Please enter Mobile</div>
                )}
                {error && Mobile !== "" && !MobileRegExp.test(Mobile) && (
                  <div className="invalid-feedback">
                    Please enter a valid 10 digits mobile no
                  </div>
                )}
              </div>
              <Row>
                <Col xs={4} sm={4} md={4} lg={4}>
                  <div className="input-spce-bottom">
                    <TextField
                      id="outlined-basic"
                      className={`width-100 lgoin-input-6  ${
                        (error && DayDOB === "") ||
                        error === "Please Enter Valid Day" ||
                        error === "Enter age must be 18."
                          ? "is-invalid"
                          : ""
                      }`}
                      label={<span>Day<span style={{ color: 'red' }}>*</span></span>}
                      // label="Day"
                      type="text"
                      variant="outlined"
                      value={DayDOB}
                      inputProps={{ maxLength: 2 }}
                      onKeyPress={(e) => handleKeyPress(e)}
                      onChange={(e) => {
                        setDayDOB(e.target.value);
                      }}
                    />
                    {(error && DayDOB === "" && (
                      <div className="invalid-feedback">
                        Please enter valid Day
                      </div>
                    )) ||
                      (error === "Please Enter Valid Day" && (
                        <div className="invalid-feedback">
                          Please Enter Valid Day
                        </div>
                      )) ||
                      (error === "Enter age must be 18." && (
                        <div className="invalid-feedback">
                          Enter age must be 18.
                        </div>
                      ))}
                  </div>
                </Col>

                <Col xs={4} sm={4} md={4} lg={4}>
                  <div className="input-spce-bottom">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-labe">
                        Month<span style={{ color: 'red' }}>*</span>
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        className={`width-100 lgoin-input-6 form-control-bb lgoin-input-6 ${
                          error && MonthDOB === "" ? "is-invalid" : ""
                        }`}
                        label="Month"
                        value={MonthDOB}
                        // onChange={(e) => {
                        //   setMonthDOB(e.target.value);
                        // }}
                        onChange={handleSubmit}
                      >
                        {MonthValue.map((dd, i) => {
                          return (
                            <MenuItem value={dd["value"]}>
                              {dd["Name"]}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {error && MonthDOB === "" && (
                        <div className="invalid-feedback">
                          Please select valid Month
                        </div>
                      )}
                    </FormControl>
                  </div>
                </Col>
                <Col xs={4} sm={4} md={4} lg={4}>
                  <div className="input-spce-bottom">
                    <TextField
                      id="outlined-basic"
                      className={`width-100 lgoin-input-6  ${
                        (error && YearDOB === "") ||
                        !yearRegExp.test(YearDOB) ||
                        error === "Year should be in range 1950 to current year"
                          ? "is-invalid"
                          : ""
                      }`}
                      label={<span>Year<span style={{ color: 'red' }}>*</span></span>}
                      // label="Year"
                      type="text"
                      variant="outlined"
                      value={YearDOB}
                      onKeyPress={(e) => handleKeyPress(e)}
                      inputProps={{ maxLength: 4 }}
                      onChange={(e) => {
                        setYearDOB(e.target.value);
                      }}
                    />
                    {error && YearDOB === "" && (
                      <div className="invalid-feedback">
                        Please enter valid Year
                      </div>
                    )}

                    {error && YearDOB !== "" && !yearRegExp.test(YearDOB) && (
                      <div className="invalid-feedback">
                        Please enter a valid 4 digits year
                      </div>
                    )}
                    {error ===
                      "Year should be in range 1950 to current year" && (
                      <div className="invalid-feedback">
                        Year should be in range 1950 to current year
                      </div>
                    )}
                  </div>
                </Col>
              </Row>

              <div className="input-spce-bottom ">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Gender<span style={{ color: 'red' }}>*</span></InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    className={`form-control-bb ${
                      error && Gender === "" ? "is-invalid" : ""
                    }`}
                    value={Gender}
                    onChange={(e) => {
                      setGender(e.target.value);
                    }}
                    
                     label="Gender"
                    // onChange={handleChange}
                  >
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                  </Select>
                  {error && Gender === "" && (
                    <div className="invalid-feedback">Please select gender</div>
                  )}
                </FormControl>
              </div>

              <Stack spacing={2} direction="row" className="submit-service">
                <Button
                  variant="outlined"
                  className="div-border-color"
                  onClick={handleCloseEditProfile}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className="next-button-7 "
                  onClick={fun_Profile_Update}
                >
                  {" "}
                  Update{" "}
                </Button>
              </Stack>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default UserProfile;
