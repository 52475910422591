import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Card from "react-bootstrap/Card";
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import GradeIcon from '@mui/icons-material/Grade';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import TextField from '@mui/material/TextField';
import { Link } from "react-router-dom";
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import PinDropIcon from '@mui/icons-material/PinDrop';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import ClearIcon from "@mui/icons-material/Clear";
import Slide from '@mui/material/Slide';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import AddAddress from "./AddAddress";
import Header from "../Header";
import Footer from "../Footer";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FooterMobile from "../FooterMobile";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import FooterAfterLogin from "../FooterAfterLogin";
import { ProductContext } from "../context/AppContext";
import { fetchApiWithLoginToken } from '../apiUtils';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function BillingAddress() {
  const { handleClickLoader, handleCloseLoader } = useContext(ProductContext);
  //  localStorage.removeItem("ADDID");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  var userLogininfo = JSON.parse(localStorage.getItem("user-Logininfo"));
  if (userLogininfo === null) {
    window.location.href = '/';
  }

  //let authToken = `${process.env.REACT_APP_BASE_AUTHKEY}`;
  let authToken = localStorage.getItem("Auth-Token").replace(/"/g, '');
  let baseURL = `${process.env.REACT_APP_BASE_URL}`;
  let baseTokenKey = `${process.env.REACT_APP_BASE_KEY}`.replace(/'/g, '');
  const [BillingAddressList, setBillingAddressList] = useState([]);
  function fun_getAddAddressListlick(id) {
    localStorage.setItem('BillingId', JSON.stringify(id));
    localStorage.setItem('ListBillint', JSON.stringify("EditBillingAddress"));
    handleClickOpenCustomerRagistration();
  }


  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  function generateString(length) {
    let result = ' ';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }
  function fun_getDefaultAddress(id) {
    handleClickLoader();
    var rand = generateString(6)
    var process = 0;
    BillingAddressList?.records?.map((status, i) => {
      process = 1;
      if (status["Landmark"] === "Active") {
        const formData = [{
          "LedgerID": status["_id"],
          "LedgerName": status["Ledger Name"].trim(),
          "LedgerGroup": status["Ledger Group"], "LedgerGroupID": status["Ledger GroupID"],
          "UnderLedgerGroup": status["Under Ledger Group"], "UnderLedgerGroupID": status["Under Ledger Group ID"],
          "Status": "Deactive",
          "ContactName": status["Contact Name"],
          "ContactMobile": status["Contact Mobile"],
          "ContactEmail": status["Contact Email"],
          "AddressLine1": status["Address"],
          "AddressType": status["Address Type"],
          "Pincode": status["Pincode"],
          "City": status["City"], 
          "State": status["State"], 
          "StateCode": status["State Code"],
          "Country": status["Country"], //"CountryID": 1,
          "LoginMobile": status["Login Mobile"],
          "UserName": status["User Name"],
          "UserID": status["UserID"],
          "UserGroup": status["User Group"],
          "UserGroupID": status["User GroupID"],
          "Gender": status["Gender"],
        }]
        try {
          fetchApiWithLoginToken(formData, "Dynamic/AddUpdateRecords?LoginType=2&tabErpID=1760")
            .then((resp) => {
              if (resp?.errorCode === "HTTP error") {
                alert("Request not valid");
                handleCloseLoader();
                return;
              }
              if (resp.hasErrors === false) {
              }
              else {
                handleCloseLoader();
                alert(resp.errors);
                return
              }
            });

        } catch (error) {
          alert(error.message);
        }
      }
    })
    if (process === 1) {
      const formDataActive =
        [{ "AddressID": id,"LedgerName": rand,"Landmark": "Active", }]
      fetchApiWithLoginToken(formDataActive, "Dynamic/AddUpdateRecords?LoginType=2&tabErpID=1760")
        .then((resp) => {
          handleCloseLoader();
          if (resp.hasErrors === false) {
            setissuccess(true)
            setsuccessmsg("Default address changed")
            setTimeout(() => {
              setissuccess(false);
            }, 3000);
          //  alert("Default address changed")
            fun_CandidateAddress();
          }
          else {
            alert(resp.errors);
          }
        });
      // });
    }
  }

    useEffect(() => {
      fun_CandidateAddress();
    }, [0]);
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [BillingAddressList]);
    
    function fun_CandidateAddress() {
      handleClickLoader();
      window.scrollTo(0, 0);
      let newusername = `{'LoginMobile':${userLogininfo["Login Mobile"]}}`;
      const loginPra = {
        "pgSize": 100,
        "index": 1,
        "toEmailID": "designMonu@dialerp.com",
        //"tabErpID": 749,
        "tabErpID": 1452,
        "filters": newusername
      }
      fetch(
        baseURL + "/api/dynamic/GetRecords",
        {
          method: "POST",
          headers: {
            "Authorization-Token": authToken,
            Accept: "application/json, text/plain",
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: JSON.stringify(loginPra),
        }
      ).then((result) => {
        result.json().then((resp) => {
          handleCloseLoader();
          if (resp.hasErrors === false) {
            if (resp.response?.records !== null) {
              if (resp.response?.records.length > 0) {
                setBillingAddressList(resp.response);
              }
            }
          }
          else {
            alert(resp.errors);
          }
        });
      });
    }

    const [openCustomerRagistration, setOpenCustomerRagistration] = React.useState(false);
    const handleClickOpenCustomerRagistration = () => {
      setOpenCustomerRagistration(true);
      localStorage.setItem('myAddress', JSON.stringify("Using Login"));
    };

    const handleCloseCustomerRagistration = () => {
      setOpenCustomerRagistration(false);
    };
    const [issuccess, setissuccess] = useState(false)
    const [successmsg, setsuccessmsg] = useState(false)
    return (
      <div>

        {/* Web Version */}
        <div className="web-view background-my-booking">
          <Header />
          <div className="top-space top-margin">
            {issuccess === true && <Alert className="alert-my-address" icon={<CheckIcon fontSize="inherit" />} severity="success">{successmsg}!</Alert>}
            <div className="my-address">

              <Container>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <h2>Billing Address</h2>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12}>
                  {BillingAddressList?.records == null ? (
                    <>
                    <hr/>
                        <div className="wishlist-div-5">
                          <img src="../../assets/images/norecord.png" style={{ width: "10%", margin: "-10px" }} alt="Icon" />
                          <p>No Record Found...</p>
                        </div>
                    </>
                  ) : (
                    <>
                    {BillingAddressList?.records?.sort((a, b) => a["Landmark"].localeCompare(b["Landmark"])).map((status, i) => {
                      return (
                        <div>
                          <Card className="service-booking-cerd-5">
                            <div className="left-view-booking-1 left-view-address-1 address-active">
                              <LocationOnIcon className="address-icon-1" />
                              <div className="width-100">
                                <Row>

                                  <Col xs={12} sm={12} md={6} lg={8}>
                                    <p className="address-background-1"><span>{status["Address Type"]}</span></p>
                                    <p><b>{status["Contact Name"]} <span>{status["Mobile"]}</span></b></p>
                                    <p>{status["Address"]}, {status["City"]} ({status["State"]}). {status["Country"]} {status["Pincode"]}</p>
                                  </Col>

                                  <Col xs={12} sm={12} md={6} lg={4}>
                                    <span className="right-side-1">
                                      {/* {status["Status"] !== "Active" &&
                                        <Button className="next-button-10" onClick={() => fun_getDefaultAddress(status["_id"])}>Default Address</Button>
                                      } */}
                                      <Button className="next-button-10"
                                        onClick={() => { fun_getAddAddressListlick(status["_id"]) }}>
                                        Edit</Button>
                              
                                    </span>
                                  </Col>
                                </Row>
                              </div>

                            </div>

                          </Card>
                        </div>
                      );
                    })}
                    </>)}
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
          <FooterAfterLogin />
        </div>
        {/* Mobile Version */}
        <div className="mobile-view">
          {/* <div className="top-space top-margin"> */}
          <div className="my-orders">
            <h2 className="mobile-header-1"><Link to="/"><ArrowBackIcon className="mobile-header-1-left-icon" /></Link>Manage Address
              <span className="mobile-search-icon-1"><SearchOutlinedIcon /></span></h2>
            <div className="top-space-mobile">
              <Container>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <h2>Billing Address</h2>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    {BillingAddressList?.records?.map((status, i) => {
                      return (
                        <div>
                          <Card className="service-booking-cerd-5">
                            <div className="left-view-booking-1 left-view-address-1 address-active">
                              <LocationOnIcon className="address-icon-1" />
                              <div className="width-100">
                                <Row>

                                  <Col xs={12} sm={12} md={6} lg={8}>
                                    <p className="address-background-1"><span>{status["Address Type"]}</span></p>
                                    <p><b>{status["Contact Name"]} <span>{status["Mobile"]}</span></b></p>
                                    <p>{status["Address Line 1"]}, {status["City"]} ({status["State"]}), {status["Country"]} {status["Pincode"]}</p>
                                  </Col>

                                  <Col xs={12} sm={12} md={6} lg={4}>
                                    <span className="right-side-1">
                                      {status["Landmark"] !== "Active" &&
                                        <Button className="next-button-10" onClick={() => fun_getDefaultAddress(status["_id"])}>Default Address</Button>
                                      }
                                      <Button className="next-button-10"
                                        onClick={() => { fun_getAddAddressListlick(status["_id"]) }}>
                                        Edit</Button>
                                   
                                    </span>
                                  </Col>
                                </Row>
                              </div>

                            </div>

                          </Card>
                        </div>
                      );
                    })}
                  </Col>
                </Row>
              </Container>
              {/* </div> */}
            </div>
          </div>
          <FooterMobile />

        </div>
        {/* Mobile Version */}
        <Dialog
          fullScreen={fullScreen}
          open={openCustomerRagistration}
          // onClose={handleCloseCustomerRagistration}
          aria-labelledby="responsive-dialog-title"
          className='login-popup-1'
          BackdropProps={{
            style: { backgroundColor: 'rgba(0, 0, 0, 0.9)' },
          }}
        >
          <CloseIcon className='cross-login-5' onClick={handleCloseCustomerRagistration} />
          <AddAddress />

        </Dialog>
      </div>
    );
  }

  export default BillingAddress