import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
import StarsIcon from "@mui/icons-material/Stars";
import Button from "@mui/material/Button";
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import PinDropIcon from '@mui/icons-material/PinDrop';
import ListAltIcon from '@mui/icons-material/ListAlt';
import WalletIcon from '@mui/icons-material/Wallet';
import LogoutIcon from '@mui/icons-material/Logout';
import DeliveryAddress from "./DeliveryAddress";
import MyAddress from "./MyAddress";
import MyWallet from "./MyWallet";
import MyOrder from "./MyOrder";
function ViewDetails() {
  return (
    <>
    <div className="order-details order-details-new">
      <Header />
      <div className="top-space">
        <Container fluid>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <div className="section-order-details section-order-details-orderdetail">
                
                <h2 className="service-detail-12">
          Basin & Sink
          <span></span>
      </h2><div className="section-service section-service-12">
              <div className="div-service-1 div-service-10">
                  <Row>
                      <Col xs={12} sm={12} md={12}>
                          <h3 className="cs-padding-1 cs-padding-11">Basin & Sink</h3>
                          <div className="inner-service-1 inner-service-11">
                              <Row>
                                  <Col xs={7} sm={12} md={7} lg={7}>
                                      <p className="service-warrantry">Basin & Sink</p>
                                      <h3>Basin & Sink</h3>
                                      <p className="rating-star rating-star-5">
                                          <StarsIcon className="star-icon3" />
                                          4.84 (6.4 M Bookings)
                                      </p>
                                      <p>₹699</p>
                                      <ul className="web-view">
                                          <li>Repair or replacement of one waste pipe</li>
                                          <li>Repair or replacement of one waste pipe</li>
                                          <li>Repair or replacement of one waste pipe</li>
                                          <li>Repair or replacement of one waste pipe</li>
                                      </ul>
                                  </Col>

                                  <Col xs={5} sm={12} md={5} lg={5}>
                                      <div className="service-images-2">
                                          <img
                                              src="../../assets/images/most-book/1.jpg"
                                              width="100%"
                                              alt="Icon" />

                                          <Button
                                              className="service-images-2-button"
                                              variant="outlined"
                                          >
                                              Add
                                          </Button>
                                      </div>
                                  </Col>
                                  <div className="inner-service-1 inner-service-15 mobile-view">
                                      <ul>
                                          <li>Repair or replacement of one waste pipe</li>
                                          <li>Repair or replacement of one waste pipe</li>
                                          <li>Repair or replacement of one waste pipe</li>
                                          <li>Repair or replacement of one waste pipe</li>
                                      </ul>
                                  </div>
                              </Row>
                          </div>
                      </Col>
                  </Row>
              </div>
          </div>
          </div>
          </Col>
          </Row>
          </Container>
          </div>
          </div>

          
          </>
  )
}

export default ViewDetails