/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import Button from "@mui/material/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "react-calendar/dist/Calendar.css";
import Card from "react-bootstrap/Card";
import { Link, json, useParams } from "react-router-dom";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CallIcon from "@mui/icons-material/Call";
import StarRateIcon from "@mui/icons-material/StarRate";
import RepeatIcon from "@mui/icons-material/Repeat";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import moment from "moment";
import Headerinner from "../Common/Headerinner";
import SideBar from "../Common/SideBar";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import PhoneForwardedOutlinedIcon from "@mui/icons-material/PhoneForwardedOutlined";
import AssistantDirectionOutlinedIcon from "@mui/icons-material/AssistantDirectionOutlined";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import { ProductContext } from "../context/AppContext";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import ClearIcon from "@mui/icons-material/Clear";
import Calendar from "react-calendar";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { fetchApiWithLoginToken } from "../apiUtils";
import PersonIcon from "@mui/icons-material/Person";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function Todayjobs() {
  const { Type } = useParams();
  const [eventtype, seteventtype] = useState(Type);
  const {
    setCancellationReason,
    CancellationReason,
    date,
    showTime,
    handleClickLoader,
    handleCloseLoader,openUserOTP, setOTP, setMobile, setOpenUserOTP, setStartJOB, setEndJOB, IsJOBStart, StartJOB,fun_JobStart,StartJobVerfiy
  } = useContext(ProductContext);
  const [expanded, setExpanded] = React.useState(false);
  const [Todayjobs, setTodayjobs] = useState([]);
  const [LoginUser, setLoginUser] = useState([]);
  const [CustomerRemark, setCustomerRemark] = useState([]);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  var userLogininfo = JSON.parse(localStorage.getItem("user-Logininfo"));
  if (userLogininfo === null) {
    window.location.href = "/";
  }

  let authToken = `${process.env.REACT_APP_BASE_AUTHKEY}`;
  let baseURL = `${process.env.REACT_APP_BASE_URL}`;
  let datetyepe = "";
  let totalAmount = 0;
  useEffect(() => {
    fun_todayJobs();
  }, []);
  function fun_todayJobs() {
    handleClickLoader();
    window.scrollTo(0, 0);
    // console.log(userLogininfo["Login Mobile"],"userLogininfo")
    // let newusername = '{UserEmail:/^' + userLogininfo["UserEmail"] + '/i}';
    const getparam = {
      pgSize: 100,
      filterButtons: "",
      tabErpID: 1258,
      columnID: 1258014,
      statusID: 0,
      statusName: "All",
      color: "#262626",
      index: 1,
      toEmailID: "designMonu@dialerp.com",
      useAtlusSearch: true,
      // "filters": `{'ProfessionalMobile':'${userLogininfo["Login Mobile"]}'}`
      filters: "{}",
    };

    fetchApiWithLoginToken(getparam, "dynamic/GetRecords").then((resp) => {
      if (resp.hasErrors === false) {
        if (resp.response?.records !== null) {
          if (resp.response?.records.length > 0) {
            const res = resp?.response?.records
              ?.slice(0)
              .sort((a, b) =>
                a["Appointment Dt"].localeCompare(b["Appointment Dt"])
              );
            setTodayjobs(res);
            console.log("Booking Data",res);
            if (userLogininfo?.["User Group"] === "Employee") {
              let UserWiseData = resp?.response?.records; //?.filter(p => p["ProfessionalID"] === userLogininfo["UserID"]) || [];
              setLoginUser(UserWiseData);
              // if ( resp.response?.records[0]["Customer Remarks"] !== "[object Object],[object Object]"
              //   && resp.response?.records[0]["Customer Remarks"] !== "[object Object]"
              //   && resp.response?.records[0]["Customer Remarks"] != null
              //   && resp.response?.records[0]["Customer Remarks"] !== undefined
              //   && resp.response?.records[0]["Customer Remarks"] !== "") {
              //   setCustomerRemark(JSON.parse(resp.response?.records[0]["Customer Remarks"]));
              // }
            } else {
              let UserWiseData =
                resp?.response?.records?.filter(
                  (p) => p["ProfessionalID"] === userLogininfo["UserID"]
                ) || [];
              setLoginUser(UserWiseData);
              // if (resp.response?.records[0]["Customer Remarks"] !== "[object Object],[object Object]"
              //&& resp.response?.records[0]["Customer Remarks"] !== "[object Object]"
              //&& resp.response?.records[0]["Customer Remarks"] != null
              //&& resp.response?.records[0]["Customer Remarks"] !== undefined
              //&& resp.response?.records[0]["Customer Remarks"] !== "") {
              // setCustomerRemark(JSON.parse(resp.response?.records[0]["Customer Remarks"]));
              // }
            }
          }
        }
      } else {
        alert(resp.errors);
      }
      handleCloseLoader();
    });
  }

  function fun_JobAccept(id) {
    handleClickLoader();
    const formData = [
      {
        BookingID: id,
        Status: "Open",
        Stage: "Professional Assigned",
        ProfessionalMobile: userLogininfo["Login Mobile"],
        ProfessionalID: userLogininfo["UserID"],
        Professional: userLogininfo["User Name"],
        ProfessionalGroup: userLogininfo["User Group"],
        ProfessionalAssignedDt:
          moment(date).format("DD-MMM-YYYY") + " " + showTime,
        ApproverName: userLogininfo["User Name"],
        ApproverID: userLogininfo["UserID"],
      },
    ];
    fetchApiWithLoginToken(
      formData,
      "Dynamic/AddUpdateRecords?LoginType=2&tabErpID=1258"
    ).then((resp) => {
      if (resp.hasErrors === false) {
        alert("Job accept successfully.");
        window.location.reload();
      } else {
        alert(resp.errors);
      }
      handleCloseLoader();
    });
  }
  function fun_JobStart1(id,ContactMobile,JobStartOTP,JobEndOTP) {
    setOpenUserOTP(true);
    setMobile(ContactMobile);
    setStartJOB(JobStartOTP);
    setEndJOB(JobEndOTP);
    setOTP(null);
    debugger;
    alert(IsJOBStart);
    if (!IsJOBStart) {
      return;
    }
    handleClickLoader();
    const formData = [
      {
        BookingID: id,
        JobStartDt: moment(date).format("DD-MMM-YYYY") + " " + showTime,
        HrsToStart: showTime,
      },
    ];
    fetchApiWithLoginToken(
      formData,
      "Dynamic/AddUpdateRecords?LoginType=2&tabErpID=1258"
    ).then((resp) => {
      if (resp.hasErrors === false) {
        alert("Job start successfully.");
        window.location.reload();
      } else {
        alert(resp.errors);
      }
      handleCloseLoader();
    });
  }

  function fun_JobEnd(id) {
    handleClickLoader();
    const formData = [
      {
        BookingID: id,
        JobCompleteDt: moment(date).format("DD-MMM-YYYY") + " " + showTime,
        Status: "Close",
        Stage: "Service Completed",
        HrsToComplete: showTime,
      },
    ];
    fetchApiWithLoginToken(
      formData,
      "Dynamic/AddUpdateRecords?LoginType=2&tabErpID=1258"
    ).then((resp) => {
      if (resp.hasErrors === false) {
        alert("Booking complete successfully.");
        window.location.reload();
      } else {
        alert(resp.errors);
      }
      handleCloseLoader();
    });
  }

  const [value, onChange] = useState(new Date());
  const [AppointmentTime, setAppointmentTime] = useState("");
  const today = new Date();
  const [time24, settime24] = useState(0);

  const nextThreeDays = new Date(today.setDate(today.getDate() + 30));
  today.setTime(today.getTime() + 2 * 60 * 60 * 1000);

  const time = today.toLocaleTimeString("en-US", {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
  });
  const currentDate = new Date().toISOString().split("T")[0];
  const AppointmentData = [
    { d: "08:00", value: "08:00 AM" },
    { d: "08:30", value: "08:30 AM" },
    { d: "09:00", value: "09:00 AM" },
    { d: "09:30", value: "09:30 AM" },
    { d: "10:00", value: "10:00 AM" },
    { d: "10:30", value: "10:30 AM" },
    { d: "11:00", value: "11:00 AM" },
    { d: "11:30", value: "11:30 AM" },
    { d: "12:00", value: "12:00 PM" },
    { d: "12:30", value: "12:30 PM" },
    { d: "13:00", value: "01:00 PM" },
    { d: "13:30", value: "01:30 PM" },
    { d: "14:00", value: "02:00 PM" },
    { d: "14:30", value: "02:30 PM" },
    { d: "15:00", value: "03:00 PM" },
    { d: "15:30", value: "03:30 PM" },
    { d: "16:00", value: "04:00 PM" },
    { d: "16:30", value: "04:30 PM" },
    { d: "17:00", value: "05:00 PM" },
    { d: "17:30", value: "05:30 PM" },
    { d: "18:00", value: "06:00 PM" },
    { d: "18:30", value: "06:30 PM" },
    { d: "19:00", value: "07:00 PM" },
    { d: "19:30", value: "07:30 PM" },
    { d: "20:00", value: "08:00 PM" },
    { d: "20:30", value: "08:30 PM" },
    { d: "21:00", value: "09:00 PM" },
  ];

  const [openReschedule, setOpenReschedule] = React.useState(false);
  const handleClickOpenReschedule = () => {
    setOpenReschedule(true);
  };
  const [RescheduleID, SetRescheduleID] = useState("");
  function fun_Reshduleclick(id) {
    SetRescheduleID(id);
    setOpenReschedule(true);
  }
  const handleCloseReschedule = () => {
    setOpenReschedule(false);
  };

  const handleSelect = (selectedItem, time24) => {
    setAppointmentTime(selectedItem);
    settime24(time24);
  };

  const [openRescheduleDone, setOpenRescheduleDone] = React.useState(false);
  const handleClickOpenRescheduleDone = () => {
    if (AppointmentTime === "") {
      alert("Please select appointment time");
      return;
    } else {
      setOpenRescheduleDone(true);
    }
  };
  const handleCloseRescheduleDone = () => {
    // if (ReasonRescheduling === "") {
    //   alert("Choose reason for rescheduling")
    // }
    // else {
    setOpenRescheduleDone(false);
    // }
  };
  const [ReasonRescheduling, setReasonRescheduling] = useState("");
  function fun_ReasonforRescheduling(id) {
    if (ReasonRescheduling === "") {
      alert("Choose reason for rescheduling");
    } else {
      handleClickLoader();
      let apdate = moment(value).format("DD-MMM-YYYY");
      const formData = [
        {
          BookingID: id,
          AppointmentDt: apdate + " " + time24,
          BookingType: "Reopen",
        },
      ];
      fetchApiWithLoginToken(
        formData,
        "Dynamic/AddUpdateRecords?LoginType=2&tabErpID=1258"
      ).then((resp) => {
        if (resp.hasErrors === false) {
          alert("Reason for rescheduling accept successfully.");
          window.location.reload();
          setOpenRescheduleDone(false);
          SetRescheduleID("");
        } else {
          alert(resp.errors);
        }
        handleCloseLoader();
      });
    }
  }
  useEffect(() => {
    document.getElementById("btnedint").click();
  }, [eventtype]);
  function fun_CancellationReason(id) {
    // if (CancellationReason === "" && id !== null) {
    //   alert("Please Select cancellation reason.");
    //   return;
    // }
    const formData = [
      {
        BookingID: id,
        CancelledDt: moment(date).format("DD-MMM-YYYY") + " " + showTime,
        Status: "Close",
        Stage: "Cancel Service",
      },
    ];

    fetchApiWithLoginToken(
      formData,
      "Dynamic/AddUpdateRecords?LoginType=2&tabErpID=1258"
    ).then((resp) => {
      if (resp.hasErrors === false) {
        alert("Booking cancel successfully.");
        window.location.reload();
        // setOpen2(false);
      } else {
        alert(resp.errors);
      }
    });
    // });
    //}
  }
  return (
    <div className="admin-panel-section">
      <Headerinner />
      <Link to={`/common/Todayjobs/${eventtype}`} id="btnedint"></Link>
      <div className="my-orders admin-body-section admin-div-1">
        <Container fluid>
          <Row>
            <Col xs={12} sm={12} md={3} lg={2}>
              <SideBar />
            </Col>
            <br></br>
            <Col
              xs={12}
              sm={12}
              md={9}
              lg={10}
              className="right-side-section right-side-section-21"
            >
              <h4>Ongoing Bookings</h4>
              <Tabs
                defaultActiveKey={eventtype}
                id="justify-tab-example"
                className="mb-3"
                justify
                onSelect={(key) => seteventtype(key)}
              >
                {/* <Tab eventKey="Upcoming" title="Upcoming">
                  {Todayjobs?.length > 0 ? (Todayjobs?.map((status, i) => {
                    if (moment(status["Appointment Dt"]).format("DD MMM") === moment(new Date()).format("DD MMM")) {
                      datetyepe = "Today"
                    }
                    else if (moment(status["Appointment Dt"]).format("DD MMM") === moment(new Date().setDate(new Date().getDate() + 1)).format("DD MMM")) {
                      datetyepe = "Tomorrow"
                    }
                    else {
                      datetyepe = moment(status["Appointment Dt"]).format("DD MMM")
                    }

                    const utcDate = new Date(status["Appointment Dt"]);
                    const localDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000);
                    const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false };
                    const formattedDate1 = localDate.toLocaleString('en-US', options);
                    const formattedDate = moment(formattedDate1).format("ddd, MMM DD");
                    const formattedTime = moment(formattedDate1).format("hh:mm A");
                   
                    if (status["Stage"] === "Booking Assigned") {
                      if (status["Customer Remarks"] !== "[object Object],[object Object]"
                        && status["Customer Remarks"] !== "[object Object]"
                        && status["Customer Remarks"] != null
                        && status["Customer Remarks"] !== undefined
                        && status["Customer Remarks"] !== "") {
                        totalAmount = JSON.parse(status["Customer Remarks"]);
                      }
                      return (
                        <div className="my-order-inner my-order-inner-5 my-order-inner-21">
                          <Row>
                            <Col xs={8} sm={10} md={10} lg={10}>

                              <div className="booking-div-21">
                                <h3>{datetyepe}</h3>
                                <h6>New Booking ({status["BookingID"]})</h6>
                              
                                <p>{status["Sub Category"]} ( {status["Category"]} ) </p>
                                <p><AccessTimeIcon /> {formattedTime}</p>
                                <p><CurrencyRupeeIcon /> {Math.round(parseFloat(totalAmount["BalAmt"]))}</p>
                                <p><PersonIcon /> {status["Cust Name"]}</p> 
                                <p><LocationOnIcon /> {status["Address"]} , {status["City"]} , {status["State"]} 
                                , {status["Country"]} , {status["State"]} - {status["Pincode"]}</p>
                                <Button variant="contained" className="recived"
                                  onClick={() => { if (window.confirm('Are you sure to accept this job?')) 
                                  { fun_JobAccept(status["BookingID"]) }; }}
                                >Job Accept</Button>
                              
                              </div>

                            </Col>
                            <Col xs={4} sm={2} md={2} lg={2}>
                              <div className="booking-div-22">
                            
                                <LocalPhoneOutlinedIcon className="call-icon-21" />
                                <AssistantDirectionOutlinedIcon />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      );
                    }
                  })
                  ) : (
                    <div className="empty-list-card">List is Empty</div>
                  )}
                </Tab> */}

                <Tab eventKey="In Process" title="In Process">
                  {Todayjobs?.filter(
                    (item) => item["Stage"] === "Professional Assigned"
                  )?.length > 0 ? (
                    LoginUser?.filter(
                      (item) => item["Stage"] === "Professional Assigned"
                    )?.map((status, i) => {
                      if (
                        moment(status["Appointment Dt"]).format("DD MMM") ===
                        moment(new Date()).format("DD MMM")
                      ) {
                        datetyepe = "Today";
                      } else if (
                        moment(status["Appointment Dt"]).format("DD MMM") ===
                        moment(
                          new Date().setDate(new Date().getDate() + 1)
                        ).format("DD MMM")
                      ) {
                        datetyepe = "Tomorrow";
                      } else {
                        datetyepe = moment(status["Appointment Dt"]).format(
                          "DD MMM"
                        );
                      }
                      const utcDate = new Date(status["Appointment Dt"]);
                      const localDate = new Date(
                        utcDate.getTime() + utcDate.getTimezoneOffset() * 60000
                      );
                      const options = {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        second: "numeric",
                        hour12: false,
                      };
                      const formattedDate1 = localDate.toLocaleString(
                        "en-US",
                        options
                      );
                      const formattedDate =
                        moment(formattedDate1).format("ddd, MMM DD");
                      const formattedTime =
                        moment(formattedDate1).format("hh:mm A");
                      if (status["Stage"] === "Professional Assigned") {
                        if (
                          status["Customer Remarks"] !==
                            "[object Object],[object Object]" &&
                          status["Customer Remarks"] !== "[object Object]" &&
                          status["Customer Remarks"] != null &&
                          status["Customer Remarks"] !== undefined &&
                          status["Customer Remarks"] !== ""
                        ) {
                          totalAmount = JSON.parse(status["Customer Remarks"]);
                        }
                        return (
                          <div className="my-order-inner my-order-inner-5 my-order-inner-21 icon_style_4">
                            <Row>
                              <Col xs={8} sm={10} md={10} lg={10}>
                                <div className="booking-div-21">
                                  <h3>{datetyepe}</h3>
                                  <h6 className="icon_style_3">
                                    {status["Stage"]} ({status["BookingID"]})
                                  </h6>
                                  <p className="icon_style_2">
                                    {status["Sub Category"]} ({" "}
                                    {status["Category"]} ){" "}
                                  </p>

                                  {/* <p>₹{Math.round(parseFloat(totalAmount["BalAmt"]))}</p> */}
                                  {/* <p>{formattedTime}</p> */}
                                  {/* <p>{status["Cust Name"]} . {status["Address"]} , {status["City"]} , {status["State"]} , {status["Country"]} , {status["State"]} - {status["Pincode"]}</p> */}

                                  {/* <p>{status["Booking Type"]}</p> */}
                                  <p>
                                    <AccessTimeIcon className="icon_style_1" />{" "}
                                    {formattedTime}
                                  </p>
                                  <p>
                                    <CurrencyRupeeIcon className="icon_style_1" />{" "}
                                    {Math.round(
                                      parseFloat(totalAmount["BalAmt"])
                                    )}
                                  </p>
                                  <p>
                                    <PersonIcon className="icon_style_1" />{" "}
                                    {status["Cust Name"]}
                                  </p>
                                  <p>
                                    <LocationOnIcon className="icon_style_1" />{" "}
                                    {status["Address"]} , {status["City"]} ,{" "}
                                    {status["State"]}, {status["Country"]} ,{" "}
                                    {status["State"]} - {status["Pincode"]}
                                  </p>

                                  {status["Job Start Dt"] === "" ||
                                  status["Job Start Dt"] === null ||
                                  status["Job Start Dt"] === undefined ? (
                                    <div>
                                      <Button
                                        variant="contained"
                                        className="recived"
                                        onClick={() => {
                                          if (
                                            window.confirm(
                                              "Are you sure to start this booking?"
                                            )
                                          ) {
                                            StartJobVerfiy(status["BookingID"]
                                          , status["Contact Mobile"], status["JobStartOTP"], status["JobEndOTP"]
                                        );
                                          }
                                        }}
                                      >
                                        Job Start
                                      </Button>
                                      <Button
                                        variant="contained"
                                        className="rejected"
                                        onClick={() => {
                                          if (
                                            window.confirm(
                                              "Are you sure to cancel this booking?"
                                            )
                                          ) {
                                            fun_CancellationReason(
                                              status["BookingID"]
                                            );
                                          }
                                        }}
                                      >
                                        Job Cancel
                                      </Button>
                                    </div>
                                  ) : (
                                    <div>
                                      <Button
                                        variant="contained"
                                        className="recived"
                                        onClick={() => {
                                          if (
                                            window.confirm(
                                              "Are you sure to end this booking?"
                                            )
                                          ) {
                                            fun_JobEnd(status["BookingID"]);
                                          }
                                        }}
                                      >
                                        Job End
                                      </Button>
                                      <Button
                                        variant="contained"
                                        className="reschedule"
                                        onClick={() => {
                                          fun_Reshduleclick(
                                            status["BookingID"]
                                          );
                                        }}
                                      >
                                        Reschedule
                                      </Button>
                                    </div>
                                  )}
                                </div>
                              </Col>
                              <Col xs={4} sm={2} md={2} lg={2}>
                                <div className="booking-div-22">
                                  <PhoneForwardedOutlinedIcon className="call-icon-21" />
                                  <AssistantDirectionOutlinedIcon />
                                </div>
                              </Col>
                            </Row>
                          </div>
                        );
                      }
                    })
                  ) : (
                    <div className="my-order-inner my-order-inner-5 my-order-inner-21">
                      <div className="empty-list-card">List is Empty</div>
                    </div>
                  )}
                </Tab>
                <Tab eventKey="Completed" title="Completed">
                  {LoginUser?.map((status, i) => {
                    if (
                      moment(status["Appointment Dt"]).format("DD MMM") ===
                      moment(new Date()).format("DD MMM")
                    ) {
                      datetyepe = "Today";
                    } else if (
                      moment(status["Appointment Dt"]).format("DD MMM") ===
                      moment(
                        new Date().setDate(new Date().getDate() + 1)
                      ).format("DD MMM")
                    ) {
                      datetyepe = "Tomorrow";
                    } else {
                      datetyepe = moment(status["Appointment Dt"]).format(
                        "DD MMM"
                      );
                    }
                    const utcDate = new Date(status["Appointment Dt"]);
                    const localDate = new Date(
                      utcDate.getTime() + utcDate.getTimezoneOffset() * 60000
                    );
                    const options = {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric",
                      hour12: false,
                    };
                    const formattedDate1 = localDate.toLocaleString(
                      "en-US",
                      options
                    );
                    const formattedDate =
                      moment(formattedDate1).format("ddd, MMM DD");
                    const formattedTime =
                      moment(formattedDate1).format("hh:mm A");
                    if (status["Stage"] === "Service Completed") {
                      if (
                        status["Customer Remarks"] !=
                          "[object Object],[object Object]" &&
                        status["Customer Remarks"] !== "[object Object]" &&
                        status["Customer Remarks"] != null &&
                        status["Customer Remarks"] !== undefined &&
                        status["Customer Remarks"] !== ""
                      ) {
                        totalAmount = JSON.parse(status["Customer Remarks"]);
                      }
                      return (
                        <div className="my-order-inner my-order-inner-5 my-order-inner-21 icon_style_4">
                          <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                              <Link
                                to={`/common/history-details/${status["BookingID"]}`}
                              >
                                <CheckBoxOutlinedIcon className="job-done-icon" />
                                <div className="booking-div-21">
                                  <h3>{datetyepe}</h3>
                                  <h6 className="icon_style_3">
                                    {status["Stage"]} ({status["BookingID"]})
                                  </h6>
                                  <p className="icon_style_2">
                                    {status["Sub Category"]} ({" "}
                                    {status["Category"]} ){" "}
                                  </p>
                                  <p>
                                    <CurrencyRupeeIcon className="icon_style_1" />

                                    {Math.round(
                                      parseFloat(totalAmount["BalAmt"])
                                    )}
                                  </p>
                                  <p>
                                    <AccessTimeIcon className="icon_style_1" />
                                    {formattedTime}
                                  </p>
                                  <p>
                                    <LocationOnIcon className="icon_style_1" />
                                    {status["Cust Name"]} . {status["Address"]}{" "}
                                    , {status["City"]} , {status["State"]} ,{" "}
                                    {status["Country"]} , {status["State"]} -{" "}
                                    {status["Pincode"]}
                                  </p>
                                </div>
                              </Link>
                            </Col>
                          </Row>
                        </div>
                      );
                    }
                  })}
                </Tab>
                <Tab eventKey="Cancelled" title="Cancelled">
                  {LoginUser?.map((status, i) => {
                    if (
                      moment(status["Appointment Dt"]).format("DD MMM") ===
                      moment(new Date()).format("DD MMM")
                    ) {
                      datetyepe = "Today";
                    } else if (
                      moment(status["Appointment Dt"]).format("DD MMM") ===
                      moment(
                        new Date().setDate(new Date().getDate() + 1)
                      ).format("DD MMM")
                    ) {
                      datetyepe = "Tomorrow";
                    } else {
                      datetyepe = moment(status["Appointment Dt"]).format(
                        "DD MMM"
                      );
                    }
                    const utcDate = new Date(status["Appointment Dt"]);
                    const localDate = new Date(
                      utcDate.getTime() + utcDate.getTimezoneOffset() * 60000
                    );
                    const options = {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric",
                      hour12: false,
                    };
                    const formattedDate1 = localDate.toLocaleString(
                      "en-US",
                      options
                    );
                    const formattedDate =
                      moment(formattedDate1).format("ddd, MMM DD");
                    const formattedTime =
                      moment(formattedDate1).format("hh:mm A");
                    if (status["Stage"] === "Cancel Service") {
                      return (
                        <div className="my-order-inner my-order-inner-5 my-order-inner-21 icon_style_4">
                          <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                              <div className="booking-div-21">
                                <h3>{datetyepe}</h3>
                                <h5 className="icon_style_3">
                                  {status["Stage"]} ({status["BookingID"]})
                                </h5>
                                <p className="icon_style_2">
                                  {status["Sub Category"]} ({" "}
                                  {status["Category"]} ){" "}
                                </p>
                                <p>
                                  <CurrencyRupeeIcon className="icon_style_1" />
                                  {Math.round(
                                    parseFloat(totalAmount["BalAmt"])
                                  )}
                                </p>
                                <p>
                                  <AccessTimeIcon className="icon_style_1" />{" "}
                                  {formattedTime}
                                </p>
                                <p>
                                  <LocationOnIcon className="icon_style_1" />
                                  {status["Cust Name"]} . {status["Address"]} ,{" "}
                                  {status["City"]} , {status["State"]} ,{" "}
                                  {status["Country"]} , {status["State"]} -{" "}
                                  {status["Pincode"]}
                                </p>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      );
                    }
                  })}
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </div>

      <Dialog
        open={openReschedule}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseReschedule}
        aria-describedby="alert-dialog-slide-description"
        // className="reschedule-div-1"
        BackdropProps={{
          style: { backgroundColor: "rgba(0, 0, 0, 0.9)" },
        }}
      >
        <DialogContent className="reschedule-div-1">
          <DialogContentText id="alert-dialog-slide-description">
            <h2 className="reason-1">
              Reschedule Booking{" "}
              <span>
                <ClearIcon
                  className="cross-icon"
                  onClick={handleCloseReschedule}
                />
              </span>
            </h2>

            <section className="section-step-1 section-step-1-popup div-Tools-5">
              <Calendar
                onChange={onChange}
                value={value}
                minDate={new Date()}
                maxDate={nextThreeDays}
              />

              <div className="time-slot time-slot-popup">
                <h2>Select Start Time of Service</h2>
                <ul>
                  {AppointmentData.map((dd, i) => {
                    if (currentDate < moment(value).format("YYYY-MM-DD")) {
                      return (
                        <li
                          key={dd["value"]}
                          onClick={() => handleSelect(dd["value"], dd["d"])}
                          className={
                            AppointmentTime === dd["value"]
                              ? "active-button-bb"
                              : ""
                          }
                        >
                          {dd["value"]}
                        </li>
                      );
                    }
                    if (
                      currentDate === moment(value).format("YYYY-MM-DD") &&
                      time <= dd["d"]
                    ) {
                      return (
                        <li
                          key={dd["value"]}
                          onClick={() => handleSelect(dd["value"], dd["d"])}
                          className={
                            AppointmentTime === dd["value"]
                              ? "active-button-bb"
                              : ""
                          }
                        >
                          {dd["value"]}
                        </li>
                      );
                    }
                  })}
                </ul>

                <Button
                  variant="contained"
                  className="next-button-7 reschedule-button"
                  onClick={() => {
                    handleClickOpenRescheduleDone();
                    handleCloseReschedule();
                  }}
                >
                  Submit
                </Button>
              </div>
            </section>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openRescheduleDone}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseRescheduleDone}
        aria-describedby="alert-dialog-slide-description"
        BackdropProps={{
          style: { backgroundColor: "rgba(0, 0, 0, 0.9)" },
        }}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <h2 className="reason-1">
              <KeyboardBackspaceIcon
                className="arrow-35"
                onClick={() => {
                  handleClickOpenReschedule();
                  handleCloseRescheduleDone();
                }}
              />
              Reason for Rescheduling{" "}
              <span>
                <ClearIcon
                  className="cross-icon"
                  onClick={handleCloseRescheduleDone}
                />
              </span>
            </h2>

            <div className="div-Tools-5">
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    className="radio-button-cancel"
                    value="Professional not assignedt"
                    checked={
                      ReasonRescheduling === "Professional not assignedt"
                    }
                    onChange={(e) => setReasonRescheduling(e.target?.value)}
                    control={<Radio />}
                    label="Professional not assigned"
                  />
                  <FormControlLabel
                    className="radio-button-cancel"
                    value="Service required at a different time"
                    checked={
                      ReasonRescheduling ===
                      "Service required at a different time"
                    }
                    onChange={(e) => setReasonRescheduling(e.target?.value)}
                    control={<Radio />}
                    label="Service required at a different time"
                  />
                </RadioGroup>
              </FormControl>
              <p className="cancallation-1">
                We won't be charging you a reschedule fee
              </p>
            </div>

            <Button
              variant="contained"
              className="next-button-7 width-100"
              onClick={() => {
                fun_ReasonforRescheduling(RescheduleID);
              }}
            >
              Reschedule Booking
            </Button>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
export default Todayjobs;
