import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import OtpInput from "react-otp-input";
import OTPInput, { ResendOTP } from "otp-input-react";
import { ProductContext } from "../context/AppContext";
import CryptoJS from "crypto-js";
import { fetchWrapper, fetchWrapperForLogin } from "../apiUtils";
import { Button } from "react-bootstrap";
function UserOTP() {
  const {
    handleCloseUserOTP,
    OTP,
    AuthkeyTEXT,
    setOTP,
    mobileLoginVerifyOTPMongo,
    Mobile,
    handleClickOpenUserMobile,
    checkMobileUserMongoDynamic,
    NewUser,
    handleClickLoader,
    LoginType,
    setLoginType, EndJOB, StartJOB,
    setOpenUserOTP, setIsJOBStart,
    handleCloseLoader,fun_JobStart,StartJobVerfiy
  } = useContext(ProductContext);
  const [state, setState] = useState({
    mobile: "",
    isValidMobile: false,
    otp: "",
  });
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [timer, setTimer] = useState(10);
  useEffect(() => {
    //let otpnew= localStorage.getItem("otp");
    handleOtpChange(OTP);
  }, [OTP]);
  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setTimer(10);
      setIsResendDisabled(false);
    }
    return () => clearInterval(interval);
  }, [timer]);
  const handleOtpChange = (otp) => {
    const isValidOtp = otp?.length === 4 && /^\d+$/.test(otp);
    setOTP(otp);
    setState((prevState) => ({
      ...prevState,
      otp: otp,
      isValidOtp: isValidOtp,
    }));
  };
  const [ContactName, setContactName] = useState("");

  function fun_GetUserInfo(id, token) {
    var redirect = localStorage.getItem("CardLogin");
    const infoPra = {
      tabErpID: "1429",
      filters: `{'_id':${id}}`,
    };

    let endpointURL = "Dynamic/GetRecords";
    try {
      fetchWrapper(infoPra, endpointURL, token).then((resp) => {
        handleCloseLoader();
        if (resp?.errorCode === "HTTP error") {
          alert("Request not valid");
          return;
        }
        if (resp?.response !== null) {
          if (resp?.response?.records !== null) {
            const logindata = resp?.response?.records[0];
            localStorage.setItem("user-Logininfo", JSON.stringify(logindata));
            localStorage.setItem("User Group", logindata["User Group"]);
            handleCloseUserOTP();
            if (LoginType === "Customer Login") {
              if (redirect != null) {
                localStorage.removeItem("CardLogin");
                window.location.href = "/customer/view-booking/1";
                // document.getElementById("Login1").click();
              } else {
                document.getElementById("Login2").click();
              }
            }
            if (
              LoginType === "Admin Login" ||
              LoginType === "Service Provider Login"
            ) {
              document.getElementById("btnAdminAndSpLogin").click();
            }
          }
        } else {
          alert("Invalid request for user details.");
        }
      });
    } catch (error) {
      alert(error.message);
    }
  }
  const AutomobileLoginVerifyOTPMongo = (otp) => {
    if (otp.trim() === "") {
      alert("Try again to login.");
      return;
    } else {
      handleClickLoader();
      const VerifyPra = {
        baseTokenKey: AuthkeyTEXT,
        EmailID: "",
        appVersion: "1",
        deviceOS: "Web",
        mobile: Mobile,
        tabErpID: "1429",
        otp: otp,
        appVersionName: "1",
        deviceCountry: "India",
        deviceID: "Web",
        deviceName: "xiaomi Mi A2",
        imeiNumber: "not found",
        countryCode: "91",
        countryName: "India",
        collectionNumber: "1", // for old use 0 and for new 1
      };

      let endpointURL = "DynamicApp/mobileLoginVerifyOTPMongo";
      try {
        fetchWrapperForLogin(VerifyPra, endpointURL, AuthkeyTEXT).then(
          (resp) => {
            if (resp?.errorCode === "HTTP error") {
              alert("Request not valid");
              handleCloseLoader();
              return;
            }
            if (resp.hasErrors === false) {
              localStorage.setItem(
                "Auth-Token",
                JSON.stringify(resp.response.loginKey)
              );
              localStorage.setItem(
                "user-parentID",
                JSON.stringify(resp.response.parentID)
              );
              fun_GetUserInfo(resp.response.partySr, resp.response.loginKey);
            } else {
              handleCloseLoader();
              alert(resp.errors);
            }
          }
        );
      } catch (error) {
        alert(error.message);
      }
    }
  };
  const key = "0e628f15575e4a71"; //"0e628f15575e4a7189ec11a50301bb70";
  const autoDecryptN1 = (encryptedText) => {
    const encryptedBytes = CryptoJS.enc.Base64.parse(encryptedText);
    const decryptedHex = CryptoJS.AES.decrypt(
      { ciphertext: encryptedBytes },
      CryptoJS.enc.Utf8.parse(key),
      {
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
        iv: CryptoJS.enc.Utf8.parse(key),
        keySize: 128,
        blockSize: 128,
      }
    );
    const decryptedText = CryptoJS.enc.Utf8.stringify(decryptedHex);
    if (
      decryptedText !== undefined &&
      decryptedText !== "undefined" &&
      decryptedText !== "" &&
      decryptedText !== null
    ) {
      // setOTP(decryptedText);
      // setOTP(decryptedText);
      // alert(decryptedText)
      AutomobileLoginVerifyOTPMongo(decryptedText);
    } else {
      autoDecryptN1(encryptedText);
    }
  };
  const automobileSendOtpMongo = () => {
    if (Mobile === "") {
      alert("Enter Mobile Number");
      handleCloseLoader();
      return;
    } else {
      const loginPra = {
        baseTokenKey: AuthkeyTEXT,
        resend: "0",
        mobile: Mobile,
      };
      fetch("https://api.dialerp.com/api/DynamicApp/mobileSendOtpMongo", {
        method: "POST",
        headers: {
          // "Authorization-Token": AuthkeyTEXT,
          Authkey: AuthkeyTEXT,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(loginPra),
      }).then((result) => {
        result.json().then((resp) => {
          if (resp?.hasErrors === false) {
            autoDecryptN1(resp.response);
          } else {
            handleCloseLoader();
            alert("Invalid request!!.");
            return;
          }
        });
      });
    }
  };
  function AutocheckMobileUserMongoDynamic() {
    const checkMobileUserPra = {
      baseTokenKey: AuthkeyTEXT,
      EmailID: "",
      appVersion: "1",
      imeiNumber: "not found",
      countryCode: "91",
      deviceOS: "Web",
      mobile: Mobile,
      countryName: "India",
      appVersionName: "1",
      deviceCountry: "India",
      deviceID: "6b068d3854a26559",
      deviceName: "Web",
      collectionNumber: "1", // for old use 0 and for new 1
    };
    fetch(
      "https://api.dialerp.com/api/DynamicApp/checkMobileUserMongoDynamic",
      {
        method: "POST",
        headers: {
          // "Authorization-Token": AuthkeyTEXT,
          Authkey: AuthkeyTEXT,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(checkMobileUserPra),
      }
    ).then((result) => {
      result.json().then((resp) => {
        if (resp.hasErrors === false) {
          automobileSendOtpMongo();
        } else {
          handleCloseLoader();
        }
      });
    });
  }
  function fun_CustomerRagistration() {
    if (
      ContactName === "" ||
      ContactName === null ||
      ContactName === undefined
    ) {
      alert("Enter Name");
      handleCloseLoader();
      return;
    } else {
      const formData = [
        {
          UserName: ContactName,
          UserGroup: "Customer",
          LedgerGroup: "Sundry Debtors",
          UserRank: 1,
          Status: "Active",
          CountryCode: 91,
          LoginMobile: parseInt(Mobile),
          FullName: ContactName,
        },
      ];
      fetch(
        "https://api.dialerp.com/api/DynamicApp/UserSignupDynamic?LoginType=2&tabErpID=1429",
        {
          method: "POST",
          headers: {
            Authkey: AuthkeyTEXT,
            Accept: "application/json, text/plain",
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: JSON.stringify(formData),
        }
      ).then((result) => {
        result.json().then((resp) => {
          if (resp.hasErrors === false) {
            AutocheckMobileUserMongoDynamic();
          } else {
            handleCloseLoader();
            alert(resp.errors);
          }
        });
      });
    }
  }
  function verifyMobileSendOtp() {
    if (OTP === "" || OTP === null || OTP === undefined) {
      alert("Enter OTP");
      return;
    } else {
      handleClickLoader();
      const checkMobileUserPra = {
        collectionNumber: "1",
        mobile: Mobile,
        otp: OTP,
        type: "12",
      };
      fetch("https://api.dialerp.com/api/DynamicApp/verifyMobileSendOtp", {
        method: "POST",
        headers: {
          // "Authorization-Token": AuthkeyTEXT,
          Authkey: AuthkeyTEXT,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(checkMobileUserPra),
      }).then((result) => {
        result.json().then((resp) => {
          if (resp.hasErrors === false) {
            fun_CustomerRagistration();
          } else {
            handleCloseLoader();
            alert(resp.errors);
          }
        });
      });
    }
  }
  const click_ResenOtp = () => {
    setOTP("");
    setIsResendDisabled(true);
    setTimer(10);
    setState({
      mobile: "",
      isValidMobile: false,
      otp: "",
    });
    checkMobileUserMongoDynamic();
  };
  const StartJobVerfiy1 = () => {
    if (StartJOB === OTP) {
      setIsJOBStart(true);
      setOpenUserOTP(false);
      fun_JobStart();

    }
    else {
      alert("Please enter Valid OTP");
    }
  }
  return (
    <div>
      <div className="login-18 login-18-Customer-login">
        <div className="form-section form-section-otp">
          {/* <div className="logo-2">
            <Link to="/">
              <img src="../../assets/images/logo1.svg" alt="logo" />
            </Link>
          </div> */}
          <div className="typing-3">
            <img
              src="../../assets/images/otp.png"
              className="typing-3-New-2-img"
              alt="logo"
            />
            {/* <h3>OTP Verify</h3> */}
            <p className="edit-number-6 otp-edit-p ">
              Please enter the verification code We’ve sent you on{" "}
              <b>+91-{Mobile}</b>
              {/* <span className="change-number-12"> +91-{Mobile}</span> */}
              <span
                className="change-number-12 otp-edit-span"
                onClick={() => {
                  handleClickOpenUserMobile();
                  handleCloseUserOTP();
                }}
              >
                <img src="../../assets/images/edit3.png" className="otp-edit" />
              </span>
            </p>
          </div>

          <div className="input-spce-bottom">
            <OtpInput
              value={state?.otp}
              onChange={handleOtpChange}
              shouldAutoFocus={true}
              autoFocus={true}
              numInputs={4}
              focused
              // renderSeparator={<span>-</span>}

              renderInput={(props) => (
                <input {...props} className="Otp-Input" type="text" />
              )}
            />
          </div>
          {NewUser === "Yes" ? (
            <>
              <div className="input-spce-bottom">
                <TextField
                  id="outlined-basic"
                  className={`width-100 lgoin-input-6}`}
                  label="Contact Name*"
                  variant="outlined"
                  value={ContactName}
                  onChange={(e) => {
                    setContactName(e.target.value);
                  }}
                />
              </div>
            </>
          ) : (
            ""
          )}
          <div className="form-group clearfix">
            {/* <button type="submit" className={`btn btn-primary btn-lg btn-theme gray-background-55`}   onClick={mobileLoginVerifyOTPMongo} >   <b>Verify OTP</b> </button> */}
            {NewUser === "No" ? (
              state?.otp?.length === 4 ? (
                <>
                  <button
                    type="submit"
                    className="btn btn-primary btn-lg btn-theme valid-mobile"
                    onClick={() => mobileLoginVerifyOTPMongo()}
                  >
                    <b>Verify OTP</b>
                  </button>
                </>
              ) : (
                <>
                  <button
                    disabled="disable"
                    className="btn btn-primary btn-lg btn-theme valid-mobile"
                  >
                    <b>Verify OTP</b>
                  </button>
                </>
              )
            ) : state?.otp?.length === 4 &&
              NewUser === "Yes" &&
              ContactName !== "" &&
              ContactName !== null ? (
              <>
                <button
                  type="submit"
                  className="btn btn-primary btn-lg btn-theme valid-mobile"
                  onClick={() => verifyMobileSendOtp()}
                >
                  <b>Verify OTP</b>
                </button>
              </>
            ) : StartJOB !== null && StartJOB !== ""  ? (
              <>
                <button
                  className="btn btn-primary btn-lg btn-theme valid-mobile"
                  onClick={() => StartJobVerfiy1()}
                >
                  <b>Start OTP</b>
                </button>
              </>
            ) : <>
              <button
                disabled="disabled"
                className="btn btn-primary btn-lg btn-theme valid-mobile"
                
              >
                <b>Start OTP</b>
              </button>
            </>}

            <div className="resenddiv">
              {isResendDisabled   ? (
                <p>
                  0
                  {`${Math.floor(timer / 60)}:${timer % 60 < 10 ? "0" : ""}${timer % 60
                    }`}
                </p>
              ) : StartJOB === null && StartJOB === "" ?(
                <Button onClick={() => click_ResenOtp()}>Resend OTP</Button>
              ):<></>}
            </div>
            {/* <ResendOTP
              onResendClick={checkMobileUserMongoDynamic}
              className="resend1"
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserOTP;
