import React from 'react'
import Header from './Header'
import Footer from './Footer'
import Banner from './Banner'
import SubBanner from './SubBanner'
import Service1 from './Service1'
import FooterMobile from './FooterMobile'

function Home() {
  return (
    <div>
        <Header />
        <Banner />
        <SubBanner />
        <Service1 />
        <Footer /><br /><br />
        <FooterMobile />
    </div>
  )
}

export default Home
