/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link, useParams } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import Banner from "./Banner";
import StarsIcon from "@mui/icons-material/Stars";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import AddIcon from "@mui/icons-material/Add";
import RemoveSharpIcon from "@mui/icons-material/RemoveSharp";
import LocalOfferSharpIcon from "@mui/icons-material/LocalOfferSharp";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import InputAdornment from "@mui/material/InputAdornment";
import RedoIcon from "@mui/icons-material/Redo";
import NearMeIcon from "@mui/icons-material/NearMe";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { red } from "@mui/material/colors";
import { LabelList } from "recharts";
import { ProductContext } from "./context/AppContext";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShoppingCart from "./Customer/ShoppingCart";
import ClearIcon from "@mui/icons-material/Clear";
import FooterMobile from "./FooterMobile";
import CloseIcon from "@mui/icons-material/Close";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

import Badge from "@mui/material/Badge";
import MailIcon from "@mui/icons-material/Mail";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = ["Service 1", "Service 2", "Service 3", "Service 4", "Service 5"];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
function ServiceList() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const CartItemms = JSON.parse(localStorage.getItem("CartItemms"));

  const {
    SubCategoryListData,
    ServiceList,
    subsubcategorylst,
    CardList,
    setCardList,
    FavoriteList,
    setFavoriteList,
  } = useContext(ProductContext);
  const { CategoryName, SubCategoryName } = useParams();
  //localStorage.removeItem("Appointmentobj");

  let baseURL = `${process.env.REACT_APP_BASE_URL}`;
  let authToken = `${process.env.REACT_APP_BASE_AUTHKEY}`;
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [openCart, setOpenCart] = React.useState(false);

  const handleClickCart = () => {
    setOpenCart(true);
  };
  const handleCloseCart = () => {
    setOpenCart(false);
  };

  const [openServiceDetail, setOpenServiceDetail] = React.useState(false);
  const [ServiceDetail, setServiceDetail] = React.useState({});

  const handleClickOpenServiceDetail = (data) => {
    setServiceDetail(JSON.parse(data));
    //alert(ServiceDetail);
    console.log(ServiceDetail["MRP"]);
    setOpenServiceDetail(true);
  };
  const handleCloseServiceDetail = () => {
    setOpenServiceDetail(false);
  };

  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === "string" ? value.split(",") : value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    const CartItemms = JSON.parse(localStorage.getItem("CartItemms")) || [];
    let fCartItemms = CartItemms.filter(
      (p) => p["data"]["Sub Category"] === SubCategoryName
    );
    setCardList(fCartItemms);
    const Favorites = JSON.parse(localStorage.getItem("FavoriteList")) || [];
    setFavoriteList(Favorites);
  }, []);
  let subsubdata = subsubcategorylst;
  let subsublist = subsubdata?.filter(
    (x) => x["Sub Category"] === SubCategoryName
  );
  let DatSubCategoryList = SubCategoryListData?.records?.filter(
    (p) => p["Sub Category"] === SubCategoryName
  );

  let CommonList = ServiceList?.filter(
    (x) => x["Sub Category"] === SubCategoryName
  ).sort((a, b) => a["Sub Sub Category"].localeCompare(b["Sub Sub Category"]));

  function fun_ServiceListFilterClick(id) {
    const headingElement = document.getElementById(id);

    if (headingElement) {
      const offsetTop = headingElement.offsetTop;
      window.scrollTo({ top: offsetTop, behavior: "smooth" });
    }
  }

  const fun_MarkFavorite = (data, i) => {
    //const existingFavorites = JSON.parse(localStorage.getItem("FavoriteList")) || [];
    const updatedFavorites = [...FavoriteList, data];
    localStorage.setItem("FavoriteList", JSON.stringify(updatedFavorites));
    setFavoriteList(updatedFavorites);
  };

  const fun_UnMarkFavorite = (dataToRemove) => {
    // const existingFavorites = JSON.parse(localStorage.getItem("FavoriteList")) || [];
    const updatedFavorites = FavoriteList.filter(
      (item) => item["ItemID"] !== dataToRemove
    );
    setFavoriteList(updatedFavorites);
    localStorage.setItem("FavoriteList", JSON.stringify(updatedFavorites));
  };

  const fun_addtocartItem = (data, i) => {
    let fltCard = CardList.filter((item) => item["ItemID"] === data["ItemID"]);
    if (fltCard.length > 0) {
      const updatedCard = CardList.map((item) => {
        if (item["ItemID"] === data["ItemID"]) {
          const qutcount = item["Quanity"] + 1;
          let itemtotal = parseFloat(parseFloat(data["MRP"] || 0) * qutcount);
          return {
            ...item,
            ItemID: data["ItemID"],
            ItemTotal: itemtotal,
            Quanity: qutcount,
          };
        }
        return item;
      });
      setCardList(updatedCard);
      localStorage.setItem("CartItemms", JSON.stringify(updatedCard));
    } else {
      const updatedCard = [
        ...CardList,
        {
          data,
          ItemID: data["ItemID"],
          ItemTotal: parseFloat(data["MRP"]),
          Quanity: 1,
        },
      ];
      setCardList(updatedCard);
      localStorage.setItem("CartItemms", JSON.stringify(updatedCard));
    }
  };

  const fun_RemovetocartItem = (data, i) => {
    let fltCard = CardList.filter((item) => item["ItemID"] === data["ItemID"]);
    if (fltCard[0]["Quanity"] > 1) {
      const updatedCard = CardList.map((item) => {
        if (item["ItemID"] === data["ItemID"]) {
          const qutcount = item["Quanity"] - 1;
          let itemtotal = parseFloat(parseFloat(data["MRP"] || 0) * qutcount);
          return {
            ...item,
            ItemID: data["ItemID"],
            ItemTotal: itemtotal,
            Quanity: qutcount,
          };
        }
        return item;
      });
      setCardList(updatedCard);
      localStorage.setItem("CartItemms", JSON.stringify(updatedCard));
    } else {
      let fltCard = CardList.filter(
        (item) => item["ItemID"] !== data["ItemID"]
      );
      //const updatedCard = [...fltCard, { data, ItemID: data["ItemID"], Quanity: 1 }];
      setCardList(fltCard);
      localStorage.setItem("CartItemms", JSON.stringify(fltCard));
    }
  };

  return (
    <div>
      <div className="web-view">
        <Header />
      </div>
      <h2 className="mobile-header-1 mobile-view">
        <Link to="/">
          <ArrowBackIcon className="mobile-header-1-left-icon" />
        </Link>
        {SubCategoryName}
        <span className="mobile-search-icon-1">
          {CartItemms && CartItemms?.length > 0 ? (
            <Badge
              badgeContent={CartItemms?.length}
              color="primary"
              className="cart-icon-service-page"
            >
              <ShoppingCartOutlinedIcon
                color="action"
                onClick={handleClickCart}
              />
            </Badge>
          ) : (
            <Badge
              badgeContent={0}
              color="primary"
              className="cart-icon-service-page"
            >
              <ShoppingCartOutlinedIcon
                color="action"
                onClick={handleClickCart}
              />
            </Badge>
          )}

          <SearchOutlinedIcon />
        </span>
      </h2>
      <div className="top-space top-space-mobile-profile-Service">
        <div className="banner-section-1 mobile-view">
          <img
            src={DatSubCategoryList?.[0]["Banner"]}
            className="banner-section-img image-bottom-space"
          />
        </div>
        <Container fluid>
          <Row>
            <Col xs={12} sm={6} md={4} lg={3}>
              <div className="service-section-1">
                <div className="banner-section-1">
                  <h3>{SubCategoryName}</h3>
                  <p>
                    <StarsIcon className="star-icon2" />
                    4.84 (6.4 M Bookings)
                  </p>
                </div>
                <div className="banner-section-2">
                  <p className="buy-2">Select a service</p>
                  <Row>
                    {subsublist?.length !== 0 ? (
                      subsublist.map((data, index) => {
                        {
                          return (
                            <>
                              <Col xs={3} md={4} key={index}>
                                <div
                                  className="banner-div-2 service-div-left-22"
                                  onClick={() =>
                                    fun_ServiceListFilterClick(
                                      data["Sub Sub CategoryID"]
                                    )
                                  }
                                >
                                  <div className="service-div-left-22-inner">
                                    <img src={data["Pic"]} />
                                    <p>{data["Sub Sub Category"]}</p>
                                  </div>
                                </div>
                              </Col>
                            </>
                          );
                        }
                      })
                    ) : (
                      <>
                        :
                        <div
                          className="banner-div-2"
                          style={{ color: "#57473" }}
                        >
                          No record found
                        </div>
                      </>
                    )}
                  </Row>
                </div>

                <div className="scroll-div-2" hidden>
                  <div className="left-service-div-1">
                    <h4>
                      Category{" "}
                      <span className="see-all-1" onClick={handleClickOpen}>
                        See All
                      </span>
                    </h4>
                    <FormGroup>
                      <FormControlLabel
                        className="text-size"
                        control={<Checkbox defaultChecked />}
                        label="AC Repair and Service"
                      />
                      <FormControlLabel
                        className="text-size"
                        required
                        control={<Checkbox />}
                        label="Air Cooler Repair"
                      />
                      <FormControlLabel
                        className="text-size"
                        required
                        control={<Checkbox />}
                        label="Air Purifier"
                      />
                      <FormControlLabel
                        className="text-size"
                        required
                        control={<Checkbox />}
                        label="Laptop repair(at home)"
                      />

                      <FormControlLabel
                        className="text-size"
                        disabled
                        control={<Checkbox />}
                        label="Refrigerator"
                      />
                      {/* <Button variant='outlined' onClick={handleClickOpen} >More</Button> */}
                    </FormGroup>
                  </div>

                  <div className="left-service-div-1">
                    <h4>Sub Category</h4>
                    <FormGroup>
                      <FormControlLabel
                        className="text-size"
                        control={<Checkbox defaultChecked />}
                        label="Salon for women"
                      />
                      <FormControlLabel
                        className="text-size"
                        required
                        control={<Checkbox />}
                        label="Spa for women"
                      />
                      <FormControlLabel
                        className="text-size"
                        required
                        control={<Checkbox />}
                        label="Hair studio for women"
                      />
                      <FormControlLabel
                        className="text-size"
                        required
                        control={<Checkbox />}
                        label="Full home cleaning"
                      />
                    </FormGroup>
                  </div>
                  <div className="left-service-div-3">
                    <FormControl className="width-100">
                      <InputLabel id="demo-multiple-chip-label">
                        Child Category
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={personName}
                        onChange={handleChange}
                        input={
                          <OutlinedInput
                            id="select-multiple-chip"
                            label="Child Category"
                          />
                        }
                        renderValue={(selected) => (
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                          >
                            {selected.map((value) => (
                              <Chip key={value} label={value} />
                            ))}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        {names.map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                            style={getStyles(name, personName, theme)}
                          >
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="left-service-div-3">
                    <FormControl variant="outlined" className="width-100">
                      <InputLabel htmlFor="outlined-adornment-password">
                        Service City
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        endAdornment={
                          <InputAdornment position="end">
                            <LocationOnIcon />
                          </InputAdornment>
                        }
                        label="Service City"
                      />
                    </FormControl>
                  </div>
                  <div className="left-service-div-3">
                    <FormControl variant="outlined" className="width-100">
                      <InputLabel htmlFor="outlined-adornment-password">
                        Service Area
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        endAdornment={
                          <InputAdornment position="end">
                            <NearMeIcon />
                          </InputAdornment>
                        }
                        label="Service Area"
                      />
                    </FormControl>
                  </div>
                </div>
              </div>
              {/* <Button variant="contained" onClick={handleClickCart} className="service-section-1">Go TO Cart</Button> */}
            </Col>

            <Col xs={12} sm={6} md={8} lg={9}>
              <div className="banner-section-1">
                <img
                  src={DatSubCategoryList?.[0]["Banner"]}
                  className="banner-section-img web-view"
                />
                {/* <img src='../../../assets/images/servicebanner.webp' className='banner-section-img' /> */}
                <div className="section-service section-service-21">
                  <div className="div-service-1">
                    <Row>
                      <Col xs={12} sm={12} md={12}>
                        {CommonList !== null ? (
                          <>
                            {subsublist?.map((subdata, i) => {
                              let previousSubSubCategory = null;
                              return (
                                <>
                                  {CommonList?.map((serviceitem, k) => {
                                    // const existingFavorites = JSON.parse(localStorage.getItem("FavoriteList")) || [];
                                    const updatedFavorites =
                                      FavoriteList?.filter(
                                        (item) =>
                                          item["ItemID"] ===
                                          serviceitem["ItemID"]
                                      );
                                    let fevMarkd = updatedFavorites?.length;

                                    //const CartItemms = JSON.parse(localStorage.getItem("CartItemms")) || [];
                                    const existinCart = CardList?.filter(
                                      (item) =>
                                        item["ItemID"] === serviceitem["ItemID"]
                                    );
                                    let existinCartlength = existinCart?.length;

                                    if (
                                      subdata["Sub Sub Category"] ===
                                      serviceitem["Sub Sub Category"]
                                    ) {
                                      const subSubCategoryChanged =
                                        previousSubSubCategory !==
                                        serviceitem["Sub Sub Category"];
                                      previousSubSubCategory =
                                        serviceitem["Sub Sub Category"];
                                      return (
                                        <>
                                          {subSubCategoryChanged && (
                                            <h3
                                              className="cs-padding-1"
                                              id={subdata["Sub Sub CategoryID"]}
                                            >
                                              {serviceitem["Sub Sub Category"]}
                                            </h3>
                                          )}
                                          <div
                                            className="inner-service-1 inner-service-11"
                                            key={k}
                                          >
                                            <Row>
                                              <Col xs={7} sm={6} md={7} lg={7}>
                                                <p className="service-warrantry">
                                                  {
                                                    serviceitem[
                                                    "Sub Sub Category"
                                                    ]
                                                  }
                                                </p>
                                                <h3> {serviceitem["Item"]} </h3>
                                                <p className="rating-star rating-star-5">
                                                  <StarsIcon className="star-icon3" />
                                                  4.84 (6.4 M Bookings)
                                                </p>
                                                <p>
                                                  ₹
                                                  {parseFloat(
                                                    serviceitem["MRP"] || 0
                                                  )}{" "}
                                                  <span
                                                    style={{ color: "gray" }}
                                                  >
                                                    | 30 min
                                                  </span>
                                                </p>
                                                <div
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      serviceitem?.[
                                                      "Item Description"
                                                      ],
                                                  }}
                                                />
                                                <Button
                                                  variant="text"
                                                  className="view-detail-service-page-1 web-view"
                                                >
                                                  <Link
                                                    className="web-view"
                                                    to=""
                                                    // {`/acservicerepairdetails/${serviceitem["ItemID"]}`}
                                                    onClick={() =>
                                                      handleClickOpenServiceDetail(
                                                        JSON.stringify(
                                                          serviceitem
                                                        )
                                                      )
                                                    }
                                                  >
                                                    View Details
                                                  </Link>
                                                </Button>
                                              </Col>

                                              <Col xs={5} sm={6} md={5} lg={5}>
                                                <div className="service-images-2">
                                                  {fevMarkd === 0 && (
                                                    <FavoriteBorderIcon
                                                      className="blank-fav-icon-1"
                                                      onClick={() =>
                                                        fun_MarkFavorite(
                                                          serviceitem,
                                                          k
                                                        )
                                                      }
                                                    />
                                                  )}
                                                  {fevMarkd > 0 && (
                                                    <FavoriteIcon
                                                      className="select-fav-icon-1"
                                                      onClick={() =>
                                                        fun_UnMarkFavorite(
                                                          serviceitem["ItemID"]
                                                        )
                                                      }
                                                    />
                                                  )}
                                                  <img
                                                    src={
                                                      serviceitem?.["Item Pic"]
                                                    }
                                                    width="100%"
                                                    alt="Icon"
                                                  />
                                                  {
                                                  existinCartlength === 0 && (
                                                    <Button
                                                      className="service-images-2-button"
                                                      onClick={() =>
                                                        fun_addtocartItem(
                                                          serviceitem,
                                                          k
                                                        )
                                                      }
                                                      variant="outlined"
                                                    >
                                                      Add
                                                    </Button>
                                                  )}
                                                  {existinCartlength > 0 && (
                                                    <div className="add-button-service add-button-service-6">
                                                      <Button
                                                        variant="text"
                                                        onClick={() =>
                                                          fun_RemovetocartItem(
                                                            serviceitem,
                                                            k
                                                          )
                                                        }
                                                      >
                                                        <RemoveSharpIcon className="add-icon-1" />
                                                      </Button>
                                                      <span>
                                                        {
                                                          existinCart[0][
                                                          "Quanity"
                                                          ]
                                                        }
                                                      </span>
                                                      <Button
                                                        variant="text"
                                                        onClick={() =>
                                                          fun_addtocartItem(
                                                            serviceitem,
                                                            k
                                                          )
                                                        }
                                                      >
                                                        <AddIcon className="add-icon-1" />
                                                      </Button>
                                                    </div>
                                                  )}
                                                </div>
                                              </Col>
                                              <div className="inner-service-1 inner-service-15 mobile-view">
                                                <ul>
                                                  <li>
                                                    Repair or replacement of one
                                                    waste pipe
                                                  </li>
                                                  <li>
                                                    Repair or replacement of one
                                                    waste pipe
                                                  </li>
                                                </ul>
                                                <Button
                                                  variant="text"
                                                  className="view-detail-service-page-1 mobil-view"
                                                >
                                                  <Link
                                                    to=""
                                                    // {`/acservicerepairdetails/${serviceitem["ItemID"]}`}
                                                    onClick={() =>
                                                      handleClickOpenServiceDetail(
                                                        0
                                                      )
                                                    }
                                                  >
                                                    View Details
                                                  </Link>
                                                </Button>
                                              </div>
                                            </Row>
                                          </div>
                                        </>
                                      );
                                    }
                                  })}
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            <h2>Service</h2>
                            <div
                              className="banner-div-2"
                              style={{ color: "#57473" }}
                            >
                              No record found
                            </div>
                          </>
                        )}
                      </Col>
                    </Row>
                  </div>
                  <br />
                  <br />
                  <br />
                  <div className="div-service-2 web-view">
                    {/* <Cart /> */}
                    <ShoppingCart cartItems={CardList} />
                  </div>

                  {/* <div className='div-service-2'>
                    <div className='empty-cart'>
                      <img src='../../../assets/images/shopping2.png' width="100%" />
                      <p>No items in your cart</p>
                    </div>
                    <div className='empty-cart'>
                      <h4>Cart</h4>
                      <h5>Deep clean AC service (split)</h5>
                      <div className='add-button-service'>
                        <Button variant="text"><RemoveSharpIcon className='add-icon-1' /></Button>
                        <span>2</span>
                        <Button variant="text"><AddIcon className='add-icon-1' /></Button>
                      </div>
                      <span className='price-span1'>₹699</span>
                      <p className='add1div'>Add 1 more service, Get 30% off 2nd one onwards</p>
                      <div className='view-cart-1'><span className='price-span1'>₹699</span> <Link to="/view-booking" onClick={() => AddItemData(itemName = "Haircut For Men", Price = "699", Quantity = "1", ItemDescription = "Get 2X deeper dust removal with foamjet technology", Servicetype = "Salon Prime for kids & men ( Screen 2 )")}>View Cart</Link></div>
                    </div>
                  </div> */}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <FooterMobile />

      <div className="web-view">
        <Footer />
      </div>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        className="ac-service-seeall-popup"
        BackdropProps={{
          style: { backgroundColor: "rgba(0, 0, 0, 0.9)" },
        }}
      >
        <DialogContent className="more-popup">
          <DialogContentText id="alert-dialog-slide-description">
            <div className="left-service-div-1 left-service-div-5">
              <h4>
                Category <span onClick={handleClose}>X</span>
              </h4>
              <FormGroup>
                <FormControlLabel
                  className="text-size"
                  control={<Checkbox defaultChecked />}
                  label="AC Repair and Service"
                />
                <FormControlLabel
                  className="text-size"
                  required
                  control={<Checkbox />}
                  label="Air Cooler Repair"
                />
                <FormControlLabel
                  className="text-size"
                  required
                  control={<Checkbox />}
                  label="Air Purifier"
                />
                <FormControlLabel
                  className="text-size"
                  required
                  control={<Checkbox />}
                  label="Laptop repair(at home)"
                />
                <FormControlLabel
                  className="text-size"
                  disabled
                  control={<Checkbox />}
                  label="Refrigerator"
                />
              </FormGroup>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        open={openServiceDetail}
        onClose={handleCloseServiceDetail}
        aria-labelledby="responsive-dialog-title"
        className="login-popup-1"
        BackdropProps={{
          style: { backgroundColor: "rgba(0, 0, 0, 0.9)" },
        }}
      >
        <h2 className="service-detail-12">
          {ServiceDetail?.["Category"]}
          <span>
            <CloseIcon
              className="cross-login-5"
              onClick={handleCloseServiceDetail}
            />
          </span>
        </h2>
        {/* {"_id":18,"Category":"Electrician, Plumber & Carpenter","Unit":"Unit 1","CGST":"1","SGST":"1",
        "ItemID":18,"LPR":"1800.00","MRP":"1500.00","MSP":"1800.00","SP":"300.00","IGST%":"1","For Sale":"Y",
        "For Purchase":"Y","Create Dt":"2023-11-14T16:44:42.793Z",
        "Item Pic":"https://dialerpstorage.blob.core.windows.net/clients-storage/Actual_Common_IMG-0-20231114164440.jpg",
        "Item Name":"Basin And Sink","Sub Category":"Plumbers","Sub Sub Category":"Basin & Sink",
        "Hsn Sac":"TEST","Item Type":"Service"} */}
        <div className="section-service section-service-12">
          <div className="div-service-1 div-service-10">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <h3 className="cs-padding-1 cs-padding-11">
                  {ServiceDetail?.["Sub Category"]}
                </h3>
                <div className="inner-service-1 inner-service-11">
                  <Row>
                    <Col xs={7} sm={12} md={7} lg={7}>
                      <p className="service-warrantry">
                        {ServiceDetail?.["Sub Sub Category"]}
                      </p>
                      <h3>{ServiceDetail["Item"]}</h3>
                      <p className="rating-star rating-star-5">
                        <StarsIcon className="star-icon3" />
                        4.84 (6.4 M Bookings)
                      </p>
                      {/* <p>{ServiceDetail["MRP"]}</p> */}
                      <p>&#8377;{parseFloat(ServiceDetail["MRP"] || 0)}| 30 min</p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: ServiceDetail?.["Item Description"],
                        }}
                      />
                    </Col>

                    <Col xs={5} sm={12} md={5} lg={5}>
                      <div className="service-images-2">
                        <img
                          src={ServiceDetail["Item Pic"]}
                          width="100%"
                          alt="Icon"
                        />

                        <Button
                          className="service-images-2-button"
                          variant="outlined"
                          onClick={() => { 
                            fun_addtocartItem(ServiceDetail, 1); 
                            handleCloseServiceDetail(); 
                        }}
                        >
                          Add
                        </Button>
                      </div>
                    </Col>
                    <div className="inner-service-1 inner-service-15 mobile-view">
                      <ul>
                        <li>Repair or replacement of one waste pipe</li>
                        <li>Repair or replacement of one waste pipe</li>
                        <li>Repair or replacement of one waste pipe</li>
                        <li>Repair or replacement of one waste pipe</li>
                      </ul>
                    </div>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={openCart}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseCart}
        aria-describedby="alert-dialog-slide-description"
        className="cart-popup-design"
        BackdropProps={{
          style: { backgroundColor: "rgba(0, 0, 0, 0.9)" },
        }}
      >
        <ClearIcon className="cross-icon-cart-2" onClick={handleCloseCart} />

        {/* <ShoppingCart  cartItems={CardList} /> */}
        <ShoppingCart cartItems={CardList} />
      </Dialog>
    </div>
  );
}

export default ServiceList;
