import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "react-calendar/dist/Calendar.css";
import Card from "react-bootstrap/Card";
import { Link, json } from "react-router-dom";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CallIcon from '@mui/icons-material/Call';
import StarRateIcon from '@mui/icons-material/StarRate';
import RepeatIcon from "@mui/icons-material/Repeat";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import moment from 'moment'
import Headerinner from '../Common/Headerinner';
import SideBar from "../Common/SideBar";
function Detailsjobs() {


    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="admin-panel-section">
            <Headerinner />

            <div className="my-orders" style={{ marginTop: "7%" }}>
                <Container fluid>
                    <Row>
                        <Col xs={12} sm={12} md={3} lg={2}>
                            <SideBar />
                        </Col>
                        <br></br>
                        <Col xs={12} sm={12} md={9} lg={10} className="right-side-section right-side-section-21">
                            <h2>Job History</h2>


                            <Link to="">

                                <div className="my-order-inner my-order-inner-5 my-order-inner-21">
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <div className="booking-div-10 booking-div-25">
                                            <h3>Location</h3>   
                                                <p>22, Mahaveer Nagar IInd, Maharani Farm (Durgapura, Tank Road), 
                                                    JDA Maharani Farm Park,
                                                    Mahaveer Nagar, Maharani Farm, Gayatri Nagar A, 
                                                    Durgapura, Jaipur, Rajasthan, India</p>
                                                    <div className="job-time-1">
                                                    <h2>Job Start</h2>
                                                    <p>08 Nov, 02:30 PM</p>
                                                    </div>
                                                    <div className="job-time-1">
                                                    <h2>Job End</h2>
                                                    <p>08 Nov, 02:30 PM</p>
                                                    </div>
                                            </div>
                                            <div className="booking-div-10 booking-div-26">
                                            <h3>Summary</h3>  
                                            <h2>Haircut for men<span>₹699</span></h2> 
                                            <h2>Haircut for men<span>₹699</span></h2> 
                                            <div className="other-payment-1">
<h3>Other</h3>
                                            <h2>Convenience Fee<span>₹49</span></h2> 

                                            <h2>Extra Job Amount<span>₹199</span></h2> 
                                            <h2>Total Amount<span>₹947</span></h2> 
                                            </div>


                                            
                                            </div>
                                        </Col>


                                    </Row>
                                </div>

                            </Link>

                           

                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}
export default Detailsjobs;