import React, { useState, useCallback } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ComposedChart,
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Bar,
} from "recharts";

import DashboardIcon from "@mui/icons-material/Dashboard";
import SearchIcon from "@mui/icons-material/Search";
import CategoryIcon from "@mui/icons-material/Category";
import StorefrontIcon from "@mui/icons-material/Storefront";
import EmojiFlagsIcon from "@mui/icons-material/EmojiFlags";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import ListAltIcon from "@mui/icons-material/ListAlt";

function SideBar() {
  var userLogininfo = JSON.parse(localStorage.getItem("user-Logininfo"));
  console.log(userLogininfo)
  if (userLogininfo === null) {
    window.location.href = "/";
  }
  return (
    <div className="left-menu">
      <Row>
        <Col xs={12} sm={12} md={12}>
          <ul>
            <li>
              <Link to="/admin/dashboard">
                <DashboardIcon className="icon-11" />
                Dashboard
              </Link>
            </li>
            {userLogininfo["User Group"] === "Employee" && (
              <>
                <li>
                  <Link to="/admin/commonlistpage/Category/none/1480">
                    {" "}
                    <ListAltIcon className="icon-11" /> Category
                  </Link>
                </li>
                <li>
                  <Link to="/admin/commonlistpage/Sub Category/none/1481">
                    {" "}
                    <ListAltIcon className="icon-11" /> Sub Category
                  </Link>
                </li>
                <li>
                  <Link to="/admin/commonlistpage/Sub Sub Category/none/1482">
                    {" "}
                    <ListAltIcon className="icon-11" /> Sub Sub Category
                  </Link>
                </li>
                <li>
                  <Link to="/admin/commonlistpage/Service/none/1478">
                    {" "}
                    <ListAltIcon className="icon-11" /> Service
                  </Link>
                </li>
                <li>
                  <Link to="/common/newJobs">
                    <SubdirectoryArrowRightIcon className="icon-11" />
                    New Jobs
                  </Link>
                </li>
                <li>
                  <Link to="/common/JobHistory">
                    <SubdirectoryArrowRightIcon className="icon-11" />
                    All Jobs History
                  </Link>
                </li>
              </>
            )}
            {userLogininfo["User Group"] === "Service Provider" && (
              <>
                <li>
                  <Link to="/common/newJobs">
                    <SubdirectoryArrowRightIcon className="icon-11" />
                    New Jobs
                  </Link>
                </li>
                <li>
                  <Link to="/common/Todayjobs/In Process">
                    <SubdirectoryArrowRightIcon className="icon-11" />
                    Ongoing Bookings
                  </Link>
                </li>
                <li>
                  <Link to="/common/JobHistory">
                    <SubdirectoryArrowRightIcon className="icon-11" />
                    All Jobs History
                  </Link>
                </li>
              </>
            )}
          </ul>
        </Col>
      </Row>
    </div>
  );
}

export default SideBar;
