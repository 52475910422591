import React, { useEffect, useState } from "react";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";
import { Link } from 'react-router-dom';

function AdminLogin() {
  const ID = "826578659859-sl1ds9uajcok1allt6dks9rdrs68aq7f.apps.googleusercontent.com";
  localStorage.removeItem("user-Logininfo");
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  let authToken = `${process.env.REACT_APP_BASE_AUTHKEY}`;
  let baseURL ="http://dialerpwebapis-dev-as.azurewebsites.net";//= `${process.env.REACT_APP_BASE_URL}`;
  let baseTokenKey = `${process.env.REACT_APP_BASE_KEY}`.replace(/'/g, '');
  function fun_DyLogin() {
    if (username.trim() === "") {
      alert('Please enter username');
      return;
    }
    else if (!emailRegex.test(username.trim())) {
      alert('Please enter a valid email address');
      return;
    }
    else if (password.trim() === "''") {
      alert('Please enter password');
      return;
    }
    else {
      let filters = "{'UserGroup':'Employee'}";
      const loginPra = {
        "baseTokenKey": baseTokenKey,
        "EmailID": username,
        "password": password,
        "filters": filters
      }
      fetch(
        baseURL + "/api/AppApi/LoginDynamicWithEmialAndPassword",
        {
          method: "POST",
          headers: {
            "Authorization-Token": authToken,
            Accept: "application/json, text/plain",
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: JSON.stringify(loginPra),
        } 
      ).then((result) => {
        result.json().then((resp) => {
          if (resp.hasErrors === false) {
            localStorage.setItem("Auth-Token", JSON.stringify(resp.response.loginKey));
            localStorage.setItem("user-Logininfo", JSON.stringify(resp.response.user));
            // //setLoading(false); // Hide loader
            // document.getElementById('candidate').click();
           // alert(resp.response.loginKey);
            window.location.href = '/admin/dashboard';
          }
          else {
            alert("Invalid username or password.");
          }

        });
      });
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    
      <div className="login-18 login-18-Customer-login">
        {/* <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12"> */}
              <div className="form-section">
                <div className="logo-2">
                  <Link to="/">
                    <img src="../assets/images/logo1.svg" alt="logo" />
                  </Link>
                </div>
                <div className="typing">
                  <h3>Admin Login </h3>
                </div>
                <div className="form-group">
                  <label
                    htmlFor="second_field"
                    className="form-label float-start"
                  >
                    Email Address
                  </label>
                  <input
                    name="email"
                    type="email"
                    className="form-control"
                    id="second_field"
                    placeholder="Email Address"
                    aria-label="Email Address"

                    value={username} onChange={(e) => { setUsername(e.target.value); }}
                  />
                </div>
                <div className="form-group clearfix">
                  <label htmlFor="third_field" className="form-label float-start">
                    Password
                  </label>
                  <input
                    name="password"
                    type="password"
                    className="form-control"
                    autoComplete="off"
                    id="third_field"
                    placeholder="Password"
                    aria-label="Password"
                    value={password} onChange={(e) => { setPassword(e.target.value); }}
                  />
                </div>
                <div className="checkbox form-group clearfix">
                  <div className="form-check checkbox-theme">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue=""
                      id="rememberMe"
                    />
                    <label className="form-check-label" htmlFor="rememberMe">
                      Remember me
                    </label>
                  </div>
                  <a href="#" className="float-end">
                  {/* forgot-password-18.html */}
                    Forgot Password
                  </a>
                </div>
                <div className="form-group clearfix">
                  <button type="submit" className="btn btn-primary btn-lg btn-theme" onClick={fun_DyLogin}>
                    <b>Login</b>
                  </button>
                </div>
                <div className="social-list">
                  <span>Or Login With</span><br /><br />
                  <GoogleOAuthProvider style={{}} clientId={ID} className="google22">
                    <GoogleLogin className="google22"
                      onSuccess={credentialResponse => {
                        var decoded = jwt_decode(credentialResponse.credential);
                        console.log(decoded);
                        localStorage.setItem("Buyer-Login", JSON.stringify(decoded));
                        //  navigate("/");

                        ;
                      }}
                      onError={() => {
                        console.log('Login Failed');
                      }}
                    />
                  </GoogleOAuthProvider>
                </div>
                <div className="clearfix" />
                <p>
                  Don't have an account?
                  <Link to="#"> Register here</Link>
                </p><br />
              </div>
            </div>
      //     </div>
      //   </div>
      // </div>
    
  )
}

export default AdminLogin
