import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "react-calendar/dist/Calendar.css";
import Card from "react-bootstrap/Card";
// import { Link, json, useParams } from "react-router-dom";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CallIcon from "@mui/icons-material/Call";
import StarRateIcon from "@mui/icons-material/StarRate";
import RepeatIcon from "@mui/icons-material/Repeat";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import moment from "moment";
import Headerinner from "../Common/Headerinner";
import SideBar from "../Common/SideBar";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import PhoneForwardedOutlinedIcon from "@mui/icons-material/PhoneForwardedOutlined";
import AssistantDirectionOutlinedIcon from "@mui/icons-material/AssistantDirectionOutlined";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import WhereToVoteOutlinedIcon from "@mui/icons-material/WhereToVoteOutlined";
function HistoryDetails() {
  const { BookingID } = useParams();

  const [expanded, setExpanded] = React.useState(false);
  const [jobsHDetails, setjobsHDetails] = useState("");
  const [CustomerRemark, setCustomerRemark] = useState([]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  var userLogininfo = JSON.parse(localStorage.getItem("user-Logininfo"));
  if (userLogininfo === null) {
    window.location.href = "/";
  }

  let authToken = `${process.env.REACT_APP_BASE_AUTHKEY}`;
  let baseURL = `${process.env.REACT_APP_BASE_URL}`;
  let datetyepe = "";
  useEffect(() => {
    fun_Jobs_History_Details();
    window.scrollTo(0, 0);
  }, []);
  function fun_Jobs_History_Details() {
    let newusername = "{BookingID:" + BookingID + "}";
    const getparam = {
      pgSize: 100,
      filterButtons: "",
      tabErpID: 1258,
      columnID: 1258014,
      statusID: 0,
      statusName: "All",
      color: "#262626",
      index: 1,
      toEmailID: "designMonu@dialerp.com",
      useAtlusSearch: true,
      filters: newusername,
    };
    fetch(baseURL + "/api/dynamic/GetRecords", {
      method: "POST",
      headers: {
        "Authorization-Token": authToken,
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(getparam),
    }).then((result) => {
      result.json().then((resp) => {
        if (resp.hasErrors === false) {
          if (resp.response?.records !== null) {
            if (resp.response?.records.length > 0) {
              setjobsHDetails(resp.response);
              if (
                resp.response?.records[0]["Customer Remarks"] !=
                "[object Object]" &&
                resp.response?.records[0]["Customer Remarks"] != null &&
                resp.response?.records[0]["Customer Remarks"] != undefined &&
                resp.response?.records[0]["Customer Remarks"] != ""
              ) {
                setCustomerRemark(
                  JSON.parse(resp.response?.records[0]["Customer Remarks"])
                );
              }
            }
          }
        } else {
          alert(resp.errors);
        }
      });
    });
  }

  return (
    <div className="admin-panel-section">
      <Headerinner />
      <div className="admin-body-section admin-div-1">
        <Container fluid>
          <Row>
            <Col xs={12} sm={12} md={3} lg={2}>
              <SideBar />
            </Col>
            <br></br>
            <Col xs={12} sm={12} md={9} lg={10}>
              <div className="job-history-section job-history-section-2">
                <h2>Customer Details</h2>
                <Row>
                  {jobsHDetails?.records?.map((status, i) => {
                    const utcDate = new Date(status["Appointment Dt"]);
                    const localDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000);
                    const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false };
                    const formattedDate1 = localDate.toLocaleString('en-US', options);
                    const formattedDate = moment(formattedDate1).format("DD MMM");
                    const formattedTime = moment(formattedDate1).format("hh:mm A");


                    const CutcDate = new Date(status["Job Complete Dt"]);
                    const ClocalDate = new Date(CutcDate.getTime() + CutcDate.getTimezoneOffset() * 60000);
                    const Coptions = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false };
                    const CformattedDate1 = ClocalDate.toLocaleString('en-US', Coptions);
                    const CformattedDate = moment(CformattedDate1).format("DD MMM");
                    const CformattedTime = moment(CformattedDate1).format("hh:mm A");
                    // datetyepe = moment(status["Appointment Dt"]).format("DD MMM");
                    return (
                      <>
                        <Col xs={12} sm={6} md={6} lg={6}>
                          <div className="job-history-section-inner">
                            <h3 >
                              {status["Contact Name"]} ( {status["Contact Mobile"]} )
                            </h3>
                            <h3> {formattedDate + ' ' + formattedTime} </h3>

                            <div
                              className="left-view-booking-1 left-view-address-1 address-active 
                                            job-history-section-inner job-history-section-inner-2"
                            >
                              <WhereToVoteOutlinedIcon className="address-icon-1 clock-icon-2" />

                              <p>
                                {status["Address"]} , {status["City"]} ,{" "}
                                {status["State"]} ,{status["Country"]} ,{" "}
                                {status["State"]} - {status["Pincode"]}
                              </p>
                            </div>

                            <div className="job-div-1">
                              Job Start : {formattedDate + ' ' + formattedTime}
                            </div>
                            <div className="job-div-2">
                              Job End : {CformattedDate != "Invalid date" && CformattedTime != "Invalid date" ? CformattedDate + ' ' + CformattedTime : ""}
                            </div>
                          </div>
                        </Col>

                        <Col xs={12} sm={6} md={6} lg={6}>
                          <div className="payment-summary-popup-design">
                            {/* <DialogContentText id="alert-dialog-slide-description"> */}

                            <Row>
                              <Col xs={12} sm={12} md={12} lg={12}>
                                <h2 className="reason-1">
                                  Payment Summary <span></span>
                                </h2>
                              </Col>
                              {CustomerRemark?.CardDetails?.map(
                                (payment, i) => {
                                  return (
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                      <h6>
                                        {payment?.data["Item Name"]} x{" "}
                                        {payment["Quanity"]}{" "}
                                        <span>₹{parseFloat(payment?.data["MRP"] || 0) * payment["Quanity"]}</span>
                                      </h6>
                                      <p className="subTitle">
                                        {payment?.data["Item Name"]} x{" "}
                                        {payment["Quanity"]}
                                      </p>
                                    </Col>
                                  );
                                }
                              )}
                              <Col xs={12} sm={12} md={12} lg={12}>
                                <div className="paymentsummary-div-1">
                                  <h6>
                                    Taxes and Fee <span>₹{Math.round(parseFloat(CustomerRemark["TaxAmt"]))}</span>
                                  </h6>
                                  <p>
                                    Taxes levied as per Govt. regulations,
                                    subject to change basis final service value.
                                    The fee goes towards training of partners
                                    and providing support & assistance during
                                    the service.
                                  </p>
                                </div>
                              </Col>
                              <div className="box-shadow-div-2">
                                <Col xs={12} sm={12} md={12} lg={12}>
                                  <h5>
                                    Convenience Fee
                                    <span>
                                      ₹0
                                    </span>
                                  </h5>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                  <h5>
                                    Offer Discount
                                    <span>
                                      ₹{Math.round(parseFloat(CustomerRemark["Discount"]))}
                                    </span>
                                  </h5>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                  <h5 className="cs-55">
                                    Amount Paid{" "}
                                    <span>
                                    {status["Stage"]==="Cancel Service"?<p>₹0</p>: <p>
                                        <span>₹{Math.round(parseFloat(CustomerRemark["BalAmt"]))}</span>
                                      </p>}
                                     
                                    </span>
                                  </h5>
                                </Col>

                                <Col xs={12} sm={12} md={12} lg={12}>
                                  <h5>
                                    Payment mode <span>UPI</span>
                                  </h5>
                                </Col>
                              </div>
                            </Row>

                          
                          </div>
                        </Col>
                      </>
                    );
                  })}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
export default HistoryDetails;
