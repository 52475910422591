/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import StarIcon from "@mui/icons-material/Star";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import Button from "@mui/material/Button";

function Service1() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };
  return (
    <div className="top-space">
      <Container fluid className="section-subbanner">
        <Row>
          <Col xs={12} sm={12} md={12}>
            <h2>New and noteworthy</h2>
            <Carousel responsive={responsive}>
              <div>
                <img src="../assets/images/s1.webp" className="sb-image" />
                <p>Native Water Purifier</p>
              </div>
              <div>
                <img
                  src="../assets/images/most-book/13.jpg"
                  className="sb-image"
                />
                <p>Bathroom & kitchen cleaning</p>
              </div>
              <div>
                <img
                  src="../assets/images/most-book/14.jpg"
                  className="sb-image"
                />
                <p>Mixer & Grinder Repair</p>
              </div>
              <div>
                <img
                  src="../assets/images/most-book/11.jpg"
                  className="sb-image"
                />
                <p>Home painting</p>
              </div>
              <div>
                <img
                  src="../assets/images/most-book/12.jpg"
                  className="sb-image"
                />
                <p>Inverter service and repair</p>
              </div>
              <div>
                <img
                  src="../assets/images/most-book/15.jpg"
                  className="sb-image"
                />
                <p>Laptop repair (at home)</p>
              </div>
              <div>
                <img
                  src="../assets/images/most-book/16.jpg"
                  className="sb-image"
                />
                <p>Spa ayurveda</p>
              </div>
              <div>
                <img
                  src="../assets/images/most-book/17.jpg"
                  className="sb-image"
                />
                <p>Nail studio for women</p>
              </div>
              <div>
                <img
                  src="../assets/images/most-book/18.jpg"
                  className="sb-image"
                />
                <p>Hair studio for women</p>
              </div>
              <div>
                <img
                  src="../assets/images/most-book/5.jpg"
                  className="sb-image"
                />
                <p>AC Service & Repair</p>
              </div>
              <div>
                <img
                  src="../assets/images/most-book/8.jpg"
                  className="sb-image"
                />
                <p>Laser hair reduction </p>
              </div>
            </Carousel>
          </Col>
        </Row>
      </Container>

      <Container fluid className="section-subbanner">
        <Row>
          <Col xs={12} sm={12} md={12}>
            <h2>Most booked services</h2>
            <Carousel responsive={responsive}>
              <div>
                <img
                  src="../assets/images/most-book/1.jpg"
                  className="sb-image"
                />
                <p>Intense bathroom cleaning</p>
                <p className="rating-1">
                  <StarIcon className="star1" />
                  4.79 (12.9k)
                </p>
                <p className="price-1">
                  <CurrencyRupeeIcon className="price1" />
                  519
                </p>
              </div>
              <div>
                <img
                  src="../assets/images/most-book/2.jpg"
                  className="sb-image"
                />
                <p>Sofa cleaning</p>
                <p className="rating-1">
                  <StarIcon className="star1" />
                  4.79 (12.9k)
                </p>
                <p className="price-1">
                  <CurrencyRupeeIcon className="price1" />
                  739
                </p>
              </div>
              <div>
                <img
                  src="../assets/images/most-book/3.jpg"
                  className="sb-image"
                />
                <p>Haircut for men</p>
                <p className="rating-1">
                  <StarIcon className="star1" />
                  4.79 (12.9k)
                </p>
                <p className="price-1">
                  <CurrencyRupeeIcon className="price1" />
                  249
                </p>
              </div>
              <div>
                <img
                  src="../assets/images/most-book/4.jpg"
                  className="sb-image"
                />
                <p>Classic bathroom cleaning</p>
                <p className="rating-1">
                  <StarIcon className="star1" />
                  4.79 (12.9k)
                </p>
                <p className="price-1">
                  <CurrencyRupeeIcon className="price1" />
                  419
                </p>
              </div>
              <div>
                <img
                  src="../assets/images/most-book/5.jpg"
                  className="sb-image"
                />
                <p>AC repair (split/ window)</p>
                <p className="rating-1">
                  <StarIcon className="star1" />
                  4.79 (12.9k)
                </p>
                <p className="price-1">
                  <CurrencyRupeeIcon className="price1" />
                  299
                </p>
              </div>
              <div>
                <img
                  src="../assets/images/most-book/6.jpg"
                  className="sb-image"
                />
                <p>Painting & Waterproofing</p>
                <p className="rating-1">
                  <StarIcon className="star1" />
                  4.79 (12.9k)
                </p>
                <p className="price-1">
                  <CurrencyRupeeIcon className="price1" />
                  49
                </p>
              </div>
              <div>
                <img src="../assets/images/s18.webp" className="sb-image" />
                <p>TV Repair</p>
                <p className="rating-1">
                  <StarIcon className="star1" />
                  4.79 (12.9k)
                </p>
                <p className="price-1">
                  <CurrencyRupeeIcon className="price1" />
                  299
                </p>
              </div>
              <div>
                <img
                  src="../assets/images/most-book/8.jpg"
                  className="sb-image"
                />
                <p>Stress relief swedish massage</p>
                <p className="rating-1">
                  <StarIcon className="star1" />
                  4.79 (12.9k)
                </p>
                <p className="price-1">
                  <CurrencyRupeeIcon className="price1" />
                  1,299
                </p>
              </div>
              <div>
                <img
                  src="../assets/images/most-book/13.jpg"
                  className="sb-image"
                />
                <p>Intense cleaning - 2 bathroom pack</p>
                <p className="rating-1">
                  <StarIcon className="star1" />
                  4.79 (12.9k)
                </p>
                <p className="price-1">
                  <CurrencyRupeeIcon className="price1" />
                  938
                </p>
              </div>
              <div>
                <img
                  src="../assets/images/most-book/4.jpg"
                  className="sb-image"
                />
                <p>Classic cleaning - 2 bathroom pack</p>
                <p className="rating-1">
                  <StarIcon className="star1" />
                  4.79 (12.9k)
                </p>
                <p className="price-1">
                  <CurrencyRupeeIcon className="price1" />
                  758{" "}
                  <span>
                    <del>
                      <CurrencyRupeeIcon className="price1" />
                      838
                    </del>
                  </span>
                </p>
              </div>
            </Carousel>
          </Col>
        </Row>
      </Container>
      <Container fluid className="section-subbanner">
        <Row>
          <Col xs={12} sm={12} md={12}>
            <h2>
              Salon for women
              <span>
                <Button
                  variant="contained"
                  size="medium"
                  className="service-button"
                  onClick={() => (window.location.href=("/service/Women's%20Salon,%20Spa%20&%20Laser%20Clinic/Salon%20for%20women"))}
                >
                  See All
                </Button>
              </span>
            </h2>
            <Carousel responsive={responsive}>
              <div className="service-section-3">
                <p>Waxing</p>
                <img src="../assets/images/waxing.jpg" className="sb-image-2" />
              </div>
              <div className="service-section-3">
                <p>Pedicure</p>
                <img src="../assets/images/pedi.jpg" className="sb-image-2" />
              </div>
              <div className="service-section-3">
                <p>Facial & cleanup</p>
                <img src="../assets/images/facial.jpg" className="sb-image-2" />
              </div>
              <div className="service-section-3">
                <p>Manicure</p>
                <img
                  src="../assets/images/manicure.jpg"
                  className="sb-image-2"
                />
              </div>
              <div className="service-section-3">
                <p>Threading & face wax</p>
                <img
                  src="../assets/images/facewax.jpg"
                  className="sb-image-2"
                />
              </div>
              <div className="service-section-3">
                <p>Bleach & detan</p>
                <img src="../assets/images/bleach.jpg" className="sb-image-2" />
              </div>
              <div className="service-section-3">
                <p>Haircare</p>
                <img src="../assets/images/hair.jpg" className="sb-image-2" />
              </div>
            </Carousel>
          </Col>
        </Row>
      </Container>
      <Container fluid className="section-subbanner">
        <Row>
          <Col xs={12} sm={12} md={12}>
            <img src="../assets/images/banner-all/banner2.png" width="100%" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default Service1;
