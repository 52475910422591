import React, { useState, useCallback, useContext } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import Badge from "@mui/material/Badge";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuIcon from "@mui/icons-material/Menu";
import Offcanvas from "react-bootstrap/Offcanvas";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CategoryIcon from "@mui/icons-material/Category";
import StorefrontIcon from "@mui/icons-material/Storefront";
import EmojiFlagsIcon from "@mui/icons-material/EmojiFlags";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import { ProductContext } from "../context/AppContext";
function Headerinner() {
  const { handleClickAdminLogin } = useContext(ProductContext);
  var userLogininfo = JSON.parse(localStorage.getItem("user-Logininfo"));

  const fun_LogOut = () => {
    localStorage.clear();
    document.getElementById("Logout").click();
  };
  if (userLogininfo === null) {
    fun_LogOut();
  }
  const [showDiv1, setShowDiv1] = useState(true);
  const handleButtonClick = () => {
    setShowDiv1(!showDiv1);
  };

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose11 = () => {
    setAnchorEl(null);
  };
  const handleClose = () => setShow(false);

  return (
    <>
      <Link to="/" id="Logout"></Link>
      <div className="header-admin">
        <Container fluid>
          <Row>
            <Col xs={6} sm={4} md={3} lg={2} className="left-side-panel-1">
              <div className="admin-logo">
                <Link to="/">
                  <img
                    src="../../../../../assets/images/logo1.svg"
                    width="50%"
                  />
                </Link>
                {/* <MenuIcon
                  className="admin-menu-icon"
                  onClick={handleButtonClick}
                />
                <MenuIcon
                  className="admin-menu-icon-mobile"
                  onClick={handleShow}
                /> */}
              </div>
            </Col>
            <Col xs={6} sm={8} md={9} lg={10} className="right-side-panel-1">
              <div className="welcome-section">
                <h2>
                  <span className="welcome-section-right">
                    {/* <SearchIcon className="icon-2" />
                    <span className="icon-space-5">
                      <Badge badgeContent={4} color="primary">
                        <NotificationsNoneIcon className="icon-5" />
                      </Badge>
                    </span> */}
                    <Button
                      id="basic-button"
                      className="user-button"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                    >
                      {userLogininfo?.["ProfilePic"] !== undefined &&
                      userLogininfo?.["ProfilePic"] !== null ? (
                        <img
                          src={userLogininfo?.["ProfilePic"]}
                          className="profile-image"
                          alt="Profile"
                        />
                      ) : (
                        <img
                          src="../../../../../assets/images/profile-icon.png"
                          className="profile-image"
                          alt="Profile"
                        />
                      )}
                      {/* <img src="../../assets/images/profile.jpeg" className='profile-image' alt="Profile" />  */}
                      <span className="display-none-on-mobile">
                        {userLogininfo?.["User Name"]}
                      </span>
                      <KeyboardArrowDownIcon />
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose11}
                      MenuListProps={{ "aria-labelledby": "basic-button" }}
                    >
                      <MenuItem>
                        <Link to="/Common/SPProfile" className="btn_Link">
                          Profile
                        </Link>
                      </MenuItem>
                      {/* <MenuItem onClick={handleClose11}>My account</MenuItem> */}
                      <MenuItem onClick={() => fun_LogOut()}>Logout</MenuItem>
                      {/* {userLogininfo?.["User Group"] === "Employee" ?
                        <MenuItem onClick={() => fun_LogOut()}>Logout</MenuItem>
                        : ""
                      }
                      {userLogininfo?.["User Group"] === "Service Provider" ?
                      // <MenuItem><Link to="/serviceprovider/login" className="btn_Link">Logout</Link></MenuItem>
                        <MenuItem onClick={() => fun_LogOut()}>Logout</MenuItem>
                        : ""
                      } */}
                    </Menu>
                  </span>
                </h2>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <img src="../../../../../assets/images/logo1.svg" width="50%" />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="left-menu-2">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <ul>
                  <li>
                    <Link to="/admin/dashboard">
                      <DashboardIcon className="icon-11" />
                      Dashboard
                    </Link>
                  </li>

                  {userLogininfo?.["User Group"] === "Employee" && (
                    <>
                      <li>
                        <Link to="/admin/commonlistpage/Category/none/1480">
                          {" "}
                          <SubdirectoryArrowRightIcon className="icon-11" />{" "}
                          Category
                        </Link>
                      </li>
                      <li>
                        <Link to="/admin/commonlistpage/Sub Category/none/1481">
                          {" "}
                          <SubdirectoryArrowRightIcon className="icon-11" /> Sub
                          Category
                        </Link>
                      </li>
                      <li>
                        <Link to="/admin/commonlistpage/Sub Sub Category/none/1482">
                          {" "}
                          <SubdirectoryArrowRightIcon className="icon-11" /> Sub
                          Sub Category
                        </Link>
                      </li>
                      <li>
                        <Link to="/admin/commonlistpage/Service/none/1478">
                          {" "}
                          <SubdirectoryArrowRightIcon className="icon-11" />{" "}
                          Service
                        </Link>
                      </li>
                      <li>
                        <Link to="/common/newJobs">
                          <SubdirectoryArrowRightIcon className="icon-11" />
                          New Jobs
                        </Link>
                      </li>
                      <li>
                        <Link to="/common/JobHistory">
                          <SubdirectoryArrowRightIcon className="icon-11" />
                          All Jobs History
                        </Link>
                      </li>
                    </>
                  )}
                  {userLogininfo["User Group"] === "Service Provider" && (
                    <>
                      <li>
                        <Link to="/common/newJobs">
                          <SubdirectoryArrowRightIcon className="icon-11" />
                          New Jobs
                        </Link>
                      </li>
                      <li>
                        <Link to="/common/Todayjobs/In Process">
                          <SubdirectoryArrowRightIcon className="icon-11" />
                          Ongoing Bookings
                        </Link>
                      </li>
                      <li>
                        <Link to="/common/JobHistory">
                          <SubdirectoryArrowRightIcon className="icon-11" />
                          All Jobs History
                        </Link>
                      </li>
                    </>
                  )}
                </ul>
              </Col>
            </Row>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default Headerinner;
