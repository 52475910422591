import React, { useState, useEffect, useContext } from "react";
import { useHistory } from 'react-router-dom';
import Button from "@mui/material/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "react-calendar/dist/Calendar.css";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { ProductContext } from "../context/AppContext";
import StarsIcon from "@mui/icons-material/Stars";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Header from "../Header";
import Footer from "../Footer";
import FooterMobile from "../FooterMobile";
import FooterAfterLogin from "../FooterAfterLogin";
function MyWishlist() {
  const { FavoriteList, setFavoriteList, CardList, setCardList, totalAmont } = useContext(ProductContext);
  useEffect(() => {
    const Favorites = JSON.parse(localStorage.getItem("FavoriteList")) || [];
    setFavoriteList(Favorites);
    window.scrollTo(0, 0);
  }, [setFavoriteList]);
  const fun_UnMarkFavorite = (dataToRemove) => {
    // const existingFavorites = JSON.parse(localStorage.getItem("FavoriteList")) || [];
    const updatedFavorites = FavoriteList.filter(
      (item) => item["ItemID"] !== dataToRemove
    );
    setFavoriteList(updatedFavorites);
    localStorage.setItem("FavoriteList", JSON.stringify(updatedFavorites));
  };
  const Movetocart = (data, k) => {
  
    console.log(CardList, "moveto");
    const updatedCard = [...CardList, { data, ItemID: data["ItemID"], ItemTotal: parseFloat(data["MRP"]), Quanity: 1 }];
    setCardList(updatedCard);
    localStorage.setItem("CartItemms", JSON.stringify(updatedCard));
    console.log(CardList, "moveto");
    let newArray = [...FavoriteList];

    // Remove the element at the specified index
    newArray.splice(k, 1);
    //setFavoriteList(newArray);
      window.location.href = "/customer/view-booking/0";
    

  }
  return (
    <div>
      {/* Web Version */}
      <div className="web-view background-my-booking">
        <Header />
        <div className="top-space top-margin">
          <div className="my-orders section-wishlist">
            <Container>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <h2>My Wishlist</h2>
                </Col>

                {FavoriteList.length === 0 ? (
                  <>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <div className="wishlist-div-5">
                        <img src="../../assets/images/wishlist.png" />
                        <p>No items in your Wishlist</p>
                      </div>
                    </Col>
                  </>
                ) : (
                  <>
                    {FavoriteList?.map((serviceitem, k) => {
                      return (
                        <>
                          <Col xs={12} sm={12} md={12} lg={6}>
                            <div className="section-service-12">
                              <div className="div-service-25 div-service-10 div-service-box-shadow">


                                <div className="inner-service-1 inner-service-11">
                                  <Row>
                                    <Col xs={7} sm={12} md={8} lg={8}>
                                      <p className="service-warrantry">
                                        {serviceitem["Sub Sub Category"]}
                                      </p>
                                      <h3>{serviceitem["Item"]}</h3>
                                      <p className="rating-star rating-star-5">
                                        <StarsIcon className="star-icon3" />
                                        4.84 (6.4 M Bookings)
                                      </p>
                                      <p>
                                        ₹
                                        {parseFloat(
                                          serviceitem["MRP"] || 0
                                        )}
                                        <span className="price-delete"><del>₹
                                          {parseFloat(
                                            serviceitem["MRP"] || 0
                                          )}</del></span>

                                      </p>
                                    </Col>

                                    <Col xs={5} sm={12} md={4} lg={4}>
                                      <div className="service-images-2">
                                        <CloseIcon
                                          className="wishlist-icon-cart"
                                          onClick={() =>
                                            fun_UnMarkFavorite(
                                              serviceitem["ItemID"]
                                            )
                                          }
                                        />
                                        <img
                                          src={serviceitem?.["Item Pic"]}
                                          width="100%"
                                          alt="Icon"
                                        />

                                        <Button
                                          className="service-images-2-button-wishlist"
                                          variant="outlined"
                                          onClick={() => Movetocart(serviceitem, k)}
                                        >
                                          Move To Cart
                                        </Button>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </>
                      );
                    })}
                  </>
                )}
              </Row>
            </Container>
          </div>
        </div>
        <FooterAfterLogin />
      </div>
      {/* Web Version */}

      {/* Mobile Version */}
      <div className="mobile-view">
        <div className="">
          <div className="my-orders">
            <h2 className="mobile-header-1">
              <Link to="/">
                <ArrowBackIcon className="mobile-header-1-left-icon" />
              </Link>
              My Wishlist
              <span className="mobile-search-icon-1">
                <SearchOutlinedIcon />
              </span>
            </h2>
            <div className="top-space-mobile">
              <Container fluid>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <h2>My Wishlist</h2>
                  </Col>

                  {FavoriteList.length === 0 ? (
                    <>
                      <Col xs={12} sm={12} md={12} lg={12}>
                        <div className="wishlist-div-5">
                          <img src="../../assets/images/wishlist.png" />
                          <p>No items in your Wishlist</p>
                        </div>
                      </Col>
                    </>
                  ) : (
                    <>
                      {FavoriteList?.map((serviceitem, k) => {
                        return (
                          <>
                            <Col xs={12} sm={12} md={3} lg={2}>
                              <div className="section-service-12">
                                <div className="div-service-1 div-service-10 div-service-border-radius">

                                  {/* <h3 className="cs-padding-1 cs-padding-11 cs-padding-15">
                                        {serviceitem["Item Name"]}
                                      </h3> */}
                                  <div className="inner-service-1 inner-service-11 inner-service-12">
                                    <Row>
                                      <Col xs={7} sm={8} md={7} lg={7}>
                                        <p className="service-warrantry">
                                          {serviceitem["Item Name"]}
                                        </p>
                                        <h3>{serviceitem["Item Name"]}</h3>
                                        <p className="rating-star rating-star-5">
                                          <StarsIcon className="star-icon3" />
                                          4.84 (6.4 M Bookings)
                                        </p>
                                        <p>
                                          ₹
                                          {parseFloat(
                                            serviceitem["MRP"] || 0
                                          )}
                                        </p>
                                      </Col>

                                      <Col xs={5} sm={4} md={5} lg={5}>
                                        <div className="service-images-2">
                                          <CloseIcon
                                            className="wishlist-icon-cart"
                                            onClick={() =>
                                              fun_UnMarkFavorite(
                                                serviceitem["ItemID"]
                                              )
                                            }
                                          />
                                          <img
                                            src={serviceitem?.["Item Pic"]}
                                            width="100%"
                                            alt="Icon"
                                          />

                                          <Button
                                            className="service-images-2-button-wishlist"
                                            variant="outlined"
                                          >
                                            Move To Cart
                                          </Button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>

                                </div>
                              </div>
                            </Col>
                          </>
                        );
                      })}
                    </>
                  )}
                </Row>
              </Container>
            </div>
          </div>
        </div>
        <FooterMobile />
      </div>
      {/* Mobile Version */}
    </div>
  );
}
export default MyWishlist;
