// apiUtils.js
const baseURL = process.env.REACT_APP_BASE_URL;

// after login call with token



export const fetchWrapperForLogin = async (Parameter, endpoint, token) => {
    if (endpoint !== "" && token !== "") {
        try {
            const response = await fetch(baseURL + "/api/" + endpoint, {
                method: "POST",
                headers: {
                    "Authkey": token,
                    Accept: "application/json, text/plain",
                    "Content-Type": "application/json;charset=UTF-8",
                },
                body: JSON.stringify(Parameter),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
        } catch (error) {
            // console.error("Error fetching user information:", error);
            throw new Error("Failed to fetch user information. Please try again.");
        }
    }
    else {
        const response = {
            errorCode: "HTTP error"
            , errors: "Request Not Valid"
            , hasErrors: true
            , response: null
        }
        return response;
    }
};
export const fetchWrapper = async (Parameter, endpoint, token) => {
    if (endpoint !== "" && token !== "") {
        try {
          
            const response = await fetch(baseURL + "/api/" + endpoint, {
                method: "POST",
                headers: {
                    "Authorization-Token": token,
                    Accept: "application/json, text/plain",
                    "Content-Type": "application/json;charset=UTF-8",
                },
                body: JSON.stringify(Parameter),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            return response.json();
        } catch (error) {
            console.error("Error fetching user information:", error);
            throw new Error("Failed to fetch user information. Please try again.");
        }
    }
    else {
        const response = {
            errorCode: "HTTP error"
            , errors: "Request Not Valid"
            , hasErrors: true
            , response: null
        }
        return response;
    }
};
export const fetchApiWithLoginToken = async (Parameter, endpoint) => {
    let authToken = localStorage.getItem("Auth-Token").replace(/"/g, '');
    if (endpoint !== "") {
        try {
            const response = await fetch(baseURL + "/api/" + endpoint, {
                method: "POST",
                headers: {
                    "Authorization-Token": authToken,
                    Accept: "application/json, text/plain",
                    "Content-Type": "application/json;charset=UTF-8",
                },
                body: JSON.stringify(Parameter),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            return response.json();
        } catch (error) {
            console.error("Error fetching user information:", error);
            throw new Error("Failed to fetch user information. Please try again.");
        }
    }
    else {
        const response = {
            errorCode: "HTTP error"
            , errors: "Request Not Valid"
            , hasErrors: true
            , response: null
        }
        return response;
    }
};