import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "./Header";
import Footer from "./Footer";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Card from "react-bootstrap/Card";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import GradeIcon from "@mui/icons-material/Grade";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import TextField from "@mui/material/TextField";
import { Link, useParams } from 'react-router-dom';
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import AddAddress from "./Customer/AddAddress";
import DeliveryAddress from "./Customer/DeliveryAddress";
import Badge from "react-bootstrap/Badge";
import { ProductContext } from "./context/AppContext";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import ClearIcon from "@mui/icons-material/Clear";

import AddIcon from '@mui/icons-material/Add';
import RemoveSharpIcon from '@mui/icons-material/RemoveSharp';
import moment from 'moment'
import { Blind } from "@mui/icons-material";

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MainCategory from "./MainCategory";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CustomerLogin from "./Customer/CustomerLogin";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Ragistration from "./Customer/Ragistration";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const steps = ["Book Appointment", "Address", "Appointment Summary"];

function ViewBooking() {
  const [activeStep, setActiveStep] = React.useState(0);
  const { step} = useParams();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  var userLogininfo = JSON.parse(localStorage.getItem("user-Logininfo"));
  if (userLogininfo === null) {
  
    localStorage.setItem("PageHistory", "ac-service-repiar");
    // window.location.href = '/customer/login';
    // handleClickOpenLogin1();
  }
  var handlebackurl = ""
  const getpath = JSON.parse(localStorage.getItem("getcp"));
  if (getpath != null && getpath !== undefined && getpath !== "") {
    handlebackurl = () => {
      window.location.href = getpath;
    };
  }


  const [skipped, setSkipped] = React.useState(new Set());
  const [value, onChange] = useState(new Date());
  const [AppointmentTime, setAppointmentTime] = useState("");

  const today = new Date();
  const nextThreeDays = new Date(today.setDate(today.getDate() + 30));
  today.setTime(today.getTime() + 2 * 60 * 60 * 1000);
  // const time_=today.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' });

  const time = today.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' });

  const currentDate = new Date().toISOString().split('T')[0];
  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  var AppointmentDate = "", AppointmentTimeData = "", paymenttype = "", ItemTotal = "", ItemDiscount = "", CouponDiscount = "", PreviousCRSschedules = "", TaxesandFee = "", Total = "", Qty = ""
  const AddCartData = (ItemTotal, Qty, ItemDiscount, CouponDiscount, PreviousCRSschedules, TaxesandFee, Total, AppointmentDate, AppointmentTimeData) => {
    const obj = {
      ItemTotal: ItemTotal,
      Qty: Qty,
      ItemDiscount: ItemDiscount,
      CouponDiscount: CouponDiscount,
      PreviousCRSschedules: PreviousCRSschedules,
      TaxesandFee: TaxesandFee,
      Total: Total,
      paymenttype: "Cash",
      AppointmentDate: AppointmentDate,
      AppointmentTimeData: AppointmentTimeData,

    };
    localStorage.setItem('AddCartData', JSON.stringify({ ...obj }));
  };
  const handleNext = () => {
    if (AppointmentTime === "") {
      alert("Please select appointment time");
      return;
    }
    else {
      if (userLogininfo === null) {
      document.getElementById("login-first").click();
      }
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);

    }
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const [openApply, setOpenApply] = React.useState(false);

  const handleClickOpenApply = () => {
    setOpenApply(true);
  };
  const handleCloseApply = () => {
    setOpenApply(false);
  };
  const [openThankyou, setOpenThankyou] = React.useState(false);

  const handleClickOpenThankyou = () => {
    setOpenThankyou(true);
  };
  const handleCloseThankyou = () => {
    setOpenThankyou(false);
  };

  const [openTermsCondition, setOpenTermsCondition] = React.useState(false);
  const handleClickOpenTermsCondition = () => {
    setOpenTermsCondition(true);
  };
  const handleCloseTermsCondition = () => {
    setOpenTermsCondition(false);
  };
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = React.useState(false);
  const handleClickOpenPrivacyPolicy = () => {
    setOpenPrivacyPolicy(true);
  };
  const handleClosePrivacyPolicy = () => {
    setOpenPrivacyPolicy(false);
  };

  const [openCancellationPolicy, setOpenCancellationPolicy] = React.useState(false);
  const handleClickOpenCancellationPolicy = () => {
    setOpenCancellationPolicy(true);
  };
  const handleCloseCancellationPolicy = () => {
    setOpenCancellationPolicy(false);
  };



  const handleReset = () => {
    setActiveStep(0);
  };
  useEffect(() => {
  //setActiveStep(step);
    window.scrollTo(0, 0);
  }, [0]);
  const AppointmentData = [
    { d: '08:00', value: "08:00 AM" },
    { d: '08:30', value: "08:30 AM" },
    { d: '09:00', value: "09:00 AM" },
    { d: '09:30', value: "09:30 AM" },
    { d: '10:00', value: "10:00 AM" },
    { d: '10:30', value: "10:30 AM" },
    { d: '11:00', value: "11:00 AM" },
    { d: '11:30', value: "11:30 AM" },
    { d: '12:00', value: "12:00 PM" },
    { d: '12:30', value: "12:30 PM" },
    { d: '13:00', value: "01:00 PM" },
    { d: '13:30', value: "01:30 PM" },
    { d: '14:00', value: "02:00 PM" },
    { d: '14:30', value: "02:30 PM" },
    { d: '15:00', value: "03:00 PM" },
    { d: '15:30', value: "03:30 PM" },
    { d: '16:00', value: "04:00 PM" },
    { d: '16:30', value: "04:30 PM" },
    { d: '17:00', value: "05:00 PM" },
    { d: '17:30', value: "05:30 PM" },
    { d: '18:00', value: "06:00 PM" },
    { d: '18:30', value: "06:30 PM" },
    { d: '19:00', value: "07:00 PM" },
    { d: '19:30', value: "07:30 PM" },
    { d: '20:00', value: "08:00 PM" },
    { d: '20:30', value: "08:30 PM" },
    { d: '21:00', value: "09:00 PM" },

  ]
  const handleSelect = (selectedItem) => {
    setAppointmentTime(selectedItem)

  }

  const [openMainCategory, setOpenMainCategory] = React.useState(false);
  const handleClickOpenMainCategory = () => {
    setOpenMainCategory(true);
  };
  const handleCloseMainCategory = () => {
    setOpenMainCategory(false);
  };

  let baseURL = `${process.env.REACT_APP_BASE_URL}`;
  let baseTokenKey = `${process.env.REACT_APP_BASE_KEY}`.replace(/'/g, "");
  let authToken = `${process.env.REACT_APP_BASE_AUTHKEY}`;
  const { CategoryList } = useContext(ProductContext);
  const [CategoryHeading, sethandleInputChange] = useState();
  const [Categoryid, setCategoryid] = useState(0);
  const [openSubCategory, setOpenSubCategory] = React.useState(false);
  function fun_getSubCategoryclick(id, Category) {
    fun_SubCategoryList(Category);
    sethandleInputChange(Category);
    setCategoryid(id);
  }
  const [SubCategoryList, setSubCategoryList] = useState([]);
  function fun_SubCategoryList(id) {
    let CategoryName = '{Category:"' + id + '"}';
    const CategoryPra = {
      pgSize: 100,
      filterButtons: "",
      index: 1,
      toEmailID: "designMonu@dialerp.com",
      tabErpID: 793,
      useAtlusSearch: false,
      filters: CategoryName,
    };

    fetch(baseURL + "/api/Dynamic/GetRecords", {
      method: "POST",
      headers: {
        "Authorization-Token": authToken,
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(CategoryPra),
    }).then((result) => {
      result.json().then((resp) => {
        if (resp.hasErrors === false) {
          if (resp.response?.records !== null) {
            setSubCategoryList(resp.response);
            setOpenSubCategory(true);
          } else {
            setSubCategoryList("");
            setOpenSubCategory(true);
          }
        } else {
          alert(resp.errors);
        }
      });
    });
  }
  const handleClickOpenSubCategory = () => {
    setOpenSubCategory(true);
  };
  const handleCloseSubCategory = () => {
    setOpenSubCategory(false);
  };
  const [openServieProduct, setOpenServieProduct] = React.useState(false);
  const handleClickOpenServieProduct = () => {
    setOpenServieProduct(true);
  };
  const handleCloseServieProduct = () => {
    setOpenServieProduct(false);
  };


  const [openCustomerLogin, setOpenCustomerLogin] = React.useState(false);
  const handleClickOpenCustomerLogin = () => {
    setOpenCustomerLogin(true);
  };
  const handleCloseCustomerLogin = () => {
    setOpenCustomerLogin(false);
  };
  const [openCustomerRagistration, setOpenCustomerRagistration] = React.useState(false);
  const handleClickOpenCustomerRagistration = () => {
    setOpenCustomerRagistration(true);
  };
  const handleCloseCustomerRagistration = () => {
    setOpenCustomerRagistration(false);
  };

  return (
    <div>
      <Header />
      <div className="top-space">
        <Container fluid>
          <Row>

            <Col xs={12} sm={12} md={12} lg={12}>
              <div className="booking-view">
                <Stepper activeStep={activeStep}>
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepOptional(index)) {
                      labelProps.optional = (
                        <Typography variant="caption"></Typography>
                      );
                    }
                    if (isStepSkipped(index)) {
                      stepProps.completed = false;
                    }
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps} >{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>

                {activeStep === steps.length ? (

                  <React.Fragment>
                    {/* <Typography className="thankyou-section">
                      <h2>Thank You</h2>
                      Your Service has been Submitted. Your Service Number is
                      ODI156156465156
                      <Button variant="outlined" className="thankyou-button">
                        <Link to="/">Back to Home</Link>
                      </Button>
                    </Typography> */}
                    {/* <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box> */}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                      {activeStep === 0 ? (
                        <section className="section-step-1">
                          <Container fluid>
                            <Row>
                              <Col xs={12} sm={12} md={4}>
                                <h2> <span className="back-arrow-5" onClick={()=>{handlebackurl()}}>
                                  <KeyboardBackspaceIcon className="back-icon-arrow" /></span>Appointment Date</h2>
                                <Calendar onChange={onChange} value={value} minDate={new Date()} maxDate={nextThreeDays} />
                              </Col>
                              <Col xs={12} sm={12} md={8} className="time-slot-button">
                                <div className="time-slot">
                                  <h2>Appointment Time</h2>
                                  <ul  >
                                    {AppointmentData.map((dd, i) => {
                                      if (currentDate < moment(value).format("YYYY-MM-DD")) {
                                        return (
                                          <li key={dd["value"]} onClick={() => handleSelect(dd["value"])} className={AppointmentTime === dd["value"] ? 'active-button-bb' : ""} >{dd["value"]}</li>
                                        );
                                      }
                                      if (currentDate === moment(value).format("YYYY-MM-DD") && (time) <= dd["d"]) {
                                        return (
                                          <li key={dd["value"]} onClick={() => handleSelect(dd["value"])} className={AppointmentTime === dd["value"] ? 'active-button-bb' : ""} >{dd["value"]}</li>
                                        );
                                      }
                                    })}
                                  </ul>

                                </div>
                                <Button hidden variant="contained" id="login-first" className='next-button-5"' onClick={()=>{handleClickOpenCustomerLogin()}}>Login</Button>
                                <Button variant="contained"  onClick={()=>{handleNext()}} className="next-button-5">Next</Button>

                              </Col>
                            </Row>

                          </Container>
                        </section>
                      ) : (
                        <></>
                      )}
                      {activeStep === 1 ? (
                        <section className="section-step-1">
                          <Container fluid>
                            <Row>
                              <Col xs={12} sm={12} md={12}>
                                <h2>
                                  <span className="back-arrow-5" disabled={activeStep === 0} onClick={()=>{handleBack()}}>
                                    <KeyboardBackspaceIcon className="back-icon-arrow" /></span>
                                  Address</h2>
                              </Col>
                              <Col xs={12} sm={12} md={7}>

                                <DeliveryAddress />
                              </Col>
                              <Col xs={12} sm={12} md={5}>

                                <div className="left-view-booking-2">
                                  <h2>

                                    Appointment Time & Date </h2>
                                  <h4>{AppointmentTime}</h4>
                                  <h5>{moment(value).format("DD-MMM-YYYY")}</h5>

                                </div>

                                <Button variant="contained" onClick={()=>{handleNext()}} className="next-button-6">Next</Button>


                              </Col>
                            </Row>
                          </Container>
                        </section>
                      ) : (
                        <></>
                      )}
                      {activeStep === 2 ? (
                        <section className="section-step-1">
                          <Container fluid>
                            <Row>
                              <Col xs={12} sm={12} md={12}>
                                <div className="time-slot">
                                  <h2>
                                    <span className="back-arrow-5" disabled={activeStep === 1} onClick={()=>{handleBack()}}>
                                      <KeyboardBackspaceIcon className="back-icon-arrow" /></span>
                                    Booking Summary</h2>
                                </div>
                              </Col>
                              <Col xs={12} sm={12} md={6}>
                                <div className="left-view-booking-4 left-view-booking-41">


                                  <h2><AccessTimeIcon className="clock-icon" /> {moment(value).format("DD-MMM-YYYY")}  {AppointmentTime}
                                    <span>
                                      <Button className="next-button-7" variant="contained" disabled={activeStep === 0} onClick={()=>{handleBack()}}>Change</Button>
                                    </span></h2>
                                </div>
                                <h2 className="address-new-class">Address</h2>
                                <DeliveryAddress />

                                <div className="payment-section-1">
                                  <h2 className="address-new-class" onClick={()=>{handleClickOpenThankyou()}}>Payment Method</h2>
                                  <Link to="/customer/payment" onClick={() => AddCartData(ItemTotal = "899", Qty = "1", ItemDiscount = "200", CouponDiscount = "00", PreviousCRSschedules = "00", TaxesandFee = "00", Total = "699", AppointmentDate = { value }, AppointmentTimeData = { AppointmentTime })}><h2 className="total-amount width-100">
                                    Pay <span>₹699</span>
                                  </h2>
                                  </Link>
                                </div>
                                <div className="payment-section-2">
                                  <p className="payment-section-condition">By proceeding, you agree to our
                                    <span><Link to="" onClick={()=>{handleClickOpenTermsCondition()}}>Terms & Condition</Link>,</span>
                                    <span><Link to="" onClick={()=>{handleClickOpenPrivacyPolicy()}}>Privacy policy</Link>and</span>
                                    <span><Link to="" onClick={()=>{handleClickOpenCancellationPolicy()}}>Cancellation Policy</Link></span> </p>
                                </div>
                                {/* <div className="payment-section-2">
                                  <h2 className="address-new-class">Cancellation policy</h2>
                                  <p>Free cancellations if done more than 3 hrs before the service or if a
                                    professional isn’t assigned. A fee will be charged otherwise.</p>
                                </div> */}
                              </Col>
                              <Col xs={12} sm={12} md={6}>
                                <div className="time-slot">
                                  <Card className="service-booking-cerd-2">
                                    <Card.Body>
                                      <div className='cart-section-1'>

                                        <div className='empty-cart view-booking-empty-cart'>
                                          <h4>Cart</h4>
                                          <h5>Deep clean AC service (split)</h5>
                                          <div className='add-button-service'>
                                            <Button variant="text"><RemoveSharpIcon className='add-icon-1' />
                                            </Button>
                                            <span>2</span>
                                            <Button variant="text"><AddIcon className='add-icon-1' /></Button>
                                          </div>
                                          <span className='price-span1'>₹699</span>
                                          <p className='add1div'>Add 1 more service, Get 30% off 2nd one onwards
                                            <span>
                                              <Button className="next-button-12" variant="contained" onClick={()=>{handleClickOpenMainCategory()}}>
                                                Add More Service</Button></span></p>

                                        </div>
                                      </div>


                                      <p>Item Total<span>₹899</span></p>
                                      <p>Item Discount<span>-₹200</span></p>
                                      <p>

                                        Coupon Discount
                                        <Badge
                                          className="apply-1"
                                          bg="secondary"
                                          onClick={()=>{handleClickOpenApply()}}
                                        >
                                          Apply
                                        </Badge>
                                        <span>₹00</span>
                                      </p>
                                      <p>Previous cancellations/reschedules<span>₹00</span></p>
                                      <p>Taxes and Fee<span>₹00</span></p>
                                      <p className="total-amount-2">Total<span>₹699</span></p>


                                    </Card.Body>
                                  </Card>
                                </div>
                              </Col>
                            </Row>
                          </Container>
                        </section>
                      ) : (
                        <></>
                      )}

                      {/* {activeStep + 1} */}
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      {/* <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                      >
                        Back
                      </Button>
                      <Box sx={{ flex: "1 1 auto" }} /> */}
                      {/* {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )} */}

                      {/* <Button variant="contained" onClick={handleNext}>
                        {activeStep === steps.length - 1 ? "Pay" : "Next"}
                      </Button> */}
                    </Box>
                  </React.Fragment>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />

      <Dialog
        open={openApply}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseApply}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className="section-step-1">
          <h2>
            Use Coupon
            <ClearIcon className="cross-icon" onClick={()=>{handleCloseApply()}} />
          </h2>
          <DialogContentText id="alert-dialog-slide-description">
            <TextField
              id="outlined-basic"
              label="Enter Coupon Code"
              className="width-100"
              variant="outlined"
            />
            <Button className="service-button-2" onClick={()=>{handleCloseApply()}}>
              Save
            </Button>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {/* Thankyou */}

      <Dialog
        open={openThankyou}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseThankyou}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className="section-step-1 section-step-12">
          <h2>
            Thank You
            <ClearIcon className="cross-icon" onClick={handleCloseThankyou} />
          </h2>
          <hr />
          <DialogContentText id="alert-dialog-slide-description">
            <img src="../assets/images/success.gif" className="thankyou-img" />
            <h3>Your Order is ODI2165561564</h3>
            <p>
              your order is confirmed. You will receive an order confirmation
              email/ SMS shortly with ths expected delivery date for your items.
            </p>
            <Button className="service-button">
              <Link to="/customer/user-profile">Back to Home</Link>
            </Button>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {/* Terms & Condition */}
      <Dialog
        open={openTermsCondition}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseTermsCondition}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className="section-step-1 section-step-12">
          <h2>
            Terms & Condition
            <ClearIcon className="cross-icon" onClick={()=>{handleCloseTermsCondition()}} />
          </h2>
          <hr />
          <DialogContentText id="alert-dialog-slide-description" className="tnc">
            <h3>Terms and Condition</h3>
            <p>The Terms constitute a binding and enforceable legal contract
              between A2Z Work (a company
              incorporated under the Companies Act, 2013 with its registered
              address at H-23, 2nd Floor, Sector 63, Noida Uttar Pradesh - 201301 and
              its principal place of business at 608, Padma Tower-II, Rajendra Place, New Delhi-110008.
              ). By using the Services, you represent and warrant that you have
              full legal capacity and authority to agree to and bind yourself to
              these Terms. If you represent any other person, you confirm and
              represent that you have the necessary power and authority to bind
              such person to these Terms.
            </p>
            <p>By using the Services, you agree that you have read, understood,
              and are bound by, these Terms, as amended from time to time, and
              that you will comply with the requirements listed here. These Terms
              expressly supersede any prior written agreements with you. If you do
              not agree to these Terms, or comply with the requirements listed
              here, please do not use the Services.</p>
            <Button className="service-button width-100-percentage">
              <Link to="" onClick={()=>{handleCloseTermsCondition()}}>Okay, got it</Link>
            </Button>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openPrivacyPolicy}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClosePrivacyPolicy}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className="section-step-1 section-step-12">
          <h2>
            Privacy Policy
            <ClearIcon className="cross-icon" onClick={()=>{handleClosePrivacyPolicy()}} />
          </h2>
          <hr />
          <DialogContentText id="alert-dialog-slide-description" className="tnc">
            <h3>Privacy Policy</h3>
            <p>The Terms constitute a binding and enforceable legal contract
              between A2Z Work (a company
              incorporated under the Companies Act, 2013 with its registered
              address at H-23, 2nd Floor, Sector 63, Noida Uttar Pradesh - 201301 and
              its principal place of business at 608, Padma Tower-II, Rajendra Place, New Delhi-110008.
              ). By using the Services, you represent and warrant that you have
              full legal capacity and authority to agree to and bind yourself to
              these Terms. If you represent any other person, you confirm and
              represent that you have the necessary power and authority to bind
              such person to these Terms.
            </p>
            <p>By using the Services, you agree that you have read, understood,
              and are bound by, these Terms, as amended from time to time, and
              that you will comply with the requirements listed here. These Terms
              expressly supersede any prior written agreements with you. If you do
              not agree to these Terms, or comply with the requirements listed
              here, please do not use the Services.</p>
            <Button className="service-button width-100-percentage">
              <Link to="" onClick={()=>{handleClosePrivacyPolicy()}}>Okay, got it</Link>
            </Button>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openCancellationPolicy}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseCancellationPolicy}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className="section-step-1 section-step-12">
          <h2>
            Cancellation Policy
            <ClearIcon className="cross-icon" onClick={()=>{handleCloseCancellationPolicy()}} />
          </h2>
          <hr />
          <DialogContentText id="alert-dialog-slide-description" className="tnc">
            {/* <h3>Cancellation Policy</h3> */}
            <p>A2Z Work Company has a fair cancellation policy. Our Professionals block time for jobs, and cancellations
              lead to lost revenue for them. We only charge cancellation fees when a Professional has been assigned
              to a job (since they no longer get any other jobs for that time).
              Depending on the service, customers have some time after placing the request to cancel the job without incurring a fees</p>
            <Button className="service-button width-100-percentage">
              <Link to="" onClick={()=>{handleCloseCancellationPolicy()}}>Okay, got it</Link>
            </Button>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openMainCategory}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseMainCategory}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className="section-step-1 section-step-12">
          <h2>
            Add More Service
            <ClearIcon className="cross-icon" onClick={()=>{handleCloseMainCategory()}} />
          </h2>
          <hr />
          <DialogContentText id="alert-dialog-slide-description" className="tnc">
            <Container>
              <Row>
                {CategoryList?.records?.map((status, index) => {
                  index++;

                  return (
                    <Col xs={4} md={4} key={(index)}>
                      <a onClick={() => { fun_getSubCategoryclick(status["CategoryID"], status["Category"]); handleCloseMainCategory() }}>
                        {/* onClick={() => fun_getSubCategoryclick(status["CategoryID"], status["Category"])} */}
                        <div className="banner-div-1" key={index}> <img src={status["Category Pic"]} alt="icon" /> </div>
                        <p>{status["Category"]}</p>
                      </a>
                    </Col>
                  );
                })}

              </Row>
            </Container>

          </DialogContentText>
        </DialogContent>
      </Dialog>




      <Dialog
        open={openSubCategory}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseSubCategory}
        aria-describedby="alert-dialog-slide-description"
        className="category-popup"
      >
        <DialogTitle>
          <KeyboardBackspaceIcon className='arrow-35'
            onClick={() => { handleClickOpenMainCategory(); handleCloseSubCategory() }} />
          {CategoryHeading}
          <IconButton
            aria-label="close"
            onClick={()=>{handleCloseSubCategory()}}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Container className="popup-p">
              <Row>
                {SubCategoryList?.records?.map((Substatus, i) => {

                  i++;
                  return (
                    <Col xs={4} md={4} key={i}>
                      {/* <Link to={`/service/${Substatus["Category"]}/${Substatus["Sub Category"]}`} >*/}

                      <div className="banner-div-1" key={i} onClick={(e) => {
                       // localStorage.setItem("SubcategoryName", Substatus["Sub Category"]);
                        handleClickOpenServieProduct(); handleCloseSubCategory()
                      }}>
                        <img src={Substatus["Sub Category Pic"]} alt="icon" />
                      </div>
                      <p>{Substatus["Sub Category"]}</p>
                      {/* </Link> */}

                    </Col>
                  );
                })}
              </Row>
            </Container>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openServieProduct}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseServieProduct}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className="section-step-1 section-step-12">
          <h2><KeyboardBackspaceIcon className='arrow-35'
            onClick={() => { handleClickOpenSubCategory(); handleCloseServieProduct() }} />
            Product
            <ClearIcon className="cross-icon" onClick={()=>{handleCloseServieProduct()}} />
          </h2>
          <hr />
          <DialogContentText id="alert-dialog-slide-description" className="tnc">
            <Container>
              <Row>
                {CategoryList?.records?.map((status, index) => {
                  index++;

                  return (
                    <Col xs={4} md={4} key={(index)}>
                      <a onClick={() => { fun_getSubCategoryclick(status["CategoryID"], status["Category"]); handleCloseMainCategory() }}>
                        {/* onClick={() => fun_getSubCategoryclick(status["CategoryID"], status["Category"])} */}
                        <div className="banner-div-1" key={index}> <img src={status["Category Pic"]} alt="icon" /> </div>
                        <p>{status["Category"]}</p>
                      </a>
                    </Col>
                  );
                })}

              </Row>
            </Container>

          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        open={openCustomerLogin}
        onClose={handleCloseCustomerLogin}
        aria-labelledby="responsive-dialog-title"
        className='login-popup-1'
      >
        <CloseIcon className='cross-login-5' onClick={() => {handleCloseCustomerLogin() }}  />

        <CustomerLogin />
        <p className='account-type-show'>
          Don't have an account?
          <Link to="" onClick={() => { handleClickOpenCustomerRagistration(); handleCloseCustomerLogin() }}> Register here</Link>
        </p>
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        open={openCustomerRagistration}
        onClose={handleCloseCustomerRagistration}
        aria-labelledby="responsive-dialog-title"
        className='login-popup-1'
      >
        <CloseIcon className='cross-login-5' onClick={() => {handleCloseCustomerRagistration() }} />
        <Ragistration />
        <p className='account-type-show'>
          Existing User
          <Link to="" onClick={() => { handleClickOpenCustomerLogin(); handleCloseCustomerRagistration() }}> Login</Link>
        </p>
      </Dialog>



    </div>
  );
}

export default ViewBooking;
