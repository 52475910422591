import React, { useState, useEffect, useContext } from "react";
import Button from "@mui/material/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import Headerinner from "../Common/Headerinner";
import SideBar from "../Common/SideBar";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import AssistantDirectionOutlinedIcon from "@mui/icons-material/AssistantDirectionOutlined";
import { ProductContext } from "../context/AppContext";
import Slide from "@mui/material/Slide";
import { fetchApiWithLoginToken } from "../apiUtils";
import PersonIcon from "@mui/icons-material/Person";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

function NewBooking() {
  const { date, showTime, handleClickLoader, handleCloseUserOTP, handleClickOpenUserOTP, openUserOTP, setOTP, setMobile, setOpenUserOTP, handleCloseLoader, setStartJOB, setEndJOB, IsJOBStart, StartJOB, } =
    useContext(ProductContext);
  const [Todayjobs, setTodayjobs] = useState([]);
  // const [LoginUser, setLoginUser] = useState([]);
  // const [CustomerRemark, setCustomerRemark] = useState([]);

  var userLogininfo = JSON.parse(localStorage.getItem("user-Logininfo"));
  if (userLogininfo === null) {
    window.location.href = "/";
  }

  let datetyepe = "";
  let totalAmount = 0;
  useEffect(() => {
    fun_todayJobs();
  }, [0]);

  function fun_todayJobs() {
    handleClickLoader();
    window.scrollTo(0, 0);
    // let newusername = '{UserEmail:/^' + userLogininfo["UserEmail"] + '/i}';
    const getparam = {
      viewFormat: "columnname",
      pgSize: 1000,
      filterButtons: "",
      tabErpID: 1258,
      columnID: 1258014,
      statusID: 0,
      statusName: "All",
      color: "#262626",
      index: 1,
      toEmailID: "designMonu@dialerp.com",
      useAtlusSearch: true,
      filters: "{'Stage':'Booking Assigned'}",
    };
    fetchApiWithLoginToken(getparam, "dynamic/GetRecords").then((resp) => {
      if (resp.hasErrors === false) {
        if (resp.response?.records !== null) {
          if (resp.response?.records.length > 0) {
            const res = resp?.response?.records
              ?.slice(0)
              .sort((a, b) =>
                a["AppointmentDt"].localeCompare(b["AppointmentDt"])
              );
            setTodayjobs(res);
           
            // let UserWiseData = resp?.response?.records?.filter(p => p["ProfessionalID"] === userLogininfo["UserID"]) || [];
            // setLoginUser(UserWiseData);
            // if (resp.response?.records?.[0]?.["Customer Remarks"]!== "[object Object],[object Object]"
            // && resp.response?.records?.[0]?.["Customer Remarks"]!== "[object Object]"
            // && resp.response?.records?.[0]?.["Customer Remarks"]!= null
            // && resp.response?.records?.[0]?.["Customer Remarks"]!== undefined
            // && resp.response?.records?.[0]?.["Customer Remarks"]!== "") {
            //   setCustomerRemark(JSON.parse(resp.response?.records?.[0]?.["Customer Remarks"]));
            // }
          }
        }
      } else {
        alert(resp.errors);
      }
      handleCloseLoader();
    });
  }
  function fun_sendWhatsAppMessage(ContactMobile) {
    handleClickLoader();
    const smsbody = `Dear sir,\n\nGreeting from A2Z Work. We are pleased to inform you that Mr. ${userLogininfo["User Name"]}, contact no. ${userLogininfo["Login Mobile"]} shall be reporting to your doorstep tomorrow between 12:00 am for pick up of Service of your vehicle. For any query or assistance, please feel free to contact the undersigned.\n\nThanks & Regards,\nA2Z Work`;
    const opt = { caption: smsbody };
    const formData = {
      executiontimems: 1000,
      callbackurl:
        "https://api.dialerp.com/api/SmsEmail/addWhatsAppLog?token=SRTZ+TN4n3CZ7ofaPMqZ1lKQzkDlJj8XZknsZ5Li5eusUS8QdCVh+RISsDQFtmbi3rzDDSHEU164Lkba3q0ORfBJW940tDl2Dy9tyiuQ9suACDrYCb8L1W5zdfR0gXMdeXuVBZKaRiunfSomKRtBrpaJsWsLY5xfqZXrg/m8vxC/J0u6SKgjcngXYdALMYsF4WyZu5X540JhrukfjuhkIC2i/+8Q6mIrZ4poQGpJ+BfPv+JkurHQfTJhFWycDm2USG8xi9oqZ+IqN/Ykj0nj4G2soYYaqlI3NGWxNQb6LlxVg+o8kmWnA1nvyWkOurHg6AAdCsmhFWBFyk/wXoDyabrNi6YvfIfgpmUcgCOP7ioROh0yxbpOfrEG0inarlKiXd3r0QlpoehYihwHHlyRE7lbDT36OVEZ2AgdwSA8PK/mlYAbavgOLkdIB8m1pXmpfP4YU0GyMFolfclVov9yuxSQJg1AVfyD/v8gjMCMABa6Yly7D7tUIIntqn7stxHg",
      clientId: "P40248U40248",
      to: ["917221926913"],
      content: {
        IsMedia: true,
        fromUrl: userLogininfo["ProfilePic"],
        body: smsbody,
      },
      options: opt,
    };
    fetch("https://dialerpwhatsapp.dialerp.com/sendMessage", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;",
      },
      body: JSON.stringify(formData),
    }).then((result) => {
      result.json().then((resp) => {
        console.log(resp, "WhatsApp massage ");
        handleCloseLoader();
        // if (resp.hasErrors === false) {

        // } else {
        //   handleCloseLoader();
        //   alert(resp.errors);
        // }
      });
    });

    // fetchApiWithLoginToken(
    //   formData,
    //   "Dynamic/AddUpdateRecords?LoginType=2&tabErpID=1258"
    // ).then((resp) => {
    //   if (resp.hasErrors === false) {
    //     alert("Job accept successfully.");
    //     window.location.reload();
    //   } else {
    //     alert(resp.errors);
    //   }

    //   handleCloseLoader();
    // });
  }
  function fun_JobAccept(id, ContactMobile, JobStartOTP, JobEndOTP) {
    // handleClickLoader();
    // setOpenUserOTP(true);
    // setMobile(ContactMobile);
    // setStartJOB(JobStartOTP);
    // setEndJOB(JobEndOTP);
    // setOTP(null);
    // alert(IsJOBStart);
    // if (!IsJOBStart) {
    //   return;
    // }
    const formData = [
      {
        BookingID: id,
        Status: "Open",
        Stage: "Professional Assigned",
        ProfessionalMobile: userLogininfo["Login Mobile"],
        ProfessionalID: userLogininfo["UserID"],
        Professional: userLogininfo["User Name"],
        ProfessionalGroup: userLogininfo["User Group"],
        ProfessionalAssignedDt:
          moment(date).format("DD-MMM-YYYY") + " " + showTime,
        ApproverName: userLogininfo["User Name"],
        ApproverID: userLogininfo["UserID"],
      },
    ];
    // fun_sendWhatsAppMessage(ContactMobile)
    // return
    fetchApiWithLoginToken(
      formData,
      "Dynamic/AddUpdateRecords?LoginType=2&tabErpID=1258"
    ).then((resp) => {
      if (resp.hasErrors === false) {
        alert("Job accept successfully.");
        window.location.reload();
      } else {
        alert(resp.errors);
      }

      handleCloseLoader();
    });
  }
  const today = new Date();
  today.setTime(today.getTime() + 2 * 60 * 60 * 1000);

  return (
    <div className="admin-panel-section">
      <Headerinner />
      {/* <Link to={`/common/newJobs`} id="btnedint"></Link> */}
      <div className="my-orders admin-body-section admin-div-1">
        <Container fluid>
          <Row>
            <Col xs={12} sm={12} md={3} lg={2}>
              <SideBar />
            </Col>
            <br></br>
            <Col
              xs={12}
              sm={12}
              md={9}
              lg={10}
              className="right-side-section right-side-section-21"
            >
              <h4> New Jobs</h4>
              {Todayjobs?.length > 0 ? (
                Todayjobs?.map((status, i) => {
                  if (
                    moment(status["AppointmentDt"]).format("DD MMM") ===
                    moment(new Date()).format("DD MMM")
                  ) {
                    datetyepe = "Today";
                  } else if (
                    moment(status["AppointmentDt"]).format("DD MMM") ===
                    moment(new Date().setDate(new Date().getDate() + 1)).format(
                      "DD MMM"
                    )
                  ) {
                    datetyepe = "Tomorrow";
                  } else {
                    datetyepe = moment(status["AppointmentDt"]).format(
                      "DD MMM"
                    );
                  }

                  const utcDate = new Date(status["AppointmentDt"]);
                  const localDate = new Date(
                    utcDate.getTime() + utcDate.getTimezoneOffset() * 60000
                  );
                  const options = {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    hour12: false,
                  };
                  const formattedDate1 = localDate.toLocaleString(
                    "en-US",
                    options
                  );
                  // const formattedDate = moment(formattedDate1).format("ddd, MMM DD");
                  const formattedTime =
                    moment(formattedDate1).format("hh:mm A");

                  if (status["Stage"] === "Booking Assigned") {
                    // if (status["Customer Remarks"] !== "[object Object],[object Object]"
                    //   && status["Customer Remarks"] !== "[object Object]"
                    //   && status["Customer Remarks"] != null
                    //   && status["Customer Remarks"] !== undefined
                    //   && status["Customer Remarks"] !== "") {
                    //     try {
                    //       // Remove backticks and parse the JSON
                    //       totalAmount = JSON.parse(status["Customer Remarks"].replace(/`/g, ''));
                    //     } catch (error) {
                    //       console.error("Error parsing JSON:", status["_id"]);
                    //     }
                    // }

                    if (
                      status["CustRemarks"] !==
                      "[object Object],[object Object]" &&
                      status["CustRemarks"] !== "[object Object]" &&
                      status["CustRemarks"] != null &&
                      status["CustRemarks"] !== undefined &&
                      status["CustRemarks"] !== ""
                    ) {
                      totalAmount = JSON.parse(status["CustRemarks"]);
                    }
                    return (
                      <div className="my-order-inner my-order-inner-5 my-order-inner-21 icon_style_4">
                        <Row>
                          <Col xs={8} sm={10} md={10} lg={10}>
                            <div className="booking-div-21">
                              <h3>{datetyepe}</h3>
                              <h6 className="icon_style_3">
                                New Booking ({status["BookingID"]})
                              </h6>
                              {/* {status["Stage"]} */}
                              <p className="icon_style_2">
                                {status["SubCategory"]} ( {status["Category"]} ){" "}
                              </p>
                              <p>
                                <AccessTimeIcon className="icon_style_1" />
                                {formattedTime}
                              </p>
                              <p>
                                <CurrencyRupeeIcon className="icon_style_1" />
                                {Math.round(parseFloat(totalAmount["BalAmt"]))}
                              </p>
                              <p>
                                <PersonIcon className="icon_style_1" />
                                {status["CustName"]}
                              </p>
                              <p>
                                <LocationOnIcon className="icon_style_1" />
                                {status["Address"]} , {status["City"]} ,{" "}
                                {status["State"]}, {status["Country"]} ,{" "}
                                {status["State"]} - {status["Pincode"]}
                              </p>
                              {userLogininfo["User Group"] ===
                                "Service Provider" && (
                                  <>
                                    <Button
                                      variant="contained"
                                      className="recived"
                                      onClick={() => {
                                        if (
                                          window.confirm(
                                            "Are you sure to accept this job?"
                                          )
                                        ) {
                                          fun_JobAccept(
                                            status["BookingID"],
                                            status["ContactMobile"], status["JobStartOTP"], status["JobEndOTP"]
                                          );
                                        }
                                      }}
                                    >
                                      Job Accept
                                    </Button>
                                  </>
                                )}
                              {/* <Button variant="contained" className="rejected" >Job Cancel</Button> */}
                            </div>
                          </Col>
                          <Col xs={4} sm={2} md={2} lg={2}>
                            <div className="booking-div-22">
                              <LocalPhoneOutlinedIcon className="call-icon-21" />
                              <AssistantDirectionOutlinedIcon />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    );
                  }
                })
              ) : (
                <div className="empty-list-card">List is Empty</div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
export default NewBooking;
