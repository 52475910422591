import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "react-calendar/dist/Calendar.css";

import { Link } from "react-router-dom";

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CallIcon from '@mui/icons-material/Call';
import StarRateIcon from '@mui/icons-material/StarRate';
import RepeatIcon from "@mui/icons-material/Repeat";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import moment from 'moment'
import Header from "../Header";
import FooterMobile from "../FooterMobile";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import FooterAfterLogin from "../FooterAfterLogin";
function MyBookings() {
  var userLogininfo = JSON.parse(localStorage.getItem("user-Logininfo"));
  if (userLogininfo === null) {
    window.location.href = '/';
  }
  else {
    if (userLogininfo["User Group"] !== "Customer") {
      window.location.href = "/not-found";
    }
  }

  const [expanded, setExpanded] = React.useState(false);
  const [MyBooking, setMyBooking] = useState("");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  let authToken = `${process.env.REACT_APP_BASE_AUTHKEY}`;
  let baseURL = `${process.env.REACT_APP_BASE_URL}`;
  useEffect(() => {
    if (userLogininfo !== "" && userLogininfo !== null && userLogininfo !== undefined) {
      fun_MyBooking();
      // fun_ServiceProviderList();
    //  renderList();
      window.scrollTo(0, 0);
    }

  }, []);


  function fun_MyBooking() {

    let newusername = '{CustMobile:' + userLogininfo["Login Mobile"] + '}';
    const getparam = {
      pgSize: 100,
      index: 1,
      toEmailID: "designMonu@dialerp.com",
      tabErpID: 1258,
      filters: newusername,
    }
    fetch(
      baseURL + "/api/dynamic/GetRecords",
      {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(getparam),
      }
    ).then((result) => {
      result.json().then((resp) => {

        if (resp.hasErrors === false) {
          if (resp.response?.records !== null) {
            if (resp.response?.records.length > 0) {
              setMyBooking(resp.response);
             // renderList();
              for (let i = 0; i < resp.response?.records.length; i++) {
                if (resp.response?.records[i]["ProfessionalID"] != ""
                  && resp.response?.records[i]["ProfessionalID"] != null
                  && resp.response?.records[i]["ProfessionalID"] != undefined) {

                  fun_ServiceProviderList(resp.response?.records[i]["ProfessionalID"])
                }
               
              }

            }
          }
        }
        else {
          alert(resp.errors);
        }
      });
    });
  }
  // const renderList = () => {
  //   for (let i = 0; i < MyBooking?.records.length; i++) {
  //     MyBooking?.records?.map((status, i) => {
  //      if(status["ProfessionalID"] !=="" && status["ProfessionalID"] !==null && status["ProfessionalID"] !==undefined){
  //       fun_ServiceProviderList(status["ProfessionalID"])
  //      }
  //     })}
  //   }
  // ;
  const [ServiceList, setServiceList] = useState("");
  const fun_ServiceProviderList = (ProfessionalID) => {

    if (ProfessionalID != "") {
      let newusername = `{'UserID':${ProfessionalID}}`;
      const loginPra = {
        pgSize: 100,
        index: 1,
        toEmailID: "designMonu@dialerp.com",
        tabErpID: 1429,
        filters: newusername,
      };
      fetch(baseURL + "/api/dynamic/GetRecords", {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(loginPra),
      }).then((result) => {

        result.json().then((resp) => {

          if (resp.hasErrors === false) {

            if (resp.response?.records !== null) {
              if (resp.response?.records.length > 0) {

                setServiceList(resp?.response);
              }
            }
          } else {

            alert(resp.errors);
          }
        });
      });
    }
    else {
      setServiceList("");
    }

  };
  console.log(ServiceList, "ServiceList");
  return (
    <div>
      {/* Web Version */}
      <div className="web-view background-my-booking">
        <Header />
        <div className="top-space top-margin">
          <div className="my-orders my-bookings-page">
            <Container>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <h2>Bookings & Plans</h2>
                  <hr />
                  {MyBooking.length === 0 ? (
                    <>
                      <Col xs={12} sm={12} md={12} lg={12}>
                        <div className="wishlist-div-5">
                          <img src="../../assets/images/norecord.png" style={{ width: "17%", margin: "-10px" }} alt="Icon" />
                          <p>No Booking Found...</p>
                        </div>
                      </Col>
                    </>
                  ) : (
                    <>
                      {MyBooking?.records?.map((status, i) => {

                        const utcDate = new Date(status["Appointment Dt"]);
                        const localDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000);
                        const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false };
                        const formattedDate1 = localDate.toLocaleString('en-US', options);
                        const formattedDate = moment(formattedDate1).format("ddd, MMM DD");
                        const formattedTime = moment(formattedDate1).format("hh:mm A");

                        if (status["Stage"] === "Booking Assigned") {
                          return (
                            <div>
                              <h3 className="booking-div-14 booking-div-140">{status["Category"]}</h3>
                              {/* <Link to="/customer/order-details/{}"  onClick={() => fun_OrderDetailclick(status["BookingID"])}> */}
                              <Link to={`/customer/order-details/${status["BookingID"]}`}>
                                <div className="my-order-inner my-order-inner-5 my-booking-inner">
                                  <Row>
                                    <Col xs={10} sm={10} md={10} lg={10}>
                                      <div className="booking-div-10">
                                        <h2> {status["Stage"]}</h2>
                                        <h3>{status["Sub Category"]}</h3>
                                        <span>{formattedDate + ' at ' + formattedTime}</span>
                                        {/* {moment(status["Appointment Dt"]).format("ddd, MMM DD") + ' at ' + '12:30 '} */}
                                      </div>
                                    </Col>
                                    <Col xs={2} sm={2} md={2} lg={2}>
                                      <div className="booking-div-11">
                                        <KeyboardArrowRightIcon />
                                      </div>
                                    </Col>
                                    <div className="booking-div-12 finding-professional">
                                      <Row>
                                        <Col xs={6} sm={6} md={6} lg={6}>
                                          <div className="booking-div-13">
                                            <h3 className="finding-1">Finding a Professional</h3>
                                          </div>
                                        </Col>
                                        <Col xs={6} sm={6} md={6} lg={6}>
                                          <div className="booking-div-11">
                                            <img src="../../assets/images/find.gif" alt="icon" />
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Row>
                                </div>

                              </Link>
                            </div>
                          );
                        }
                        else if (status["Stage"] === "Professional Assigned") {
                          return (
                            <div>
                              {ServiceList?.records?.map((Service, j) => {
                                
                                // if (status["Stage"] === "Booking Assigned") {
                                return (
                                  <>
                                    <h3 className="booking-div-14 booking-div-140">{status["Category"]}</h3>
                                    <div className="my-order-inner my-order-inner-5">
                                      <Row>
                                        <Col xs={10} sm={10} md={10} lg={10}>
                                          <div className="booking-div-10">
                                            <h2> {status["Stage"]}</h2>
                                            <h3>{status["Sub Category"]}</h3>
                                            <span>{formattedDate + ' at ' + formattedTime}</span>
                                          </div>
                                        </Col>
                                        <Col xs={2} sm={2} md={2} lg={2}>
                                          <Link to={`/customer/order-details/${status["BookingID"]}`}>
                                            <div className="booking-div-11">
                                              <KeyboardArrowRightIcon style={{ color: "blue" }} />
                                            </div>
                                          </Link>
                                        </Col>

                                        <div className="booking-div-12">
                                          <Row>
                                            <Col xs={6} sm={6} md={6} lg={6}>
                                              <div className="booking-div-15">
                                                {Service?.["ProfilePic"] !== undefined &&
                                                  Service?.["ProfilePic"] !== null ? (
                                                  <img
                                                    src={Service["ProfilePic"]}
                                                  // className="profile-pic-user" 
                                                  alt="Icon"
                                                  />
                                                ) : (
                                                  <img src="../../assets/images/chandu.jpeg" alt="icon" />
                                                )}
                                                <h3>{Service["User Name"]}<span><p className="rating-5"><StarRateIcon className="rating-6" />4.88</p></span></h3>
                                              </div>
                                            </Col>
                                            <Col xs={6} sm={6} md={6} lg={6}>
                                              <div className="booking-div-11">
                                                <CallIcon />
                                              </div>
                                            </Col>
                                          </Row>
                                        </div>


                                      </Row>
                                    </div>
                                  </>
                                );

                              })}
                            </div>
                          );
                        }
                        else if (status["Stage"] === "Service Completed") {
                          return (
                            <div>
                              <h3 className="booking-div-14 booking-div-140">{status["Category"]}</h3>
                              {/* <h3 className="booking-div-14">{status["Category"]}</h3> */}
                              {/* <Link to={`/customer/order-details/${status["BookingID"]}`}> */}
                              <div className="my-order-inner my-order-inner-5">
                                <Row>
                                  <Col xs={10} sm={10} md={10} lg={10}>
                                    <div className="booking-div-10">
                                      <h2> {status["Stage"]}</h2>
                                      <h3>{status["Sub Category"]}</h3>
                                      <span>{formattedDate + ' at ' + formattedTime}</span>
                                    </div>
                                  </Col>
                                  <Col xs={2} sm={2} md={2} lg={2}>
                                    <Link to={`/customer/order-details/${status["BookingID"]}`}>
                                      <div className="booking-div-11">
                                        <KeyboardArrowRightIcon style={{ color: "blue" }} />
                                      </div>
                                    </Link>
                                  </Col>
                                  <div className="booking-div-12">
                                    <Row>
                                      <Col xs={6} sm={6} md={8} lg={9}>
                                        <div className="booking-div-15">
                                          <p className="amount-pay-1"> <CheckCircleIcon className="amount-pay-icon" />Amount Pay ₹{Math.round(status["TotalAmt"])} </p>
                                        </div>
                                      </Col>
                                      <Col xs={6} sm={6} md={4} lg={3}>
                                        <div className="book-again-button-1">
                                          <Link to="/"><Button variant="contained" className="track-button-2">
                                            <RepeatIcon className="buy-icon" />
                                            Book Again
                                          </Button>
                                          </Link>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </Row>
                              </div>
                              {/* </Link> */}
                            </div>
                          );
                        }
                        else if (status["Stage"] === "Cancel Service") {
                          return (
                            <div>
                              {/* <h3 className="booking-div-14">{status["Category"]}</h3> */}
                              <h3 className="booking-div-14 booking-div-140">{status["Category"]}</h3>
                              {/* <Link to={`/customer/order-details/${status["BookingID"]}`}> */}
                              <div className="my-order-inner my-order-inner-5">
                                <Row>
                                  <Col xs={10} sm={10} md={10} lg={10}>
                                    <div className="booking-div-10">
                                      <h2 className="booking-cancel">{status["Stage"]}</h2>
                                      <h3>{status["Sub Category"]}</h3>
                                      {moment(status["Appointment Dt"]).format("ddd, MMM DD") + ' at ' + '12:30 '}
                                      <span>{formattedDate + ' at ' + formattedTime}</span>
                                    </div>
                                  </Col>
                                  <Col xs={2} sm={2} md={2} lg={2}>
                                    <Link to={`/customer/order-details/${status["BookingID"]}`}>
                                      <div className="booking-div-11">
                                        <KeyboardArrowRightIcon style={{ color: "blue" }} />
                                      </div>
                                    </Link>
                                    {/* 
                                      </div>
                                    </Col>
                                    <Col xs={2} sm={2} md={2} lg={2}>
                                      <div className="booking-div-11">
                                        <KeyboardArrowRightIcon />
                                      </div> */}
                                  </Col>
                                </Row>
                              </div>
                              {/* </Link> */}
                            </div>
                          );
                        }
                      })}</>)}
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <FooterAfterLogin />
      </div>
      {/* Web Version */}

      {/* Mobile Version */}
      <div className="mobile-view">

        <div className="my-orders">
          <h2 className="mobile-header-1"><Link to="/"><ArrowBackIcon className="mobile-header-1-left-icon" /></Link>My Bookings
            <span className="mobile-search-icon-1"><SearchOutlinedIcon /></span></h2>
          <div className="top-space-mobile">
            <Container>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <h2>My Bookings</h2>

                  {MyBooking?.records?.map((status, i) => {
                    if (status["Stage"] === "Booking Assigned") {
                      return (
                        <div>
                          {/* <h3 className="booking-div-14">{status["Category"]}</h3> */}
                          <h3 className="booking-div-14 booking-div-140">{status["Category"]}</h3>
                          <Link to={`/customer/order-details/${status["BookingID"]}`}>

                            <div className="my-order-inner my-order-inner-5">
                              <Row>
                                <Col xs={10} sm={10} md={10} lg={10}>
                                  <div className="booking-div-10">
                                    <h2> {status["Stage"]}</h2>
                                    <h3>{status["Sub Category"]}</h3>
                                    {moment(status["Appointment Dt"]).format("ddd, MMM DD") + ' at ' + '12:30 '}
                                  </div>
                                </Col>
                                <Col xs={2} sm={2} md={2} lg={2}>
                                  <div className="booking-div-11">
                                    <KeyboardArrowRightIcon />
                                  </div>
                                </Col>
                                <div className="booking-div-12">
                                  <Row>
                                    <Col xs={6} sm={6} md={6} lg={6}>
                                      <div className="booking-div-13">
                                        <h3>Finding a Professional</h3>
                                      </div>
                                    </Col>
                                    <Col xs={6} sm={6} md={6} lg={6}>

                                      <div className="booking-div-11">
                                        <img src="../../assets/images/find.gif" />

                                      </div>
                                    </Col>
                                  </Row>
                                </div>

                              </Row>
                            </div>

                          </Link>
                        </div>
                      );
                    }
                    else if (status["Stage"] === "Professional Assigned") {

                      return (

                        <div>
                          {ServiceList?.records?.map((Service, j) => {

                            // if (status["Stage"] === "Booking Assigned") {
                            return (
                              <>

                                {/* <h3 className="booking-div-14">{status["Category"]}</h3> */}
                                <h3 className="booking-div-14 booking-div-140">{status["Category"]}</h3>

                                <Link to={`/customer/order-details/${status["BookingID"]}`}>

                                  <div className="my-order-inner my-order-inner-5">
                                    <Row>
                                      <Col xs={10} sm={10} md={10} lg={10}>
                                        <div className="booking-div-10">
                                          <h2> {status["Stage"]}</h2>
                                          <h3>{status["Sub Category"]}</h3>
                                          {moment(status["Appointment Dt"]).format("ddd, MMM DD") + ' at ' + '12:30 '}
                                        </div>
                                      </Col>
                                      <Col xs={2} sm={2} md={2} lg={2}>
                                        <div className="booking-div-11">
                                          <KeyboardArrowRightIcon />
                                        </div>
                                      </Col>

                                      <div className="booking-div-12">
                                        <Row>
                                          <Col xs={6} sm={6} md={6} lg={6}>
                                            <div className="booking-div-15">
                                              {/* <img src="../../assets/images/chandu.jpeg" /> */}
                                              {Service?.["ProfilePic"] !== undefined &&
                                                Service?.["ProfilePic"] !== null ? (
                                                <img
                                                  src={Service["ProfilePic"]}
                                                // className="profile-pic-user"
                                                />
                                              ) : (
                                                <img src="../../assets/images/chandu.jpeg" />
                                              )}

                                              <h3>{Service["User Name"]}<span><p className="rating-5"><StarRateIcon className="rating-6" />4.88</p></span></h3>

                                            </div>
                                          </Col>
                                          <Col xs={6} sm={6} md={6} lg={6}>

                                            <div className="booking-div-11">
                                              <CallIcon />

                                            </div>
                                          </Col>
                                        </Row>
                                      </div>

                                    </Row>
                                  </div>

                                </Link>
                              </>
                            );

                          })}
                        </div>
                      );
                    }
                    else if (status["Stage"] === "Service Completed") {


                      return (
                        <div>

                          <h3 className="booking-div-14 booking-div-140">{status["Category"]}</h3>
                          {/* <h3 className="booking-div-14">{status["Category"]}</h3> */}
                          <Link to={`/customer/order-details/${status["BookingID"]}`}>

                            <div className="my-order-inner my-order-inner-5">
                              <Row>
                                <Col xs={10} sm={10} md={10} lg={10}>
                                  <div className="booking-div-10">
                                    <h2> {status["Stage"]}</h2>
                                    <h3>{status["Sub Category"]}</h3>
                                    {moment(status["Appointment Dt"]).format("ddd, MMM DD") + ' at ' + '12:30 '}
                                  </div>
                                </Col>
                                <Col xs={2} sm={2} md={2} lg={2}>
                                  <div className="booking-div-11">
                                    <KeyboardArrowRightIcon />
                                  </div>
                                </Col>
                                <div className="booking-div-12">
                                  <Row>
                                    <Col xs={6} sm={8} md={9} lg={10}>
                                      <div className="booking-div-15">
                                        {/* <img src="../../assets/images/chandu.jpeg" /> */}


                                        <p className="amount-pay-1"> <CheckCircleIcon className="amount-pay-icon" />Amount Pay ?699 </p>

                                      </div>
                                    </Col>
                                    <Col xs={6} sm={4} md={3} lg={2}>

                                      <div className="book-again-button-1">
                                        <Link to="/"><Button variant="contained" className="track-button-2">
                                          <RepeatIcon className="buy-icon" />
                                          Book Again
                                        </Button>
                                        </Link>

                                      </div>
                                    </Col>
                                  </Row>
                                </div>

                              </Row>
                            </div>

                          </Link>
                        </div>
                      );
                    }
                    else if (status["Stage"] === "Cancel Service") {

                      return (
                        <div>
                          <h3 className="booking-div-14 booking-div-140">{status["Category"]}</h3>
                          {/* <h3 className="booking-div-14">{status["Category"]}</h3> */}
                          <Link to={`/customer/order-details/${status["BookingID"]}`}>

                            <div className="my-order-inner my-order-inner-5">
                              <Row>
                                <Col xs={10} sm={10} md={10} lg={10}>
                                  <div className="booking-div-10">
                                    <h2 className="booking-cancel">{status["Stage"]}</h2>
                                    <h3>{status["Sub Category"]}</h3>
                                    {moment(status["Appointment Dt"]).format("ddd, MMM DD") + ' at ' + '12:30 '}
                                  </div>
                                </Col>
                                <Col xs={2} sm={2} md={2} lg={2}>
                                  <div className="booking-div-11">
                                    <KeyboardArrowRightIcon />
                                  </div>
                                </Col>

                              </Row>
                            </div>

                          </Link>
                        </div>
                      );
                    }
                  })}
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <FooterMobile />
      </div>
      {/* Mobile Version */}
    </div>
  );
}
export default MyBookings
