import {
    BrowserRouter as Router,
    Route,
    Link,
    useParams,
} from "react-router-dom";
import React, { useState, useEffect, useContext } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "@mui/material/Button";
import Form from "react-bootstrap/Form";
import Headerinner from "../Common/Headerinner";
import SideBar from "../Common/SideBar";
import moment from "moment";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ProductContext } from "../context/AppContext";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": { backgroundColor: theme.palette.action.hover, },
    // hide last border
    "&:last-child td, &:last-child th": { border: 0, },
}));
function CommonListPage() {
    const { handleClickLoader, handleCloseLoader } = useContext(ProductContext);
    const { type, viewFormat, taberpid } = useParams();

    var userLogininfo = JSON.parse(localStorage.getItem("user-Logininfo"));
    if (userLogininfo === null) {
        window.location.href = "/";
    }
    else if (userLogininfo["User Group"] !== "Employee") {
      window.location.href = "/not-found";
      
    }
    let baseURL = `${process.env.REACT_APP_BASE_URL}`;
    let authToken = localStorage.getItem("Auth-Token").replace(/"/g, "");

    const [commonList, setcommonList] = useState([]);
    const [showcolumn, setshowcolumn] = useState([]);
    function fun_CommonList() {
        handleClickLoader();
        const listpra = {
            pgSize: 500,
            viewFormat: viewFormat,
            filterButtons: "",
            index: 1,
            toEmailID: "designMonu@dialerp.com",
            tabErpID: taberpid,
            useAtlusSearch: false,
            filters: "{}",
        };

        fetch(baseURL + "/api/Dynamic/GetRecords", {
            method: "POST",
            headers: {
                "Authorization-Token": authToken,
                Accept: "application/json, text/plain",
                "Content-Type": "application/json;charset=UTF-8",
            },
            body: JSON.stringify(listpra),
        }).then((result) => {
            result.json().then((resp) => {
                if (resp.hasErrors === false) {
                    if (resp.response?.records !== null) {
                        setcommonList(resp.response);
                        let dataFlt = resp?.response?.columns;
                        setshowcolumn(
                            dataFlt.filter((item) => item["listDataViewSequence"] > 0)
                        );
                        handleCloseLoader();
                    } else {
                        setcommonList("");
                        handleCloseLoader();
                    }
                } else {
                    alert(resp.errors);
                    handleCloseLoader();
                }
            });
        });
    }
    useEffect(() => {
        fun_CommonList();
    }, [taberpid]);

    return (
        <>
        <Link to="/not-found" id="not-found" hidden>Not Found</Link>
        <div className="admin-panel-section">
            <Headerinner />
            <div className="admin-body-section admin-div-1">
                <Container fluid>
                    <Row>
                        <Col xs={12} sm={12} md={3} lg={2}>
                            <SideBar />
                        </Col>
                        <Col xs={12} sm={12} md={9} lg={10} className="right-side-section">
                            <div className="add-sevice-section-2">
                                <Form className="addservice-form">
                                    <Row>
                                        <Col xs={12} sm={12} md={12}>
                                            <h2 className="list-heading-1">{type} List
                                                <span>

                                                    <Link to={`/admin/common/${type}/Add/${taberpid}/0`}>
                                                        <Button variant="outlined" className="list-heading-1-button">Add</Button>
                                                    </Link>
                                                    {/* <Link to="">
                                                        <Button variant="outlined">Filter</Button>
                                                    </Link> */}
                                                    {/* <EditIcon className="list-heading-1-icon" />Add</Link> */}
                                                    {/* <Link to =""><FilterAltIcon /></Link> */}
                                                </span>
                                            </h2>
                                        </Col>

                                        <TableContainer component={Paper}>
                                            <Table aria-label="customized table" className="custom-table-5 custom-table-2">
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell className="table-th-2" >
                                                            <VisibilityIcon />
                                                        </StyledTableCell>
                                                        {showcolumn?.map((inputtype, i) => {
                                                            return (
                                                                <StyledTableCell className="table-th-2">
                                                                    {inputtype["displayName"]}
                                                                </StyledTableCell>
                                                            );
                                                        })}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {commonList?.records?.map((data, i) => {
                                                        i++;
                                                        return (
                                                            <>
                                                                <StyledTableRow key={i}>
                                                                    <StyledTableCell>
                                                                        <Link 
                                                                        to={`/admin/commonviewDetails/${type}/View/${taberpid}/${data["_id"]}`}>
                                                                            <VisibilityIcon />
                                                                        </Link>
                                                                    </StyledTableCell>
                                                                    {showcolumn?.map((coldata, J) => {
                                                                        if (coldata["addType"] === "PicFile") {
                                                                            return (
                                                                                <>
                                                                                    <StyledTableCell component="th" scope="row">
                                                                                        {data[coldata["displayName"]] !== null && data[coldata["displayName"]] !== undefined && data[coldata["displayName"]] !== "" ?
                                                                                            <img src={data[coldata["displayName"]]} alt="icon" />
                                                                                            : <img src="../../../../assets/images/photo-album-icon.png" alt="icon"
                                                                                            />
                                                                                        }
                                                                                    </StyledTableCell>
                                                                                </>
                                                                            );
                                                                        }
                                                                        if (coldata["columnType"] === "cDt") {
                                                                          return (
                                                                                <>
                                                                                    <StyledTableCell component="th" scope="row">
                                                                                        {data[coldata["displayName"]] !== undefined &&
                                                                                            moment(
                                                                                                data[coldata["displayName"]]
                                                                                            ).format("DD-MMM-yyyy hh:mm:ss")
                                                                                        }
                                                                                        { }
                                                                                    </StyledTableCell>

                                                                                </>
                                                                            );
                                                                        }
                                                                        if (coldata["columnType"] === "SR") {
                                                                            return (
                                                                                <>
                                                                                    <StyledTableCell component="th" scope="row">{i}    </StyledTableCell>
                                                                                </>
                                                                            );
                                                                        } else {
                                                                            return (
                                                                                <>
                                                                                    <StyledTableCell component="th" scope="row">
                                                                                        {data[coldata["displayName"]] !== undefined &&
                                                                                            data[coldata["displayName"]]
                                                                                        }

                                                                                    </StyledTableCell>
                                                                                </>
                                                                            );
                                                                        }
                                                                    })}
                                                                </StyledTableRow>
                                                            </>
                                                        );
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Row>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
        </>
    );
}
export default CommonListPage;
