import React, { useState, useEffect, useContext } from "react";
import Button from "@mui/material/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "react-calendar/dist/Calendar.css";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import ClearIcon from "@mui/icons-material/Clear";
import axios from 'axios';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import Stack from "@mui/material/Stack";
import AddAddress from "./AddAddress";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { ProductContext } from "../context/AppContext";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DeliveryAddress() {
  const {CandidateActiveAddress, fun_getAddAddressListlick, fun_Delete,
    openAddress, handleClickopenAddress, handleCloseopenAddress, NewAddress,
     handleClickNewAddress, handleCloseNewAddress, fullScreen, AllAddress,fun_CandidateAllAddress} = useContext(ProductContext);
  //localStorage.removeItem("ADDID");
  

  // const [FirstName, setFirstName] = useState("");
  // const [LastName, setLastName] = useState("");
  var userLogininfo = JSON.parse(localStorage.getItem("user-Logininfo"));
  useEffect(() => {
    if (userLogininfo !== null && userLogininfo !== "" && userLogininfo !== undefined) {
      fun_CandidateAllAddress();
    }
  }, [0]);

  return (
    <div>
      <div className="">
        <>
          {CandidateActiveAddress.length > 0 ?
            <>
              {CandidateActiveAddress?.map((status, i) => {
                if (i === 0) {
                  return (
                    <>
                      <div className="left-right-radio" key={i}>
                        <div className="left-view-booking-1">
                          <span>
                            <FmdGoodOutlinedIcon className="location-icon-1" />
                          </span>
                          <div className="width-100">
                            <p><b>Send booking details to</b></p>
                            <p>{status["ContactName"]} (+91 {status["ContactMobile"]})</p>
                            {/* <p>{status["Address Line1"]}, {status["City"]} ({status["State"]}). {status["Country"]} {status["Pincode"]}</p> */}
                          </div>
                        </div>
                      </div>
                      <div className="left-right-radio">
                        <div className="left-view-booking-1">
                          <span>
                            <FmdGoodOutlinedIcon className="location-icon-1" />
                          </span>
                          <div className="width-100 div-inline-css-1">
                            {/* <p>{status["Alais Name"]}</p>
                        <p>{status["Mobile"]}</p> */}
                            <p className="padding-right-20"><b>Address</b></p>
                            <p className="padding-right-20">{status["AddressLine1"]}, {status["City"]} ({status["State"]}). {status["Country"]} {status["Pincode"]}</p>
                          </div>
                          
                            <Button variant="outlined" className="next-button-11" onClick={handleClickopenAddress}>
                              Edit
                            </Button>
                        </div>
                      </div>
                    </>
                  );
                }
              })}
            </>
            :
            <div className="add-address-div-6 padding-8">
              <h2 onClick={handleClickopenAddress}><ControlPointIcon className="add-icon-2" />Select an Address</h2>
            </div>

          }

        </>
      </div>
     
    </div>
  );
}
export default DeliveryAddress;
