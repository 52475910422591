import React, { useEffect, useState, useContext } from "react";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";
import { Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { ProductContext } from "../context/AppContext";

function CustomerLogin() {
  const {fun_LogOut, username, setUsername, password, setPassword, fun_DyLogin, ID,
    ContactName, setContactName,CountryCode, setCountryCode,Mobile, setMobile,Email, setEmail,Password2, setPassword2,
    DOB, setDOB,Gender, setGender,error, setError,emailRegex,DayDOB, setDayDOB,MonthDOB, setMonthDOB,YearDOB,
     setYearDOB,DayDOBTypeDropdown, 
    setDayDOBTypeDropdown,MonthDOBTypeDropdown, setMonthDOBTypeDropdown,YearDOBTypeDropdown, setYearDOBTypeDropdown,
    bindDayDOBDropdown,bindMonthDOBDropdown,bindYearDOBYearDropdown,handleKeyPress,fun_CustomerRagistration} = useContext(ProductContext)

  
  return (
    <div>


      <div className="login-18 login-18-Customer-login">
              <div className="form-section">
                <div className="logo-2">
                  <Link to="/">
                    <img src="../../assets/images/logo1.svg" alt="logo" />
                  </Link>
                </div>
                <div className="typing-3">
                  <h3>Customer Login</h3>
                </div>
                {/* <div className="form-group">
                  <label
                    htmlFor="second_field"
                    className="form-label float-start"
                  >
                    Email Address
                  </label>
                  <input
                    name="email"
                    type="email"
                    className="form-control"
                    id="second_field"
                    placeholder="Email Address"
                    aria-label="Email Address"

                    value={username} onChange={(e) => { setUsername(e.target.value); }}
                  />
                </div> */}
                <TextField id="outlined-basic" className="width-100 lgoin-input-1" label="Email" variant="outlined"
                 value={username} onChange={(e) => { setUsername(e.target.value); }} />

                <TextField id="outlined-basic" className="width-100 lgoin-input-1" label="Password" variant="outlined"  type="password"
                value={password} onChange={(e) => { setPassword(e.target.value); }} />


                {/* <div className="form-group clearfix">
                  <label htmlFor="third_field" className="form-label float-start" >
                    Password
                  </label>
                  <input
                    name="password"
                    type="password"
                    className="form-control"
                    autoComplete="off"
                    id="third_field"
                    placeholder="Password"
                    aria-label="Password"
                    value={password} onChange={(e) => { setPassword(e.target.value); }}
                  />
                </div> */}
                <div className="checkbox form-group clearfix">
                  <div className="form-check checkbox-theme">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue=""
                      id="rememberMe"
                    />
                    <label className="form-check-label" htmlFor="rememberMe">
                      Remember me
                    </label>
                  </div>
                  <a href="#" className="float-end">
                  {/* forgot-password-18.html */}
                    Forgot Password
                  </a>
                </div>
                <div className="form-group clearfix">
                  <button type="submit" className="btn btn-primary btn-lg btn-theme" onClick={fun_DyLogin}>
                    <b>Login</b>
                  </button>
                </div>
                <div className="social-list">
                  {/* <span>Or Login With</span><br /><br /> */}
                  <GoogleOAuthProvider style={{}} clientId={ID} className="google22">
                    <GoogleLogin className="google22"
                      onSuccess={credentialResponse => {
                        var decoded = jwt_decode(credentialResponse.credential);
                        console.log(decoded);
                        localStorage.setItem("Buyer-Login", JSON.stringify(decoded));
                        //  navigate("/");

                        ;
                      }}
                      onError={() => {
                        console.log('Login Failed');
                      }}
                    />
                  </GoogleOAuthProvider>
                </div>
                <div className="clearfix" />
                {/* <p>
                  Don't have an account?
                  <Link to="/customer/ragistration"> Register here</Link>
                </p> */}
              </div>
            </div>

    </div>
  )
}

export default CustomerLogin
