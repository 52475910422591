import { BrowserRouter as Router, Route, Link, useParams } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "@mui/material/Button";
import Form from "react-bootstrap/Form";
import Stack from "@mui/material/Stack";
import Headerinner from "../Common/Headerinner";
import SideBar from "../Common/SideBar";
import moment from "moment";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
function Service() {

    const { type, addEdit, taberpid, recordErpID } = useParams();
    var userLogininfo = JSON.parse(localStorage.getItem("user-Logininfo"));
    if (userLogininfo === null) {
        window.location.href = '/';
    }
    else {
        if (userLogininfo["User Group"] !== "Employee") {
            window.location.href = "/not-found";
        }

    }
    let baseURL = `${process.env.REACT_APP_BASE_URL}`;
    let authToken = localStorage.getItem("Auth-Token").replace(/"/g, '');
    const [CategoryImg, setCategoryImg] = useState(null);

    useEffect(() => {
        getInputColumnsDropdown();
        //fun_CategoryList();
    }, [0]);

    const [ColumnsDropdownlist, setColumnsDropdownlist] = useState([]);
    const getInputColumnsDropdown = () => {
        window.scrollTo(0, 0);
        var formData = { "tabErpID": taberpid, "filterButtons": "", "recordErpID": recordErpID, "toEmailID": "designMonu@dialerp.com" };
        fetch(
            baseURL + "/api/Dynamic/GetInputColumns",
            {
                method: "POST",
                headers: {
                    "Authorization-Token": authToken,
                    Accept: "application/json, text/plain",
                    "Content-Type": "application/json;charset=UTF-8",
                },
                body: JSON.stringify(formData),
            }
        ).then((result) => {
            result.json().then((resp) => {

                if (resp.hasErrors === false) {
                    if (resp.response !== null) {
                        setColumnsDropdownlist(resp.response);
                    }
                    else {
                        alert("Not find any drop down list");
                    }
                }
                else {
                    if (resp.errors !== undefined) {
                        alert(JSON.stringify(resp.errors) + "3");
                    }
                }
            });
        });
    };

    const [formdata, setformdata] = useState({});
    const handleInputChange = (e, fieldName, i) => {

        //let value = '';
        //if (e && e.target) {
        const { value } = e.target;
        setformdata({
            ...formdata,
            [fieldName]: value,
        });
        //}

        //let testvalue = totalRecords.find(p => p[fieldName]);
        let indexrecord = totalRecords.findIndex(p => p[fieldName]);
        //if (testvalue !== undefined && testvalue !== null) {
        if (indexrecord > -1) {
            //let data = testvalue[fieldName];

            let recordindex = "";
            let onClickColumndataindex = ColumnsDropdownlist.findIndex(p => p.columnName === fieldName);
            if (ColumnsDropdownlist[onClickColumndataindex]["columnType"] === "Int64") {
                recordindex = totalRecords[indexrecord][fieldName].findIndex(p => p[fieldName] === parseInt(value));
            }
            else {
                recordindex = totalRecords[indexrecord][fieldName].findIndex(p => p[fieldName] === value);
            }
            console.log(ColumnsDropdownlist[onClickColumndataindex]);
            if (recordindex > -1) {
                const updatedData = totalRecords[indexrecord][fieldName][recordindex];
                let objkeys = Object.keys(updatedData);
                for (let i = 0; i < objkeys.length; i++) {
                    if (objkeys[i] !== "_id") {
                        let index = ColumnsDropdownlist.findIndex(p => p.columnName === objkeys[i]);
                        if (index > -1) ColumnsDropdownlist[index]["columnDefaultValue"] = updatedData[objkeys[i]];
                    }
                }
            }
        }
        else {
            ColumnsDropdownlist[i]["columnDefaultValue"] = value;
        }

        // let indexrecord = totalRecords.findIndex(p => p[fieldName]);
        //  
        // if (indexrecord > -1) {
        //     let recordindex = totalRecords[indexrecord][fieldName].findIndex(p => p[fieldName] === value);
        //     if (recordindex > -1) {
        //         const updatedData = totalRecords[indexrecord][fieldName][recordindex];
        //         let objkeys = Object.keys(updatedData);
        //         for (let i = 0; i < objkeys.length; i++) {
        //             if (objkeys[i] !== "_id") {
        //                 let index = ColumnsDropdownlist.findIndex(p => p.columnName === objkeys[i]);
        //                 if (index > -1) ColumnsDropdownlist[index]["columnDefaultValue"] = updatedData[objkeys[i]];
        //             }
        //         }
        //     }
        // }
        // else {
        //     ColumnsDropdownlist[i]["columnDefaultValue"] = value;
        // }

    };
    const [file, setFile] = useState("");

    const handleFile = (e, column, i) => {

        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            const img = new Image();
            img.onload = () => {
                if (column !== "Banner") {
                    if (column === "SubSubCategoryPic") {
                        if (img.naturalWidth === 400 && img.naturalHeight === 400) {
                            // alert(img.naturalWidth + " : Width");
                            // alert(img.naturalHeight + " : Height");
                            UploadhandleFile(e.target.files[0], column, i);
                        }
                        else {
                            alert('Image dimensions must be 400x400 pixels.')
                        }
                    }
                    else if (column === "Pic") {
                        // if (img.naturalWidth === 400 && img.naturalHeight === 400) {
                        //     // alert(img.naturalWidth + " : Width");
                        //     // alert(img.naturalHeight + " : Height");
                        UploadhandleFile(e.target.files[0], column, i);
                        // }
                        // else {
                        //     alert('Image dimensions must be 400x400 pixels.')
                        // }
                    }

                    else {

                        if (img.naturalWidth === 1000 && img.naturalHeight === 1000) {
                            // alert(img.naturalWidth + " : Width");
                            // alert(img.naturalHeight + " : Height");
                            UploadhandleFile(e.target.files[0], column, i);
                        }
                        else {
                            alert('Image dimensions must be 1000x1000 pixels.')
                        }
                    }
                }
                if (column === "Banner") {
                    if (img.naturalWidth === 1920 && img.naturalHeight === 900) {
                        //alert(img.naturalWidth + " : Width");
                        //alert(img.naturalHeight + " : Height");
                        UploadhandleFile(e.target.files[0], column, i);
                    }
                    else {
                        alert('Image dimensions must be 1920x900 pixels.')
                    }
                }
            };
            img.src = event.target.result;
        };

        reader.readAsDataURL(file);
    };
    const UploadhandleFile = (e, column, i) => {
        // setImgWidth(img.naturalWidth);
        // setImgHeight(img.naturalHeight);
        setFile(URL.createObjectURL(e));
        const formData = new FormData();
        formData.append(column, e);
        fetch(
            baseURL + "/api/UploadData/UploadCommonFilesToAzure",
            {
                method: "POST",
                headers: { HttpHeaders: "multipart/form-data" },
                body: formData,
            }
        ).then((result) => {
            result.json().then((resp) => {
                if (resp.hasErrors === false) {
                    if (resp?.response !== null) {
                        // setformdata({ ...formdata, CategoryPic: resp?.response[0]["imageUrl"] });
                        setCategoryImg(resp?.response[0]["imageUrl"]);
                        ColumnsDropdownlist[i]["columnDefaultValue"] = resp?.response[0]["imageUrl"]
                    }
                }
                else {
                    alert(resp?.errors);
                }
            });
        });
    };


    const dropdowndata = [];
    const [totalRecords, settotalRecords] = useState([]);
    const bindDropdown = (field) => {
        const updatedData = totalRecords.filter(item => item[field.columnName]);
        if (updatedData.length === 0) {
            var dataMapper = {};
            if (field === "JobRole") {
                dataMapper = {
                    "CollectionName": field.dataMapper.collectionName,
                    "filter": `{'${field.columnName}':/^null/i}`,
                    "project": field.dataMapper.project
                }
            }
            else {
                dataMapper = {
                    "CollectionName": field.dataMapper.collectionName,
                    "filter": `{'${field.columnName}':/^null/i}`,
                    "project": field.dataMapper.project
                }
            }
            var formData =
            {
                "userID": 40364,
                "filters": "{tabErpID:707}",
                "index": 1,
                "pgSize": 200,
                "isDownload": false,
                "toEmailID": "chandrakant111420@gmail.com",
                "tabErpID": field.tabErpID,
                "dataMapper": dataMapper
                //  {"CollectionName":"JOBSCONNECT_JobTitle","filter":"{'JobTitle':/^null/i}","project":"{'JobTitle':'$JobTitle'}"}
            }
            fetch(
                baseURL + "/api/dynamic/AutoFillMapperWise",
                {
                    method: "POST",
                    headers: {
                        "Authorization-Token": authToken,
                        Accept: "application/json, text/plain",
                        "Content-Type": "application/json;charset=UTF-8",
                    },
                    body: JSON.stringify(formData),
                })
                .then((result) => {
                    result.json().then((resp) => {
                        if (resp.hasErrors === true) {
                            alert(resp.errors);
                        }
                        else {
                            if (resp.response?.records !== null) {
                                let updatedData = resp.response?.records;
                                dropdowndata[field.columnName] = updatedData;
                                settotalRecords(prevPra => [...prevPra, dropdowndata]);
                            }
                        }
                    }
                    )
                });
        }
    }
    const Fun_Cancel = () => {
        // window.location.href = "/admin/dashboard";
        document.getElementById("list-page").click();

    }
    const fun_formValidation = () => {
        let myObject = {};
        setformdata({});
        let isvalid = false;
        for (let i = 0; i < ColumnsDropdownlist.length; i++) {
            if (ColumnsDropdownlist[i]["isMandatory"] === true && ColumnsDropdownlist[i]["addType"] !== "ReadOnly"
                && ColumnsDropdownlist[i]["columnType"] !== "key_sr" &&
                (ColumnsDropdownlist[i]["columnDefaultValue"] === null || ColumnsDropdownlist[i]["columnDefaultValue"] === ""
                    || ColumnsDropdownlist[i]["columnDefaultValue"] === undefined)) {
                alert(ColumnsDropdownlist[i]["columnName"] + " is Required");
                document.getElementById(ColumnsDropdownlist[i]["columnID"]).focus();
                isvalid = true;
                return;
            }
            else {
                let columnName = ColumnsDropdownlist[i]["columnName"];
                let fvalue ="";
                if(ColumnsDropdownlist[i]["displayName"]==="cDt")
                fvalue=moment(ColumnsDropdownlist[i]["columnDefaultValue"]).format("DD-MMM-yyyy HH:mm")
                else
                fvalue=ColumnsDropdownlist[i]["columnDefaultValue"];
                // setformbody({
                //     ...formbody,
                //     [columnName]: fvalue,
                // });
                if (fvalue !== null&&fvalue !== "") {
                    myObject = {
                        ...myObject,
                        [columnName]: fvalue
                    };
                    // setformbody(prevObject => {
                    //     return { ...prevObject, [columnName]: fvalue };
                    // });
                    // console.log(formbody);
                }
            }
        }
        if (isvalid === false) {
            fun_AddPageData(isvalid, myObject);

        }

    }
    const fun_AddPageData = (isvalid, myObject) => {
        if (isvalid === false) {

            fetch(
                `${baseURL}/api/Dynamic/AddUpdateRecords?LoginType=2&tabErpID=${taberpid}`,
                {
                    method: "POST",
                    headers: {
                        "Authorization-Token": authToken,
                        Accept: "application/json, text/plain",
                        "Content-Type": "application/json;charset=UTF-8",
                    },
                    body: JSON.stringify([myObject]),
                }
            ).then((result) => {
                result.json().then((resp) => {
                    if (resp.hasErrors === false) {
                        alert(resp.response.message)
                        document.getElementById("list-page").click();
                    }
                    else {
                        alert(resp.errors);
                    }
                });
            });
        }
    }
    const handleInputChangetexteditor = (e, fieldName, i) => {

        //let value = '';
        //if (e && e.target) {
        const value = e;
        setformdata({
            ...formdata,
            [fieldName]: value,
        });
        //}
        let indexrecord = totalRecords.findIndex(p => p[fieldName]);
        if (indexrecord > -1) {
            let recordindex = "";
            recordindex = totalRecords[indexrecord][fieldName].findIndex(p => p[fieldName] === value);
            if (recordindex > -1) {
                const updatedData = totalRecords[indexrecord][fieldName][recordindex];
                let objkeys = Object.keys(updatedData);
                for (let i = 0; i < objkeys.length; i++) {
                    if (objkeys[i] !== "_id") {
                        let index = ColumnsDropdownlist.findIndex(p => p.columnName === objkeys[i]);
                        if (index > -1) ColumnsDropdownlist[index]["columnDefaultValue"] = updatedData[objkeys[i]];
                    }
                }
            }
        }
        else {
            ColumnsDropdownlist[i]["columnDefaultValue"] = value;
        }

        // let indexrecord = totalRecords.findIndex(p => p[fieldName]);
        //  
        // if (indexrecord > -1) {
        //     let recordindex = totalRecords[indexrecord][fieldName].findIndex(p => p[fieldName] === value);
        //     if (recordindex > -1) {
        //         const updatedData = totalRecords[indexrecord][fieldName][recordindex];
        //         let objkeys = Object.keys(updatedData);
        //         for (let i = 0; i < objkeys.length; i++) {
        //             if (objkeys[i] !== "_id") {
        //                 let index = ColumnsDropdownlist.findIndex(p => p.columnName === objkeys[i]);
        //                 if (index > -1) ColumnsDropdownlist[index]["columnDefaultValue"] = updatedData[objkeys[i]];
        //             }
        //         }
        //     }
        // }
        // else {
        //     ColumnsDropdownlist[i]["columnDefaultValue"] = value;
        // }

    };

    return (

        <div className="admin-panel-section">
            {/* <Link to ="/admin/dashboard" id="dashboard"></Link> */}
            <Link to={`/admin/commonlistpage/${type}/${addEdit}/${taberpid}`} id='list-page'></Link>
            <Headerinner />
            <div className="admin-body-section admin-div-1">
                <Container fluid>
                    <Row>
                        <Col xs={12} sm={12} md={3} lg={2}>
                            <SideBar />
                        </Col>
                        <Col xs={12} sm={12} md={9} lg={10} className="right-side-section">
                            <div className="add-sevice-section-2">
                                <div className="addservice-form">
                                    <Row>
                                        <Col xs={12} sm={12} md={12}>
                                            <h2>{addEdit} {type}</h2>
                                        </Col>
                                        {ColumnsDropdownlist?.map((inputtype, i) => {

                                            if (inputtype.addType === "ReadOnly" && recordErpID !== "0") {
                                                return (
                                                    <>
                                                        <Col xs={12} sm={12} md={12} key={i} style={{ paddingBottom: "10px" }}>
                                                            {/* <TextField
                                                                id="outlined-basic"
                                                                label={inputtype.displayName}
                                                                variant="outlined"
                                                                className="addservice-1"
                                                                onChange={(e) => handleInputChange(e, inputtype.columnName)}
                                                                disabled style={{ background: 'gray' }}
                                                            /> */}
                                                            <div className="form-group">
                                                                <label htmlFor={i}>
                                                                    {inputtype.displayName}
                                                                    {inputtype.isMandatory === true && <span style={{ color: "red" }}>*</span>}
                                                                </label>
                                                                {inputtype["columnType"] === "cDt" ?
                                                                    <input type="text" name={inputtype.columnName} placeholder={`Auto ${inputtype.displayName}`}
                                                                        className="form-control input-height"
                                                                        value={moment(inputtype["columnDefaultValue"]).format("DD-MMM-yyyy HH:mm")}
                                                                        id={inputtype.columnID} readOnly
                                                                    />
                                                                    : <input type="text" name={inputtype.columnName} placeholder={`Auto ${inputtype.displayName}`}
                                                                        className="form-control input-height"
                                                                        value={inputtype["columnDefaultValue"] || ''}
                                                                        id={inputtype.columnID} readOnly
                                                                    />}
                                                            </div >
                                                        </Col>
                                                    </>
                                                )
                                            }
                                            if (inputtype.addType === "Decimal") {
                                                return (
                                                    <>
                                                        <Col xs={12} sm={12} md={12} key={i} style={{ paddingBottom: "10px" }}>
                                                            {/* <TextField
                                                                id="outlined-basic"
                                                                label={inputtype.displayName}
                                                                variant="outlined"
                                                                className="addservice-1"
                                                                onChange={(e) => handleInputChange(e, inputtype.columnName)}
                                                                disabled style={{ background: 'gray' }}
                                                            /> */}
                                                            <div className="form-group">
                                                                <label htmlFor={i}>
                                                                    {inputtype.displayName}
                                                                    {inputtype.isMandatory === true && <span style={{ color: "red" }}>*</span>}
                                                                </label>
                                                                <input type="number" name={inputtype.columnName} placeholder={`Enter Decimal ${inputtype.displayName}`}
                                                                    className="form-control input-height"
                                                                    onChange={(e) => handleInputChange(e, inputtype.columnName, i)}
                                                                    value={inputtype["columnDefaultValue"] || ''}
                                                                    id={inputtype.columnID}
                                                                />
                                                            </div >
                                                        </Col>
                                                    </>
                                                )
                                            }
                                            if (inputtype.addType === "Number" || inputtype.addType === "TwoDecimal") {
                                                return (
                                                    <>
                                                        <Col xs={12} sm={12} md={12} key={i} style={{ paddingBottom: "10px" }}>
                                                            {/* <TextField
                                                                id="outlined-basic"
                                                                label={inputtype.displayName}
                                                                variant="outlined"
                                                                className="addservice-1"
                                                                onChange={(e) => handleInputChange(e, inputtype.columnName)}
                                                                disabled style={{ background: 'gray' }}
                                                            /> */}
                                                            <div className="form-group">
                                                                <label htmlFor={i}>
                                                                    {inputtype.displayName}
                                                                    {inputtype.isMandatory === true && <span style={{ color: "red" }}>*</span>}
                                                                </label>
                                                                <input type="number" name={inputtype.columnName}
                                                                    placeholder={`Enter Number ${inputtype.displayName}`}
                                                                    className="form-control input-height"
                                                                    onChange={(e) => handleInputChange(e, inputtype.columnName, i)}
                                                                    value={inputtype["columnDefaultValue"] || ''}
                                                                    id={inputtype.columnID}
                                                                />
                                                            </div >
                                                        </Col>
                                                    </>
                                                )
                                            }
                                            if (inputtype.addType === "TextBox") {
                                                return (
                                                    <>
                                                        <Col xs={12} sm={12} md={12} key={i} style={{ paddingBottom: "10px" }}>
                                                            <div className="form-group">
                                                                <label htmlFor={i}>
                                                                    {inputtype.displayName}
                                                                    {inputtype.isMandatory === true && <span style={{ color: "red" }}>*</span>}
                                                                </label>
                                                                <input type="text" name={inputtype.columnName} placeholder={`Enter ${inputtype.displayName}`}
                                                                    className="form-control input-height"
                                                                    id={inputtype.columnID}
                                                                    onChange={(e) => handleInputChange(e, inputtype.columnName, i)}
                                                                    value={inputtype["columnDefaultValue"] || ''} />
                                                            </div >
                                                        </Col>
                                                    </>
                                                )
                                            }
                                            if (inputtype.addType === "Dropdown") {
                                                return (
                                                    <>
                                                        <Col xs={12} sm={12} md={12} key={i} style={{ paddingBottom: "10px" }}>
                                                            <div className="form-group">
                                                                <label htmlFor={i}>
                                                                    {inputtype.displayName}
                                                                    {inputtype.isMandatory === true && <span style={{ color: "red" }}>*</span>}
                                                                </label>
                                                                <select className={`form-control input-height`} id={inputtype.columnID} onChange={(e) => handleInputChange(e, inputtype.columnName, i)} value={inputtype["columnDefaultValue"]}>
                                                                    <option value={""}>Select {inputtype.displayName}</option>
                                                                    {inputtype["objDropDown"]?.map((dd, j) => {
                                                                        return (
                                                                            <option key={j} value={dd["name"]} style={{ color: dd["colorCode"] }}> {dd["name"]} </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </div >
                                                        </Col>
                                                    </>
                                                )
                                            }
                                            if (inputtype.addType === "GoButton") {
                                                return (
                                                    <>
                                                        <Col xs={12} sm={12} md={12} key={i} style={{ paddingBottom: "10px" }}>
                                                            <div className="form-group">
                                                                <label htmlFor={i}>
                                                                    {inputtype.displayName}
                                                                    {inputtype.isMandatory === true && <span style={{ color: "red" }}>*</span>}
                                                                </label>
                                                                <select className={`form-control input-height`} id={inputtype.columnID}
                                                                    onClick={(e) => bindDropdown(inputtype)}
                                                                    onChange={(e) => handleInputChange(e, inputtype.columnName, i)}
                                                                    value={inputtype["columnDefaultValue"]}
                                                                >
                                                                    <option value={""}>Select {inputtype.displayName}</option>
                                                                    {totalRecords?.map((status, i) => {
                                                                        return (
                                                                            <>
                                                                                {status[inputtype.columnName]?.map((dd, i) => {
                                                                                    return (
                                                                                        <option key={dd['_id']} value={dd[inputtype.columnName]}>
                                                                                            {dd[inputtype.columnName]}
                                                                                        </option>
                                                                                    );
                                                                                })}
                                                                            </>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </div >
                                                        </Col>
                                                        {/* </div> */}
                                                    </>
                                                );
                                            }
                                            if (inputtype.addType === "TextArea") {
                                                return (
                                                    <>
                                                        <Col xs={12} sm={12} md={12} key={i} style={{ paddingBottom: "10px" }}>
                                                            <div className="form-group">
                                                                <label htmlFor={i}>
                                                                    {inputtype.displayName}
                                                                    {inputtype.isMandatory === true && <span style={{ color: "red" }}>*</span>}
                                                                </label>
                                                                <input type="text" name={inputtype.columnName} placeholder={`Enter ${inputtype.displayName}`}
                                                                    className="form-control input-height"
                                                                    id={inputtype.columnID}
                                                                    onChange={(e) => handleInputChange(e, inputtype.columnName, i)}
                                                                    value={inputtype["columnDefaultValue"] || ''} />
                                                                {/* <ReactQuill
                                                                    name={inputtype.columnName} placeholder={`Enter ${inputtype.displayName}`}
                                                                    className="form-control input-height"
                                                                    id={inputtype.columnID}
                                                                    //theme="snow" // You can change the theme if needed
                                                                    value={inputtype["columnDefaultValue"] || ''}
                                                                    onChange={(e) => handleInputChange(e, inputtype.columnName, i)}
                                                                /> */}

                                                            </div >
                                                            {/* <textarea
                                                                value={inputtype?.columnDefaultValue || ''}
                                                                onChange={(e) => { handleInputChange(e, inputtype.columnName); }}
                                                                rows={4}
                                                                style={{ width: "100%" }}
                                                            /> */}

                                                            {/* <TextField
                                                                id="outlined-multiline-static"
                                                                label="Enter Message"
                                                                multiline
                                                                className="addservice-1"
                                                                rows={2}
                                                                onChange={(e) => handleInputChange(e, inputtype.columnName)}
                                                                value={inputtype?.columnDefaultValue || ''}
                                                            /> */}
                                                        </Col>
                                                    </>
                                                )
                                            }
                                            if (inputtype.addType === "PicFile") {
                                                return (
                                                    <>
                                                        <label htmlFor={i}>
                                                            {inputtype.displayName}
                                                            {inputtype.isMandatory === true && <span style={{ color: "red" }}>*</span>}
                                                        </label>
                                                        <Col xs={10} sm={10} md={10} key={i} style={{ paddingBottom: "10px", marginTop: "-13px" }}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Control style={{ width: "85%" }}
                                                                    type="file" placeholder="Upload Image" className="upload-image"
                                                                    onChange={(e) => handleFile(e, inputtype.columnName, i)}
                                                                    id={inputtype["columnID"]}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs={2} sm={2} md={2} style={{ paddingBottom: "10px", marginTop: "-13px" }}>
                                                            {inputtype["columnDefaultValue"] !== null && inputtype["columnDefaultValue"] !== "" ?
                                                                <img src={inputtype["columnDefaultValue"]} alt="icon" style={{ width: "90px" }}
                                                                    className="upload-image-2" />
                                                                : <img src="../../../../../assets/images/photo-album-icon.png" alt="icon img"
                                                                    className="upload-image-2" />
                                                            }
                                                            {/* <p style={{marginLeft:"-134px,", color:"red"}}>Image dimensions must be 2500x1450 pixels.</p> */}
                                                        </Col>
                                                    </>
                                                )
                                            }
                                            if (inputtype.addType === "HTMLEditor") {

                                                return (
                                                    <>
                                                        <Col xs={12} sm={12} md={12} key={i} style={{ paddingBottom: "60px" }}>
                                                            <div className="form-group" >
                                                                <label htmlFor={i}>
                                                                    {inputtype.displayName}
                                                                    {inputtype.isMandatory === true && <span style={{ color: "red" }}>*</span>}
                                                                </label>
                                                                {/* <input type="text" name={inputtype.columnName} placeholder={`Enter ${inputtype.displayName}`}
                                                                    className="form-control input-height"
                                                                    id={inputtype.columnID}
                                                                    onChange={(e) => handleInputChange(e, inputtype.columnName, i)}
                                                                    value={inputtype["columnDefaultValue"] || ''} /> */}
                                                                <ReactQuill
                                                                    name={inputtype.columnName}
                                                                    //className="form-control"
                                                                    id={inputtype.columnID}
                                                                    //theme="snow" // You can change the theme if needed
                                                                    value={inputtype["columnDefaultValue"] || ''}
                                                                    onChange={(e) => handleInputChangetexteditor(e, inputtype.columnName, i)}
                                                                />
                                                            </div >
                                                        </Col>
                                                    </>
                                                )
                                            }

                                        }
                                        )}
                                        <Col xs={12} sm={6} md={12}>
                                            <Stack spacing={2} direction="row" className="btn-align-end">
                                                <Button variant="outlined" onClick={Fun_Cancel}>Cancel</Button>
                                                <Button variant="contained" onClick={fun_formValidation}>Submit</Button>
                                            </Stack>
                                        </Col>
                                    </Row >
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}
export default Service