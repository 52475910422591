import React, { useContext } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { ProductContext } from "./context/AppContext";
function Footer() {
  const {
    handleClickAdminLogin,
    handleClickOpenUserMobile,
    setLoginType,
    handleClickLoader,
    setAuthkeyTEXT,
  } = useContext(ProductContext);
  const fun_loginMDL = (type, Authkey) => {
    setLoginType(type);
    setAuthkeyTEXT(Authkey);
    handleClickOpenUserMobile();
  };
  return (
    <div className="footer-section top-space">
      <Container fluid>
        <Row>
          <Col xs={6} sm={6} md={3}>
            <div className="footer-div-1">
              {/* <img
                src="../../../assets/images/logo1.svg"
                className="sb-image-footer sb-image-footer-21"
              /> */}
              <h2>
                A<span className="color_orange">2</span>Z
                <span className="color_orange">work</span>
              </h2>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                 <li>
                  <Link to="/about">About us</Link>
                </li> 
                <li>
                  <Link to="/privacy-policy">Privacy policy</Link>
                </li>
                <li>
                  <Link to="/terms-and-condtion">Terms & conditions</Link>
                </li>

                <li>
                  <Link to="/refund-and-cancellation">
                    Refund & cancellation
                  </Link>
                </li>
                {/* <li>
                  <Link to="/">Careers</Link>
                </li> */}
              </ul>
            </div>
          </Col>

          <Col xs={6} sm={6} md={3}>
            <div className="footer-div-1">
              <h2>For customers</h2>
              <ul style={{ textAlign: "center" }}>
                {/* <li>
                  <Link to="/">DialERP Review</Link>
                </li>
                <li>
                  <Link to="/">Categories near you</Link>
                </li>
                <li>
                  <Link to="/">Blog</Link>
                </li> */}
                <li>
                  <Link to="/contactus">Contact Us</Link>
                </li>
              </ul>
            </div>
          </Col>
          <Col xs={6} sm={6} md={3}>
            <div className="footer-div-1">
              <h2>For partners</h2>
              <ul>
                {/* <li><Link to = "/">Register as a professional</Link></li>
            <li><Link to = "" onClick={handleClickAdminLogin}>Admin Login</Link></li>
            <li><Link to = "serviceprovider/login">A2Zwork India</Link></li> */}
                <li>
                  <Link
                    to=""
                    onClick={() =>
                      fun_loginMDL(
                        "Admin Login",
                        "6N7DRjOdSK/01HTG+NxmUr51ylEW54o/YSsgyRnl5wG9y42IL4wOQ/PzWNJeZjXJwLTa8uhNhFQ/TUibx83gB5Zic1/v44iKEZ9uKD4WhyQ="
                      )
                    }
                  >
                    Admin Login
                  </Link>
                </li>
                <li>
                  <Link
                    to=""
                    onClick={() =>
                      fun_loginMDL(
                        "Service Provider Login",
                        "2OQiGzJCWVRFIAphnLpBTiz4irdT4SVqZqsu6wF0YPlSHEmQYMyxwqq7UiwH3b6oULG+nRviP2NPxN8Uqo05XeOybDNVXt6cqXbmyboaUfQ="
                      )
                    }
                  >
                    Service Provider
                  </Link>
                </li>
                {/* <li>
                  <Link
                    to=""
                    onClick={() =>
                      fun_loginMDL(
                        "A2Zwork India Login",
                        "2OQiGzJCWVRFIAphnLpBTiz4irdT4SVqZqsu6wF0YPlSHEmQYMyxwqq7UiwH3b6oULG+nRviP2NPxN8Uqo05XeOybDNVXt6cqXbmyboaUfQ="
                      )
                    }
                  >
                    A2Zwork India
                  </Link>
                </li> */}
                {/* <li>
                  <Link to="/" onClick={handleClickLoader}>
                    Loader
                  </Link>
                </li> */}
              </ul>
            </div>
          </Col>
          <Col xs={6} sm={6} md={3}>
            <div className="footer-div-1">
              <h2>Social links</h2>
              <ul className="footer-social-media">
                <li>
                  <Link to="/">
                    <FacebookIcon />
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <TwitterIcon />
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <InstagramIcon />
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <LinkedInIcon />
                  </Link>
                </li>
              </ul>
              <br />
              <br />
              <img
                src="../../../assets/images/f1.webp"
                className="sb-image-footer-5"
              />
              <img
                src="../../../assets/images/f2.webp"
                className="sb-image-footer-5"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12}>
            <div className="footer-div-2">
              <p>©Copyright 2024 a2zworkindia.com. All rights reserved.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Footer;
