import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom';
import ListAltIcon from '@mui/icons-material/ListAlt';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Person2Icon from '@mui/icons-material/Person2';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import WhereToVoteOutlinedIcon from '@mui/icons-material/WhereToVoteOutlined';
import { ProductContext } from './context/AppContext';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CustomerLogin from './Customer/CustomerLogin';
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function FooterMobile() {
    const CartItemms = JSON.parse(localStorage.getItem("CartItemms"));
    const{totalAmont, setCardList}=useContext(ProductContext);
    var userLogininfo = JSON.parse(localStorage.getItem("user-Logininfo"));

useEffect(() => {
  const CartItemms = JSON.parse(localStorage.getItem("CartItemms")) || [];
  setCardList(CartItemms);
  }, [0]);

  
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [openCustomerLogin, setOpenCustomerLogin] = React.useState(false);
  const handleClickOpenCustomerLogin = () => {
    localStorage.removeItem("CardLogin");
    setOpenCustomerLogin(true);
  };
  const handleCloseCustomerLogin = () => {
    setOpenCustomerLogin(false);
  };
  return (
    <div>
        {CartItemms && CartItemms?.length > 0 ? (

       <div className='view-cart-1 view-cart-bootom-2 view-cart-bootom-3 view-new-class mobile-view'><span className='price-span1'>₹{totalAmont}</span> 
                    <Link to="/customer/view-booking/0" >View Cart</Link>
                    </div>
        ) :(

        

                    <div className='view-cart-1 view-cart-bootom-2 mobile-view'>
                      {userLogininfo?.["User Group"] === "Customer" ?

                      <ul>
                        <li><Link to = "/"><HomeOutlinedIcon className='view-cart-bootom-2-icon' />Home</Link></li>
                        <li><Link to = "/customer/my-bookings"><ListAltIcon className='view-cart-bootom-2-icon' />Bookings</Link></li>
                        <li><Link to = "/customer/my-address"><WhereToVoteOutlinedIcon className='view-cart-bootom-2-icon' />Address</Link></li>
                        <li><Link to = "/customer/my-wishlist"><FavoriteBorderIcon className='view-cart-bootom-2-icon' />Wishlist</Link></li>
                      </ul>
                      : 

                      <ul>
                      <li><Link to = "/"><HomeOutlinedIcon className='view-cart-bootom-2-icon' />Home</Link></li>
                      <li><Link to = "" onClick={handleClickOpenCustomerLogin}><ListAltIcon className='view-cart-bootom-2-icon' />Bookings</Link></li>
                      <li><Link to = "" onClick={handleClickOpenCustomerLogin}><WhereToVoteOutlinedIcon className='view-cart-bootom-2-icon' />Address</Link></li>
                      <li><Link to = "/customer/my-wishlist"><FavoriteBorderIcon className='view-cart-bootom-2-icon' />Wishlist</Link></li>
                    </ul>
}
                    </div>
        )}


<Dialog
        fullScreen={fullScreen}
        open={openCustomerLogin}
        onClose={handleCloseCustomerLogin}
        aria-labelledby="responsive-dialog-title"
        className='login-popup-1'
      >
       <CloseIcon className='cross-login-5' onClick={handleCloseCustomerLogin} />
       
        <CustomerLogin />
        <p className='account-type-show'>
                  Don't have an account?
                  <Link to="" onClick={()=>{handleCloseCustomerLogin()}}> Register here</Link>
                </p>
       </Dialog>
    </div>
  )
}

export default FooterMobile
