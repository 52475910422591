import {
  BrowserRouter as Router,
  Route,
  Link,
  useParams,
} from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import React, { useState, useEffect } from "react";
import axios from "axios";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Form from "react-bootstrap/Form";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Headerinner from "../Common/Headerinner";
import SideBar from "../Common/SideBar";
import { borderColor } from "@mui/system";
import moment from "moment";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import EditIcon from '@mui/icons-material/Edit';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));


function CommonViewData() {
  const { type, addEdit, taberpid, recordErpID } = useParams();

  ;
  var userLogininfo = JSON.parse(localStorage.getItem("user-Logininfo"));
  if (userLogininfo === null) {
    window.location.href = "/";
  }
  else {
    if (userLogininfo["User Group"] !== "Employee") {
      window.location.href = "/not-found";
    }

  }
  let baseURL = `${process.env.REACT_APP_BASE_URL}`;
  let authToken = localStorage.getItem("Auth-Token").replace(/"/g, "");

  const [commonList, setcommonList] = useState([]);
  function fun_CommonList() {
    // const listpra = {
    //   pgSize: 100,
    //   filterButtons: "",
    //   index: 1,
    //   toEmailID: "designMonu@dialerp.com",
    //   tabErpID: taberpid,
    //   recordErpID: recordErpID,
    //   useAtlusSearch: false,
    //   filters: "{}",
    // };
    const listpra = { "tabErpID": taberpid, "filterButtons": "", "recordErpID": recordErpID, "toEmailID": "designMonu@dialerp.com" };
    fetch(
      baseURL + "/api/Dynamic/GetInputColumns", {
      method: "POST",
      headers: {
        "Authorization-Token": authToken,
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(listpra),
    }).then((result) => {
      result.json().then((resp) => {
        if (resp.hasErrors === false) {
          if (resp.response?.records !== null) {
            setcommonList(resp.response);
          } else {
            setcommonList("");
          }
        } else {
          alert(resp.errors);
        }
      });
    });
  }
  useEffect(() => {
    fun_CommonList();
  }, [taberpid, recordErpID]);

  return (
    <div className="admin-panel-section">
      <Headerinner />
      <div className="admin-body-section admin-div-1">
        <Container fluid>
          <Row>
            <Col xs={12} sm={12} md={3} lg={2}>
              <SideBar />
            </Col>
            <Col xs={12} sm={12} md={9} lg={10} className="right-side-section">
              <div className="add-sevice-section-2">
                <Form className="addservice-form">
                  <Row>
                    <Col xs={12} sm={12} md={12}>
                      <h2 className="list-heading-1" style={{marginLeft:"-12px"}}>
                        <Link to={`/admin/commonlistpage/${type}/none/${taberpid}`}
                        style={{margin: "0px 10px 0px 0px"}}>
                          <KeyboardBackspaceIcon className="back-icon-arrow" />
                        </Link>
                        {type} Details
                        <span>

                          {/* <Link to={`/admin/common/${type}/Add/${taberpid}/0`} id='list-page' className="btn-align-end">Add</Link> */}

                          <Link to={`/admin/common/${type}/Edit/${taberpid}/${recordErpID}`}>
                            <Button variant="outlined" className="list-heading-1-button">Edit</Button></Link>
                          {/* <Link to =""> */}
                          {/* <Button variant="outlined">Filter</Button></Link> */}
                          {/* <EditIcon className="list-heading-1-icon" />Add</Link> */}
                          {/* <Link to =""><FilterAltIcon /></Link> */}
                        </span>
                      </h2>
                    </Col>
                    <Col xs={12} sm={12} md={12}>
                      <Row>

                        <table className="custom-table-5 custom-table-2">

                          <tbody>
                            {commonList?.map((coldata, j) => {
                              if (coldata["addType"] === "PicFile") {

                                return (
                                  <>
                                    <tr key={j} style={{ border: "1px solid", borderColor: "#68686826" }}>
                                      <td style={{ width: "200px", padding: "6px" }}> {coldata["displayName"]}  </td>

                                      <td style={{ display: "inline-grid" }}>
                                        {coldata["columnDefaultValue"] !== null && coldata["columnDefaultValue"] !== "" ?
                                          <img src={coldata["columnDefaultValue"]} style={{ height: '85px', width: "85px" }} alt={coldata["displayName"]} />
                                          : <img src="../../../../../assets/images/photo-album-icon.png" alt={coldata["displayName"]}
                                            style={{ height: '100px', width: "100px" }} />
                                        }

                                      </td>
                                    </tr>
                                  </>
                                );
                              }
                              if (coldata["columnType"] === "cDt") {
                                return (
                                  <>
                                    <tr key={j} style={{ border: "1px solid", borderColor: "#68686826" }}>
                                      <td style={{ width: "200px", padding: "6px" }} > {coldata["displayName"]} </td>
                                      <td>
                                        :   {moment(coldata["columnDefaultValue"]).format("DD-MMM-yyyy hh:mm:ss")}
                                      </td>

                                    </tr>
                                  </>
                                );
                              }
                              if (coldata["columnType"] === "SR") {
                                j++
                                return (
                                  <>

                                  </>
                                );
                              }
                              if (coldata?.["columnName"] === "Description"||coldata?.["columnName"] === "ShortDescription") {
                                return (
                                  <>
                                   <>
                                    <tr key={j} style={{ border: "1px solid", borderColor: "#68686826" }}>
                                      <td style={{ width: "200px", padding: "6px" }}>
                                        {coldata["displayName"]} </td>
                                      <td>
                                          <div dangerouslySetInnerHTML={{ __html: coldata["columnDefaultValue"] }}></div>
                                      </td>
                                    </tr>
                                  </>
                                  
                                  </>
                                );
                              }
                              else {
                                return (
                                  <>
                                    <tr key={j} style={{ border: "1px solid", borderColor: "#68686826" }}>
                                      <td style={{ width: "200px", padding: "6px" }}>
                                        {coldata["displayName"]} </td>
                                      <td>
                                        :   {coldata["columnDefaultValue"]}
                                      </td>
                                    </tr>
                                  </>
                                );
                              }
                             
                            })}
                          </tbody>
                        </table>

                      </Row>
                    </Col>


                    {/* <table class="custom-table" style={{border: "1px solid black"}}> */}
                    {/* <Table striped bordered className="custom-table-2"> */}
                    {/* <thead>
                        {showcolumn?.map((inputtype, i) => {
                          return (
                            <>
                              <th>{inputtype["displayName"]}</th>
                            </>
                          );
                        })}
                      </thead> */}

                    {/* <tbody>
                        {commonList?.records?.map((data, i) => {
                          i++;
                          return (
                            <>
                              <tr>
                                {showcolumn?.map((coldata, J) => {
                                  if (coldata["addType"] === "PicFile") {
                                    return (
                                      <>
                                        <td>
                                          <img
                                            src={data[coldata["displayName"]]}
                                            alt="icon"
                                          />
                                        </td>
                                      </>
                                    );
                                  }
                                  if (coldata["columnType"] === "cDt") {
                                    // let value= moment{data[coldata["displayName"]]).format("DD-MMM-yyyy")}
                                    return (
                                      <>
                                        <td>
                                          {moment(
                                            data[coldata["displayName"]]
                                          ).format("DD-MMM-yyyy hh:mm:ss")}
                                        </td>
                                      </>
                                    );
                                  }
                                  if (coldata["columnType"] === "SR") {
                                    // let value= moment{data[coldata["displayName"]]).format("DD-MMM-yyyy")}
                                    return (
                                      <>
                                        <td>{i}</td>
                                      </>
                                    );
                                  } else {
                                    return (
                                      <>
                                        <td>{data[coldata["displayName"]]}</td>
                                      </>
                                    );
                                  }
                                })}
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </Table> */}
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div >
    </div >
  );
}
export default CommonViewData;
