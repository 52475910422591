import React, { useState,useEffect} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Headerinner from '../Common/Headerinner';
import SideBar from "../Common/SideBar";
import moment from 'moment'

function CategoryList() {
    var userLogininfo = JSON.parse(localStorage.getItem("user-Logininfo"));
    if (userLogininfo === null) {
        window.location.href = '/';
    }
    else {
      if (userLogininfo["User Group"] !== "Employee") {
          window.location.href="/not-found";
      }

  }
    let baseURL = `${process.env.REACT_APP_BASE_URL}`;
    let authToken = localStorage.getItem("Auth-Token").replace(/"/g, '');
    const [CategoryList, setCategoryList] = useState([]);
    function fun_CategoryList() {
        const CategoryPra = {
            "pgSize": 100,
            "viewFormat": "gwk",
            "filterButtons": "",
            "index": 1,
            "toEmailID": "designMonu@dialerp.com",
            "tabErpID": 792,
            "useAtlusSearch": false,
            "filters": "{}"
        }

        fetch(
            baseURL + "/api/Dynamic/GetRecords",
            {
                method: "POST",
                headers: {
                    "Authorization-Token": authToken,
                    Accept: "application/json, text/plain",
                    "Content-Type": "application/json;charset=UTF-8",
                },
                body: JSON.stringify(CategoryPra),
            }
        ).then((result) => {
            result.json().then((resp) => {

                if (resp.hasErrors === false) {
                    if (resp.response?.records !== null) {
                        setCategoryList(resp.response);
                    }
                    else {
                        setCategoryList("");
                    }
                }
                else {
                    alert(resp.errors);
                }
            });
        });
    }
    useEffect(() => {
        fun_CategoryList();

    }, []);

  return (
    <div className="admin-panel-section">
      <Headerinner />
      <div className="admin-body-section admin-div-1">
        <Container fluid>
          <Row>
            <Col xs={12} sm={12} md={3} lg={2}>
              <SideBar />
            </Col>
            <Col xs={12} sm={12} md={9} lg={10} className="right-side-section">  
           
              <div className="welcome-section-2">
                <Row>
                  <Col xs={12} sm={12} md={12}>
                    <div className="sales-1">
                      <p>
                       Category List
                        <span>
                          <Form.Select aria-label="Default select example">
                            <option value="Yearly">Yearly</option>
                            <option value="Monthly">Monthly</option>
                            <option value="Weekly">Weekly</option>
                            <option value="Today">Today</option>
                          </Form.Select>
                        </span>
                      </p>
                      <TableContainer component={Paper}>
                        <Table
                          sx={{ minWidth: 650 }}
                          aria-label="simple table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <b>Sr.</b>
                              </TableCell>
                              <TableCell>
                                <b>Create Dt</b>
                              </TableCell>
                              <TableCell>
                                <b>Category Pic</b>
                              </TableCell>
                              <TableCell>
                                <b>Category</b>
                              </TableCell>
                              <TableCell>
                                <b>Category Desc</b>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                          {CategoryList?.records?.map((status, i) => {
                                i++;
                               return (
                              <TableRow
                                key={i}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                 <TableCell align="right">
                                  {i}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                {moment(status["Create Dt"]).format("DD-MMM-YYYY hh:mm:ss")}
                                </TableCell>
                               
                                <TableCell align="right"><img src={status["Category Pic"]} alt="Profile Image" style={{ height: "100px", width: "100px" }} /></TableCell>
                                <TableCell align="right">
                                {status["Category"]["Category"]}
                                </TableCell>
                                <TableCell align="right">
                                {status["Category Desc"]}
                                </TableCell>
                              </TableRow>
                             );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default CategoryList;
