/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { createContext, useContext, useState, useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import {
  fetchWrapper,
  fetchWrapperForLogin,
  fetchApiWithLoginToken,
} from "../apiUtils";
import { Tune } from "@mui/icons-material";
const ProductContext = createContext();

function AppContext({ children }) {
  let baseURL = `${process.env.REACT_APP_BASE_URL}`;
  let baseTokenKey = `${process.env.REACT_APP_BASE_KEY}`.replace(/'/g, "");
  let authToken = `${process.env.REACT_APP_BASE_AUTHKEY}`;
  // List of keys to remove from local storage when logging out
  const keysToRemove = [
    "user-Logininfo",
    "Auth-Token",
    "Appointmentobj",
    "deliveryaddress",
    "CardLogin",
  ];

  // Function to handle user logout
  function fun_LogOut() {
    // Iterate through each key and remove the corresponding item from local storage
    // keysToRemove.forEach(key => {
    //   localStorage.removeItem(key);
    // });
    localStorage.clear();
    // Redirect the user to the home page after logout
    document.getElementById("Logout").click();
    // window.location.href = "/";
  }

  const [CategoryList, setCategoryList] = useState([]);

  function fun_CategoryList() {
    handleClickLoader();
    const CategoryPra = {
      pgSize: 500,
      filterButtons: "",
      index: 1,
      toEmailID: "designMonu@dialerp.com",
      tabErpID: 1480,
      useAtlusSearch: false,
      filters: "{}",
    };

    // fetch(baseURL + "/api/Dynamic/GetRecords", {
    //   method: "POST",
    //   headers: {
    //     "Authorization-Token": authToken,
    //     Accept: "application/json, text/plain",
    //     "Content-Type": "application/json;charset=UTF-8",
    //   },
    //   body: JSON.stringify(CategoryPra),
    // }).then((result) => {
    //   result.json().then((resp) => {
    //     handleCloseLoader();
    //     if (resp.hasErrors === false) {
    //       if (resp.response?.records !== null) {
    //         setCategoryList(resp.response);
    //       } else {
    //         setCategoryList("");
    //       }
    //     } else {
    //       alert(resp.errors);
    //     }
    //   });
    // });

    let endpointURL = "Dynamic/GetRecords";
    try {
      fetchWrapper(CategoryPra, endpointURL, authToken).then((resp) => {
        handleCloseLoader();
        if (resp?.errorCode === "HTTP error") {
          alert("Request not valid");
          return;
        }
        if (resp.hasErrors === false) {
          if (resp.response?.records !== null) {
            setCategoryList(resp.response);
          } else {
            setCategoryList("");
          }
        } else {
          alert(resp.errors);
        }
      });
    } catch (error) {
      alert(error.message);
    }
  }

  const [SubCategoryListData, setSubCategoryList] = useState([]);
  const fun_SubCategoryList = () => {
    handleClickLoader();
    // let CategoryName = '{Category:"' + id + '"}';
    const CategoryPra = {
      pgSize: 500,
      filterButtons: "",
      index: 1,
      toEmailID: "designMonu@dialerp.com",
      tabErpID: 1481,
      useAtlusSearch: false,
      filters: "{}",
    };

    // fetch(baseURL + "/api/Dynamic/GetRecords", {
    //   method: "POST",
    //   headers: {
    //     "Authorization-Token": authToken,
    //     Accept: "application/json, text/plain",
    //     "Content-Type": "application/json;charset=UTF-8",
    //   },
    //   body: JSON.stringify(CategoryPra),
    // }).then((result) => {
    //   result.json().then((resp) => {
    //     handleCloseLoader();
    //     if (resp.hasErrors === false) {
    //       if (resp.response?.records !== null) {
    //         setSubCategoryList(resp.response);
    //       } else {
    //         setSubCategoryList("");
    //       }
    //     } else {
    //       alert(resp.errors);
    //     }
    //   });
    // });
    let endpointURL = "Dynamic/GetRecords";
    try {
      fetchWrapper(CategoryPra, endpointURL, authToken).then((resp) => {
        handleCloseLoader();
        if (resp?.errorCode === "HTTP error") {
          alert("Request not valid");
          return;
        }
        if (resp.hasErrors === false) {
          if (resp.response?.records !== null) {
            setSubCategoryList(resp.response);
          } else {
            setSubCategoryList("");
          }
        } else {
          alert(resp.errors);
        }
      });
    } catch (error) {
      alert(error.message);
    }
  };
  const [ServiceList, setServiceList] = useState([]);
  const [subsubcategorylst, setsubsubcategorylst] = useState([]);
  const getSubsubcategoryList = () => {
    handleClickLoader();
    var formData = {
      pgSize: 1000,
      //"viewFormat": "none",
      index: 1,
      toEmailID: "designMonu@dialerp.com",
      tabErpID: 1482,
      filters: "{}",
    };
    // fetch(
    //   baseURL + "/api/dynamic/GetRecords",
    //   {
    //     method: "POST",
    //     headers: {
    //       "Authorization-Token": authToken,
    //       Accept: "application/json, text/plain",
    //       "Content-Type": "application/json;charset=UTF-8",
    //     },
    //     body: JSON.stringify(formData),
    //   }
    // ).then((result) => {
    //   result.json().then((resp) => {
    //     handleCloseLoader();
    //     if (resp.hasErrors === false) {
    //       if (resp?.response?.records !== null) {
    //         //let list = [];
    //         //list = resp.response.records;
    //         // const flist = list.filter(x => x.SubCategory === SubCategoryName);
    //         //console.log(flist);
    //         setsubsubcategorylst(resp?.response?.records);
    //       }
    //       else {
    //         setsubsubcategorylst([]);
    //         // alert("Not find  list");
    //       }
    //     }
    //     else {
    //       if (resp.errors !== undefined) {
    //         alert(JSON.stringify(resp.errors) + "3");
    //       }
    //     }
    //   });
    // });

    let endpointURL = "Dynamic/GetRecords";
    try {
      fetchWrapper(formData, endpointURL, authToken).then((resp) => {
        handleCloseLoader();
        if (resp?.errorCode === "HTTP error") {
          alert("Request not valid");
          return;
        }
        if (resp.hasErrors === false) {
          if (resp?.response?.records !== null) {
            //let list = [];
            //list = resp.response.records;
            // const flist = list.filter(x => x.SubCategory === SubCategoryName);
            //console.log(flist);
            setsubsubcategorylst(resp?.response?.records);
          } else {
            setsubsubcategorylst([]);
            // alert("Not find  list");
          }
        } else {
          if (resp.errors !== undefined) {
            alert(JSON.stringify(resp.errors) + "3");
          }
        }
      });
    } catch (error) {
      alert(error.message);
    }
  };
  const fun_ServiceList = () => {
    handleClickLoader();
    const listpra = {
      pgSize: 5000,
      index: 1,
      toEmailID: "designMonu@dialerp.com",
      tabErpID: 1478,
      useAtlusSearch: false,
      filters: "{}",
      //filters: `{'SubCategory':${SubCategoryName}}`
    };

    fetch(baseURL + "/api/Dynamic/GetRecords", {
      method: "POST",
      headers: {
        "Authorization-Token": authToken,
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(listpra),
    }).then((result) => {
      result.json().then((resp) => {
        handleCloseLoader();
        if (resp.hasErrors === false) {
          if (resp.response?.records !== null) {
            setServiceList(resp?.response?.records);
          } else {
            setServiceList([]);
          }
        } else {
          alert(resp.errors);
        }
      });
    });
  };
  const [Card, setCard] = useState([]);

  const ID =
    "826578659859-sl1ds9uajcok1allt6dks9rdrs68aq7f.apps.googleusercontent.com";
  // localStorage.clear();
  // var redirect = localStorage.getItem("CardLogin");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  //  let authToken = `${process.env.REACT_APP_BASE_AUTHKEY}`;
  //  let baseURL ="http://dialerpwebapis-dev-as.azurewebsites.net";
  //  let baseTokenKey = `${process.env.REACT_APP_BASE_KEY}`.replace(/'/g, '');

  //still Not use
  function fun_DyLogin(event) {
    var redirect = localStorage.getItem("CardLogin");

    event.preventDefault();
    if (username.trim() === "") {
      alert("Please enter username");
      return;
    } else if (!emailRegex.test(username.trim())) {
      alert("Please enter a valid email address");
      return;
    } else if (password.trim() === "''") {
      alert("Please enter password");
      return;
    } else {
      let filters = "{'UserGroup':'Customer'}";
      const loginPra = {
        baseTokenKey: baseTokenKey,
        EmailID: username,
        password: password,
        filters: filters,
      };
      fetch(baseURL + "/api/AppApi/LoginDynamicWithEmialAndPassword", {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(loginPra),
      }).then((result) => {
        result.json().then((resp) => {
          if (resp.hasErrors === false) {
            localStorage.setItem(
              "Auth-Token",
              JSON.stringify(resp.response.loginKey)
            );
            localStorage.setItem(
              "user-Logininfo",
              JSON.stringify(resp.response.user)
            );

            if (redirect != null) {
              localStorage.removeItem("CardLogin");
              document.getElementById("Login1").click();
              handleCloseCustomerLogin();
              // window.location.href = "/customer/view-booking/1";
            } else {
              document.getElementById("Login2").click();

              // window.location.href = '/customer/user-profile';
            }
          } else {
            alert("Invalid username or password.");
          }
        });
      });
    }
  }

  //  Ragistration Form

  const [ContactName, setContactName] = useState("");
  const [CountryCode, setCountryCode] = useState("");
  const [Mobile, setMobile] = useState("");
  const [RegMobile, setRegMobile] = useState("");
  const [Email, setEmail] = useState("");
  const [Password2, setPassword2] = useState("");
  const [DOB, setDOB] = useState("");
  const [Gender, setGender] = useState("");
  const [error, setError] = useState("");
  const [expanded, setExpanded] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [openCustomerLogin, setOpenCustomerLogin] = React.useState(false);
  const [DayDOB, setDayDOB] = useState("");
  const [MonthDOB, setMonthDOB] = useState("");
  const [MonthName, setMonthName] = useState("");
  const [YearDOB, setYearDOB] = useState("");
  const [StartJOB, setStartJOB] = useState("");
  const [EndJOB, setEndJOB] = useState("");
  const [IsJOBStart, setIsJOBStart] = useState(false);
  const handleClickOpenCustomerLogin = () => {
    setOpenCustomerLogin(true);
  };
  const handleCloseCustomerLogin = () => {
    setOpenCustomerLogin(false);
  };

  function handleKeyPress(e) {
    var key = e.key;
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      e.preventDefault();
    }
  }

  function isValidDate(d, m, y) {
    var day = parseInt(d);
    var month = parseInt(m);
    var year = parseInt(y);

    if (isNaN(day) || isNaN(month) || isNaN(year)) {
      return false;
    }
    if (day < 1 || year < 1) return false;
    if (month > 12 || month < 1) return false;
    if (
      (month === 1 ||
        month === 3 ||
        month === 5 ||
        month === 7 ||
        month === 8 ||
        month === 10 ||
        month === 12) &&
      day > 31
    ) {
      return false;
    }
    if (
      (month === 4 || month === 6 || month === 9 || month === 11) &&
      day > 30
    ) {
      return false;
    }
    if (month === 2) {
      if (
        (year % 4 === 0 && year % 100 !== 0) ||
        (year % 400 === 0 && year % 100 === 0)
      ) {
        if (day > 29) {
          return false;
        }
      } else {
        if (day > 28) {
          return false;
        }
      }
    }
    return true;
  }
  const MonthValue = [
    { Name: "Jan", value: "1", fullName: "January" },
    { Name: "Feb", value: "2", fullName: "February" },
    { Name: "Mar", value: "3", fullName: "March" },
    { Name: "Apr", value: "4", fullName: "April" },
    { Name: "May", value: "5", fullName: "May" },
    { Name: "Jun", value: "6", fullName: "June" },
    { Name: "Jul", value: "7", fullName: "July" },
    { Name: "Aug", value: "8", fullName: "August" },
    { Name: "Sep", value: "9", fullName: "September" },
    { Name: "Oct", value: "10", fullName: "October" },
    { Name: "Nov", value: "11", fullName: "November" },
    { Name: "Dec", value: "12", fullName: "December" },
  ];

  function handleSubmit(e) {
    let flist = MonthValue?.filter((p) => p["value"] === e.target.value);
    setMonthDOB(flist[0]["value"]);
    setMonthName(flist[0]["Name"]);
  }
  const MobileRegExp = new RegExp("^[0-9]{10}$");
  const yearRegExp = new RegExp("^[0-9]{4}$");

  function fun_CustomerRagistration() {
    var current_year = new Date().getFullYear();
    let validdateData = isValidDate(DayDOB, MonthDOB, YearDOB);
    const fullage = DayDOB + "-" + MonthName + "-" + YearDOB;
    const age = Math.floor(
      (new Date() - new Date(fullage)) / 1000 / 60 / 60 / 24 / 365.25
    );
    if (ContactName.trim() === "") {
      setError("required");
      return;
    } else if (RegMobile.trim() === "") {
      setError("required");
      return;
    } else if (!MobileRegExp.test(RegMobile.trim())) {
      alert("Please enter a valid 10 digits mobile no");
      return;
    } else if (YearDOB < 1950 || YearDOB > current_year) {
      setError("Year should be in range 1950 to current year");
      return;
    } else if (DayDOB.trim() === "") {
      setError("required");
      return;
    } else if (MonthDOB.trim() === "") {
      setError("required");
      return;
    } else if (YearDOB === "") {
      setError("required");
      return;
    } else if (!yearRegExp.test(YearDOB.trim())) {
      alert("Please enter a valid 4 digits year");
      return;
    } else if (Gender.trim() === "") {
      setError('required"');
      return;
    } else if (validdateData === false) {
      setError("Please Enter Valid Day");
      return;
    } else if (age < 18) {
      setError("Enter age must be 18.");
      return;
    } else {
      const formData = [
        {
          LedgerGroup: "Sundry Debtors",
          UserRank: 1,
          CountryCode: 91,
          UserName: ContactName,
          LoginMobile: parseInt(RegMobile),
          UserGroup: "Customer",
          Status: "Active",
          Gender: Gender,
          DOB: DayDOB + "-" + MonthName + "-" + YearDOB,
        },
      ];
      fetch(
        baseURL + "/api/Dynamic/AddUpdateRecords?LoginType=2&tabErpID=1429",
        {
          method: "POST",
          headers: {
            "Authorization-Token": authToken,
            Accept: "application/json, text/plain",
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: JSON.stringify(formData),
        }
      ).then((result) => {
        result.json().then((resp) => {
          if (resp.hasErrors === false) {
            alert("Account registered successfully. Please login ");
            setOpenCustomerLogin(true);
            window.location.reload();
            //alert(resp.response.message)
          } else {
            alert(resp.errors);
          }
        });
      });
    }
  }
  const [CardList, setCardList] = useState([]);
  const [FavoriteList, setFavoriteList] = useState([]);
  const [AutoStep, setAutoStep] = useState([]);

  useEffect(() => {
    fun_CategoryList();
    fun_SubCategoryList();
    getSubsubcategoryList();
    fun_ServiceList();
  }, [0]);

  const [totalAmont, settotalAmont] = useState(0);
  useEffect(() => {
    fun_CaltotalAmount();
  }, [CardList]);

  const fun_CaltotalAmount = () => {
    let totalAmount = 0;
    CardList.forEach((data) => {
      totalAmount += parseFloat(data["ItemTotal"] || 0);
    });
    settotalAmont(totalAmount);
  };

  const [openUserOTP, setOpenUserOTP] = React.useState(false);
  const handleClickOpenUserOTP = () => {

    handleCloseLoader();
    setOpenUserOTP(true);
  };
  const handleCloseUserOTP = () => {
    handleCloseLoader();
    setOpenUserOTP(false);
  };

  const [openUserMobile, setOpenUserMobile] = React.useState(false);
  const handleClickOpenUserMobile = () => {
    setOpenUserMobile(true);
  };
  const handleCloseUserMobile = () => {
    setOpenUserMobile(false);
  };

  const [adminLogin, setAdminLogin] = React.useState(false);
  const handleClickAdminLogin = () => {
    setAdminLogin(true);
  };
  const handleCloseAdminLogin = () => {
    setAdminLogin(false);
  };

  const [openAddress, setopenAddress] = React.useState(false);
  const handleClickopenAddress = () => {
    localStorage.removeItem("myAddress");

    setopenAddress(true);
  };
  const handleCloseopenAddress = () => {
    setopenAddress(false);
  };

  const [NewAddress, setNewAddress] = React.useState(false);
  const handleClickNewAddress = () => {
    setNewAddress(true);
  };
  const handleCloseNewAddress = () => {
    setNewAddress(false);
  };

  const [Loader, setLoader] = React.useState(false);
  const handleClickLoader = () => {
    setLoader(true);
  };
  const handleCloseLoader = () => {
    setLoader(false);
  };

  var userLogininfo = JSON.parse(localStorage.getItem("user-Logininfo"));
  // if (userLogininfo === null) {
  //   window.location.href = '/';
  // }

  const [open, setOpen] = React.useState(false);
  // const [Addopen2, setAddOpen2] = React.useState(false);
  const [AllAddress, setAllAddress] = useState([]);
  const [CandidateActiveAddress, setCandidateActiveAddress] = useState([]);
  const [SetStatus, setSetStatus] = useState("");
  const handleClickOpen = () => {
    setOpen(true);
  };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  // const handleAddressClickOpen = () => {
  //   setAddOpen2(true);
  //   setOpen(false);
  // };

  // const handleAddressClose = () => {
  //   setAddOpen2(false);
  // };
  function fun_getAddAddressListlick(id) {
    handleClickNewAddress();
    localStorage.setItem("ADDID", JSON.stringify(id));
    //  localStorage.setItem('myAddress', JSON.stringify("Using Login"));
  }

  useEffect(() => {
    localStorage.removeItem("deliveryaddress");
    if (
      userLogininfo !== null &&
      userLogininfo !== "" &&
      userLogininfo !== undefined
    ) {
      // var fullName = [];
      // fullName = userLogininfo["UserName"]?.split(" ");
      // if (fullName.length === 1) {
      //   setFirstName(fullName[0]);
      // }
      // if (fullName.length === 2) {
      //   setFirstName(fullName[0]);
      //   setLastName(fullName[1])
      // }
      // if (fullName.length === 3) {
      //   let fname = fullName[0] + " " + fullName[1]
      //   setFirstName(fname);
      //   setLastName(fullName[2])
      // }
      fun_CandidateAllAddress();
      window.scrollTo(0, 0);
    }
  }, [0]);

  function fun_CandidateAllAddress() {
    let newusername = "{UserMobile:" + userLogininfo["Login Mobile"] + "}";
    const AllAddPara = {
      pgSize: 100,
      index: 1,
      toEmailID: "designMonu@dialerp.com",
      tabErpID: 1760,
      filters: newusername,
      viewFormat: "columnname",
    };
    fetch(baseURL + "/api/dynamic/GetRecords", {
      method: "POST",
      headers: {
        "Authorization-Token": authToken,
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(AllAddPara),
    }).then((result) => {
      result.json().then((resp) => {
        if (resp.hasErrors === false) {
          if (resp.response?.records !== null) {
            if (resp.response?.records.length > 0) {
              setAllAddress(resp.response);
              let Activeaddress =
                resp?.response?.records?.filter(
                  (p) => p["Landmark"] === "Active"
                ) || [];
              setCandidateActiveAddress(Activeaddress);
              setSetStatus(resp.response?.records[0]["Landmark"]);
              localStorage.setItem(
                "deliveryaddress",
                JSON.stringify(Activeaddress)
              );
            }
          }
        } else {
          alert(resp.errors);
        }
      });
    });
  }
  function fun_Delete(Id) {
    const DeleteParameter = {
      userID: 40396,
      srs: Id,
      deleteALL: false,
      tabErpID: 1760,
      toEmailID: "designMonu@dialerp.com",
    };
    fetch(baseURL + "/api/dynamic/DeleteRecords", {
      method: "POST",
      headers: {
        "Authorization-Token": authToken,
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(DeleteParameter),
    }).then((result) => {
      result.json().then((resp) => {
        if (resp.hasErrors === false) {
          alert(resp.response);
          // fun_CandidateAddressList();
          window.location.href = "/customer/view-booking/1";
        } else {
          alert(resp.errors);
        }
      });
    });
  }
  const [array, setArray] = React.useState([]);
  const history = useNavigate();
  function updateactiveAddress() {
    //window.location.reload();
    fun_CandidateAllAddress();
    handleCloseopenAddress();
  }

  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  function generateString(length) {
    let result = " ";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }

  function fun_getDefaultAddress(id) {
    handleClickLoader();
    var rand = generateString(6);
    AllAddress?.records?.map((status, i) => {
      if (status["Landmark"] === "Active") {
        const formData = [
          {
            "AddressID": status["AddressID"],
            // "AddressLine1": status["AddressLine1"],
            // "Pincode": status["Pincode"],
            "Landmark": "Deactive",
            //   "City": status["City"],
            //   "CityID": status["CityID"],
            //   "State": status["State"],
            //   "StateID": status["StateID"],
            //   "StateCode": status["StateCode"],
            //   "Country": status["Country"],
            //   "CountryID": status["CountryID"],
            //   //"Coordinates": "",
            //   "AddressType": status["AddressType"],
            //   "ContactName": status["ContactName"],
            //   "ContactMobile": status["ContactMobile"],
            //  // "Coordinates": ["00.000000", "00.000000"],
            //   "UserMobile": userLogininfo?.["LoginMobile"],
            //   "AddressNickName":status["AddressNickName"],
          },
        ];
        try {
          fetchApiWithLoginToken(
            formData,
            "Dynamic/AddUpdateRecords?LoginType=2&tabErpID=1760"
          ).then((resp) => {
            if (resp?.errorCode === "HTTP error") {
              alert("Request not valid");
              handleCloseLoader();
              return;
            }
            if (resp.hasErrors === true) {
              handleCloseLoader();
              alert(resp.errors);
              return;
            }
          });
        } catch (error) {
          alert(error.message);
        }
      }
    });
    const formDataActive = [
      { "AddressID": id, "Landmark": "Active" },
    ];
    fetchApiWithLoginToken(
      formDataActive,
      "Dynamic/AddUpdateRecords?LoginType=2&tabErpID=1760"
    ).then((resp) => {
      handleCloseLoader();
      if (resp.hasErrors === false) {
        fun_CandidateAllAddress();
        handleCloseopenAddress();
      } else {
        alert(resp.errors);
      }
    });
  }
  const [LoginType, setLoginType] = React.useState([]);
  //const AuthkeyTEXT = "4Y4x1pWJuhV506cJmmqKWOCXNrkl72EFWiO/BeQV+PLgAoKYCYRSK1eUrQeZyeazC0Y0L6rTc4vZUD3LiWm0O7ZfrARK57zu2crCxV66v2g="
  const [AuthkeyTEXT, setAuthkeyTEXT] = React.useState("");
  const key = "0e628f15575e4a71"; //"0e628f15575e4a7189ec11a50301bb70";
  const [OTP, setOTP] = useState("");
  function fun_GetUserInfo(id, token) {
    var redirect = localStorage.getItem("CardLogin");
    const infoPra = {
      tabErpID: "1429",
      filters: `{'_id':${id}}`,
    };

    let endpointURL = "Dynamic/GetRecords";
    try {
      fetchWrapper(infoPra, endpointURL, token).then((resp) => {
        handleCloseLoader();
        if (resp?.errorCode === "HTTP error") {
          alert("Request not valid");
          return;
        }
        if (resp?.response !== null) {
          if (resp?.response?.records !== null) {
            const logindata = resp?.response?.records[0];
            localStorage.setItem("user-Logininfo", JSON.stringify(logindata));
            localStorage.setItem("User Group", logindata["User Group"]);
            handleCloseUserOTP();
            if (LoginType === "Customer Login") {
              if (redirect != null) {
                localStorage.removeItem("CardLogin");
                window.location.href = "/customer/view-booking/1";
                // document.getElementById("Login1").click();
              } else {
                document.getElementById("Login2").click();
              }
            }
            if (
              LoginType === "Admin Login" ||
              LoginType === "Service Provider Login"
            ) {
              document.getElementById("btnAdminAndSpLogin").click();
            }
          }
        } else {
          alert("Invalid request for user details.");
        }
      });
    } catch (error) {
      alert(error.message);
    }
  }
  const mobileLoginVerifyOTPMongo = () => {
    if (OTP.trim() === "") {
      alert("Please enter OTP");
      return;
    } else {
      handleClickLoader();
      const VerifyPra = {
        baseTokenKey: AuthkeyTEXT,
        EmailID: "",
        appVersion: "1",
        deviceOS: "Web",
        mobile: Mobile,
        tabErpID: "1429",
        otp: OTP,
        appVersionName: "1",
        deviceCountry: "India",
        deviceID: "Web",
        deviceName: "xiaomi Mi A2",
        imeiNumber: "not found",
        countryCode: "91",
        countryName: "India",
        collectionNumber: "1", // for old use 0 and for new 1
      };

      // fetch(
      //   baseURL + "/api/DynamicApp/mobileLoginVerifyOTPMongo",
      //   {
      //     method: "POST",
      //     headers: {
      //       "Authkey": AuthkeyTEXT,
      //       Accept: "application/json, text/plain",
      //       "Content-Type": "application/json;charset=UTF-8",
      //     },
      //     body: JSON.stringify(VerifyPra),
      //   }
      // )
      let endpointURL = "DynamicApp/mobileLoginVerifyOTPMongo";
      // fetchWrapperForLogin(VerifyPra, endpointURL, AuthkeyTEXT)
      //   .then((result) => {
      //     result.json().then((resp) => {
      //       if (resp.hasErrors === false) {
      //         localStorage.setItem("Auth-Token", JSON.stringify(resp.response.loginKey));
      //         localStorage.setItem("user-parentID", JSON.stringify(resp.response.parentID));
      //         fun_GetUserInfo(resp.response.partySr, resp.response.loginKey);
      //       }
      //       else {
      //         handleCloseLoader();
      //         alert(resp.errors);
      //       }
      //     });
      //   });
      try {
        fetchWrapperForLogin(VerifyPra, endpointURL, AuthkeyTEXT).then(
          (resp) => {
            if (resp?.errorCode === "HTTP error") {
              alert("Request not valid");
              handleCloseLoader();
              return;
            }
            if (resp.hasErrors === false) {
              localStorage.setItem(
                "Auth-Token",
                JSON.stringify(resp.response.loginKey)
              );
              localStorage.setItem(
                "user-parentID",
                JSON.stringify(resp.response.parentID)
              );
              fun_GetUserInfo(resp.response.partySr, resp.response.loginKey);
            } else {
              handleCloseLoader();
              alert(resp.errors);
            }
          }
        );
      } catch (error) {
        alert(error.message);
      }
    }
  };

  const mobileSendOtp = (otp) => {
    if (Mobile.trim() === "") {
      alert("Please enter MobileNo");
      return;
    } else {
      const SMSPra = {
        senderID: "DALERP",
        dltEntityID: "1201159187215982418",
        mobile: Mobile,
        smsContent: `Otp for signin App is ${otp} \nDIALERP Team`,
        templateID: "1207161521868534072",
        parentID: "40396",
      };
      handleCloseLoader();
      handleClickOpenUserOTP();
      handleCloseUserMobile();
      return;
      let endpointURL = "AppApi/SendSMSOTP";
      try {
        fetchWrapper(SMSPra, endpointURL, authToken).then((resp) => {
          handleCloseLoader();
          if (resp?.errorCode === "HTTP error") {
            alert("Request not valid");
            return;
          }
          if (resp.hasErrors === false) {
            handleClickOpenUserOTP();
            handleCloseUserMobile();
            alert("Otp sent successfully");
          } else {
            alert("Invalid request.");
          }
        });
      } catch (error) {
        alert(error.message);
      }
    }
  };

  const DecryptN1 = (encryptedText, key) => {
    const encryptedBytes = CryptoJS.enc.Base64.parse(encryptedText);
    const decryptedHex = CryptoJS.AES.decrypt(
      { ciphertext: encryptedBytes },
      CryptoJS.enc.Utf8.parse(key),
      {
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
        iv: CryptoJS.enc.Utf8.parse(key),
        keySize: 128,
        blockSize: 128,
      }
    );
    const decryptedText = CryptoJS.enc.Utf8.stringify(decryptedHex);
    setOTP(decryptedText);
    mobileSendOtp(decryptedText);
  };
  const mobileSendOtpMongo = () => {
    if (Mobile.trim() === "") {
      alert("Please enter mobile no");
      return;
    } else {
      const loginPra = {
        baseTokenKey: AuthkeyTEXT,
        resend: "0",
        mobile: Mobile,
      };
      // fetch(
      //   baseURL + "/api/DynamicApp/mobileSendOtpMongo",
      //   {
      //     method: "POST",
      //     headers: {
      //       "Authkey": AuthkeyTEXT,
      //       Accept: "application/json, text/plain",
      //       "Content-Type": "application/json;charset=UTF-8",
      //     },
      //     body: JSON.stringify(loginPra),
      //   }
      // )

      let endpointURL = "DynamicApp/mobileSendOtpMongo";
      try {
        fetchWrapperForLogin(loginPra, endpointURL, AuthkeyTEXT).then(
          (resp) => {
            if (resp?.errorCode === "HTTP error") {
              alert("Request not valid");
              handleCloseLoader();
              return;
            }
            if (resp.hasErrors === false) {
              DecryptN1(resp.response, key);
            } else {
              handleCloseLoader();
              alert("Invalid request!!.");
            }
          }
        );
      } catch (error) {
        alert(error.message);
      }
    }
  };
  const [NewUser, setNewUser] = useState();
  function mobileSendOtpNew() {
    // setIsResendDisabled(true);
    // setTimer(30);
    const checkMobileUserPra = {
      resend: "0",
      collectionNumber: "1",
      mobile: Mobile,
      type: "12",
    };
    fetch(baseURL + "/api/DynamicApp/mobileSendOtpNew", {
      method: "POST",
      headers: {
        // "Authorization-Token": AuthkeyTEXT,
        Authkey: AuthkeyTEXT,
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(checkMobileUserPra),
    }).then((result) => {
      result.json().then((resp) => {
        if (resp.hasErrors === false) {
          mobileSendOtp(resp?.response?.otp);
          setOTP(resp?.response?.otp);
          // alert(resp?.response?.otp,"New")
        } else {
          alert(resp.errors);
          handleCloseLoader();
        }
      });
    });
  }
  const checkMobileUserMongoDynamic = () => {
    handleClickLoader();
    //var redirect = localStorage.getItem("CardLogin");
    //event.preventDefault();
    //alert("test");
    if (Mobile === "" || Mobile === null) {
      alert("Please enter mobile");
      return;
    } else {
      const checkMobileUserPra = {
        baseTokenKey: AuthkeyTEXT,
        EmailID: "",
        appVersion: "1",
        imeiNumber: "not found",
        countryCode: "91",
        deviceOS: "Web",
        mobile: Mobile,
        countryName: "India",
        appVersionName: "1",
        deviceCountry: "India",
        deviceID: "6b068d3854a26559",
        deviceName: "Web",
        collectionNumber: "1", // for old use 0 and for new 1
      };

      // fetch(
      //   baseURL + "/api/DynamicApp/checkMobileUserMongoDynamic",
      //   {
      //     method: "POST",
      //     headers: {
      //       "Authkey": AuthkeyTEXT,
      //       Accept: "application/json, text/plain",
      //       "Content-Type": "application/json;charset=UTF-8",
      //     },
      //     body: JSON.stringify(checkMobileUserPra),
      //   }
      // )
      let endpointURL = "DynamicApp/checkMobileUserMongoDynamic";
      try {
        fetchWrapperForLogin(checkMobileUserPra, endpointURL, AuthkeyTEXT).then(
          (resp) => {
            if (resp?.errorCode === "HTTP error") {
              alert("Request not valid");
              handleCloseLoader();
              return;
            }
            if (resp.hasErrors === false) {
              setNewUser("No");
              mobileSendOtpMongo();
            } else {
              if (LoginType === "Customer Login") {
                setNewUser("Yes");
                mobileSendOtpNew();
              } else {
                handleCloseLoader();
                alert("Invalid mobile number.");
              }
            }

            // if (resp.hasErrors === false) {
            //   mobileSendOtpMongo();
            // } else {
            //   handleCloseLoader();
            //   alert("Invalid mobile number.");
            // }
          }
        );
      } catch (error) {
        alert(error.message);
      }
    }
  };

  const date = new Date();
  const showTime = date.getHours() + ":" + date.getMinutes();
  const [CancellationReason, setCancellationReason] = useState("");
  function fun_CancellationReason(id) {
    if (CancellationReason === "" && id !== null) {
      alert("Please Select cancellation reason.");
      return;
    }
    const formData = [
      {
        BookingID: id,
        CancelledDt: moment(date).format("DD-MMM-YYYY") + " " + showTime,
        Status: "Close",
        Stage: "Cancel Service",
      },
    ];

    // fetch(
    //   baseURL + "/api/Dynamic/AddUpdateRecords?LoginType=2&tabErpID=1258",
    //   {
    //     method: "POST",
    //     headers: {
    //       "Authorization-Token": authToken,
    //       Accept: "application/json, text/plain",
    //       "Content-Type": "application/json;charset=UTF-8",
    //     },
    //     body: JSON.stringify(formData),
    //   }
    // ).then((result) => {
    //   result.json()
    fetchApiWithLoginToken(
      formData,
      "Dynamic/AddUpdateRecords?LoginType=2&tabErpID=1258"
    ).then((resp) => {
      if (resp.hasErrors === false) {
        alert("Booking cancel successfully.");
        window.location.reload();
        // setOpen2(false);
      } else {
        alert(resp.errors);
      }
    });
    // });
    //}
  }

  const [openCustomerRagistration, setOpenCustomerRagistration] =
    React.useState(false);
  const handleClickOpenCustomerRagistration = () => {
    setOpenCustomerRagistration(true);
  };
  const handleCloseCustomerRagistration = () => {
    setOpenCustomerRagistration(false);
  };

  const StartJobVerfiy = (id, ContactMobile, JobStartOTP, JobEndOTP) => {
    debugger;
    setOpenUserOTP(true);
    setMobile(ContactMobile);
    setStartJOB(JobStartOTP);
    setEndJOB(JobEndOTP);    
    localStorage.setItem('BookingID', id);
    setOTP(null);
    if (IsJOBStart) {
      
      setOpenUserOTP(false);

    }
  }
  function fun_JobStart() {
    debugger
    var BookingID = JSON.parse(localStorage.getItem("BookingID"));
    handleClickLoader();
    const formData = [
      {
        BookingID: BookingID,
        JobStartDt: moment(date).format("DD-MMM-YYYY") + " " + showTime,
        HrsToStart: showTime,
      },
    ];
    fetchApiWithLoginToken(
      formData,
      "Dynamic/AddUpdateRecords?LoginType=2&tabErpID=1258"
    ).then((resp) => {
      if (resp.hasErrors === false) {
        alert("Job start successfully.");
localStorage.removeItem("BookingID");

        window.location.reload();
      } else {
        alert(resp.errors);
      }
      handleCloseLoader();
    });
  }
  return (
    <>
      <Link to="/customer/view-booking/1" id="Login1"></Link>
      <Link to="/admin/dashboard" id="btnAdminAndSpLogin"></Link>
      <Link to="/customer/my-bookings" id="Login2"></Link>
      <Link to="/" id="Logout"></Link>

      <ProductContext.Provider
        value={{
          fun_LogOut,
          CategoryList,
          fun_SubCategoryList,
          SubCategoryListData,
          subsubcategorylst,
          ServiceList,
          Card,
          setCard,
          username,
          setUsername,
          password,
          setPassword,
          fun_DyLogin,
          ID,
          ContactName,
          setContactName,
          CountryCode,
          setCountryCode,
          Mobile,
          setMobile,
          Email,
          setEmail,
          Password2,
          setPassword2,
          DOB,
          setDOB,
          Gender,
          setGender,
          error,
          setError,
          emailRegex,
          DayDOB,
          setDayDOB,
          MonthDOB,
          setMonthDOB,
          YearDOB,
          setYearDOB,
          handleKeyPress,
          fun_CustomerRagistration,
          CardList,
          setCardList,
          FavoriteList,
          setFavoriteList,
          MonthName,
          setMonthName,
          handleSubmit,
          MonthValue,
          AutoStep,
          setAutoStep,
          totalAmont,
          openUserOTP,
          setOpenUserOTP,
          handleClickOpenUserOTP,
          handleCloseUserOTP,
          openUserMobile,
          setOpenUserMobile,
          handleClickOpenUserMobile,
          handleCloseUserMobile,
          CandidateActiveAddress,
          handleClickOpen,
          fun_getAddAddressListlick,
          fun_Delete,
          openAddress,
          handleClickopenAddress,
          handleCloseopenAddress,
          NewAddress,
          handleClickNewAddress,
          handleCloseNewAddress,
          fullScreen,
          AllAddress,
          openCustomerLogin,
          handleClickOpenCustomerLogin,
          handleCloseCustomerLogin,
          adminLogin,
          handleClickAdminLogin,
          handleCloseAdminLogin,
          fun_CandidateAllAddress,
          updateactiveAddress,
          fun_getDefaultAddress,
          checkMobileUserMongoDynamic,
          OTP,
          setOTP,
          mobileLoginVerifyOTPMongo,
          fun_CancellationReason,
          setCancellationReason,
          CancellationReason,
          date,
          showTime,
          LoginType,
          setLoginType,
          Loader,
          handleClickLoader,
          handleCloseLoader,
          setAuthkeyTEXT,
          openCustomerRagistration,
          setOpenCustomerRagistration,
          handleClickOpenCustomerRagistration,
          handleCloseCustomerRagistration,
          RegMobile,
          setRegMobile,
          NewUser,
          setNewUser,
          AuthkeyTEXT, setStartJOB, setEndJOB, EndJOB, StartJOB, setIsJOBStart, IsJOBStart, fun_JobStart, StartJobVerfiy
        }}
      >
        {children}
      </ProductContext.Provider>
    </>
  );
}

export { ProductContext, AppContext };
