/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-pascal-case */
import logo from "./logo.svg";
import "./App.css";
import React, {
  Component,
  useEffect,
  useState,
  useCallback,
  useContext,
} from "react";
import {
  BrowserRouter,
  Router,
  Route,
  Switch,
  useParams,
  Routes,
  Link,
  Navigate,
} from "react-router-dom";
import Home from "./Component/Home";
import ServiceProviderLogin from "./Component/ServiceProviderLogin";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";

import AdminLogin from "./Component/AdminLogin";
import ACServiceRepiar from "./Component/ACServiceRepiar";
import AddCategory from "./Component/Admin/_AddCategory";
import AdminDashboard from "./Component/Admin/AdminDashboard";
import AddSubCategory from "./Component/Admin/_AddSubCategory";
import AddSUB_SUBCategory from "./Component/Admin/_AddSUB_SUBCategory";
import DynamicDataSave from "./Component/Admin/DynamicDataSave";
import AddService from "./Component/Admin/_AddService";
import ACServiceRepairDetails from "./Component/ACServiceRepairDetails";
import Service from "./Component/Admin/Service";
import AddCategoryList from "./Component/Admin/_CategoryList";
import ViewBooking from "./Component/ViewBooking";
import SubCategoryList from "./Component/Admin/_SubCategoryList";
import CustomerLogin from "./Component/Customer/CustomerLogin";
import Ragistration from "./Component/Customer/Ragistration";
import MyProfile from "./Component/Customer/MyProfile";
import OrderDetails from "./Component/Customer/OrderDetails";
import TrackLocation from "./Component/Customer/TrackLocation";
import CommonListPage from "./Component/Admin/CommonListPage";
import AddAddress from "./Component/Customer/AddAddress";
import CommonViewData from "./Component/Admin/CommonViewData";
import Payment from "./Component/Customer/Payment";
import ServiceList from "./Component/ServiceList";
import EditProfile from "./Component/Customer/EditProfile";
import Todayjobs from "./Component/Common/Todayjobs";
import SPProfile from "./Component/Common/SPProfile";
import EditSPProfile from "./Component/Common/EditSPProfile";
import Detailsjobs from "./Component/Common/DetailsJobs";
import ViewBookingNew from "./Component/ViewBooking_New";
import UserProfile from "./Component/Customer/UserProfile";
import MyBookings from "./Component/Customer/MyBookings";
import MyWishlist from "./Component/Customer/MyWishlist";
import MyAddress from "./Component/Customer/MyAddress";
import BookingConfirmed from "./Component/Customer/BookingConfirmed";
import { ProductContext } from "./Component/context/AppContext";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import UserOTP from "./Component/Customer/UserOTP";
import CustomerMobileLogin from "./Component/Customer/CustomerMobileLogin";
import ClearIcon from "@mui/icons-material/Clear";

import JobHistory from "./Component/Common/JobHistory";

import HistoryDetails from "./Component/Common/HistoryDetails";

import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DecryptN from "./Component/Customer/DecryptN";
import { BarLoader, ClimbingBoxLoader, PacmanLoader } from "react-spinners";
import NotFound from "./Component/Common/NotFound";
import ViewDetails from "./Component/Customer/View-Details";
import NewBooking from "./Component/Common/NewBooking";
import TermsCondition from "./Component/TermsCondition";
import RefundCancellation from "./Component/RefundCancellation";
import PrivacyPolicy from "./Component/PrivacyPolicy";
import Contactus from "./Component/Contactus";
import BillingAddress from "./Component/Customer/BillingAddress";
import About from "./Component/About";

const ITEM_HEIGHT = 48;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function App() {
  const {
    fun_LogOut,
    CardList,
    totalAmont,
    openUserOTP,
    setOpenUserOTP,
    handleClickOpenUserOTP,
    handleCloseUserOTP,
    openUserMobile,
    setOpenUserMobile,
    handleClickOpenUserMobile,
    handleCloseUserMobile,
    openAddress,
    handleClickopenAddress,
    handleCloseopenAddress,
    NewAddress,
    handleClickNewAddress,
    handleCloseNewAddress,
    AllAddress,
    fun_getAddAddressListlick,
    fun_Delete,
    adminLogin,
    handleClickAdminLogin,
    handleCloseAdminLogin,
    updateactiveAddress,
    fun_getDefaultAddress,
    Loader,
    handleClickLoader,
    handleCloseLoader,
  } = useContext(ProductContext);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route
          path="serviceprovider/login"
          element={<ServiceProviderLogin />}
        />
        {/* <Route path="admin/login" element={<AdminLogin />} /> */}
        {/* <Route path="customer/login" element={<CustomerLogin />} /> */}
        <Route path="ac-service-repiar/:Id" element={<ACServiceRepiar />} />
        <Route
          path="service/:CategoryName/:SubCategoryName"
          element={<ServiceList />}
        />
        <Route path="admin/category" element={<AddCategory />} />
        <Route path="admin/SubCategory" element={<AddSubCategory />} />
        <Route path="admin/Childcategory" element={<AddSUB_SUBCategory />} />

        <Route path="admin/Service" element={<Service />} />
        <Route
          path="admin/common/:type/:addEdit/:taberpid/:recordErpID"
          element={<Service />}
        />
        <Route
          path="admin/commonlistpage/:type/:viewFormat/:taberpid"
          element={<CommonListPage />}
        />
        <Route
          path="admin/commonviewDetails/:type/:addEdit/:taberpid/:recordErpID"
          element={<CommonViewData />}
        />
        <Route path="admin/dashboard" element={<AdminDashboard />} />
        <Route path="admin/Dynamic/:id" element={<DynamicDataSave />} />
        {/* <Route path="/*" element={<Navigate to="/" />} /> */}
        <Route path="/admin/addservice" element={<AddService />} />
        <Route
          path="acservicerepairdetails/:Id"
          element={<ACServiceRepairDetails />}
        />
        <Route path="/admin/categorylist" element={<AddCategoryList />} />
        <Route path="/view-booking" element={<ViewBooking />} />
        <Route
          path="customer/view-booking/:step"
          element={<ViewBookingNew />}
        />
        <Route path="/admin/subcategorylist" element={<SubCategoryList />} />
        <Route path="/customer/ragistration" element={<Ragistration />} />

        <Route path="/customer/my-profile" element={<MyProfile />} />
        <Route path="/customer/order-details" element={<OrderDetails />} />
        <Route
          path="/customer/order-details/:BookingID"
          element={<OrderDetails />}
        />
        <Route path="/customer/track-location" element={<TrackLocation />} />
        <Route path="/customer/add-address" element={<AddAddress />} />
        <Route path="/customer/BillingAddress" element={<BillingAddress />} />
        <Route path="/customer/payment" element={<Payment />} />
        <Route path="/customer/EditProfile" element={<EditProfile />} />
        <Route path="/common/Todayjobs/:Type" element={<Todayjobs />} />
        <Route path="/common/newJobs" element={<NewBooking />} />
        <Route path="/terms-and-condtion" element={<TermsCondition />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/contactus" element={<Contactus />} />
        <Route
          path="/refund-and-cancellation"
          element={<RefundCancellation />}
        />

        <Route path="/Common/SPProfile" element={<SPProfile />} />
        <Route path="/Common/EditSPProfile" element={<EditSPProfile />} />
        <Route path="/common/jobs-details" element={<Detailsjobs />} />
        <Route path="/customer/user-profile" element={<UserProfile />} />
        <Route path="/customer/my-bookings" element={<MyBookings />} />
        <Route path="/customer/my-wishlist" element={<MyWishlist />} />
        <Route path="/customer/my-address" element={<MyAddress />} />
        <Route path="/customer/ViewDetails/:ItemId" element={<ViewDetails />} />
        <Route
          path="/customer/confirmed/:BookingID"
          element={<BookingConfirmed />}
        />
        <Route
          path="/common/history-details/:BookingID"
          element={<HistoryDetails />}
        />
        <Route path="/common/JobHistory" element={<JobHistory />} />
        <Route path="/DecryptN" element={<DecryptN />} />
        <Route path="/not-found" exact element={<NotFound />} />
        <Route path="/*" element={<Navigate to="/not-found" />} />
      </Routes>

      <Dialog
        fullScreen={fullScreen}
        open={openUserMobile}
        // onClose={handleCloseUserMobile}
        aria-labelledby="responsive-dialog-title"
        className="login-popup-1"
        BackdropProps={{
          style: { backgroundColor: "rgba(0, 0, 0, 0.9)" },
        }}
      >
        <CloseIcon className="cross-login-5" onClick={handleCloseUserMobile} />

        <CustomerMobileLogin />
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        open={openUserOTP}
        // onClose={handleCloseUserOTP}
        aria-labelledby="responsive-dialog-title"
        className="login-popup-1"
        BackdropProps={{
          style: { backgroundColor: "rgba(0, 0, 0, 0.9)" },
        }}
      >
        {/* <p className='edit-number-5' onClick={() => { handleClickOpenUserMobile(); handleCloseUserOTP() }}>Edit Number
          <CloseIcon className='cross-login-8' onClick={() => { handleCloseUserMobile(); handleCloseUserOTP() }} />
        </p> */}
        <p className="edit-number-5">
          OTP Verify
          <CloseIcon
            className="cross-login-8"
            onClick={() => {
              handleCloseUserMobile();
              handleCloseUserOTP();
            }}
          />
        </p>

        <UserOTP />
      </Dialog>

      <Dialog
        open={openAddress}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseopenAddress}
        aria-describedby="alert-dialog-slide-description"
        BackdropProps={{
          style: { backgroundColor: "rgba(0, 0, 0, 0.9)" },
        }}
      >
        <DialogTitle>
          {"Saved Address"}
          <ClearIcon className="cross-icon" onClick={handleCloseopenAddress} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Container>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <div className="add-address-div-6 add-address-div-7">
                    <Link
                      onClick={() => {
                        handleClickNewAddress();
                        handleCloseopenAddress();
                      }}
                    >
                      <h2>
                        <ControlPointIcon className="add-icon-2" />
                        Add Another Address
                      </h2>
                    </Link>
                  </div>
                  {AllAddress?.records?.map((Allstatus, i) => {

                    return (
                      <div className="left-right-radio" key={i}>
                        <div className="left-view-booking-1 left-view-booking-6">
                          <span className="next-button-8">
                            <input
                              type="radio"
                              // id="address2"
                              defaultChecked={
                                Allstatus["Landmark"] === "Active" ? 1 : 0
                              }
                              // value={_id}
                              name="radiovalues"
                              className="add-7"
                              onChange={() =>
                                fun_getDefaultAddress(Allstatus["AddressID"])
                              }
                            />
                          </span>
                          <div className="width-100">
                            <p>
                              <b>{Allstatus["AddressType"]}</b> (
                              {Allstatus["ContactMobile"]})
                            </p>
                            {/* <p>{Allstatus["Alais Name"]}Address Type</p>
                            <p>{Allstatus["Mobile"]}</p> */}
                            <p>
                              {Allstatus["AddressLine1"]}, {Allstatus["City"]} (
                              {Allstatus["State"]}). {Allstatus["Country"]}{" "}
                              {Allstatus["Pincode"]}
                            </p>
                          </div>
                          <div className="mobile-view">
                            <IconButton
                              aria-label="more"
                              id="long-button"
                              aria-controls={open ? "long-menu" : undefined}
                              aria-expanded={open ? "true" : undefined}
                              aria-haspopup="true"
                              onClick={handleClick}
                              className="mobile-view"
                            >
                              <MoreVertIcon className="mobile-view" />
                            </IconButton>
                            <Menu
                              id="long-menu"
                              MenuListProps={{
                                "aria-labelledby": "long-button",
                              }}
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleClose}
                              className="mobile-view"
                              PaperProps={{
                                style: {
                                  maxHeight: ITEM_HEIGHT * 4.5,
                                  width: "20ch",
                                },
                              }}
                            >
                              <MenuItem
                                onClick={() =>
                                  fun_getAddAddressListlick(Allstatus["_id"])
                                }
                              >
                                Edit
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  if (window.confirm("Are you sure you want to delete this record?")) {
                                    fun_Delete(Allstatus["_id"]);
                                  }
                                }}
                              >
                                Delete
                              </MenuItem>
                            </Menu>
                          </div>
                          <span
                            style={{ display: "contents" }}
                            className="web-view"
                          >
                            <Button
                              className="next-button-8"
                              onClick={() =>
                                fun_getAddAddressListlick(Allstatus["_id"])
                              }
                            >
                              Edit
                            </Button>
                            {/* <Link to='/customer/add-address' style={{ display: "none" }} id="btnAddAddressId" target="_blank"></Link> */}
                            <Button
                              className="next-button-8"
                              onClick={() => {
                                if (window.confirm("Are you sure you want to delete this record?")) {
                                  fun_Delete(Allstatus["_id"]);
                                }
                              }}
                            >
                              Delete
                            </Button>
                            {/* <EditIcon /> <DeleteIcon /> */}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </Col>
                {/* <Col xs={12} sm={12} md={12} lg={12}>
                  <Button variant="contained" className="next-button-7 width-100" onClick={() => updateactiveAddress()}>
                    Proceed
                  </Button>
                </Col> */}
              </Row>
            </Container>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        open={NewAddress}
        onClose={handleCloseNewAddress}
        aria-labelledby="responsive-dialog-title"
        className="login-popup-1-mobile"
        BackdropProps={{
          style: { backgroundColor: "rgba(0, 0, 0, 0.9)" },
        }}
      >
        <CloseIcon className="cross-login-5" onClick={handleCloseNewAddress} />
        <AddAddress />
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        open={adminLogin}
        onClose={handleCloseAdminLogin}
        aria-labelledby="responsive-dialog-title"
        className="login-popup-1-mobile"
        BackdropProps={{
          style: { backgroundColor: "rgba(0, 0, 0, 0.9)" },
        }}
      >
        <CloseIcon className="cross-login-5" onClick={handleCloseAdminLogin} />
        <AdminLogin />
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        open={Loader}
        // onClose={handleCloseLoader}
        aria-labelledby="responsive-dialog-title"
        className="loader-popup loader-popup-new2"
        BackdropProps={{
          style: { backgroundColor: "rgba(0, 0, 0, 0.8)" },
        }}
      >
        {/* <CloseIcon className="cross-login-5" onClick={handleCloseLoader} /> */}
        <PacmanLoader color="#36d7b7" className="loader-popup-new3" />
      </Dialog>
    </div>
  );
}

export default App;
