import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Card from "react-bootstrap/Card";
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import GradeIcon from '@mui/icons-material/Grade';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import TextField from '@mui/material/TextField';
import { Link } from "react-router-dom";
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import ControlPointIcon from '@mui/icons-material/ControlPoint';



function MyWallet() {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="my-address">
        <Container>
          <Row>
            <Col xs={12} sm={12} md={6} lg={6}>
                <div className="add-address-div">
                <img src="../assets/images/credit.png" alt="" className="wallet-img" />
                    <h3>Created Balance</h3>
                    <h4>1,00000</h4>
                </div>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                <div className="add-address-div">
                <img src="../assets/images/debit.png" alt="" className="wallet-img" />
                    <h3>Debit Balance</h3>
                    <h4>1,00000</h4>
                </div>
                </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}


export default MyWallet
