/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react'
import { ProductContext } from "./context/AppContext";
import { useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import Banner from './Banner';
import StarsIcon from '@mui/icons-material/Stars';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import AddIcon from '@mui/icons-material/Add';
import RemoveSharpIcon from '@mui/icons-material/RemoveSharp';
import LocalOfferSharpIcon from '@mui/icons-material/LocalOfferSharp';
function ACServiceRepairDetails() {
  const { Id } = useParams();

  const { SubCategoryListData, ServiceList, subsubcategorylst } = useContext(ProductContext);
  //console.log(ServiceList, "ko");
  const[dataList,setdatalist] =useState([]);// ServiceList?.find(x => x["ItemID"] === Id);  
  useEffect(() => {
    setdatalist(ServiceList?.find((record) => record["ItemID"] === Id));
    console.log(dataList, "dataList");
    window.scrollTo(0, 0);
  }, [Id, ServiceList]);

  return (
    <div>
      <Header />
      <div className='top-space'>
        <Container>
          <Row>
            <Col xs={12} sm={12} md={8} lg={8}>
              {dataList?.map((data, i) => {                
                return <>
                  <div className='service-section-1'>
                    <div className='banner-section-1 banner-section-11'>
                      <h4>{data["Sub Sub Category"]}<span>{data["MRP"]}</span></h4>
                      <div dangerouslySetInnerHTML={{ __html: data["Description"] }}></div>
                      
                      <Button className="service-images-2-button detail-button" variant="outlined">Add</Button>
                    </div>
                  </div></>
              })}


            </Col>
            <Col xs={12} sm={12} md={4} lg={4}>
              <div className='banner-section-1'>
                <img src='../assets/images/servicebanner.webp' className='banner-section-img' />
                <div className='div-service-2 div-service-12'>
                  <div className='empty-cart'>
                    <img src='../assets/images/shopping2.png' width="100%" />
                    <p>No items in your cart</p>
                  </div>
                  <div className='empty-cart'>
                    <h4>Cart</h4>
                    <h5>Deep clean AC service (split)</h5>
                    <div className='add-button-service'>
                      <Button variant="text"><RemoveSharpIcon className='add-icon-1' /></Button>
                      <span>2</span>
                      <Button variant="text"><AddIcon className='add-icon-1' /></Button>
                    </div>
                    <span className='price-span1'>₹699</span>
                    <p className='add1div'>Add 1 more service, Get 30% off 2nd one onwards</p>
                    <div className='view-cart-1'><span className='price-span1'>₹699</span> <Link to="/">View Cart</Link></div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Footer />
    </div>
  )
}
export default ACServiceRepairDetails
