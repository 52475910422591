import React, {
  useState,
  useEffect,
  useContext,
  useParams,
  useRef,
} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Form, InputGroup } from "react-bootstrap";
import Button from "@mui/material/Button";

// import Button from 'react-bootstrap/Button';
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Dropdown from "react-bootstrap/Dropdown";
import Person2Icon from "@mui/icons-material/Person2";

import LogoutIcon from "@mui/icons-material/Logout";
import ListAltIcon from "@mui/icons-material/ListAlt";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import axios from "axios";
import Badge from "@mui/material/Badge";
import MailIcon from "@mui/icons-material/Mail";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { ProductContext } from "./context/AppContext";
import CustomerLogin, { Login } from "./Customer/CustomerLogin";

import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";

import jwt_decode from "jwt-decode";
import Ragistration from "./Customer/Ragistration";
import TextField from "@mui/material/TextField";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import ShoppingCart from "./Customer/ShoppingCart";
import ClearIcon from "@mui/icons-material/Clear";
import Slide from "@mui/material/Slide";
import PinDropIcon from "@mui/icons-material/PinDrop";
import UserOTP from "./Customer/UserOTP";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import MyLocationIcon from "@mui/icons-material/MyLocation";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// import logo from './public/images/logo1.webp'

function Header() {
  const {
    fun_LogOut,
    CardList,
    totalAmont,
    handleClickOpenUserOTP,
    handleCloseUserOTP,
    handleClickOpenUserMobile,
    setLoginType,
    setAuthkeyTEXT,
    openCustomerRagistration,
    setOpenCustomerRagistration,
    handleClickOpenCustomerRagistration,
    handleCloseCustomerRagistration,
    ServiceList,
    setServiceList,
    handleClickNewAddress,
    handleCloseNewAddress,
    handleCloseopenAddress,
  } = useContext(ProductContext);

  var userLogininfo = JSON.parse(localStorage.getItem("user-Logininfo"));
  let baseURL = `${process.env.REACT_APP_BASE_URL}`;
  //let baseTokenKey = `${process.env.REACT_APP_BASE_KEY}`.replace(/'/g, "");
  let authToken = `${process.env.REACT_APP_BASE_AUTHKEY}`;
  //console.log(userLogininfo, "userLogininfo");

  const [inputValue, setInputValue] = useState("");
  const texts = ["Search for AC Service", "Search for Facial"];
  const textInterval = 2000; // Adjust the interval to change text (milliseconds)

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [openCustomerLogin, setOpenCustomerLogin] = React.useState(false);
  const handleClickOpenCustomerLogin = () => {
    localStorage.removeItem("CardLogin");
    setOpenCustomerLogin(true);
  };
  const handleCloseCustomerLogin = () => {
    setOpenCustomerLogin(false);
  };

  // const [openCustomerRagistration, setOpenCustomerRagistration,] = React.useState(false);
  // const handleClickOpenCustomerRagistration = () => {
  //   setOpenCustomerRagistration(true);
  // };
  // const handleCloseCustomerRagistration = () => {
  //   setOpenCustomerRagistration(false);
  // };

  const [openCart, setOpenCart] = React.useState(false);

  const handleClickCart = () => {
    setOpenCart(true);
  };
  const handleCloseCart = () => {
    setOpenCart(false);
  };
  const CartItemms = JSON.parse(localStorage.getItem("CartItemms"));
  // console.log(CartItemms.length, "CartItemms");
  //LoginType, setLoginType
  const fun_loginMDL = () => {
    localStorage.clear();
    // localStorage.removeItem("CardLogin");
    setLoginType("Customer Login");
    setAuthkeyTEXT(
      "vNA7UP2NdlzIyUTcc5PMqxbuLwFV2qE16i15J4D9dd5uvmES3sOwchZqN04oGjd0NpigKNAxcORxKVn/nxafML8ko9t6Y8m2KpRx6nsUReA="
    );
    handleClickOpenUserMobile();
  };

  const [openMAp, setopenMAp] = React.useState(false);
  const handleClickopenMap = () => {
    //localStorage.removeItem("myAddress");

    setopenMAp(true);
  };
  const handleCloseopenMap = () => {
    setopenMAp(false);
  };
  useEffect(() => {
    // navigator.geolocation.getCurrentPosition((position) => {
    //   const { latitude, longitude } = position.coords;

    //   getNominatimLocation(latitude, longitude);
    // });
  }, [0]);
  const handleMapPress = (event) => {
    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;

      getNominatimLocation(latitude, longitude);
    });

    // setCurrentLocation({ latitude, longitude });
  };
  const [fulllocationName, setfullLocationName] = useState(null);
  const getNominatimLocation = async (latitude, longitude) => {
    const apiKey = "AIzaSyCwAnMuGBG7adjaM-WB_sYA9YrW_f7ncMM"; // Replace 'YOUR_API_KEY' with your actual Google API key
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      // Check if the request was successful
      if (data.status === "OK") {
        const address = data.results[0].formatted_address;
        // address1 = data.results[0];
        setfullLocationName(address);
        console.log(address, "address");
        setopenMAp(false);
      } else {
        console.error("Geocoding request failed:", data.status);
        // return null; // Return null or handle the error as needed
      }
    } catch (error) {
      console.error("Error fetching geocode data:", error);
    }
  };
  // const Filtersearch = (event) => {
  //   setFilter1(
  //     CategoryList?.records?.filter((f) =>
  //       f?.Category?.toLowerCase().includes()
  //     )
  //   );
  // };

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const fun_close = (sub) => {
    localStorage.setItem("SubcategoryName", sub);
    setSearchTerm("");
  };
  // Your data array to filter
  const yourDataArray = [
    { serviceName: "Test" },
    { serviceName: "Men" },
    { serviceName: "Women" },
    { serviceName: "Service" },
    { serviceName: "Spa" },
    { serviceName: "Product" },
    { serviceName: "Product 5" },
  ];

  // Filtering logic
  // const filteredData = yourDataArray.filter((item) =>
  //   item.serviceName.toLowerCase().includes(searchTerm.toLowerCase())
  // );

  // const filteredData = searchTerm
  //   ? yourDataArray.filter((item) =>
  //       item.serviceName.toLowerCase().includes(searchTerm.toLowerCase())
  //     )
  //   : [];
  const fun_ServiceList = (item) => {
    //  handleClickLoader();
    const listpra = {
      pgSize: 5000,
      index: 1,
      toEmailID: "designMonu@dialerp.com",
      tabErpID: 1478,
      useAtlusSearch: true,
      //filters: "{}",
      filters: `{'Item':'${item}'}`,
    };

    fetch(baseURL + "/api/Dynamic/GetRecords", {
      method: "POST",
      headers: {
        "Authorization-Token": authToken,
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(listpra),
    }).then((result) => {
      result.json().then((resp) => {
        // handleCloseLoader();
        if (resp.hasErrors === false) {
          if (resp.response?.records !== null) {
            setSearchTerm(resp?.response?.records);
          } else {
            setSearchTerm("");
          }
        } else {
          //alert(resp.errors);
          setSearchTerm("");
        }
      });
    });
  };
  console.log("searchTerm", searchTerm);

  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // const handleClickOutside = (event) => {
  //   const searchItemDiv = document.querySelector(".searchitem_div");
  //   if (searchItemDiv && !searchItemDiv.contains(event.target)) {
  //     setSearchTerm("");
  //     setSearchResults([]);
  //   }
  // };
  const handleClickOutside = (event) => {
    const searchItemDiv = document.querySelector(".searchitem_div");
    if (searchItemDiv && !searchItemDiv.contains(event.target)) {
      setSearchTerm(""); // Reset search term
      setSearchResults([]); // Optionally reset search results
    }
  };

  return (
    <div className="header">
      {/* Conditional rendering of the filtered list */}

      <Navbar expand="lg" className="bg-body-tertiary web-view">
        <Container fluid>
          <Navbar.Brand>
            <Link to="/">
              <img
                src={
                  process.env.PUBLIC_URL + "../../../assets/images/logo1.svg"
                }
                alt="logo"
              />
            </Link>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="navbarScroll" />

          {CartItemms && CartItemms?.length > 0 ? (
            <Badge
              badgeContent={CartItemms?.length}
              color="primary"
              className="cart-icon-2"
            >
              <ShoppingCartOutlinedIcon
                color="action"
                onClick={handleClickCart}
              />
            </Badge>
          ) : (
            <Badge badgeContent={0} color="primary" className="cart-icon-2">
              <ShoppingCartOutlinedIcon
                color="action"
                onClick={handleClickCart}
              />
            </Badge>
          )}
          <Navbar.Collapse id="navbarScroll">
            <Form className="d-flex div-center-1">
              {/* <InputGroup
                className="div-1"
                onClick={() => {
                  handleClickopenMap();
                }}
              >
                <InputGroup.Text className="location-header-icon-1">
                  <FmdGoodOutlinedIcon />
                </InputGroup.Text>
                <Form.Control
                  type="search"
                  placeholder="Delhi NCR"
                  className="me-2 location-header"
                  aria-label="Search"
                  value={fulllocationName}
                  onChange={(e) => setfullLocationName(e.target.value)}
                />
                <InputGroup.Text className="location-header-icon-2">
                  <KeyboardArrowDownOutlinedIcon />
                </InputGroup.Text>
              </InputGroup> */}

              {/* <InputGroup className="div-2">
      <InputGroup.Text className="location-header-icon-1">
        <SearchOutlinedIcon />
      </InputGroup.Text>
      <Form.Control
        type="search"
        placeholder="Search for"
        className="me-2 location-header-2"
        aria-label="Search"
      />
      
    </InputGroup> */}
              {/* <InputGroup className="div-2">
                <InputGroup.Text className="location-header-icon-1">
                  <SearchOutlinedIcon />
                </InputGroup.Text>
                <Form.Control
                  type="search"
                  placeholder="Search for Service New"
                  className="me-2 location-header-2 search-input-text-2"
                  aria-label="Search"
                  // value={searchTerm}
                  // onChange={handleSearch}
                  onKeyUp={(e) => fun_ServiceList(e.target.value)}
                />
              </InputGroup> */}
              <InputGroup className="div-2">
                <InputGroup.Text className="location-header-icon-1">
                  <SearchOutlinedIcon />
                </InputGroup.Text>
                <Form.Control
                  type="search"
                  placeholder="Search for Service New"
                  className="me-2 location-header-2 search-input-text-2"
                  aria-label="Search"
                  // value={searchTerm}
                  // onChange={handleSearch}
                  onKeyUp={(e) => fun_ServiceList(e.target.value)}
                />
              </InputGroup>
            </Form>

            {/* {userLogininfo?.["User Group"] === "Customer" ? */}
            {userLogininfo !== null ? (
              <>
                {userLogininfo?.["User Group"] === "Customer" && (
                  <Dropdown className="login-profile-12">
                    <Dropdown.Toggle
                      variant="outlined"
                      id="dropdown-basic"
                      className="login-profile-icon"
                    >
                      {userLogininfo?.["ProfilePic"] !== undefined &&
                      userLogininfo?.["ProfilePic"] !== null ? (
                        <img
                          src={userLogininfo["ProfilePic"]}
                          className="login-profile-icon-1_New_2"
                          alt="Profile"
                        />
                      ) : (
                        <img
                          src="../../assets/images/profile-icon.png"
                          className="login-profile-icon-1"
                        />
                      )}

                      {/* <img src={userLogininfo["Profile Pic"]} className='login-profile-icon-1' alt="Profile" /> */}
                      <span>
                        {userLogininfo["User Name"]}{" "}
                        <KeyboardArrowDownOutlinedIcon />
                      </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="menu-profile-1">
                      <Dropdown.Item>
                        <Link to="/customer/user-profile">
                          <Person2Icon className="left-profile-icon" />
                          My Profile
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <Link to="/customer/my-bookings">
                          <ListAltIcon className="left-profile-icon" />
                          My Booking
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <Link to="/customer/my-address">
                          <PinDropIcon className="left-profile-icon" />
                          Visit Address
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <Link to="/customer/BillingAddress">
                          <PinDropIcon className="left-profile-icon" />
                          Billing Address
                        </Link>
                      </Dropdown.Item>

                      <Dropdown.Item>
                        <Link to="/customer/my-wishlist">
                          <FavoriteBorderIcon className="left-profile-icon" />
                          My Wishlist
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => fun_LogOut()}>
                        <Link to="">
                          <LogoutIcon className="left-profile-icon" />
                          Logout
                        </Link>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
                {userLogininfo?.["User Group"] !== "Customer" && (
                  <Dropdown className="login-profile-12">
                    <Dropdown.Toggle
                      variant="outlined"
                      id="dropdown-basic"
                      className="login-profile-icon"
                    >
                      {userLogininfo?.["ProfilePic"] !== undefined &&
                      userLogininfo?.["ProfilePic"] !== null ? (
                        <img
                          src={userLogininfo["ProfilePic"]}
                          className="login-profile-icon-1"
                          alt="Profile"
                        />
                      ) : (
                        <img
                          src="../../assets/images/profile-icon.png"
                          className="login-profile-icon-1"
                        />
                      )}
                      {/* <img src={userLogininfo["Profile Pic"]} className='login-profile-icon-1' alt="Profile" /> */}
                      <span>{userLogininfo["User Name"]}</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="menu-profile-1">
                      <Dropdown.Item>
                        <Link to="/admin/dashboard">
                          <Person2Icon className="left-profile-icon" />
                          My Profile
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => fun_LogOut()}>
                        <Link to="">
                          <LogoutIcon className="left-profile-icon" />
                          Logout
                        </Link>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </>
            ) : (
              <>
                <Button
                  variant="outlined"
                  className="login-1"
                  onClick={() => fun_loginMDL()}
                >
                  Login
                </Button>
                {/* <Button variant="outlined" className='login-1' onClick={handleClickOpenCustomerLogin}>Login</Button> */}
              </>
            )}

            {/* <Link to = "/login"><Button variant="outlined" className='login-1'>A2Zwork India</Button></Link> */}
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {/* Mobile View */}
      <Navbar expand="lg" className="bg-body-tertiary mobile-view">
        <Container fluid>
          <Navbar.Brand>
            <Link to="/">
              <img
                src={process.env.PUBLIC_URL + "../../assets/images/logo1.svg"}
                alt="logo"
              />
            </Link>
          </Navbar.Brand>
          {CartItemms && CartItemms?.length > 0 ? (
            <Badge
              badgeContent={CartItemms?.length}
              color="primary"
              className="cart-icon-256"
            >
              <ShoppingCartOutlinedIcon
                color="action"
                onClick={handleClickCart}
              />
            </Badge>
          ) : (
            <></>
          )}
          {userLogininfo?.["User Group"] === "Customer" ? (
            //  <Button variant="outlined" className='login-1' onClick={fun_LogOut}>Logout</Button>
            <Dropdown className="login-profile-12">
              <Dropdown.Toggle
                variant="outlined"
                id="dropdown-basic"
                className="login-profile-icon"
              >
                {/* <img
                  src={userLogininfo["Profile Pic"]}
                  className="login-profile-icon-1"
                  alt="logo"
                /> */}
                {userLogininfo?.["ProfilePic"] !== undefined &&
                userLogininfo?.["ProfilePic"] !== null ? (
                  <img
                    src={userLogininfo["ProfilePic"]}
                    className="login-profile-icon-1_New_2"
                    alt="Profile"
                  />
                ) : (
                  <img
                    src="../../assets/images/profile-icon.png"
                    className="login-profile-icon-1" alt='icon'
                  />
                )}
                {/* <span className='user-name-2' >{userLogininfo["User Name"]}</span> */}
              </Dropdown.Toggle>
              <Dropdown.Menu className="menu-profile-1">
                <Dropdown.Item>
                  <Link to="/customer/user-profile">
                    <Person2Icon className="left-profile-icon" />
                    My Profile
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link to="/customer/my-bookings">
                    <ListAltIcon className="left-profile-icon" />
                    My Booking
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link to="/customer/my-address">
                    <PinDropIcon className="left-profile-icon" />
                    My Address
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link to="/customer/my-wishlist">
                    <FavoriteBorderIcon className="left-profile-icon" />
                    My Wishlist
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item onClick={fun_LogOut}>
                  <Link to="">
                    <LogoutIcon className="left-profile-icon" />
                    Logout
                  </Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <>
              <Button
                variant="outlined"
                className="login-1"
                onClick={() => fun_loginMDL()}
              >
                Login
              </Button>
              {/* <Button variant="outlined" className='login-1' onClick={handleClickOpenCustomerLogin}>Login</Button> */}
            </>
          )}
          <Form className="d-flex div-center-1">
            {/* <InputGroup className="div-1">
                <InputGroup.Text className="location-header-icon-1">
                  <FmdGoodOutlinedIcon />
                </InputGroup.Text>
                <Form.Control
                  type="search"
                  placeholder="Delhi NCR"
                  className="me-2 location-header"
                  aria-label="Search"
                />
                <InputGroup.Text className="location-header-icon-2">
                  <KeyboardArrowDownOutlinedIcon />
                </InputGroup.Text>
              </InputGroup> */}
            <InputGroup className="div-2">
              <InputGroup.Text className="location-header-icon-1">
                <SearchOutlinedIcon />
              </InputGroup.Text>
              <Form.Control
                type="search"
                placeholder="Search for Service"
                className="me-2 location-header-2"
                aria-label="Search"
                // value={searchTerm}
                // onChange={handleSearch}
                onKeyUp={(e) => fun_ServiceList(e.target.value)}
              />
            </InputGroup>
          </Form>
        </Container>
      </Navbar>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" className="service-text-1">
          {"Choose Login"}
        </DialogTitle>
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Container className="div-class-50">
            <Row>
              <Col xs={6} md={6}>
                <Link to="serviceprovider/login" className="link-text-1">
                  <Card>
                    <CardContent className="service-div-1">
                      <img
                        src="../../../assets/images/configuration.png"
                        width="100%"
                        alt="logo"
                      />
                      <div className="service-div-2">
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          className="service-h2"
                        >
                          A2Zwork India
                        </Typography>
                        <Button
                          variant="contained"
                          size="medium"
                          className="service-button"
                        >
                          Login
                        </Button>
                      </div>
                    </CardContent>
                  </Card>
                </Link>
              </Col>
              <Col xs={6} md={6}>
                <Link
                  to=""
                  onClick={() => {
                    handleClickOpenCustomerLogin();
                    handleClose();
                  }}
                  className="link-text-1"
                >
                  <Card>
                    <CardContent className="service-div-1">
                      <img
                        src="../../../assets/images/architec.png"
                        width="100%"
                        alt="logo"
                      />
                      <div className="service-div-2">
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          className="service-h2"
                        >
                          Customer
                        </Typography>
                        <Button
                          variant="contained"
                          size="medium"
                          className="service-button"
                        >
                          Login
                        </Button>
                      </div>
                    </CardContent>
                  </Card>
                </Link>
              </Col>
            </Row>
          </Container>
        </DialogContent>
      </Dialog>
      <Dialog
        fullScreen={fullScreen}
        open={openCustomerLogin}
        onClose={handleCloseCustomerLogin}
        aria-labelledby="responsive-dialog-title"
        className="login-popup-1"
        BackdropProps={{
          style: { backgroundColor: "rgba(0, 0, 0, 0.9)" },
        }}
      >
        <CloseIcon
          className="cross-login-5"
          onClick={handleCloseCustomerLogin}
        />

        <CustomerLogin />

        <p className="account-type-show">
          Don't have an account?
          <Link
            to=""
            onClick={() => {
              handleClickOpenCustomerRagistration();
              handleCloseCustomerLogin();
            }}
          >
            {" "}
            Register here
          </Link>
        </p>
      </Dialog>
      {/* <Dialog
        fullScreen={fullScreen}
        open={openUserTOP}
        onClose={handleCloseUserTOP}
        aria-labelledby="responsive-dialog-title"
        className='login-popup-1'
      >
        <p className='edit-number-5'onClick={()=>{handleCloseUserTOP();handleClickOpenCustomerLogin()}}>Edit Number
       <CloseIcon className='cross-login-8' onClick={handleCloseUserTOP} />
       </p>
       
        <UserOTP />
       
       </Dialog> */}
      <Dialog
        fullScreen={fullScreen}
        open={openCustomerRagistration}
        onClose={handleCloseCustomerRagistration}
        aria-labelledby="responsive-dialog-title"
        className="login-popup-1"
        BackdropProps={{
          style: { backgroundColor: "rgba(0, 0, 0, 0.9)" },
        }}
      >
        <CloseIcon
          className="cross-login-5"
          onClick={handleCloseCustomerRagistration}
        />
        <Ragistration />
        <p className="account-type-show">
          Existing User
          <Link
            to=""
            onClick={() => {
              fun_loginMDL();
              handleCloseCustomerRagistration();
            }}
          >
            {" "}
            Login
          </Link>
        </p>
      </Dialog>
      <Dialog
        open={openCart}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseCart}
        aria-describedby="alert-dialog-slide-description"
        className="cart-popup-design"
        BackdropProps={{
          style: { backgroundColor: "rgba(0, 0, 0, 0.9)" },
        }}
      >
        <ClearIcon className="cross-icon-cart-2" onClick={handleCloseCart} />
        {/* <ShoppingCart  cartItems={CardList} /> */}
        <ShoppingCart cartItems={CardList} />
      </Dialog>
      <Dialog
        open={openMAp}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseopenMap}
        aria-describedby="alert-dialog-slide-description"
        BackdropProps={{
          style: { backgroundColor: "rgba(0, 0, 0, 0.9)" },
        }}
      >
        <DialogTitle>
          {"Search for location"}
          <ClearIcon className="cross-icon" onClick={handleCloseopenMap} />
        </DialogTitle>

        <DialogContent className="search-div-new-5">
          <DialogContentText id="alert-dialog-slide-description">
            <div className="add-address-div-6 add-address-div-75">
              <div className="Input_search_2">
                {/* <input
                        type="text"
                        placeholder="Search for your location/society/apartment"

                      /> */}
                <InputGroup size="sm" className="mb-3">
                  <InputGroup.Text
                    id="inputGroup-sizing-sm"
                    className="Search_Back_Button_1"
                  >
                    <KeyboardBackspaceIcon onClick={handleCloseopenMap} />
                  </InputGroup.Text>
                  <Form.Control
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    placeholder="Search for your location/society/apartment"
                    autoFocus={true}
                    className="Search_Input_search_2"
                  />
                </InputGroup>
              </div>
              <Link
                onClick={() => {
                  handleMapPress();
                }}
              >
                <h2>
                  {/* <ControlPointIcon className="add-icon-2" /> */}
                  <MyLocationIcon /> Use current location
                </h2>
              </Link>
              <Link
                onClick={() => {
                  handleClickNewAddress();
                  handleCloseopenAddress();
                }}
              >
                <h2>
                  <ControlPointIcon className="add-icon-2" />
                  Add Address
                </h2>
              </Link>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <div className="searchitem_div">
        <div className="Search_div-service-1">
          {searchTerm.length > 0 ? (
            <Row className="searchitem_div5">
              <Col xs={12} sm={12} md={12}>
                {searchTerm.map((item, index) => (
                  <div className="search_item_div_8">
                    <Link
                      to={`/service/${item["Category"]}/${item["Sub Category"]}`}
                      onClick={(e) => fun_close(item["Sub Category"])}
                      className="search_item_div_9"
                    >
                      <div className="search_item_div_6">
                        <img
                          // src='process.env.PUBLIC_URL + "../../assets/images/most-book/20.png'
                          src={item?.["Item Pic"]}
                          width="100%"
                          alt="Icon"
                        />
                      </div>
                      <div className="search_item_div_7">
                        <h3> {item.Item} </h3>
                        <p>
                          ₹ {item.MRP}
                          <span style={{ color: "gray" }}>| 30 min</span>
                        </p>
                      </div>
                    </Link>
                  </div>
                ))}
              </Col>
            </Row>
          ) : searchTerm ? (
            <p style={{ textAlign: "center" }}>No results found</p>
          ) : (
            []
          )}
        </div>
      </div>
    </div>
  );
}
export default Header;
