import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";

function SubBanner() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      margin: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div className="top-space">
      <Container fluid className="section-subbanner section-subbanner-22">
        <Row>
          <Col xs={12} sm={12} md={12}>
            <Carousel responsive={responsive}>
              <Link>
                <div>
                  <img
                    src="../assets/images/banner-all/1.png"
                    className="sb-image-sub-banner"
                  />
                </div>
              </Link>
              <div>
                <img
                  src="../assets/images/banner-all/2.png"
                  className="sb-image-sub-banner"
                />
              </div>
              <div>
                <img
                  src="../assets/images/banner-all/3.png"
                  className="sb-image-sub-banner"
                />
              </div>
              <div>
                <img
                  src="../assets/images/banner-all/4.png"
                  className="sb-image-sub-banner"
                />
              </div>
              <div>
                <img
                  src="../assets/images/banner-all/5.png"
                  className="sb-image-sub-banner"
                />
              </div>
              <div>
                <img
                  src="../assets/images/banner-all/6.png"
                  className="sb-image-sub-banner"
                />
              </div>
            </Carousel>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default SubBanner;
