import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Form from "react-bootstrap/Form";
import Button from "@mui/material/Button";

function Contactus() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [0]);
  return (
    <div>
      <Header />
      <div className="top-space refundclass contactclass-2">
        <Container fluid>
          <Row>
            <Col xs={12} sm={12} md={12}>
              <h2>Contact Us</h2>
            </Col>
            <Col xs={12} sm={12} md={4}>
              <div className="address-div-1">
                <MarkEmailReadIcon className="IconColor-1" />
                <h3>Send Us Mail</h3>
                <p>
                  <a href="mailto:.info@A2ZWorkIndia.com">
                  info@A2ZWorkIndia.com
                  </a>
                </p>
              </div>
            </Col>
            <Col xs={12} sm={12} md={4}>
              <div className="address-div-1">
                <PhoneInTalkIcon className="IconColor-1" />
                <h3>Call Us Anytime</h3>
                <p>
                  <a href="tel:+9999365655 ">+91 9999365655 
                  </a>
                </p>
              </div>
            </Col>

            <Col xs={12} sm={12} md={4}>
              <div className="address-div-1">
                <LocationOnIcon className="IconColor-1" />
                <h3>Visit Our Office</h3>
                <p>
                Perfect ERP Software
                <br></br>
                C-28,29 and 30, 9th floor, Sec-62 Noida, Uttar Pradesh-201301</p>
              </div>
            </Col>
          </Row>
        </Container>
        <Container fluid className="address-div-2" hidden>
          <Row>
            <Col xs={12} sm={12} md={12}>
              <h2>Write us what you want to Know</h2>
            </Col>

            <Col xs={12} sm={6} md={6}>
              <div className="address-div-3">
                <Form>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Name*</Form.Label>
                    <Form.Control
                      className="contact-input-1"
                      type="email"
                      placeholder="Enter Your Name"
                    />

                    <Form.Label>Email*</Form.Label>
                    <Form.Control
                      className="contact-input-1"
                      type="email"
                      placeholder="Enter Your Email"
                    />

                    <Form.Label>Mobile*</Form.Label>
                    <Form.Control
                      className="contact-input-1"
                      type="email"
                      placeholder="Enter Your Mobile"
                      maxLength={10}
                      minLength={0}
                    />

                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>Message</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Enter Your Message"
                      />
                    </Form.Group>
                  </Form.Group>
                  <Button variant="contained" className="Submit-button-7">
                    Submit
                  </Button>
                </Form>
              </div>
            </Col>
            <Col xs={12} sm={6} md={6}>
              <div className="address-div-6">
                <img src="../assets/images/contact-us.jpg" width="100%" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  );
}
export default Contactus;
