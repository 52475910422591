/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { Link, json } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import axios from 'axios';
import moment from 'moment'

function DynamicDataSave() {
    var userLogininfo = JSON.parse(localStorage.getItem("user-Logininfo"));
    console.log(userLogininfo);
    if (userLogininfo === null) {
        window.location.href = '/';
    }
    else {
        if (userLogininfo["User Group"] !== "Employee") {
            window.location.href="/not-found";
        }

    }
    let baseURL = `${process.env.REACT_APP_BASE_URL}`;
    let authToken = localStorage.getItem("Auth-Token").replace(/"/g, '');

    const date = {
        position: "relative",
        width: "100%",
        display: "block",
        lineHeight: "1.5px",
        padding: "15px 20px",
        fontSize: "15px",
        color: "#696969",
        background: "#F0F5F7",
        border: "1px solid #ced4da",
        boxSizing: "borderBox",
        borderRadius: "8px",
        transition: "all 300ms ease",
    };
    
    const [CategoryImg, setCategoryImg] = useState(null);
    const [col, setcol] = useState("");
    const [formdata, setformdata] = useState({});
    // eslint-disable-next-line no-unused-vars
    const [file, setFile] = useState("");
    const [CategoryList, setCategoryList] = useState([]);
    const [SubCategoryList, setSubCategoryList] = useState([]);
    const [CategoryDropdown, setCategoryDropdown] = useState([]);

    useEffect(() => {
        getcoldata();
    }, []);

    

    const handleInputChange = (e, fieldName) => {

        const { value } = e.target;

        setformdata({
            ...formdata,
            [fieldName]: value,
        });
    };
    const getcoldata = async () => {
        const options = {
            method: "POST",
            url: "https://api.dialerp.com/api/Dynamic/GetInputColumns?appname=web&tmpUId=40398&tmpPId=40398",
            headers: {
                "Authorization-Token": authToken,
                Accept: "application/json, text/plain",
                "Content-Type": "application/json;charset=UTF-8",
            },
            data: {
                filterButtons: "",
                recordErpID: 0,
                tabErpID: 794,
                toEmailID: "designMonu@dialerp.com",
            },
        };
        try {
            const response = await axios.request(options);
            setcol(response.data);
        } catch (error) {
            console.error(error);
        }
    };
    const handleFile = (event) => {

        setFile(URL.createObjectURL(event.target.files[0]));
        const formData = new FormData();
        formData.append("CategoryPic", event.target.files[0]);
        fetch(
            "https://dialerpwebapis-dev-as.azurewebsites.net" +
            "/api/UploadData/getWebUploadImgUrlData?PartyAcTBSr=" +
            "&parentID=40004&userID=40004",
            {
                method: "POST",
                headers: { HttpHeaders: "multipart/form-data" },
                body: formData,
            }
        ).then((result) => {
            result.json().then((resp) => {
                setformdata({ ...formdata, CategoryPic: resp.response });
                setCategoryImg(resp.response, "ggg");
            });
        });
    };
   

    const bindDropdown = (field) => {
        var dataMapper = {};
        dataMapper = {
            "CollectionName": "FINANCECONNECT_SubCategory",
            "filter": `{'${field}':/^null/i}`,
            "project": "{'SubCategory':'$SubCategory','Category':'$Category'}"
        }
        console.log(dataMapper);
        var formData =
        {
            "useAtlusSearch": false,
            "userID": 40396,
            "filters": "{tabErpID:794}",
            "index": 1,
            "isDownload": false,
            "toEmailID": "designMonu@dialerp.com",
            "tabErpID": 794,
            "dataMapper": dataMapper

        }

        fetch(
            "https://api.dialerp.com/api/dynamic/AutoFillMapperWise?appname=web&tmpUId=40396&tmpPId=40396",
            {
                method: "POST",
                headers: {
                    "Authorization-Token": authToken,
                    Accept: "application/json, text/plain",
                    "Content-Type": "application/json;charset=UTF-8",
                },
                body: JSON.stringify(formData),
            })
            .then((result) => {
                result.json().then((resp) => {
                     
                    if (resp.hasErrors === true) {
                        alert(resp.errors);

                    }
                    else {
                        if (resp.response?.records !== null) {
                             ;
                            if (field === "SubCategory") {

                                setCategoryDropdown(resp.response?.records)
                            }



                            //toastrr.success(locationDropdown);
                        }
                        //setimgurl_(response.data.response[0].imageUrl);
                    }
                }
                )
            });
    }
    const setcaregory = (obj) => {
        setCategoryDropdown(obj);
    }
    return (
        <>
            
        </>
    )
}
export default DynamicDataSave
