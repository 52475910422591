
import React, { useState, useEffect } from "react";
import axios from 'axios';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Form from "react-bootstrap/Form";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Headerinner from '../Common/Headerinner';
import SideBar from "../Common/SideBar";

function AddCategory() {
    var userLogininfo = JSON.parse(localStorage.getItem("user-Logininfo"));
    if (userLogininfo === null) {
        window.location.href = '/';
    }
    else {
        if (userLogininfo["User Group"] !== "Employee") {
            window.location.href="/not-found";
        }

    }
    let baseURL = `${process.env.REACT_APP_BASE_URL}`;
    let authToken = localStorage.getItem("Auth-Token").replace(/"/g, '');
    const [CategoryImg, setCategoryImg] = useState(null);
    const [col, setcol] = useState([]);
    const [formdata, setformdata] = useState({});
    const [file, setFile] = useState("");


    useEffect(() => {
        getcoldata();
    }, []);

    const handleInputChange = (e, fieldName) => {
        const { value } = e.target;
        setformdata({
            ...formdata,
            [fieldName]: value,
        });
    };
    const getcoldata = async () => {
        const options = {
            method: "POST",
            url: baseURL + "/api/Dynamic/GetInputColumns",
            headers: {
                "Authorization-Token": authToken,
                Accept: "application/json, text/plain",
                "Content-Type": "application/json;charset=UTF-8",
            },
            data: {
                filterButtons: "",
                recordErpID: 0,
                tabErpID: 792,
                toEmailID: "designMonu@dialerp.com",
            },
        };
        try {
            const response = await axios.request(options);
            setcol(response.data);
        } catch (error) {
            console.error(error);
        }
    };
    const handleFile = (event) => {
        setFile(URL.createObjectURL(event.target.files[0]));
        const formData = new FormData();
        formData.append("CategoryPic", event.target.files[0]);
        fetch(
            baseURL + "/api/UploadData/UploadCommonFilesToAzure",
            {
                method: "POST",
                headers: { HttpHeaders: "multipart/form-data" },
                body: formData,
            }
        ).then((result) => {
            result.json().then((resp) => {
                if (resp.hasErrors === false && resp?.response !== null) {
                    setformdata({ ...formdata, CategoryPic: resp?.response[0]["imageUrl"] });
                    setCategoryImg(resp?.response[0]["imageUrl"]);
                }
                else {
                    alert(resp?.errors);
                }
            });
        });
    };
    function fun_AddCategoryData() {
        // for (let i = 0; i < col.response.length; i++) {
        //     if (col.response[i]["isMandatory"] == true && col.response[i]["columnName"] != "CategoryID") {
        //         col.response[i]["columnDefaultValue"] = formdata["Category"]
        //         if (col.response[i]["columnDefaultValue"] == null && formdata["Category"] == undefined) {
        //             alert(col.response[i]["columnName"] +" is Required");
        //         }
        //         else {
        //}}
                    fetch(
                        baseURL + "/api/Dynamic/AddUpdateRecords?LoginType=2&tabErpID=792",
                        {
                            method: "POST",
                            headers: {
                                "Authorization-Token": authToken,
                                Accept: "application/json, text/plain",
                                "Content-Type": "application/json;charset=UTF-8",
                            },
                            body: JSON.stringify([formdata]),
                        }
                    ).then((result) => {
                        result.json().then((resp) => {
                            if (resp.hasErrors === false) {
                                alert(resp.response.message)
                                window.location.reload();
                            }
                            else {
                                alert(resp.errors);
                            }
                        });
                    });
             
           

        


    }

    function Fun_Cancel() {
        window.location.href = "/admin/dashboard";
    }
    return (
        <div className="admin-panel-section">
            <Headerinner />
            <div className="admin-body-section admin-div-1">
                <Container fluid>
                    <Row>
                        <Col xs={12} sm={12} md={3} lg={2}>
                            <SideBar />
                        </Col>
                        <Col xs={12} sm={12} md={9} lg={10} className="right-side-section">
                            <div className="add-sevice-section-2">
                                <Form className="addservice-form">
                                    <Row>

                                        <Col xs={12} sm={12} md={12}>
                                            <h2>Add Category</h2>

                                        </Col>
                                        {col?.response?.map((inputtype, i) => {
                                            if (inputtype.addType === "TextBox") {
                                                return (
                                                    <>
                                                        <Col xs={12} sm={6} md={3}> </Col>
                                                        <Col xs={12} sm={6} md={7}>
                                                            <TextField
                                                                id="outlined-basic"
                                                                label={inputtype.importName
                                                                }
                                                                variant="outlined"
                                                                className="addservice-1"
                                                                onChange={(e) => handleInputChange(e, inputtype.columnName)}

                                                            />
                                                        </Col>
                                                        <Col xs={12} sm={6} md={2}> </Col>
                                                    </>
                                                )
                                            }
                                            if (inputtype.addType === "Dropdown") {
                                                return (
                                                    <>
                                                        <Col xs={12} sm={6} md={3}> </Col>
                                                        <Col xs={12} sm={6} md={7}>
                                                            <FormControl fullWidth>
                                                                <InputLabel
                                                                    id="demo-simple-select-label"
                                                                    variant="outlined"
                                                                >
                                                                    {inputtype.displayName}
                                                                </InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"

                                                                    variant="outlined"
                                                                    label={inputtype.displayName}
                                                                    onChange={(e) => handleInputChange(e, inputtype.displayName)}
                                                                >
                                                                    <MenuItem value="">Select an option</MenuItem>
                                                                    {inputtype["dropDownList"]?.map((dd, j) => {
                                                                        return (
                                                                            <MenuItem key={j} value={dd}>
                                                                                {dd}
                                                                            </MenuItem>
                                                                        );
                                                                    })}
                                                                </Select>
                                                            </FormControl>
                                                        </Col>
                                                        <Col xs={12} sm={6} md={2}> </Col>
                                                    </>
                                                )
                                            }

                                            if (inputtype.addType === "PicFile") {

                                                return (
                                                    <>
                                                        <Col xs={12} sm={6} md={3}> </Col>
                                                        <Col xs={12} sm={6} md={7}>
                                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1"
                                                            >
                                                                <Form.Control type="file" placeholder="Upload Image"
                                                                    className="upload-image" onChange={handleFile} id="inputGroupFile01"
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs={12} sm={6} md={2}>
                                                            {
                                                                CategoryImg != null ? <img src={CategoryImg} alt="icon" class="upload-image-2" />
                                                                : <img src="../../assets/images/profile.jpeg" alt="icon" class="upload-image-2" />
                                                            }
                                                        </Col>
                                                    </>
                                                )
                                            }

                                            if (inputtype.addType === "TextArea") {
                                                return (
                                                    <>
                                                        <Col xs={12} sm={6} md={3}> </Col>
                                                        <Col xs={12} sm={6} md={7}>
                                                            <TextField
                                                                id="outlined-multiline-static"
                                                                label="Enter Message"
                                                                multiline
                                                                className="addservice-1"
                                                                rows={4}
                                                                onChange={(e) => handleInputChange(e, inputtype.columnName)}
                                                            />
                                                        </Col>
                                                        <Col xs={12} sm={6} md={2}> </Col>
                                                    </>
                                                )
                                            }

                                        })}

                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={6} md={12}>
                                            <Stack
                                                spacing={2}
                                                direction="row"
                                                className="submit-service"
                                            >
                                                <Button variant="outlined" onClick={Fun_Cancel}>Cancel</Button>
                                                <Button variant="contained" onClick={fun_AddCategoryData}>Submit</Button>
                                            </Stack>
                                        </Col>


                                    </Row>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}
export default AddCategory
