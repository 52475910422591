/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { Link, json } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import axios from 'axios';
import moment from 'moment'

function AddSUB_SUBCategory() {
    var userLogininfo = JSON.parse(localStorage.getItem("user-Logininfo"));
    console.log(userLogininfo);
    if (userLogininfo === null) {
        window.location.href = '/';
    }
    else {
        if (userLogininfo["User Group"] !== "Employee") {
            window.location.href="/not-found";
        }

    }
    let baseURL = `${process.env.REACT_APP_BASE_URL}`;
    let authToken = localStorage.getItem("Auth-Token").replace(/"/g, '');

    const date = {
        position: "relative",
        width: "100%",
        display: "block",
        lineHeight: "1.5px",
        padding: "15px 20px",
        fontSize: "15px",
        color: "#696969",
        background: "#F0F5F7",
        border: "1px solid #ced4da",
        boxSizing: "borderBox",
        borderRadius: "8px",
        transition: "all 300ms ease",
    };

    const [CategoryImg, setCategoryImg] = useState(null);
    const [col, setcol] = useState("");
    const [formdata, setformdata] = useState({});
    // eslint-disable-next-line no-unused-vars
    const [file, setFile] = useState("");
    const [category, setcategory] = useState({});
    const [CategoryList, setCategoryList] = useState([]);
    const [SubCategoryList, setSubCategoryList] = useState([]);
    const [CategoryDropdown, setCategoryDropdown] = useState([]);

    useEffect(() => {
        getcoldata();
        fun_CategoryList();
        fun_getsubcategory();
    }, []);

    const fun_getsubcategory = () => {
        const CategoryPra =
        {
            "pgSize": 100,
            "viewFormat": "gwk",
            "filterButtons": "",
            "index": 1,
            "toEmailID": "designMonu@dialerp.com",
            "tabErpID": 793,
            "useAtlusSearch": false,
            "filters": "{}"
        }
        fetch(
            "https://api.dialerp.com/api/Dynamic/GetRecords",
            {
                method: "POST",
                headers: {
                    "Authorization-Token": authToken,
                    Accept: "application/json, text/plain",
                    "Content-Type": "application/json;charset=UTF-8",
                },
                body: JSON.stringify(CategoryPra),
            }
        ).then((result) => {
            result.json().then((resp) => {

                if (resp.hasErrors === false) {
                    if (resp.response?.records !== null) {
                        setCategoryList(resp.response);
                    }
                    else {
                        setCategoryList("");
                    }
                }
                else {
                    alert(resp.errorCode, "3");
                }
            });
        });
    }

    const handleInputChange = (e, fieldName) => {

        const { value } = e.target;
        if (fieldName === "GoButtonField") {

            // For GoButton dropdown, directly set the value to formdata

            setformdata({

                ...formdata,

                Category: value,

            });

        } else {

            // For other fields, handle them as usual

            setformdata({

                ...formdata,

                [fieldName]: value,

            });

        }

        setformdata({
            ...formdata,
            [fieldName]: value,
        });
    };
    const getcoldata = async () => {
        const options = {
            method: "POST",
            url: "https://api.dialerp.com/api/Dynamic/GetInputColumns?appname=web&tmpUId=40398&tmpPId=40398",
            headers: {
                "Authorization-Token": authToken,
                Accept: "application/json, text/plain",
                "Content-Type": "application/json;charset=UTF-8",
            },
            data: {
                filterButtons: "",
                recordErpID: 0,
                tabErpID: 794,
                toEmailID: "designMonu@dialerp.com",
            },
        };
        try {
            const response = await axios.request(options);
            setcol(response.data);
        } catch (error) {
            console.error(error);
        }
    };
    const handleFile = (event) => {

        setFile(URL.createObjectURL(event.target.files[0]));
        const formData = new FormData();
        formData.append("CategoryPic", event.target.files[0]);
        fetch(
            "https://dialerpwebapis-dev-as.azurewebsites.net" +
            "/api/UploadData/getWebUploadImgUrlData?PartyAcTBSr=" +
            "&parentID=40004&userID=40004",
            {
                method: "POST",
                headers: { HttpHeaders: "multipart/form-data" },
                body: formData,
            }
        ).then((result) => {
            result.json().then((resp) => {
                setformdata({ ...formdata, CategoryPic: resp.response });
                setCategoryImg(resp.response, "ggg");
            });
        });
    };
    function fun_AddCategoryData() {
        fetch(
            "https://api.dialerp.com/api/Dynamic/AddUpdateRecords?LoginType=2&tabErpID=794",
            {
                method: "POST",
                headers: {
                    "Authorization-Token": authToken,
                    Accept: "application/json, text/plain",
                    "Content-Type": "application/json;charset=UTF-8",
                },
                body: JSON.stringify([formdata]),
            }
        ).then((result) => {
            result.json().then((resp) => {
                if (resp.hasErrors === false) {
                    alert(resp.response.message, "1")
                    window.location.reload();
                }
                else {
                    alert(resp.errors, "2");
                }
            });
        });

    }
    function fun_CategoryList() {
        const CategoryPra = {
            "pgSize": 100,
            "viewFormat": "gwk",
            "filterButtons": "",
            "index": 1,
            "toEmailID": "designMonu@dialerp.com",
            "tabErpID": 794,
            "useAtlusSearch": false,
            "filters": "{}"
        }

        fetch(
            "https://api.dialerp.com/api/Dynamic/GetRecords",
            {
                method: "POST",
                headers: {
                    "Authorization-Token": authToken,
                    Accept: "application/json, text/plain",
                    "Content-Type": "application/json;charset=UTF-8",
                },
                body: JSON.stringify(CategoryPra),
            }
        ).then((result) => {
            result.json().then((resp) => {

                if (resp.hasErrors === false) {
                    if (resp.response?.records !== null) {
                        setCategoryList(resp.response);
                    }
                    else {
                        setCategoryList("");
                    }
                }
                else {
                    alert(resp.errors, "3");
                }
            });
        });
    }

    const bindDropdown = (field) => {
        var dataMapper = {};
        dataMapper = {
            "CollectionName": "FINANCECONNECT_SubCategory",
            "filter": `{'${field}':/^null/i}`,
            "project": "{'SubCategory':'$SubCategory','Category':'$Category'}"
        }
        console.log(dataMapper);
        var formData =
        {
            "useAtlusSearch": false,
            "userID": 40396,
            "filters": "{tabErpID:794}",
            "index": 1,
            "isDownload": false,
            "toEmailID": "designMonu@dialerp.com",
            "tabErpID": 794,
            "dataMapper": dataMapper

        }

        fetch(
            "https://api.dialerp.com/api/dynamic/AutoFillMapperWise?appname=web&tmpUId=40396&tmpPId=40396",
            {
                method: "POST",
                headers: {
                    "Authorization-Token": authToken,
                    Accept: "application/json, text/plain",
                    "Content-Type": "application/json;charset=UTF-8",
                },
                body: JSON.stringify(formData),
            })
            .then((result) => {
                result.json().then((resp) => {
                     
                    if (resp.hasErrors === true) {
                        alert(resp.errors);

                    }
                    else {
                        if (resp.response?.records !== null) {
                             ;
                            if (field === "SubCategory") {

                                setCategoryDropdown(resp.response?.records)
                            }



                            //toastrr.success(locationDropdown);
                        }
                        //setimgurl_(response.data.response[0].imageUrl);
                    }
                }
                )
            });
    }
    const setcaregory = (obj) => {
        setCategoryDropdown(obj);
    }
    return (
        <>
            <section className="page-title mrt-70">
                <div className="auto-container">
                    <div className="title-outer" style={{ marginTop: "20px", marginLeft: "-8%" }}>
                        <h2>Add Sub Sub-Category</h2>

                    </div>
                    <div className="title-outer">
                    </div>
                </div>
            </section>
            <section className="job-detail-section">
                <div className="job-detail-outer">
                    <div className="auto-container" style={{ width: "800px" }}>
                        <div className="outer-box care-padding">

                            <div className="login-form default-form" >
                                <div className="form-inner" style={{ padding: "15px" }}>

                                    <div className="tableresp">
                                        <div className="login-form default-form">

                                            <div className="row">
                                                {col?.response?.map((inputtype, i) => {
                                                    if (inputtype.addType === "PicFile") {

                                                        return (
                                                            <>
                                                                <div className="form-group col-lg-6 col-md-6" style={{ marginLeft: "60%" }}>
                                                                    <label style={{ marginLeft: "-74%" }}>{inputtype.displayName}</label>
                                                                    <input type="file" accept=".png, .jpg, .jpeg" className="form-control" id="inputGroupFile01" onChange={handleFile} />
                                                                </div>

                                                                <br></br>

                                                                <div className="form-group col-lg-6 col-md-6" style={{ marginLeft: "60%" }}>
                                                                    {
                                                                        CategoryImg != null ? <img src={CategoryImg ? CategoryImg : '../assets/images/no-image.jpg'} alt="Profile Image" style={{ height: "150px", width: "150px" }} /> :
                                                                            ""
                                                                        // <img src='../assets/images/no-image.jpg' alt="Profile Image" style={{ height: "150px", width: "150px" }} 


                                                                    }

                                                                </div>
                                                            </>

                                                        )

                                                    }
                                                    if (inputtype.addType === "TextBox") {
                                                        return (
                                                            <>
                                                                <div className="form-group col-lg-6 col-md-6"></div>
                                                                <div className="form-group col-lg-6 col-md-6" style={{ marginLeft: "60%" }}>
                                                                    <label style={{ marginLeft: "-79%" }}>{inputtype.displayName}<span style={{ color: "red" }}>*</span></label>
                                                                    <input className="form-control" type="text" placeholder={`Enter ${inputtype.displayName}`} onChange={(e) => handleInputChange(e, inputtype.columnName)} />
                                                                </div>
                                                                <br></br>
                                                            </>
                                                        )
                                                    }
                                                    if (inputtype.addType === "TextArea") {
                                                        return (
                                                            <>
                                                                <div className="form-group col-lg-6 col-md-6" style={{ marginLeft: "60%" }}>
                                                                    <label style={{ marginLeft: "-70%" }}>{inputtype.displayName}</label>
                                                                    <input className="form-control" type="text" placeholder={`Enter ${inputtype.displayName}`} onChange={(e) => handleInputChange(e, inputtype.columnName)} />
                                                                </div>
                                                                <br></br>
                                                            </>
                                                        );
                                                    }
                                                    if (inputtype.addType === "GoButton") {
                                                        return (
                                                            <>
                                                                <div className="form-group col-lg-6 col-md-6" style={{ marginLeft: "60%" }}>
                                                                    <label style={{ marginLeft: "-83%" }}>{inputtype.displayName}</label><br></br>
                                                                    {/* <select onChange={(e) => handleInputChange(e, inputtype.displayName)} style={date}>
                                                                        <option value="">Select an option</option>
                                                                        {inputtype["dropDownList"]?.map((dd, j) => {
                                                                            return (
                                                                                <option key={j} value={dd}>
                                                                                    {dd}
                                                                                </option>
                                                                            );
                                                                        })}
                                                                    </select> */}

                                                                    {/* <select className={`form-control`} style={{ backgroundColor: "#ffffff" }}
                                                                        id="SubCategory" onClick={(e) => bindDropdown('SubCategory')} onChange={(e) => { handleInputChange(e, inputtype.columnName); }}> */}
                                                                        <select style={date}
                                                                            onChange={(e) => handleInputChange(e, "GoButtonField")}
                                                                            value={formdata.Category} // Set the selected value
                                                                        >
                                                                            <option value={""}>Please Select One</option>
                                                                            {CategoryDropdown?.map((status, i) => {
                                                                                return (<>
                                                                                    <option value={JSON.stringify(status.SubCategory)}>{status.SubCategory}</option>

                                                                                </>
                                                                                );
                                                                            })}
                                                                        </select>
                                                                </div>
                                                            </>
                                                        );
                                                    }
                                                    if (inputtype.addType === "ReadOnly") {
                                                        return (
                                                            <>
                                                                <div className="form-group col-lg-6 col-md-6"></div>
                                                                <div className="form-group col-lg-6 col-md-6" style={{ marginLeft: "60%" }}>
                                                                    <label style={{ marginLeft: "-79%" }}>{inputtype.displayName}<span style={{ color: "red" }}>*</span></label>
                                                                    <input className="form-control" type="text" disabled="true" placeholder={`Enter ${inputtype.displayName}`} onChange={(e) => handleInputChange(e, inputtype.columnName)} />
                                                                </div>
                                                                <br></br>
                                                            </>
                                                        )
                                                    }

                                                })}
                                            </div>

                                        </div>
                                        <div className="form-group col-lg-12 col-md-12" s
                                            tyle={{ marginTop: "2%", marginLeft: "36%" }}>
                                            <button className="btn btn-success" style={{ width: "12%" }}
                                                onClick={fun_AddCategoryData}>Submit</button>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>

                <div className="tableresp">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Sr.</th>
                                <th scope="col">Create Dt</th>
                                <th scope="col">Category Pic</th>
                                <th scope="col">Sub CategoryID</th>
                                <th scope="col">Sub Sub-Category</th>
                                <th scope="col"> SUB Category</th>
                                <th scope="col">Category</th>

                            </tr>
                        </thead>
                        <tbody>
                            {CategoryList?.records?.map((status, i) => {
                                i++;
                                return (
                                    <tr>
                                        <th scope="row">{i}</th>
                                        <td>{moment(status["Create Dt"]).format("DD-MMM-YYYY hh:mm:ss")}</td>
                                        <td>

                                            <img src={status["Category Pic"]} alt="Profile Image"
                                                style={{ height: "100px", width: "100px" }} />
                                        </td>
                                        <td>{status["Sub CategoryID"]}</td>
                                        <td>{status["Sub Sub Category"]?.["Sub Sub Category"]}</td>
                                        <td>{status["SubCategory"]}</td>
                                        <td>{status["Category"]}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </section>
        </>
    )
}
export default AddSUB_SUBCategory
