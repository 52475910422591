import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
import Button from "@mui/material/Button";
import RepeatIcon from "@mui/icons-material/Repeat";
import OrderTimeDetail from "./OrderTimeDetail";

function TrackLocation() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="order-details">
      <Header />
      <div className="top-space">
        <Container>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <div className="track-order-location">
                <h2 className="section-order-details-h3">Track Service</h2>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>

                    <div className="my-order-inner">
                      <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                         <OrderTimeDetail />
                        </Col>
                        
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  );
}


export default TrackLocation
