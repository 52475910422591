import React, { useEffect, useState } from "react";
import CryptoJS from 'crypto-js';
function DecryptN() {
  let baseTokenKey = `${process.env.REACT_APP_BASE_KEY}`.replace(/'/g, "");
  let authToken = `${process.env.REACT_APP_BASE_AUTHKEY}`;
  let baseURL = `${process.env.REACT_APP_BASE_URL}`;
 const AuthkeyTEXT="4Y4x1pWJuhV506cJmmqKWOCXNrkl72EFWiO/BeQV+PLgAoKYCYRSK1eUrQeZyeazC0Y0L6rTc4vZUD3LiWm0O7ZfrARK57zu2crCxV66v2g="
 
  const key = "0e628f15575e4a71";//"0e628f15575e4a7189ec11a50301bb70";
  const [Mobile, setMobile] = useState('8952894790');

  function fun_GetUserInfo(id,token) {

      const loginPra ={
        "tabErpID": "1429",
        "filters": `{'_id':${id}}`
    }
      fetch(
        baseURL + "/api//Dynamic/GetRecords",
        {
          method: "POST",
          headers: {
            "Authorization-Token": token,
            Accept: "application/json, text/plain",
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: JSON.stringify(loginPra),
        }
      ).then((result) => {
        result.json().then((resp) => {
          if (resp?.response!== null) {
            if (resp?.response?.records!== null) {
            const logindata=resp?.response?.records[0];
              localStorage.setItem("user-Logininfo", JSON.stringify(logindata));}
          }
          else {
            alert("Invalid request!!!! .");
          }

        });
      });
    
  }

  const mobileLoginVerifyOTPMongo = (otp) => {
    if (Mobile.trim() === "") {
      alert('Please enter username');
      return;
    }
    else {
      const VerifyPra = {
        "baseTokenKey": AuthkeyTEXT,
        "EmailID": "",
        "appVersion": "1",
        "deviceOS": "Web",
        "mobile": Mobile,
        "tabErpID": "1429",
        "otp": otp,
        "appVersionName": "1",
        "deviceCountry": "India",
        "deviceID": "Web",
        "deviceName": "xiaomi Mi A2",
        "imeiNumber": "not found",
        "countryCode": "91",
        "countryName": "India"
    }
      
      fetch(
        baseURL + "/api/DynamicApp/mobileLoginVerifyOTPMongo",
        {
          method: "POST",
          headers: {
            "Authkey": AuthkeyTEXT,
            Accept: "application/json, text/plain",
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: JSON.stringify(VerifyPra),
        }
      ).then((result) => {
        result.json().then((resp) => {
          console.log(resp,"mobileLoginVerifyOTPMongo")
          if (resp.hasErrors === false) {
            //alert(resp.hasErrors)
            localStorage.setItem("Auth-Token", JSON.stringify(resp.response.loginKey));
            localStorage.setItem("user-parentID", JSON.stringify(resp.response.parentID));
            fun_GetUserInfo(resp.response.partySr,resp.response.loginKey);
          }
          else {
            alert(resp.errors);
          }
        });
      });
    }
  };

  const mobileSendOtp = (otp) => {
    if (Mobile.trim() === "") {
      alert('Please enter username');
      return;
    }
    else {
      const SMSPra = {
        "senderID": "DALERP",
        "dltEntityID": "1201159187215982418",
        "mobile": "7840022603",
        "smsContent": `Otp for signin App is ${otp} \nDIALERP Team`,
        "templateID": "1207161521868534072",
        "parentID": "40396"
      }
      mobileLoginVerifyOTPMongo(otp)
      return
      fetch(
        baseURL + "/api/AppApi/SendSMSOTP",
        {
          method: "POST",
          headers: {
            "Authorization-Token": authToken,
            Accept: "application/json, text/plain",
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: JSON.stringify(SMSPra),
        }
      ).then((result) => {
        result.json().then((resp) => {
          if (resp.hasErrors === false) {
            alert("Otp sent successfully")
            //alert(resp.response)
          }
          else {
            alert("Invalid request!!!.");
          }
        });
      });
    }
  };

  const DecryptN1 = (encryptedText, key) => {
    const encryptedBytes = CryptoJS.enc.Base64.parse(encryptedText);
    const decryptedHex = CryptoJS.AES.decrypt(
      { ciphertext: encryptedBytes },
      CryptoJS.enc.Utf8.parse(key),
      {
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
        iv: CryptoJS.enc.Utf8.parse(key),
        keySize: 128,
        blockSize: 128,
      }
    );
    const decryptedText = CryptoJS.enc.Utf8.stringify(decryptedHex);
    mobileSendOtp(decryptedText);
    // return decryptedText;
  };
  const mobileSendOtpMongo = () => {
    if (Mobile.trim() === "") {
      alert('Please enter username');
      return;
    }

    else {
      const loginPra = {
        "baseTokenKey":AuthkeyTEXT,
        "resend": "0",
        "mobile": Mobile
      }
      fetch(
        baseURL + "/api/DynamicApp/mobileSendOtpMongo",
        {
          method: "POST",
          headers: {
            "Authkey": AuthkeyTEXT,
            Accept: "application/json, text/plain",
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: JSON.stringify(loginPra),
        }
      ).then((result) => {
        result.json().then((resp) => {
          if (resp.hasErrors === false) {
           // localStorage.setItem("decryptedPassword", JSON.stringify(resp.response));
            //const decryptedText =
            DecryptN1(resp.response, key);
            //console.log(decryptedText, "decryptedText");
          }
          else {
            alert("Invalid request!!.");
          }
        });
      });
    }
  };
  const checkMobileUserMongoDynamic = () => {
    //var redirect = localStorage.getItem("CardLogin");
    //event.preventDefault();
    if (Mobile.trim() === "") {
      alert('Please enter username');
      return;
    }
    else {
      const checkMobileUserPra = {
        "baseTokenKey": AuthkeyTEXT,
        "EmailID": "",
        "appVersion": "1",
        "imeiNumber": "not found",
        "countryCode": "91",
        "deviceOS": "Web",
        "mobile": Mobile,
        "countryName": "India",
        "appVersionName": "1",
        "deviceCountry": "India",
        "deviceID": "6b068d3854a26559",
        "deviceName": "Web"
      }
      fetch(
        baseURL + "/api//DynamicApp/checkMobileUserMongoDynamic",
        {
          method: "POST",
          headers: {
            "Authkey": AuthkeyTEXT,
            Accept: "application/json, text/plain",
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: JSON.stringify(checkMobileUserPra),
        }
      ).then((result) => {
        result.json().then((resp) => {
          if (resp.hasErrors === false) {
            mobileSendOtpMongo();
          }
          else {
            alert("Invalid mobile number!.");
          }

        });
      });
    }
  }

  useEffect(() => {
    checkMobileUserMongoDynamic();
  }, [0]);

  return (

    <>
      <p>TEST</p>
    </>
  );
}
export default DecryptN;
