import React, { useState, useEffect, useContext } from "react";
import { ProductContext } from "./context/AppContext";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { Link } from "react-router-dom";

import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import DialogContentText from "@mui/material/DialogContentText";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
function MainCategory() {
  let baseURL = `${process.env.REACT_APP_BASE_URL}`;
  let baseTokenKey = `${process.env.REACT_APP_BASE_KEY}`.replace(/'/g, "");
  let authToken = `${process.env.REACT_APP_BASE_AUTHKEY}`;
  const { CategoryList,SubCategoryListData } = useContext(ProductContext);
  const [CategoryHeading, sethandleInputChange] = useState();
  const [Categoryid, setCategoryid] = useState(0);
  const [open, setOpen] = React.useState(false);
  function fun_getSubCategoryclick(id, Category) {
    fun_SubCategoryList(Category);
    sethandleInputChange(Category);
    setCategoryid(id);
  }
  const [SubCategoryList, setSubCategoryList] = useState([]);
  const fun_SubCategoryList = (id) => {
    var data=SubCategoryListData?.records;
    const SubCategoryListData1=data?.filter(x => x.Category === id)|| [];
    setSubCategoryList(SubCategoryListData1);
    setOpen(true);
    // let CategoryName = '{Category:"' + id + '"}';
    // const CategoryPra = {
    //   pgSize: 100,
    //   filterButtons: "",
    //   index: 1,
    //   toEmailID: "designMonu@dialerp.com",
    //   tabErpID: 793,
    //   useAtlusSearch: false,
    //   filters: CategoryName,
    // };

    // fetch(baseURL + "/api/Dynamic/GetRecords", {
    //   method: "POST",
    //   headers: {
    //     "Authorization-Token": authToken,
    //     Accept: "application/json, text/plain",
    //     "Content-Type": "application/json;charset=UTF-8",
    //   },
    //   body: JSON.stringify(CategoryPra),
    // }).then((result) => {
    //   result.json().then((resp) => {
    //     if (resp.hasErrors === false) {
    //       if (resp.response?.records !== null) {
    //         setSubCategoryList(resp.response);
    //         setOpen(true);
    //       } else {
    //         setSubCategoryList("");
    //         setOpen(true);
    //       }
    //     } else {
    //       alert(resp.errors);
    //     }
    //   });
    // });
  }
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>

        <Row>
          {CategoryList?.records?.map((status, index) => {
            index++;
            return (
              <Col xs={4} md={4} key={(index)}>
                 <a onClick={() => fun_getSubCategoryclick(status["CategoryID"], status["Category"])}>
                  {/* onClick={() => fun_getSubCategoryclick(status["CategoryID"], status["Category"])} */}
                  <div className="banner-div-5">
                  <div className="">

                  <div className="banner-div-1-banner" key={index}> 
                  {status["Pic"]?
                  <img src={status["Pic"]} alt="icon" className="banner-icon-100" />:
                  <img src="../../../../../assets/images/photo-album-icon.png" alt="icon" className="banner-icon-100" />
                 }
                   </div>
                  <p>{status["Category"]}</p>
                  </div>
                  </div>
                </a>
              </Col>
            );
          })}

        </Row>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        className="category-popup"
        BackdropProps={{
          style: { backgroundColor: 'rgba(0, 0, 0, 0.9)' }, 
        }}
      >
        <DialogTitle>
          {" "}
          {CategoryHeading}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Container className="popup-p">
              <Row>
                {SubCategoryList?.map((Substatus, i) => {
                  i++;
                  return (
                    <Col xs={4} md={4} key={i}>
                      <Link to={`/service/${Substatus["Category"]}/${Substatus["Sub Category"]}`}
                        onClick={(e) => localStorage.setItem("SubcategoryName", Substatus["Sub Category"])}>
                          <div className="banner-div-hover-2">
                          <div className="">
                        <div className="banner-div-1-banner" key={i}>
                          <img src={Substatus["Pic"]} alt="icon" className="banner-icon-100" />
                        </div>
                        <p>{Substatus["Sub Category"]}</p>
                        </div>
                        </div>
                      </Link>

                    </Col>
                  );
                })}
              </Row>
            </Container>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default MainCategory
