import React, { useState, useEffect, useContext } from "react";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import RemoveSharpIcon from "@mui/icons-material/RemoveSharp";
import { ProductContext } from "../context/AppContext";

const ShoppingCart = ({ cartItems }) => {
  const { CardList, setCardList, totalAmont } = useContext(ProductContext);
  const fun_addtocartItem = (data, i) => {
    let fltCard = CardList.filter((item) => item["ItemID"] === data["ItemID"]);
    if (fltCard.length > 0) {
      const updatedCard = CardList.map((item) => {
        if (item["ItemID"] === data["ItemID"]) {
          const qutcount = item["Quanity"] + 1;
          let itemtotal = parseFloat(
            parseFloat(data?.data["MRP"] || 0) * qutcount
          );
          return {
            ...item,
            ItemID: data["ItemID"],
            ItemTotal: itemtotal,
            Quanity: qutcount,
          };
        }
        return item;
      });
      setCardList(updatedCard);
      localStorage.setItem("CartItemms", JSON.stringify(updatedCard));
    } else {
      const updatedCard = [
        ...CardList,
        {
          data,
          ItemID: data["ItemID"],
          ItemTotal: parseFloat(data["MRP"]),
          Quanity: 1,
        },
      ];
      setCardList(updatedCard);
      localStorage.setItem("CartItemms", JSON.stringify(updatedCard));
    }
  };

  const fun_RemovetocartItem = (data, i) => {
    let fltCard = CardList.filter((item) => item["ItemID"] === data["ItemID"]);
    if (fltCard[0]["Quanity"] > 1) {
      const updatedCard = CardList.map((item) => {
        if (item["ItemID"] === data["ItemID"]) {
          const qutcount = item["Quanity"] - 1;
          let itemtotal = parseFloat(
            parseFloat(data?.data["MRP"] || 0) * qutcount
          );
          return {
            ...item,
            ItemID: data["ItemID"],
            ItemTotal: itemtotal,
            Quanity: qutcount,
          };
        }
        return item;
      });
      setCardList(updatedCard);
      localStorage.setItem("CartItemms", JSON.stringify(updatedCard));
    } else {
      let fltCard = CardList.filter(
        (item) => item["ItemID"] !== data["ItemID"]
      );
      //const updatedCard = [...fltCard, { data, ItemID: data["ItemID"], Quanity: 1 }];
      setCardList(fltCard);
      localStorage.setItem("CartItemms", JSON.stringify(fltCard));
    }
  };
  // const [totalAmont, settotalAmont] = useState(0);
  // useEffect(() => {
  //     fun_CaltotalAmount();
  // }, [CardList]);
  const AppointmentobjData = JSON.parse(localStorage.getItem("Appointmentobj"));
  const DeliveryAddressOBJData = JSON.parse(
    localStorage.getItem("deliveryaddress")
  );

  // const fun_CaltotalAmount = () => {
  //     let totalAmount = 0;
  //     CardList.forEach(data => {
  //         totalAmount += parseFloat(data["ItemTotal"]);
  //     });
  //     settotalAmont(totalAmount);
  // }

  // const getcp = window.location.href
  // localStorage.setItem("getcp", JSON.stringify(getcp));
  return (
    <div>
      {CardList.length === 0 ? (
        <>
          <div className="empty-cart">
            <img src="../../assets/images/shopping2.png" width="100%" />
            <p>No items in your cart</p>
          </div>
        </>
      ) : (
        <div className="empty-cart empty_cart_New-18">
          <div className="cart-div-25 cart-div-New-18">
            <h4>Cart</h4>
            {CardList?.map((data, maini) => {
              return (
                <div className="cart-div-22">
                  <h5 key={maini}>{data?.data["Item"]}</h5>
                  <div className="add-button-service">
                    <Button
                      variant="text"
                      onClick={() => fun_RemovetocartItem(data, maini)}
                    >
                      <RemoveSharpIcon className="add-icon-1" />
                    </Button>
                    <span>{data["Quanity"]}</span>
                    <Button
                      variant="text"
                      onClick={() => fun_addtocartItem(data, maini)}
                    >
                      <AddIcon className="add-icon-1" />
                    </Button>
                  </div>
                  <span className="price-span1 price-span22">
                    ₹{parseFloat(data?.data["MRP"] || 0) * data["Quanity"]}
                  </span>
                </div>
              );
            })}
          </div>
          <p className="add1div">
            Add 1 more service, Get 30% off 2nd one onwards
          </p>
          <div className="view-cart-1">
            <span className="price-span1">₹{totalAmont}</span>
            {AppointmentobjData !== null && DeliveryAddressOBJData !== null ? (
              <Link to="/customer/view-booking/1">View Cart</Link>
            ) : (
              <Link to="/customer/view-booking/0">View Cart</Link>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ShoppingCart;
