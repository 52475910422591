/* eslint-disable no-sequences */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { Link, useParams } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
import Button from "@mui/material/Button";
import RepeatIcon from "@mui/icons-material/Repeat";
import Stack from "@mui/material/Stack";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import ClearIcon from "@mui/icons-material/Clear";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarRateIcon from "@mui/icons-material/StarRate";
import Form from "react-bootstrap/Form";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import CallIcon from "@mui/icons-material/Call";
import moment from "moment";
import WhereToVoteOutlinedIcon from "@mui/icons-material/WhereToVoteOutlined";
import WalletOutlinedIcon from "@mui/icons-material/WalletOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";

import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { ProductContext } from "../context/AppContext";
import FooterAfterLogin from "../FooterAfterLogin";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function OrderDetails() {
  const { BookingID } = useParams();

  const {
    fun_CancellationReason,
    setCancellationReason,
    CancellationReason,
    date,
    showTime,
    handleClickLoader,
    handleCloseLoader,
    CardList,
    setCardList,
  } = useContext(ProductContext);

  var userLogininfo = JSON.parse(localStorage.getItem("user-Logininfo"));
  let authToken = localStorage.getItem("Auth-Token").replace(/"/g, "");
  if (userLogininfo === null || authToken === null) {
    window.location.href = "/";
  } else {
    if (userLogininfo["User Group"] !== "Customer") {
      window.location.href = "/not-found";
    }
  }

  let baseURL = `${process.env.REACT_APP_BASE_URL}`;
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    fun_OrderDetail(BookingID);
  }, [BookingID]);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [open2, setOpen2] = React.useState(false);
  const handleClickOpen2 = () => {
    setOpen2(true);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };
  const [openReview, setOpenReview] = React.useState(false);
  const handleClickOpenReview = () => {
    setOpenReview(true);
  };
  const handleCloseReview = () => {
    setOpenReview(false);
  };
  const [paymentconfirm, setpaymentconfirm] = React.useState(false);
  const handleClickOpenpaymentconfirm = () => {
    setpaymentconfirm(true);
  };
  const handleClosepaymentconfirm = () => {
    setpaymentconfirm(false);
  };
  const [openReschedule, setOpenReschedule] = React.useState(false);
  const handleClickOpenReschedule = () => {
    setOpenReschedule(true);
  };
  const handleCloseReschedule = () => {
    setOpenReschedule(false);
  };
  const [openRescheduleDone, setOpenRescheduleDone] = React.useState(false);
  const handleClickOpenRescheduleDone = () => {
    if (AppointmentTime === "") {
      alert("Please select appointment time");
      return;
    } else {
      setOpenRescheduleDone(true);
    }
  };
  const handleCloseRescheduleDone = () => {
    setOpenRescheduleDone(false);
  };
  const [ReasonRescheduling, setReasonRescheduling] = useState("");
  function fun_ReasonforRescheduling(id) {
    if (ReasonRescheduling === "") {
      alert("Choose reason for rescheduling");
    } else {
      let apdate = moment(value).format("DD-MMM-YYYY");
      let AppointmentDt = apdate + " " + time24;
      console.log(OrderDetails);
      const formData = [
        {
          CustMobile: userLogininfo["Login Mobile"],
          CustName: userLogininfo["User Name"],
          CustID: userLogininfo["UserID"],
          CustGroup: userLogininfo["User Group"],
          BookingID: id,
          AppointmentDt: AppointmentDt,
          Status: "Open",
          Stage: "Booking Assigned",
          BookingType: "Fresh",
          ProfessionalMobile: "$unset",
          ProfessionalID: "$unset",
          Professional: "$unset",
          ProfessionalGroup: "$unset",
          ProfessionalAssignedDt: "$unset",
          ApproverName: "$unset",
          ApproverID: "$unset",
          AddressType: OrderDetails?.[0]?.["Address Type"],
          Address: OrderDetails?.[0]?.["Address"],
          Pincode: OrderDetails?.[0]?.["Pincode"],
          ContactName: OrderDetails?.[0]?.["Cust Name"],
          ContactMobile: OrderDetails?.[0]?.["Cust Mobile"],
        },
      ];

      fetch(
        baseURL + "/api/Dynamic/AddUpdateRecords?LoginType=2&tabErpID=1258",
        {
          method: "POST",
          headers: {
            "Authorization-Token": authToken,
            Accept: "application/json, text/plain",
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: JSON.stringify(formData),
        }
      ).then((result) => {
        result.json().then((resp) => {
          if (resp.hasErrors === false) {
            alert("Reason for rescheduling accept successfully.");
            setOpenRescheduleDone(false);
            fun_OrderDetail(BookingID);
          } else {
            alert(resp.errors);
          }
        });
      });
    }
  }

  const [value, onChange] = useState(new Date());
  const [AppointmentTime, setAppointmentTime] = useState("");
  const today = new Date();
  const [time24, settime24] = useState(0);

  const nextThreeDays = new Date(today.setDate(today.getDate() + 30));
  today.setTime(today.getTime() + 2 * 60 * 60 * 1000);

  const time = today.toLocaleTimeString("en-US", {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
  });
  const currentDate = new Date().toISOString().split("T")[0];

  const AppointmentData = [
    { d: "08:00", value: "08:00 AM" },
    { d: "08:30", value: "08:30 AM" },
    { d: "09:00", value: "09:00 AM" },
    { d: "09:30", value: "09:30 AM" },
    { d: "10:00", value: "10:00 AM" },
    { d: "10:30", value: "10:30 AM" },
    { d: "11:00", value: "11:00 AM" },
    { d: "11:30", value: "11:30 AM" },
    { d: "12:00", value: "12:00 PM" },
    { d: "12:30", value: "12:30 PM" },
    { d: "13:00", value: "01:00 PM" },
    { d: "13:30", value: "01:30 PM" },
    { d: "14:00", value: "02:00 PM" },
    { d: "14:30", value: "02:30 PM" },
    { d: "15:00", value: "03:00 PM" },
    { d: "15:30", value: "03:30 PM" },
    { d: "16:00", value: "04:00 PM" },
    { d: "16:30", value: "04:30 PM" },
    { d: "17:00", value: "05:00 PM" },
    { d: "17:30", value: "05:30 PM" },
    { d: "18:00", value: "06:00 PM" },
    { d: "18:30", value: "06:30 PM" },
    { d: "19:00", value: "07:00 PM" },
    { d: "19:30", value: "07:30 PM" },
    { d: "20:00", value: "08:00 PM" },
    { d: "20:30", value: "08:30 PM" },
    { d: "21:00", value: "09:00 PM" },
  ];
  const handleSelect = (selectedItem, time24) => {
    setAppointmentTime(selectedItem);
    settime24(time24);
  };
  // var BookingID = localStorage.getItem("BookingID")
  const [OrderDetails, setOrderDetails] = useState("");
  const [Category, setCategory] = useState("");
  const [AppointmentDt, setAppointmentDt] = useState();
  const [sAppointmentTime, setsAppointmentTime] = useState();
  const [CancelDt, setCancelDt] = useState();
  const [CancelTime, setCancelTime] = useState();
  const [CustomerRemark, setCustomerRemark] = useState([]);
  const [Stage, setStage] = useState("");
  let totalAmount1 = 0;
  let TotalDiscount = 0;
  CustomerRemark?.CardDetails?.forEach((data) => {
    totalAmount1 += parseFloat(data.data["MRP"] || 0);
    TotalDiscount += parseFloat(data.data["SP"] || 0);
  });
  function fun_OrderDetail(BookingID) {
    handleClickLoader();
    if (BookingID === 0 && BookingID===null) {
      return;
    } else {
      let newusername = `{'BookingID': ${BookingID}}`;
      const getparam = {
        pgSize: 100,
        filterButtons: "",
        tabErpID: 1258,
        columnID: 1258014,
        statusID: 0,
        statusName: "All",
        color: "#262626",
        index: 1,
        toEmailID: "designMonu@dialerp.com",
        useAtlusSearch: true,
        filters: newusername,
      };
      fetch(baseURL + "/api/dynamic/GetRecords", {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(getparam),
      }).then((result) => {
        result.json().then((resp) => {
          if (resp.hasErrors === false) {
            console.clear();
            console.log(resp.response, "Booking Data");
            if (resp.response?.records !== null && resp.response?.records.length > 0) {                            
                handleCloseLoader();
                setOrderDetails(resp.response);
                for (let i = 0; i < resp.response?.records.length; i++) {
                  if (resp.response?.records[i]["ProfessionalID"] !== ""
                    && resp.response?.records[i]["ProfessionalID"] !== null
                    && resp.response?.records[i]["ProfessionalID"] !== undefined) {
                    fun_ServiceProviderList(resp.response?.records[i]["ProfessionalID"], authToken)
                  }
                }
                setCategory(resp.response?.records[0]["Category"]);
                //const date= new  Date(resp.response?.records[0]["Appointment Dt"],);
                const utcDate = new Date(
                  resp.response?.records[0]["Appointment Dt"]
                );
                const localDate = new Date(
                  utcDate.getTime() + utcDate.getTimezoneOffset() * 60000
                );
                const options = {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric",
                  hour12: false,
                };
                const formattedDate1 = localDate.toLocaleString("en-US", options);
                const formattedDate =
                  moment(formattedDate1).format("ddd, MMM DD");
                const formattedTime = moment(formattedDate1).format("hh:mm A");
                onChange(formattedDate1);
                setStage(resp.response?.records[0]["Stage"]);
                setAppointmentDt(formattedDate);
                setsAppointmentTime(formattedTime);
                setAppointmentTime(formattedTime);
                if (
                  resp.response?.records?.[0]?.["Customer Remarks"] !==
                  "[object Object]" &&
                  resp.response?.records?.[0]?.["Customer Remarks"] != null &&
                  resp.response?.records?.[0]?.["Customer Remarks"] !==
                  undefined &&
                  resp.response?.records?.[0]?.["Customer Remarks"] !== ""
                ) {
                  setCustomerRemark(
                    JSON.parse(resp?.response?.records?.[0]?.["Customer Remarks"])
                  );
                }
                if (
                  resp.response?.records?.[0]?.["Cancelled Dt"] !== "" &&
                  resp.response?.records?.[0]?.["Cancelled Dt"] != null &&
                  resp.response?.records?.[0]?.["Cancelled Dt"] !== undefined
                ) {
                  const CutcDate = new Date(
                    resp.response?.records[0]["Cancelled Dt"]
                  );
                  const ClocalDate = new Date(
                    CutcDate.getTime() + CutcDate.getTimezoneOffset() * 60000
                  );
                  const Coptions = {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    hour12: false,
                  };
                  const CformattedDate1 = ClocalDate.toLocaleString(
                    "en-US",
                    Coptions
                  );
                  const CformattedDate =
                    moment(CformattedDate1).format("ddd, MMM DD");
                  const CformattedTime =
                    moment(CformattedDate1).format("hh:mm A");
                  setCancelDt(CformattedDate);
                  setCancelTime(CformattedTime);
                  handleCloseLoader();
                } else {
                }
             
              
            } else {
              handleCloseLoader();
              fun_OrderDetail(BookingID);
            }
          } else {
            handleCloseLoader();
            alert(resp.errors);
          }
        });
      });
    }
  }

  // const fun_addtocartItem = () => {
  //     setCardList(CustomerRemark?.CardDetails);
  //     localStorage.setItem("CartItemms", JSON.stringify(CustomerRemark?.CardDetails ));
  //   }
  const [ServiceList, setServiceList] = useState("");
  const fun_ServiceProviderList = (ProfessionalID) => {
    if (ProfessionalID !== "") {
      let newusername = `{'UserID':${ProfessionalID}}`;
      const loginPra = {
        pgSize: 100,
        index: 1,
        toEmailID: "designMonu@dialerp.com",
        tabErpID: 1429,
        filters: newusername,
      };
      fetch(baseURL + "/api/dynamic/GetRecords", {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(loginPra),
      }).then((result) => {
        result.json().then((resp) => {
          if (resp.hasErrors === false) {
            if (resp.response?.records !== null) {
              if (resp.response?.records.length > 0) {
                setServiceList(resp?.response);
                handleCloseLoader();
              }
            }
          } else {

            alert(resp.errors);
          }
        });
      });
    }
  };
  return (
    <div className="order-details order-details-new">
      <Header />
      <div className="top-space">
        <Container fluid>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <div className="section-order-details section-order-details-orderdetail section-order-details-orderdetail_New_1">
                <Link to="/customer/my-bookings">
                  <KeyboardBackspaceIcon className="back-icon-arrow-orderdetail" />
                </Link>
                <h2 className="section-order-details-h3 section-order-details-h3-orderdetail">
                  {Category}
                  {/* {AppointmentDt} */}
                  <span>
                    {AppointmentDt && sAppointmentTime !== undefined
                      ? AppointmentDt + " at " + sAppointmentTime
                      : ""}
                  </span>
                  {/* {AppointmentDt + ' at ' + sAppointmentTime} */}
                  {/* <Button variant="outlined" >Invoice</Button> */}
                </h2>

                {/* <h6>{AppointmentDt}</h6> */}
              </div>
            </Col>
            <Col xs={12} sm={12} md={8} lg={8}>
              {/* <div className="section-order-details">
                <h2 className="section-order-details-h3">Booking Details</h2> */}
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  {OrderDetails?.records?.map((status, i) => {
                    if (status["Stage"] === "Booking Assigned") {
                      return (
                        <div className="section-order-details">
                          <div className="my-order-inner">
                            <Row>
                              <Col xs={6} sm={7} md={7} lg={9}>
                                {/* <h6>{moment(status["Appointment Dt"]).format("ddd, MMM DD") + ' at ' + ' 12:30 '}</h6> */}
                                <p className="booking-acceptted-1">
                                  {status["Stage"]}
                                </p>
                                <h4>Service Type : {status["Sub Category"]}</h4>
                                <h4>
                                  Service Booking ID : {status["BookingID"]}
                                </h4>
                              </Col>
                              <Col xs={6} sm={5} md={5} lg={3}>
                                <Button
                                  variant="contained"
                                  className="next-button-assigned track-button"
                                  onClick={handleClickOpenReschedule}
                                >
                                  <Link to="">Reschedule</Link>
                                </Button>
                                <Button
                                  variant="contained"
                                  className="next-button-assigned track-button"
                                  onClick={handleClickOpenpaymentconfirm}
                                >
                                  View Receipt
                                </Button>
                                <Button
                                  variant="contained"
                                  className="next-button-reject track-button bb2"
                                  onClick={handleClickOpen}
                                >
                                  Cancel Booking
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      );
                    } else if (status["Stage"] === "Professional Assigned") {
                       var otp  =
                      (status["Job Start Dt"] === "" ||
                       status["Job Start Dt"] === null ||
                       status["Job Start Dt"] === undefined ?  status["JobStartOTP"]?.split(""): 
                       status["JobEndOTP"]?.split("")
                       )
                     
                      return (
                        <div className="section-order-details">
                          <div className="my-order-inner">
                            <Row>
                              <Col xs={12} sm={12} md={12} lg={12}>
                                <h6>
                                  {/* {moment(status["Appointment Dt"]).format(
                                    "ddd, MMM DD"
                                  ) +
                                    " at " +
                                    " 12:30 "} */}

                                  {AppointmentDt && sAppointmentTime !== undefined
                                    ? AppointmentDt + " at " + sAppointmentTime
                                    : ""}
                                </h6>
                                <p className="booking-acceptted-1">
                                  {status["Stage"]}
                                </p>
                              </Col>
                              {ServiceList?.records?.map((Service, j) => {

                                // if (status["Stage"] === "Booking Assigned") {
                                return (
                                  <>
                                    <Col xs={4} sm={3} md={2} lg={2}>
                                      {/* <img src="../../assets/images/chandu.jpeg" /> */}
                                      {Service?.["ProfilePic"] !== undefined &&
                                        Service?.["ProfilePic"] !== null ? (
                                        <img
                                          src={Service["ProfilePic"]}

                                        />
                                      ) : (
                                        <img src="../../assets/images/chandu.jpeg" />
                                      )}
                                    </Col>
                                    <Col xs={8} sm={4} md={6} lg={7}>
                                      <h3>{Service["User Name"]}</h3>
                                      <h4>
                                        <StarRateIcon className="rating-6" />
                                        4.88
                                      </h4>
                                      <h4>
                                        Service Type : {status["Sub Category"]}{" "}
                                      </h4>
                                      <h4>
                                        Service Booking ID : {status["BookingID"]}
                                      </h4>
                                    </Col>
                                  </>
                                );

                              })}
                              <Col xs={12} sm={5} md={4} lg={3}>
                                <Button
                                  variant="contained"
                                  className="next-button-assigned"
                                >
                                  <Link to="/customer/track-location">
                                    Track Service
                                  </Link>
                                </Button>
                                <Button
                                  variant="contained"
                                  className="next-button-assigned track-button"
                                  onClick={handleClickOpenReschedule}
                                >
                                  Reschedule
                                </Button>
                                <Button
                                  variant="contained"
                                  className="next-button-assigned track-button"
                                  onClick={handleClickOpenpaymentconfirm}
                                >
                                  View Receipt
                                </Button>
                                <Button
                                  variant="contained"
                                  className="next-button-reject track-button bb2"
                                  onClick={handleClickOpen}
                                >
                                  Cancel Booking
                                </Button>
                              </Col>
                            </Row>
                          </div>
                          <div className="my-order-inner my-order-inner-otp">
                            <Row>
                              <Col xs={12} sm={6} md={6} lg={6}>
                                <div className="otp-share">
                                {status["Job Start Dt"] === "" ||
                       status["Job Start Dt"] === null ||
                       status["Job Start Dt"] === undefined ?  <h4>OTP to Start the service </h4>: 
                       <h4>OTP to End the service </h4>
                            }
                                 
                                </div>
                              </Col>
                              <Col xs={12} sm={6} md={6} lg={6}>
                                <div className="otp-share1">
                                  <h4>
                                    <span className="number-11">
                                      {otp?.[3]}
                                    </span>
                                    <span className="number-11">
                                      {otp?.[2]}
                                    </span>
                                    <span className="number-11">
                                      {otp?.[1]}
                                    </span>
                                    <span className="number-11">
                                      {otp?.[0]}
                                    </span>
                                  </h4>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      );
                    } else if (status["Stage"] === "Service Completed") {
                      return (
                        <div className="section-order-details">
                          <div className="my-order-inner my-order-inner-orderdetail">
                            <Row>
                              <Col xs={12} sm={12} md={12} lg={12}>
                                {/* <h6>{moment(status["Appointment Dt"]).format("ddd, MMM DD") + ' at ' + ' 12:30 '}</h6> */}
                                {/* <p className="booking-acceptted-1">{status["Stage"]}</p> */}
                              </Col>
                              {/* <Col xs={4} sm={3} md={2} lg={2}>
                                <img src="../../assets/images/chandu.jpeg" />
                              </Col> */}
                              <Col xs={7} sm={8} md={9} lg={10}>
                                <h3>
                                  Thanks for choosing <br />
                                  A2Zwork India
                                </h3>

                                <h4>
                                  {" "}
                                  Paid: ₹
                                  {Math.round(
                                    parseFloat(CustomerRemark["BalAmt"])
                                  )}
                                </h4>
                                <Button
                                  variant="outlined"
                                  className="view-receipt"
                                  onClick={handleClickOpenpaymentconfirm}
                                >
                                  View Receipt
                                </Button>

                                {/* <h3>{status["Professional"]}</h3>
                                <h4><StarRateIcon className="rating-6" />4.88</h4>
                                <h4>Service Type : {status["Sub Category"]} </h4>
                                <h4>Service Booking ID : {status["BookingID"]}</h4> */}
                              </Col>
                              <Col xs={5} sm={4} md={3} lg={2}>
                                <img
                                  src="../../assets/images/done1.png"
                                  className="order-done-1"
                                />

                                {/* <Button variant="contained" className="next-button-7 track-button">
                                  <RepeatIcon className="buy-icon" />
                                  Book Again
                                </Button>
                                <Button variant="contained" className="next-button-7 track-button" onClick={handleClickOpenReview}>
                                  Rate & Review
                                </Button> */}
                              </Col>
                            </Row>
                          </div>
                        </div>
                      );
                    } else if (status["Stage"] === "Cancel Service") {
                      return (
                        <div className="section-order-details">
                          <div className="my-order-inner">
                            <Row>
                              <Col xs={6} sm={8} md={8} lg={10}>
                                <p className="booking-cancel-1">
                                  <CloseOutlinedIcon className="close-icon-2" />
                                  {status["Stage"]}
                                </p>
                                {/* <h4>{status["Sub Category"]} </h4> */}
                                <h4 className="cs-h4">
                                  Your request was cancelled
                                </h4>
                                <h4>{CancelDt + " at " + CancelTime} </h4>
                              </Col>
                              <Col xs={6} sm={4} md={4} lg={2}>
                                <Link to="/customer/view-booking/0">
                                  <Button
                                    variant="contained"
                                    onClick={() => (
                                      setCardList(CustomerRemark?.CardDetails),
                                      localStorage.setItem(
                                        "CartItemms",
                                        JSON.stringify(
                                          CustomerRemark?.CardDetails
                                        )
                                      )
                                    )}
                                    className="next-button-7 track-button"
                                  >
                                    <RepeatIcon className="buy-icon" />
                                    Book Again
                                  </Button>
                                  {/* <Button variant="contained" onClick={()=>(localStorage.setItem("bookagainId",BookingID))} className="next-button-7 track-button">
                                    <RepeatIcon className="buy-icon" />
                                    Book Again
                                  </Button> */}
                                </Link>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      );
                    }
                  })}
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={12} md={4} lg={4}>
              <Row>
                {OrderDetails?.records?.map((order, i) => {
                  return (
                    <div>
                      {/* {order["Stage"] === "Booking Assigned" || order["Stage"] === "Professional Assigned" ?
                        <div>
                          <Col xs={12} sm={12} md={12} lg={12}>
                            <div className="section-order-details">
                              <h2 className="section-order-details-h3">Booking Summary
                                <span>
                                  <Link to="">Invoice</Link>
                                </span></h2>
                              <div className="my-order-inner-2">
                                <Row>
                                  <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className="shipping-detail-1">
                                      <h2>Billing To</h2>
                                      <p>{order["Cust Name"]}</p>
                                      <p>{order["Cust Mobile"]}</p>
                                      <p>{order["Address"]} , {order["City"]} , {order["State"]} , {order["Country"]} , {order["State"]} - {order["Pincode"]}</p>
                                    </div>
                                  </Col>
                                  <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className="shipping-detail-1">
                                      <h2>Payment Method</h2>
                                      <p>
                                        Pay on Service (Cash/Card). Pay on Service
                                        (POS) available. Card/Net banking acceptance
                                        subject to device availability.
                                      </p>
                                      <Link to="/customer/payment"><Button variant="contained" className="buy-button-1">
                                        Pay Now
                                      </Button></Link>
                                    </div>
                                  </Col>
                                  <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className="shipping-detail-1">
                                      <h2>Order Summary</h2>
                                      <p>
                                        Service <span>₹00</span>
                                      </p>
                                      <p>
                                        Shipping <span>₹00</span>
                                      </p>
                                      <p>
                                        Total <span>₹699</span>
                                      </p>
                                      <p>
                                        Promotion Applied: <span>₹00</span>
                                      </p>
                                      <p className="total-2">
                                        Grand Total:<span>₹699</span>
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </Col>
                        </div>
                        : ""} */}
                      {/* <div className="section-order-details">
                        <Row className="my-order-inner">

                          <Col xs={12} sm={12} md={12} lg={12}>
                            <div className="booking-div-15">
                              <img src="../../assets/images/chandu.jpeg" />

                              <h3>Chetan<span><p className="rating-5"><StarRateIcon className="rating-6" />4.88</p></span>
                                <span><Button className="pay-now-button" >Pay</Button></span>
                              </h3>

                            </div>
                          </Col>
                          <Col xs={12} sm={12} md={12} lg={12}>
                            <div className="booking-div-book">
                              <Button variant="outlined" >Book Again</Button>
                              <Button variant="outlined" >Rate & Review</Button>
                            </div>
                          </Col>


                        </Row>

                      </div> */}
                      <div className="section-order-details">
                        <Row className="my-order-inner-21">
                          <Col xs={12} sm={12} md={12} lg={12}>
                            <h3 className="section-order-details-h3">
                              Booking Details
                            </h3>
                            {/* <Link className="orderdetail-div-1" onClick={handleClickOpenpaymentconfirm}></Link> */}

                            <div
                              className="pay-now-right"
                              onClick={handleClickOpenpaymentconfirm}
                            >
                              <span className="left-icon-pay">
                                {" "}
                                <WalletOutlinedIcon />
                              </span>

                              {order["Stage"] === "Booking Assigned" ||
                                order["Stage"] === "Professional Assigned" ||
                                order["Stage"] === "Service Completed" ? (
                                <p>
                                  Amount Paid ₹
                                  {Math.round(
                                    parseFloat(CustomerRemark["BalAmt"])
                                  )}
                                </p>
                              ) : (
                                <p>Amount Paid ₹0</p>
                              )}

                              <span className="right-icon-pay">
                                <KeyboardArrowRightIcon />
                              </span>
                            </div>

                            <div className="pay-now-right">
                              <span className="left-icon-pay">
                                {" "}
                                <WhereToVoteOutlinedIcon />
                              </span>
                              <p>
                                {order["Address"]} , {order["City"]} ,{" "}
                                {order["State"]} , {order["Country"]} ,
                                {order["State"]} - {order["Pincode"]}
                              </p>
                            </div>

                            <div className="pay-now-right">
                              <span className="left-icon-pay">
                                {" "}
                                <AccessTimeOutlinedIcon />
                              </span>
                              <p>{AppointmentDt + " at " + sAppointmentTime}</p>
                            </div>

                            {/*                         
                          <Col xs={12} sm={12} md={12} lg={12}>
                            <span  className="pay-now-right"><WhereToVoteOutlinedIcon /> </span>
                            <div className="orderdetail-div-2">
                              {order["Address"]} , {order["City"]} , {order["State"]} , {order["Country"]} , 
                              {order["State"]} - {order["Pincode"]}
                              <br/>
                              {moment(order["Appointment Dt"]).format("ddd, MMM DD") + ' at ' + ' 10:00 AM'}
                            </div>
                          </Col> */}

                            {/*                          
                          <Col xs={12} sm={12} md={12} lg={12}>
                            <div className="orderdetail-div-2">
                              {moment(order["Appointment Dt"]).format("ddd, MMM DD") + ' at ' + ' 10:00 AM'}
                            </div>
                          </Col>
                           */}
                          </Col>
                        </Row>
                      </div>
                      <Col xs={12} sm={12} md={12} lg={12}>
                        <div className="my-order-inner-2 shipping-detail-1 cancellation-policy">
                          <h2>Cancellation Policy</h2>
                          <p>
                            Free cancellations if done more than 3 hrs before
                            the service or if a professional isn’t assigned. A
                            fee will be charged otherwise.
                          </p>
                        </div>
                      </Col>
                    </div>
                  );
                })}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <FooterAfterLogin />

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        BackdropProps={{
          style: { backgroundColor: "rgba(0, 0, 0, 0.9)" },
        }}
      >
        <DialogTitle>
          {"You will missing out, when you cancel"}
          <ClearIcon className="cross-icon" onClick={handleClose} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="div-Tools-5">
              <h2>Tools & Expertise</h2>
              <p>
                Expert Therapists who bring branded trimmers and high quality
                Products
              </p>
            </div>
            <div className="div-Tools-5">
              <h2>Flexibility</h2>
              <p>
                Easy Reschedule, cancel and repeat with the same professional
              </p>
            </div>
            <div className="div-Tools-5">
              <h2>Safety and Support</h2>
              <p>
                Extensive quality checks access to our support team for on-job
                issues
              </p>
            </div>

            <Button
              variant="contained"
              className="next-button-7 width-100"
              onClick={handleClose}
            >
              No, continue the service
            </Button>
            <Button
              variant="outlined"
              className="cancel-button-7"
              onClick={() => {
                handleClickOpen2();
                handleClose();
              }}
            >
              Yes, I still want to cancel
            </Button>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        open={open2}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleClose2}
        aria-describedby="alert-dialog-slide-description"
        BackdropProps={{
          style: { backgroundColor: "rgba(0, 0, 0, 0.9)" },
        }}
      >
        <DialogTitle>
          <KeyboardBackspaceIcon
            className="arrow-35"
            onClick={() => {
              handleClickOpen();
              handleClose2();
            }}
          />
          {"Cancellation Reason"}
          <ClearIcon className="cross-icon" onClick={handleClose2} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="div-Tools-5">
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    className="radio-button-cancel"
                    value="Booking address is incorrect"
                    checked={
                      CancellationReason === "Booking address is incorrect"
                    }
                    onChange={(e) => setCancellationReason(e.target?.value)}
                    control={<Radio />}
                    label="Booking address is incorrect"
                  />
                  <FormControlLabel
                    className="radio-button-cancel"
                    value="Did not like the professional"
                    checked={
                      CancellationReason === "Did not like the professional"
                    }
                    onChange={(e) => setCancellationReason(e.target?.value)}
                    control={<Radio />}
                    label="Did not like the professional"
                  />
                  <FormControlLabel
                    className="radio-button-cancel"
                    value="Service no longer required"
                    checked={
                      CancellationReason === "Service no longer required"
                    }
                    onChange={(e) => setCancellationReason(e.target?.value)}
                    control={<Radio />}
                    label="Service no longer required"
                  />
                  <FormControlLabel
                    className="radio-button-cancel"
                    value="Place the request by mistake"
                    checked={
                      CancellationReason === "Place the request by mistake"
                    }
                    onChange={(e) => setCancellationReason(e.target?.value)}
                    control={<Radio />}
                    label="Place the request by mistake"
                  />
                  <FormControlLabel
                    className="radio-button-cancel"
                    value="Professional was unreachable"
                    checked={
                      CancellationReason === "Professional was unreachable"
                    }
                    onChange={(e) => setCancellationReason(e.target?.value)}
                    control={<Radio />}
                    label="Professional was unreachable"
                  />
                </RadioGroup>
              </FormControl>
              <p className="cancallation-1">
                A Cancellation fee of ₹50 will apply
              </p>
            </div>

            <Button
              variant="contained"
              className="next-button-7 width-100"
              onClick={() => {
                if (window.confirm("Are you sure to cancel this booking?")) {
                  fun_CancellationReason(BookingID);
                }
              }}
            >
              Cancel Booking
            </Button>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openReview}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleCloseReview}
        aria-describedby="alert-dialog-slide-description"
        BackdropProps={{
          style: { backgroundColor: "rgba(0, 0, 0, 0.9)" },
        }}
      >
        <DialogTitle>
          {"Rate & Review"}
          <ClearIcon className="cross-icon" onClick={handleCloseReview} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="div-Tools-6">
              <p>
                <StarRateIcon className="star-color" />
                <StarRateIcon className="star-color" />
                <StarRateIcon className="star-color" />
                <StarRateIcon className="star-color" />
                <StarBorderIcon />
                (4/5) Rating
              </p>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Message</Form.Label>
                <Form.Control as="textarea" rows={3} />
              </Form.Group>
            </div>
            <Button variant="contained" className="next-button-7 width-100">
              Submit
            </Button>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openReschedule}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleCloseReschedule}
        aria-describedby="alert-dialog-slide-description"
        // className="reschedule-div-1"
        BackdropProps={{
          style: { backgroundColor: "rgba(0, 0, 0, 0.9)" },
        }}
      >
        <DialogContent className="reschedule-div-1">
          <DialogContentText id="alert-dialog-slide-description">
            <h2 className="reason-1">
              Reschedule Booking{" "}
              <span>
                <ClearIcon
                  className="cross-icon"
                  onClick={handleCloseReschedule}
                />
              </span>
            </h2>

            <section className="section-step-1 section-step-1-popup div-Tools-5">
              <Calendar
                onChange={onChange}
                value={value}
                minDate={new Date()}
                maxDate={nextThreeDays}
              />

              <div className="time-slot time-slot-popup">
                <h2>Select Start Time of Service</h2>
                <ul>
                  {AppointmentData.map((dd, i) => {
                    if (currentDate < moment(value).format("YYYY-MM-DD")) {
                      return (
                        <li
                          key={dd["value"]}
                          onClick={() => handleSelect(dd["value"], dd["d"])}
                          className={
                            AppointmentTime === dd["value"]
                              ? "active-button-bb"
                              : ""
                          }
                        >
                          {dd["value"]}
                        </li>
                      );
                    }
                    if (
                      currentDate === moment(value).format("YYYY-MM-DD") &&
                      time <= dd["d"]
                    ) {
                      return (
                        <li
                          key={dd["value"]}
                          onClick={() => handleSelect(dd["value"], dd["d"])}
                          className={
                            AppointmentTime === dd["value"]
                              ? "active-button-bb"
                              : ""
                          }
                        >
                          {dd["value"]}
                        </li>
                      );
                    }
                  })}
                </ul>

                <Button
                  variant="contained"
                  className="next-button-7 reschedule-button"
                  onClick={() => {
                    handleClickOpenRescheduleDone();
                    handleCloseReschedule();
                  }}
                >
                  Submit
                </Button>
              </div>
            </section>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openRescheduleDone}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleCloseRescheduleDone}
        aria-describedby="alert-dialog-slide-description"
        BackdropProps={{
          style: { backgroundColor: "rgba(0, 0, 0, 0.9)" },
        }}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <h2 className="reason-1">
              <KeyboardBackspaceIcon
                className="arrow-35"
                onClick={() => {
                  handleClickOpenReschedule();
                  handleCloseRescheduleDone();
                }}
              />
              Reason for Rescheduling{" "}
              <span>
                <ClearIcon
                  className="cross-icon"
                  onClick={handleCloseRescheduleDone}
                />
              </span>
            </h2>

            <div className="div-Tools-5">
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    className="radio-button-cancel"
                    value="Professional not assignedt"
                    checked={
                      ReasonRescheduling === "Professional not assignedt"
                    }
                    onChange={(e) => setReasonRescheduling(e.target?.value)}
                    control={<Radio />}
                    label="Professional not assigned"
                  />
                  <FormControlLabel
                    className="radio-button-cancel"
                    value="Service required at a different time"
                    checked={
                      ReasonRescheduling ===
                      "Service required at a different time"
                    }
                    onChange={(e) => setReasonRescheduling(e.target?.value)}
                    control={<Radio />}
                    label="Service required at a different time"
                  />
                </RadioGroup>
              </FormControl>
              <p className="cancallation-1">
                We won't be charging you a reschedule fee
              </p>
            </div>

            <Button
              variant="contained"
              className="next-button-7 width-100"
              onClick={() => {
                fun_ReasonforRescheduling(BookingID);
              }}
            >
              Reschedule Booking
            </Button>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        open={paymentconfirm}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleClosepaymentconfirm}
        aria-describedby="alert-dialog-slide-description"
        BackdropProps={{
          style: { backgroundColor: "rgba(0, 0, 0, 0.9)" },
        }}
      >
        <DialogContent className="payment-summary-popup-design">
          {/* <DialogContentText id="alert-dialog-slide-description"> */}

          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <h2 className="reason-1">
                Payment Summary{" "}
                <span>
                  <ClearIcon
                    className="cross-icon"
                    onClick={handleClosepaymentconfirm}
                  />
                </span>
              </h2>
            </Col>
            {CustomerRemark?.CardDetails?.map((payment, i) => {
              return (
                <Col xs={12} sm={12} md={12} lg={12}>
                  <h6>
                    {payment?.data["Item"]} x {payment["Quanity"]}{" "}
                    <span>
                      ₹
                      {parseFloat(payment?.data["MRP"] || 0) *
                        payment["Quanity"]}
                    </span>
                  </h6>
                  <p className="subTitle">
                    {payment?.data["Item"]} x {payment["Quanity"]}
                  </p>
                </Col>
              );
            })}
            <Col xs={12} sm={12} md={12} lg={12}>
              <div className="paymentsummary-div-1">
                <h6>
                  Taxes and Fee{" "}
                  <span>
                    ₹{Math.round(parseFloat(CustomerRemark["TaxAmt"]))}
                  </span>
                </h6>
                <p>
                  Taxes levied as per Govt. regulations, subject to change basis
                  final service value. The fee goes towards training of partners
                  and providing support & assistance during the service.
                </p>
              </div>
            </Col>
            <div className="box-shadow-div-2">
              {Stage === "Cancel Service" ? (
                <Col xs={12} sm={12} md={12} lg={12}>
                  <div className="paymentsummary-div-1">
                    <h6>
                      Pending Cancellation Charges <span>₹50</span>
                    </h6>
                    <p className="cancellation">
                      Cancellation Charges on your previous requests
                    </p>
                  </div>
                </Col>
              ) : (
                ""
              )}
              <Col xs={12} sm={12} md={12} lg={12}>
                <h5>
                  Discount
                  <span>
                    ₹{Math.round(parseFloat(CustomerRemark["Discount"]))}
                  </span>
                </h5>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                <h5 className="cs-55">
                  Amount Paid{" "}
                  <span>
                    {Stage === "Booking Assigned" ||
                      Stage === "Professional Assigned" ||
                      Stage === "Service Completed" ? (
                      <p>
                        <span>
                          ₹{Math.round(parseFloat(CustomerRemark["BalAmt"]))}
                        </span>
                      </p>
                    ) : (
                      <p>
                        <span>₹0</span>
                      </p>
                    )}
                  </span>
                </h5>
              </Col>

              {Stage === "Booking Assigned" ||
                Stage === "Professional Assigned" ||
                Stage === "Service Completed" ? (
                <Col xs={12} sm={12} md={12} lg={12}>
                  <h5>
                    Payment mode <span>UPI</span>
                  </h5>
                </Col>
              ) : (
                ""
              )}
            </div>
          </Row>

          {/* </DialogContentText> */}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default OrderDetails;
