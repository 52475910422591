/* eslint-disable jsx-a11y/alt-text */
import React from 'react'

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Link } from 'react-router-dom';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import ClearIcon from "@mui/icons-material/Clear";
import Button from "@mui/material/Button";

import AddIcon from '@mui/icons-material/Add';
import RemoveSharpIcon from '@mui/icons-material/RemoveSharp';
import moment from 'moment'
import { Blind } from "@mui/icons-material";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function Payment() {
  var userLogininfo = JSON.parse(localStorage.getItem("user-Logininfo"));
    if (userLogininfo === null) {
        window.location.href = '/';
    }
    else {
        if (userLogininfo["User Group"] !== "Employee") {
            window.location.href="/not-found";
        }

    }
  const [openThankyou, setOpenThankyou] = React.useState(false);

  const handleClickOpenThankyou = () => {
    setOpenThankyou(true);
  };
  const handleCloseThankyou = () => {
    setOpenThankyou(false);
  }; //Store data in Local Storage Start
  var OrderNo=""
  const AddPaymentData = (OrderNo) => {
    const obj = {
      OrderNo: OrderNo
    };
    localStorage.setItem('PaymentData', JSON.stringify({ ...obj }));
};
//End

  return (
    <div className='section-payment'>
      <Container>
        <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
                <div className='payment-inner-1'>
                  <div className='back-arrow-1'>
                    <Link to = "/view-booking"><KeyboardBackspaceIcon className='arrow-2' /></Link>
                  </div>
                  <div className='back-arrow-2'>
                    <h2>Select Payment Method</h2>
                    <p>Amount to pay ₹699</p>
                    </div>
                    
                 
                 <Link to ="">
                  <div className='back-arrow-3'>
                  <img src="../../assets/images/payment/credit-card.png" className='pay-div-1' />

                    <h2 className='pay-div-2'>Debit or Credit card<span><ArrowForwardIosIcon className='pay-icon-1' /></span></h2>
                 </div>
                 </Link>
                 <Link to ="">
                  <div className='back-arrow-3'>
                  <img src="../../assets/images/payment/ewallet.png" className='pay-div-1' />

                    <h2 className='pay-div-2'>Wallet<span><ArrowForwardIosIcon className='pay-icon-1' /></span></h2>
                 </div>
                 </Link>
                 <Link to ="">
                  <div className='back-arrow-3'>
                  <img src="../../assets/images/payment/pay.png" className='pay-div-1' />

                    <h2 className='pay-div-2'>UPI<span><ArrowForwardIosIcon className='pay-icon-1' /></span></h2>
                 </div>
                 </Link>

                 <Link to ="">
                  <div className='back-arrow-3'>
                  <img src="../../assets/images/payment/upi.png" className='pay-div-1' />

                    <h2 className='pay-div-2'>Net Banking<span><ArrowForwardIosIcon className='pay-icon-1' /></span></h2>
                 </div>
                 </Link>
                 <Link to ="">
                  <div className='back-arrow-3'>
                  <img src="../../assets/images/payment/online-payment.png" className='pay-div-1' />

                    <h2 className='pay-div-2'>Pay Online After Service<span><ArrowForwardIosIcon className='pay-icon-1' /></span></h2>
                 </div>
                 </Link>
                 <Link to ="">
                  <div className='back-arrow-3'>
                  <img src="../../assets/images/payment/online-banking.png" className='pay-div-1' />

                    <h2 className='pay-div-2' onClick={handleClickOpenThankyou}>Pay with Cash after Service<span><ArrowForwardIosIcon className='pay-icon-1' /></span></h2>
                 </div>
                 </Link>
                 
                          
                

                </div>
                
            </Col>
        </Row>
      </Container>
      <Dialog
        open={openThankyou}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseThankyou}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className="section-step-1 section-step-12">
          <h2>
            Thank You
            <ClearIcon className="cross-icon" onClick={handleCloseThankyou} />
          </h2>
          <hr />
          <DialogContentText id="alert-dialog-slide-description">
            <img src="../assets/images/success.gif" className="thankyou-img" />
            <h3>Your Booking ID is ODI2165561564</h3>
            <p>
              your Service is confirmed. You will receive an Service confirmation
              email/ SMS shortly with ths expected Service 30 November 2023.
            </p>
            <Button className="service-button">
              <Link to="/customer/my-bookings"  onClick={() => AddPaymentData(OrderNo="ODI2165561564")}>Back to Home</Link>
            </Button>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default Payment
