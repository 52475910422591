

import React, { useEffect, useState } from "react";
import axios from "axios";
import { red } from "@mui/material/colors";
import moment from 'moment'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import TextField from "@mui/material/TextField";
import Container from "react-bootstrap/Container";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Headerinner from '../Common/Headerinner';
import SideBar from "../Common/SideBar";


function AddSubCategory() {

    let baseURL = `${process.env.REACT_APP_BASE_URL}`;
    let authToken = localStorage.getItem("Auth-Token").replace(/"/g, '');
    const [formdata, setformdata] = useState({});
    const [col, setcol] = useState();
    const [drop, setdrop] = useState();
    const [file, setFile] = useState("");
    const [SubCategoryImg, setSubCategoryImg] = useState(null);
    var userLogininfo = JSON.parse(localStorage.getItem("user-Logininfo"));
    if (userLogininfo === null) {
        window.location.href = '/';
    }
    else {
        if (userLogininfo["User Group"] !== "Employee") {
            window.location.href="/not-found";
        }

    }
    var SubCategoryID = localStorage.getItem('SubCategoryID');

    useEffect(() => {

        getcoldata();
        getdropdata();
        fun_SubCategoryList();

    }, []);

    function fun_SubCategoryList() {

        let subcategorylistData = '{SubCategoryID:{$eq :' + SubCategoryID + '},}';

        const SubCategoryPra = {
            "pgSize": 100,
            
            "filterButtons": "",
            "index": 1,
            "toEmailID": "designMonu@dialerp.com",
            "tabErpID": 793,
            "useAtlusSearch": false,
            "filters": subcategorylistData
        }

        fetch(
            baseURL + "/api/Dynamic/GetRecords",
            {
                method: "POST",
                headers: {
                    "Authorization-Token": authToken,
                    Accept: "application/json, text/plain",
                    "Content-Type": "application/json;charset=UTF-8",
                },
                body: JSON.stringify(SubCategoryPra),
            }
        ).then((result) => {
            result.json().then((resp) => {

                if (resp.hasErrors === false) {
                    if (resp.response?.records !== null) {

                         ;
                        for (let i = 0; i < resp.response?.records.length; i++) {
                            console.log("Test", resp.response);
                            if ("fieldName" === "GoButtonField") {
                                setformdata({
                                    ...formdata,
                                    Category: "value",
                                });
                    
                            } else {
                                setformdata({
                                    ...formdata,
                                    ["fieldName"]: "value",
                                });
                            }
                        }

                        // console.log("Test",resp.response);


                    }
                    else {
                        // setSubCategoryList("");
                    }
                }
                else {
                    alert(resp.errors);
                }
            });
        });
    }

    const Url = "https://api.dialerp.com/api/";
    const Headers = {
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Methods": "POST",
        "Access-Control-Allow-Origin": { Url },
    };

    function Fun_Cancel() {
        window.location.href = "/admin/dashboard";
    }
    const getdropdata = async () => {
        const options = {
            method: "POST",
            url: Url + "dynamic/AutoFillMapperWise?appname=web&tmpUId=40398&tmpPId=40398",
            headers: {
                Headers,
                "Authorization-Token": authToken,
            },
            data: {
                useAtlusSearch: false,
                userID: 40398,
                filters: "{tabErpID:793}",
                index: 1,
                isDownload: false,
                toEmailID: "designMonu@dialerp.com",
                tabErpID: 793,
                dataMapper: {
                    CollectionName: "FINANCECONNECT_Category",
                    filter: "{'Category':'/^null/i'}",
                    project: "{'Category':'$Category'}",
                },
            },
        };
        try {
            const response = await axios.request(options);
            setdrop(response.data);

        } catch (error) {
            console.error(error);
        }

    };

    const getcoldata = async () => {
        const options = {
            method: "POST",
            url: baseURL + "/api/Dynamic/GetInputColumns",
            headers: {
                "Authorization-Token": authToken,
                Accept: "application/json, text/plain",
                "Content-Type": "application/json;charset=UTF-8",
            },
            data: {
                filterButtons: "",
                recordErpID: 0,
                tabErpID: 793,
                toEmailID: "designMonu@dialerp.com",
            },
        };
        try {
            const response = await axios.request(options);
            setcol(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const handleInputChange = (e, fieldName) => {
        const { value } = e.target;
        if (fieldName === "GoButtonField") {
            setformdata({
                ...formdata,
                Category: value,
            });

        } else {
            setformdata({
                ...formdata,
                [fieldName]: value,
            });
        }
    };

    const handleFile = (event) => {
        setFile(URL.createObjectURL(event.target.files[0]));
        const formData = new FormData();
        formData.append("SubCategoryPic", event.target.files[0]);
        fetch("https://dialerpwebapis-dev-as.azurewebsites.net" + "/api/UploadData/getWebUploadImgUrlData?PartyAcTBSr=" +
            "&parentID=40004&userID=40004",
            {
                method: "POST",
                headers: { HttpHeaders: "multipart/form-data" },
                body: formData,
            }

        ).then((result) => {
            result.json().then((resp) => {
                setformdata({ ...formdata, SubCategoryPic: resp?.response });
                setSubCategoryImg(resp?.response);
            });
        });
    };

    function fun_AddSubCategoryData() {
        fetch(
            baseURL + "/api/Dynamic/AddUpdateRecords?LoginType=2&tabErpID=793",
            {
                method: "POST",
                headers: {
                    "Authorization-Token": authToken,
                    Accept: "application/json, text/plain",
                    "Content-Type": "application/json;charset=UTF-8",
                },
                body: JSON.stringify([formdata]),
            }
        ).then((result) => {
            result.json().then((resp) => {

                if (resp.hasErrors === false) {
                    alert(resp.response.message)
                    window.location.reload();
                }
                else {
                    alert(resp.errors);
                }
            });
        });

    }

    return (

        <div className="admin-panel-section">
            <Headerinner />
            <div className="admin-body-section admin-div-1">
                <Container fluid>
                    <Row>
                        <Col xs={12} sm={12} md={3} lg={2}>
                            <SideBar />
                        </Col>
                        <Col xs={12} sm={12} md={9} lg={10} className="right-side-section">
                            <div className="add-sevice-section-2">
                                <Form className="addservice-form">
                                    <Row>
                                        <Col xs={12} sm={12} md={12}>
                                            <h2>Add Sub Category</h2>
                                        </Col>
                                        {col?.response?.map((inputtype, i) => {

                                            if (inputtype.addType === "PicFile") {

                                                return (
                                                    <>
                                                        <Col xs={12} sm={6} md={3}> </Col>
                                                        <Col xs={12} sm={6} md={7}>
                                                            <Form.Group
                                                                className="mb-3"
                                                                controlId="exampleForm.ControlInput1"
                                                            >
                                                                <Form.Control
                                                                    type="file"
                                                                    placeholder="Upload Image"
                                                                    className="upload-image"
                                                                    onChange={handleFile}
                                                                    id="inputGroupFile01"
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs={12} sm={6} md={2}>

                                                            {SubCategoryImg != null ? <img src={SubCategoryImg} alt="Profile Image" class="upload-image-2" /> : <img src="../../assets/images/profile.jpeg" class="upload-image-2" />}
                                                        </Col>
                                                    </>
                                                )
                                            }

                                            if (inputtype.addType === "TextBox") {
                                                return (
                                                    <>
                                                        <Col xs={12} sm={6} md={3}> </Col>
                                                        <Col xs={12} sm={6} md={7}>
                                                            <TextField
                                                                id="outlined-basic"
                                                                label={inputtype.displayName}
                                                                variant="outlined"
                                                                className="addservice-1"
                                                                onChange={(e) => handleInputChange(e, inputtype.columnName)}
                                                            />
                                                        </Col>
                                                        <Col xs={12} sm={6} md={2}> </Col>
                                                    </>
                                                )
                                            }

                                            if (inputtype.addType === "GoButton") {
                                                return (
                                                    <>


                                                        <Col xs={12} sm={6} md={3}> </Col>
                                                        <Col xs={12} sm={6} md={7}>
                                                            <FormControl fullWidth className="bb1">
                                                                <InputLabel
                                                                    id="demo-simple-select-label"
                                                                    variant="outlined"
                                                                >
                                                                    {inputtype.displayName}
                                                                </InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"

                                                                    variant="outlined"
                                                                    label={inputtype.displayName}

                                                                    onChange={(e) => handleInputChange(e, "GoButtonField")}
                                                                    value={formdata.Category}
                                                                >
                                                                    {drop?.response?.records?.map((dd, j) => {
                                                                        return (
                                                                            <MenuItem value={dd.Category}>
                                                                                {dd.Category}
                                                                            </MenuItem>
                                                                        );
                                                                    })}
                                                                </Select>


                                                            </FormControl>
                                                        </Col><br /><br />
                                                        <Col xs={12} sm={6} md={2}> </Col>
                                                    </>
                                                )
                                            }
                                            if (inputtype.addType === "TextArea") {
                                                return (
                                                    <>
                                                        <Col xs={12} sm={6} md={3}> </Col>
                                                        <Col xs={12} sm={6} md={7}>
                                                            <TextField
                                                                id="outlined-multiline-static"
                                                                label="Enter Message"
                                                                multiline
                                                                className="addservice-1"
                                                                rows={4}
                                                                onChange={(e) => handleInputChange(e, inputtype.columnName)}
                                                            />
                                                        </Col>
                                                        <Col xs={12} sm={6} md={2}> </Col>
                                                    </>
                                                )
                                            }
                                            if (inputtype.addType === "Dropdown") {
                                                return (
                                                    <>
                                                        <Col xs={12} sm={6} md={3}> </Col>
                                                        <Col xs={12} sm={6} md={7}>
                                                            <FormControl fullWidth>
                                                                <InputLabel
                                                                    id="demo-simple-select-label"
                                                                    variant="outlined"
                                                                >
                                                                    {inputtype.displayName}
                                                                </InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"

                                                                    variant="outlined"
                                                                    label={inputtype.displayName}
                                                                    onChange={(e) => handleInputChange(e, inputtype.displayName)}
                                                                >
                                                                    {inputtype["dropDownList"]?.map((dd, j) => {
                                                                        return (
                                                                            <MenuItem key={j} value={dd}>
                                                                                {dd}
                                                                            </MenuItem>
                                                                        );
                                                                    })}
                                                                </Select>
                                                            </FormControl>
                                                        </Col>
                                                        <Col xs={12} sm={6} md={2}> </Col>
                                                    </>
                                                )
                                            }

                                        })}

                                    </Row>

                                    <Row>


                                        <Col xs={12} sm={6} md={12}>
                                            <Stack
                                                spacing={2}
                                                direction="row"
                                                className="submit-service"
                                            >
                                                <Button variant="outlined" onClick={Fun_Cancel}>Cancel</Button>
                                                <Button variant="contained" onClick={fun_AddSubCategoryData}>Submit</Button>
                                            </Stack>
                                        </Col>


                                    </Row>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>

    )
}
export default AddSubCategory;





