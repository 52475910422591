/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useContext } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";

import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import DialogContentText from "@mui/material/DialogContentText";
import { ProductContext } from "./context/AppContext";
import MainCategory from "./MainCategory";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
// const BootstrapDialog = styled(Dialog)(({ theme }) => (
//   {"& .MuiDialogContent-root": { padding: theme.spacing(2), },
//   "& .MuiDialogActions-root": {padding: theme.spacing(1), }, }));

function Banner() {


  // useEffect(() => {
  //   fun_CategoryList();
  // }, []);

  let baseURL = `${process.env.REACT_APP_BASE_URL}`;
  let baseTokenKey = `${process.env.REACT_APP_BASE_KEY}`.replace(/'/g, "");
  let authToken = `${process.env.REACT_APP_BASE_AUTHKEY}`;
 
  return (
    <div className="top-space">
      <Container fluid>
        <Row>
          <Col xs={12} sm={12} md={5} lg={5}>
            <div className="banner-section-1 banner-section-1-mobile">
              <h2>Home services at your doorstep</h2>
            </div>
            <div className="banner-section-2 banner-section-1-mobile-1">
              <h3>What are you looking for?</h3>
              <Row>
                      <MainCategory />
                  
                <Col xs={12} md={12}>
                  <div className="">
                    <p className="buy-1">Buy Products</p>
                    <img src="../assets/images/b7.webp" width="100%" />
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={12} md={1} lg={1}></Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <div className="banner-section-1">
              <img src="../assets/images/bannernew.png" width="100%" />
            </div>
          </Col>
        </Row>
      </Container>{" "}
    </div>
  );
}

export default Banner;
