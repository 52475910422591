import React, { useEffect, useState, useContext } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { ProductContext } from "../context/AppContext";
import InputAdornment from "@mui/material/InputAdornment";

function CustomerMobileLogin() {
  const { handleClickOpenUserOTP, handleCloseUserMobile, ID, checkMobileUserMongoDynamic, setMobile, Mobile
    , LoginType, setLoginType, AuthkeyTEXT,handleClickLoader,handleCloseLoader
    , openCustomerRagistration, setOpenCustomerRagistration, handleClickOpenCustomerRagistration } =
    useContext(ProductContext);
  const [state, setState] = useState({
    mobile: "",
    isValidMobile: false,
  });
  setMobile(state?.mobile)
  useEffect(() => {
    state.mobile = Mobile;
  }, [0]);
  const [disabledbtn, setdisabledbtn] = useState(true);
  const CheckMobileNo = () => {
    if (state?.mobile === "" || state?.mobile === null || state?.mobile === undefined) {
      alert('Please enter Mobile');
      setdisabledbtn(false);
    }
    else {
      setdisabledbtn(false);
      fun_ChecksacchasathiData();

    }
  };
  const fun_ChecksacchasathiData = () => {
    handleClickLoader();
    // var fullName = [];
    let UserID = "{'LoginMobile':" + Mobile + "}";
    const loginPra =
    {
      filters: UserID,
      tabErpID: "1429",
      collectionNumber: "1"
    }
    fetch("https://dialerpwebapis-dev-as.azurewebsites.net/api/DynamicApp/checkValidFilterUserMongoDynamicV1", {
      method: "POST",
      headers: {
        "Authkey": AuthkeyTEXT,
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(loginPra),
    }).then((result) => {
      result.json().then((resp) => {
        if (resp.hasErrors === false) {
          if (resp.response !== null) {
            if (LoginType === "Customer Login") {
              if (resp.response?.UserGroup === "Customer") {
                checkMobileUserMongoDynamic();
              }
              else {
                handleCloseLoader();
                setdisabledbtn(false);
                alert("Invalid Mobile Number.");
                return;
              }
            }
            if (LoginType === "Service Provider Login") {
              if (resp.response?.UserGroup === "Service Provider") {
                checkMobileUserMongoDynamic();
              }
              else {
                setdisabledbtn(false);
                handleCloseLoader();
                alert("Invalid Mobile Number.");
                return;
              }
            }
            if (LoginType === "Admin Login") {
              if (resp.response?.UserGroup === "Employee") {
                checkMobileUserMongoDynamic();
              }
              else {
                handleCloseLoader();
                setdisabledbtn(false);
                alert("Invalid Mobile Number.");
                return;
              }
            }
          } else {
            checkMobileUserMongoDynamic();
          }
        } else {
          alert(resp.errors);
        }
      });
    });
  };
  return (
    <div>
      <div className="login-18 login-18-Customer-login">

        <div className="form-section">
          <div className="logo-2">
            <Link to="/">
              <img src="../../assets/images/logo1.svg" alt="logo" />
            </Link>
          </div>
          <div className="typing-3">
            <h3>{LoginType}</h3>
          </div>

          <div className="input-spce-bottom">
            <TextField
              floatingLabelText="number"
              autoFocus={true}
              defaultValue="+91 | "
              className={`width-100 lgoin-input-6`}
              label="Mobile Number"
              maxLength={10}
              required
              variant="outlined"
              // inputProps={{ maxLength: 10 }}
              //max={10}
              onInput={(e) => {
                e.target.value = e.target.value.toString().slice(0, 10);
              }}
              id="validation-outlined-input"
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+91</InputAdornment>
                ),
              }}
              // onChange={(e) => {
              //   setMobile(e.target.value);
              // }}
              // onKeyPress={(e) => handleKeyPress(e)}
              value={state.mobile}
              onChange={(event) => {
                const enteredMobile = event.target.value;
                const strippedMobile = enteredMobile.replace(/\D/g, ""); // Remove non-digit characters
                const limitedMobile = strippedMobile.slice(0, 10); // Limit to 10 digits
                const isValidMobile =
                  limitedMobile.length === 10 && /^\d+$/.test(limitedMobile);
                setState((prevState) => ({
                  ...prevState,
                  mobile: limitedMobile,
                  isValidMobile: isValidMobile,
                }));
              }}
            />
            {/* {error && Mobile === "" && (
                <div className="invalid-feedback">Please enter mobile number</div>
              )} */}
          </div>

          <div className="form-group clearfix">
            {disabledbtn && state.mobile.length !== 10 ?
              <>
                <button disabled="disable" type="submit" className="btn btn-primary btn-lg btn-theme valid-mobile" > <b>Request OTP</b>
                </button>
              </>
              :
              <>
                <button type="submit" className="btn btn-primary btn-lg btn-theme valid-mobile"
                  onClick={() => { setdisabledbtn(true); CheckMobileNo() }} >  <b>Request OTP</b>
                </button>
              </>
            }
            <br />
            <br />

            {/* {LoginType === "Customer Login" &&
              <p className='account-type-show' style={{paddingTop:"10px"}}>
                Don't have an account?
                <Link style={{color:"blue"}} to="" onClick={() => { handleClickOpenCustomerRagistration() }}> Register here</Link>
              </p>
            } */}
          </div>

        </div>
      </div>
    </div>
  );
}

export default CustomerMobileLogin;
