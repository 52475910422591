import React, { useContext } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { ProductContext } from "./context/AppContext";
function FooterAfterLogin() {
  const {
    handleClickAdminLogin,
    handleClickOpenUserMobile,
    LoginType,
    setLoginType,
    handleClickLoader,
  } = useContext(ProductContext);
  const fun_loginMDL = (type) => {
    setLoginType(type);
    handleClickOpenUserMobile();
  };
  return (
    <div className="footer-section top-space">
      <Container fluid>
        <Row>
          <Col xs={6} sm={6} md={4}>
            <div className="footer-div-1">
              {/* <img
                src="../../../assets/images/logo1.svg"
                className="sb-image-footer sb-image-footer-21"
              /> */}
              <h2>
                A<span className="color_orange">2</span>Z
                <span className="color_orange">work</span>
              </h2>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                {/* <li>
                  <Link to="/">About us</Link>
                </li> */}
                <li>
                  <Link to="/privacy-policy">Privacy policy</Link>
                </li>
                <li>
                  <Link to="/terms-and-condtion">Terms & conditions</Link>
                </li>

                <li>
                  <Link to="/refund-and-cancellation">
                    Refund & cancellation
                  </Link>
                </li>
                {/* <li>
                  <Link to="/">Careers</Link>
                </li> */}
              </ul>
            </div>
          </Col>

          <Col xs={6} sm={6} md={4}>
            <div className="footer-div-1">
              <h2>For customers</h2>
              <ul>
                {/* <li><Link to = "/">DialERP Review</Link></li>
            <li><Link to = "/">Categories near you</Link></li>
            <li><Link to = "/">BLog</Link></li>
            <li><Link to = "/">Contact Us</Link></li> */}
                <li>
                  <Link to="/contactus">Contact Us</Link>
                </li>
              </ul>
            </div>
          </Col>
          <Col xs={6} sm={6} md={4}>
            <div className="footer-div-1">
              <h2>Social links</h2>
              <ul className="footer-social-media">
                <li>
                  <Link to="/">
                    <FacebookIcon />
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <TwitterIcon />
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <InstagramIcon />
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <LinkedInIcon />
                  </Link>
                </li>
              </ul>
              <br />
              <br />
              <img
                src="../../../assets/images/f1.webp"
                className="sb-image-footer-5"
                alt="icon"
              />
              <img
                src="../../../assets/images/f2.webp"
                className="sb-image-footer-5"
                alt="icon"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12}>
            <div className="footer-div-2">
              <p>©Copyright 2024 a2zworkindia.com. All rights reserved.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default FooterAfterLogin;
