import React, { useEffect, useState } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import { Link } from "react-router-dom";

function ServiceProviderLogin() {
  localStorage.removeItem("user-Logininfo");
  const ID =
    "826578659859-sl1ds9uajcok1allt6dks9rdrs68aq7f.apps.googleusercontent.com";

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  let authToken = `${process.env.REACT_APP_BASE_AUTHKEY}`;
  let baseURL = "http://dialerpwebapis-dev-as.azurewebsites.net"; //= `${process.env.REACT_APP_BASE_URL}`;
  let baseTokenKey = `${process.env.REACT_APP_BASE_KEY}`.replace(/'/g, "");

  function fun_DyLogin() {
    if (username.trim() === "") {
      alert("Please enter username");
      return;
    } else if (!emailRegex.test(username.trim())) {
      alert("Please enter a valid email address");
      return;
    } else if (password.trim() === "''") {
      alert("Please enter password");
      return;
    } else {
      let filters = "{'UserGroup':'Service Provider'}";
      const loginPra = {
        baseTokenKey: baseTokenKey,
        EmailID: username,
        password: password,
        filters: filters,
      };
      fetch(baseURL + "/api/AppApi/LoginDynamicWithEmialAndPassword", {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(loginPra),
      }).then((result) => {
        result.json().then((resp) => {
          if (resp.hasErrors === false) {
            localStorage.setItem(
              "Auth-Token",
              JSON.stringify(resp.response.loginKey)
            );
            localStorage.setItem(
              "user-Logininfo",
              JSON.stringify(resp.response.user)
            );
            window.location.href = "/admin/dashboard";
          } else {
            alert("Invalid username or password.");
          }
        });
      });
    }
  }

  return (
    <div>
      <div className="login-13">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-md-12 bg-img">
              <div className="bg-img-inner">
                <Link to="/">
                  <img
                    src="../assets/images/logo1.svg"
                    alt="logo"
                    width="50%"
                    className="logo-design"
                  />
                </Link>
                <div className="info">
                  <div className="center">
                    <h1>Welcome To A2Zwork India</h1>
                  </div>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, Ipsum has been the
                    industry's standard dummy{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 form-info">
              <div className="form-section">
                <div className="form-section-innner">
                  <Link to="/">
                    <img
                      src="../assets/images/logo10.svg"
                      alt="logo"
                      width="60%"
                      className="logo-design-web"
                    />
                  </Link>
                  <h3>
                    <b>Service Provider Login</b>
                  </h3>
                  <div className="login-inner-form">
                    <div className="form-group form-box clearfix">
                      <input
                        name="email"
                        type="email"
                        className="form-control"
                        placeholder="Email Address"
                        aria-label="Email Address"
                        id="yourUsername"
                        value={username}
                        onChange={(e) => {
                          setUsername(e.target.value);
                        }}
                      />
                      <i className="fa fa-envelope" />
                    </div>
                    <div className="form-group form-box clearfix">
                      <input
                        name="password"
                        type="password"
                        className="form-control"
                        autoComplete="off"
                        placeholder="Password"
                        aria-label="Password"
                        id="yourPassword"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                      />
                      <i className="fa fa-lock flaticon22" />
                    </div>
                    <div className="checkbox form-group clearfix">
                      <div className="form-check float-start">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="rememberme"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="rememberme"
                        >
                          Remember me
                        </label>
                      </div>
                      <a
                        href="#"
                        className="link-light float-end forgot-password"
                      >
                        {/* forgot-password-13.htm */}
                        Forgot password?
                      </a>
                    </div>
                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn btn-primary btn-lg btn-theme"
                        onClick={fun_DyLogin}
                      >
                        Login
                      </button>
                    </div>
                  </div>
                  <ul className="social-list">
                    <li>
                      <GoogleOAuthProvider style={{}} clientId={ID}>
                        <GoogleLogin
                          onSuccess={(credentialResponse) => {
                            var decoded = jwt_decode(
                              credentialResponse.credential
                            );
                            console.log(decoded);
                            localStorage.setItem(
                              "Buyer-Login",
                              JSON.stringify(decoded)
                            );
                            //  navigate("/");
                          }}
                          onError={() => {
                            console.log("Login Failed");
                          }}
                        />
                      </GoogleOAuthProvider>
                    </li>
                  </ul>
                  <p className="none-2">
                    Don't have an account?{" "}
                    <Link to="/" className="thembo">
                      Register here
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceProviderLogin;
