import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "react-calendar/dist/Calendar.css";
import Card from "react-bootstrap/Card";
import { Link, json } from "react-router-dom";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CallIcon from '@mui/icons-material/Call';
import StarRateIcon from '@mui/icons-material/StarRate';
import RepeatIcon from "@mui/icons-material/Repeat";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import moment from 'moment'
function MyOrder() {



  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="my-orders">
        <Container>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <h2>Bookings & Plans</h2>

             
                    <div>
                      <h3 className="booking-div-14">Salon Prime for kids & men ( Screen 1 )</h3>
                      <Link to="/customer/order-details">
                      
                            <div className="my-order-inner my-order-inner-5">
                              <Row>
                                <Col xs={10} sm={10} md={10} lg={10}>
                                  <div className="booking-div-10">
                                  <h2>Booking Accepted</h2>
                                <h3>Haircut for men</h3>
                                <p> Wed 8 at 12:30 PM</p>
                                  </div>
                                </Col>
                                <Col xs={2} sm={2} md={2} lg={2}>
                                  <div className="booking-div-11">
                                    <KeyboardArrowRightIcon />
                                  </div>
                                </Col>
                                <div className="booking-div-12">
                                  <Row>
                                    <Col xs={6} sm={6} md={6} lg={6}>
                                      <div className="booking-div-13">
                                        <h3>Finding a Professional</h3>
                                      </div>
                                    </Col>
                                    <Col xs={6} sm={6} md={6} lg={6}>

                                      <div className="booking-div-11">
                                        <img src="../../assets/images/find.gif" />

                                      </div>
                                    </Col>
                                  </Row>
                                </div>

                              </Row>
                            </div>
                       
                      </Link>
                    </div>
              
                      <h3 className="booking-div-14">Salon Prime for kids & men ( Screen 2 )</h3>
                      <Link to="/customer/order-details">
                     
                        <div className="my-order-inner my-order-inner-5">
                          <Row>
                            <Col xs={10} sm={10} md={10} lg={10}>
                              <div className="booking-div-10">
                              <h2>Professional Assigned</h2>
                                <h3>Haircut for men</h3>
                                <p> Wed 8 at 12:30 PM</p>
                              </div>
                            </Col>
                            <Col xs={2} sm={2} md={2} lg={2}>
                              <div className="booking-div-11">
                                <KeyboardArrowRightIcon />
                              </div>
                            </Col>
                            <div className="booking-div-12">
                              <Row>
                                <Col xs={6} sm={6} md={6} lg={6}>
                                  <div className="booking-div-15">
                                    <img src="../../assets/images/chandu.jpeg" />

                                    <h3>Chetan<span><p className="rating-5"><StarRateIcon className="rating-6" />4.88</p></span></h3>

                                  </div>
                                </Col>
                                <Col xs={6} sm={6} md={6} lg={6}>

                                  <div className="booking-div-11">
                                    <CallIcon />

                                  </div>
                                </Col>
                              </Row>
                            </div>

                          </Row>
                        </div>
                       
                      </Link>
                 
             
                      <h3 className="booking-div-14">Salon Prime for kids & men ( Screen 1 )</h3>
                      <Link to="/customer/order-details">
                 
                        <div className="my-order-inner my-order-inner-5">
                          <Row>
                            <Col xs={10} sm={10} md={10} lg={10}>
                              <div className="booking-div-10">
                              <h2>Job completed</h2>
                                <h3>Haircut for men</h3>
                                <p> Wed 8 at 12:30 PM</p>
                              </div>
                            </Col>
                            <Col xs={2} sm={2} md={2} lg={2}>
                              <div className="booking-div-11">
                                <KeyboardArrowRightIcon />
                              </div>
                            </Col>
                            <div className="booking-div-12">
                              <Row>
                                <Col xs={6} sm={8} md={9} lg={10}>
                                  <div className="booking-div-15">
                                    {/* <img src="../../assets/images/chandu.jpeg" /> */}


                                    <p className="amount-pay-1"> <CheckCircleIcon className="amount-pay-icon" />Amount Pay ₹699 </p>

                                  </div>
                                </Col>
                                <Col xs={6} sm={4} md={3} lg={2}>

                                  <div className="">
                                    <Link to="/"><Button variant="contained" className="track-button-2">
                                      <RepeatIcon className="buy-icon" />
                                      Book Again
                                    </Button>
                                    </Link>

                                  </div>
                                </Col>
                              </Row>
                            </div>

                          </Row>
                        </div>
                      
                      </Link>
                  
              
                      <h3 className="booking-div-14">Salon Prime for kids & men ( Screen Cancellation )</h3>
                      <Link to="/customer/order-details">
                     
                        <div className="my-order-inner my-order-inner-5">
                          <Row>
                            <Col xs={10} sm={10} md={10} lg={10}>
                              <div className="booking-div-10">
                                <h2>Booking Cancelled</h2>
                                <h3>Haircut for men</h3>
                                <p> Wed 8 at 12:30 PM</p>
                              </div>
                            </Col>
                            <Col xs={2} sm={2} md={2} lg={2}>
                              <div className="booking-div-11">
                                <KeyboardArrowRightIcon />
                              </div>
                            </Col>

                          </Row>
                        </div>
                       
                      </Link>
                  










            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
export default MyOrder;
