import React, { useState, useEffect } from "react";
import moment from "moment";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Link } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import axios from 'axios';
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Row from "react-bootstrap/Row";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import dayjs from "dayjs";

function EditSPProfile() {
  var userLogininfo = JSON.parse(localStorage.getItem("user-Logininfo"));
  if (userLogininfo === null) {
    window.location.href = '/';
  }
  let authToken = `${process.env.REACT_APP_BASE_AUTHKEY}`;
  let baseURL = `${process.env.REACT_APP_BASE_URL}`;

  const LoginEdit = localStorage.getItem("LoginEdit");

  const [ContactName, setContactName] = useState("");
  const [CountryCode, setCountryCode] = useState("");
  const [Mobile, setMobile] = useState("");
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [DOB, setDOB] = React.useState(dayjs("2022-04-17"));
  const [Gender, setGender] = useState("");
  const [UserStatus, setUserStatus] = useState("");
  const [UserGroup, setUserGroup] = useState("");
  const [error, setError] = useState("");
  const [UserID, setUserID] = useState("");
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const [ProfilePic, setProfilePic] = useState(null);
  const [imgurl, setimgurl] = useState("");
  const [ProfileImg, setProfileImg] = useState(null);

  useEffect(() => {
    fun_ProfileData();
  }, []);

  function handleKeyPress(e) {
    var key = e.key;
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      e.preventDefault();
    }
  }

  function fun_SPProfile() {
    if (ContactName.trim() === "") {
      setError("required");
      return;
    } else if (Email.trim() === "") {
      setError("required");
      return;
    } else if (!emailRegex.test(Email.trim())) {
      setError("required");
      return;
    } else if (CountryCode.trim() === "") {
      setError('required"');
      return;
    } else if (Mobile === "") {
      setError('required"');
      return;
    } else if (DOB.trim() === "") {
      setError("required");
      return;
    } else if (Gender.trim() === "") {
      setError('required"');
      return;
    } else {
      const formData = [
        {
          CountryCode: CountryCode,
          UserName: ContactName,
          LoginMobile: parseInt(Mobile),
          UserGroup: UserGroup,
          UserStatus: UserStatus,
          rByMobile: "",
          Gender: Gender,
          cDt: "",
          UserID: UserID,
          Password: Password,
          DOB: DOB,
          LoginEmail: Email,
          DOA: "",
          rByUserID: "",
          rByUser: "",
          ProfilePic: imgurl,
        },
      ];

      fetch(baseURL + "/api/Dynamic/AddUpdateRecords?LoginType=2&tabErpID=1429", {
        method: "POST",
        headers: {
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(formData),
      }).then((result) => {
        result.json().then((resp) => {
          if (resp.hasErrors === false) {
            alert("Profile update successfully.");
            window.location.href = "/Common/SPProfile";
          }
        });
      });
    }
  }
  function fun_ProfileData() {
    // var fullName = [];
   let newusername = "{UserMobile:/^" + LoginEdit + "/i}";
    const loginPra = {
      pgSize: 100,
      index: 1,
      toEmailID: "designMonu@dialerp.com",
      tabErpID: 1429,
      filters: newusername,
    };
    fetch(baseURL + "/api/dynamic/GetRecords", {
      method: "POST",
      headers: {
        "Authorization-Token": authToken,
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(loginPra),
    }).then((result) => {
      result.json().then((resp) => {
        if (resp.hasErrors === false) {
          if (resp.response?.records !== null) {
            if (resp.response?.records.length > 0) {
              console.log(resp.response, "resp.response");
              setContactName(resp.response?.records[0]["User Name"]);
              setCountryCode(resp.response?.records[0]["Country Code"]);
              setEmail(resp.response?.records[0]["UserEmail"]);
              setUserID(resp.response?.records[0]["UserID"]);
              setGender(resp.response?.records[0]["Gender"]);
              setUserGroup(resp.response?.records[0]["User Group"]);
              setMobile(resp.response?.records[0]["User Mobile"]);
              setUserStatus(resp.response?.records[0]["User Status"]);
              setProfilePic(resp.response?.records[0]["Profile Pic"])
              setimgurl(resp.response?.records[0]["Profile Pic"])


              setDOB(
                moment(resp.response?.records[0]["DOB"]).format("yyyy-MM-DD")
              );
            }
          }
        } else {
          alert(resp.errors);
        }
      });
    });
  }
  const [file, setFile] = useState("");
  const handleFile = (e, column) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        if (img.naturalWidth === 400 && img.naturalHeight === 400) {
          ProfileImgUpload(e.target.files[0], column);
        }
        else {
          alert('Image dimensions must be 400x400 pixels.')
        }
      };
      img.src = event.target.result;
    };

    reader.readAsDataURL(file);
  };

  const ProfileImgUpload = (e, column) => {
    setFile(URL.createObjectURL(e));
    const formData = new FormData();
    formData.append(column, e);

    axios.post('https://api.dialerp.com/api/api/UploadCommonFilesToAzure', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization-Token': authToken,
      },
    }).then((response) => {
      if (response.data.hasErrors === true) {
        alert(response.data.errors);
      }
      else if (response.data.response !== null) {
        setimgurl(response.data.response[0].imageUrl);

      }
    })
      .catch((err) => alert(err));
  };

  // function ProfileImgUpload() {

  //   const formData = new FormData();
  //   if (ProfileImg !== null && ProfileImg !== undefined) {
  //     formData.append("myFile", ProfileImg)
  //     formData.append("name", ProfileImg.name);
  //     setProfileImg(ProfileImg.name);

  //     axios.post('https://api.dialerp.com/api/api/UploadCommonFilesToAzure', formData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //         'Authorization-Token': authToken,
  //       },
  //     }).then((response) => {
  //       if (response.data.hasErrors === true) {
  //         // toastr.info(response.data.errors);
  //       }
  //       else if (response.data.response !== null) {
  //         setimgurl(response.data.response[0].imageUrl);

  //       }
  //     })
  //       .catch((err) => alert(err));
  //   }
  // };

  function fun_Cancel() {
    window.location.href = "/Common/SPProfile";
  }

  return (
    <div>
      <div className="login-18">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="form-section">
                <div className="typing typing-2">
                  <h3>Edit Profile</h3>
                </div>
                <Row>
                  <Col xs={12} sm={8} md={8}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control
                        type="file"
                        placeholder="Upload Image"
                        className="upload-image-22"
                        //value={ProfileImg} onChange={(e) => setProfileImg(e.target.files[0])}
                        // onClick={ProfileImgUpload()}
                        onChange={(e) => { handleFile(e, "ProfileImg"); }}
                        name="attachments[]"
                        accept="image/*, application/pdf"
                        id="upload"
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={4} md={4}>
                    {
                      imgurl != null ? <img src={imgurl}
                        alt="Profile Image"
                        className="upload-image-2" /> :
                        ''
                    }

                  </Col>
                </Row>






                <TextField
                  id="outlined-basic"
                  className={`width-100 ${error && ContactName.trim() === "" ? "is-invalid" : ""
                    }`}
                  label="Contact Name"
                  defaultValue="ContactName"
                  value={ContactName}
                  onChange={(e) => {
                    setContactName(e.target.value);
                  }}
                  variant="outlined"
                />
                <br />
                <br />
                {error && ContactName.trim() === "" && (
                  <div className="invalid-feedback">
                    Please enter Contact Name
                  </div>
                )}

                <TextField
                  id="outlined-basic"
                  className={`width-100 ${error &&
                      (Email.trim() === "" || !emailRegex.test(Email.trim()))
                      ? "is-invalid"
                      : ""
                    }`}
                  label="Email"
                  defaultValue="Email"
                  value={Email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  variant="outlined"
                />
                <br />
                <br />

                {error && Email.trim() === "" && (
                  <div className="invalid-feedback">Please enter Email</div>
                )}
                {error &&
                  Email.trim() !== "" &&
                  !emailRegex.test(Email.trim()) && (
                    <div className="invalid-feedback">
                      Please enter a valid email address
                    </div>
                  )}

                <TextField
                  id="outlined-basic"
                  className={`width-100  ${error && CountryCode.trim() === "" ? "is-invalid" : ""
                    }`}
                  label="Country Code"
                  defaultValue="CountryCode"
                  value={CountryCode}
                  onChange={(e) => {
                    setCountryCode(e.target.value);
                  }}
                  onKeyPress={(e) => handleKeyPress(e)}
                  variant="outlined"
                />
                <br />
                <br />
                {error && CountryCode.trim() === "" && (
                  <div className="invalid-feedback">
                    Please enter CountryCode
                  </div>
                )}

                <TextField
                  id="outlined-basic"
                  className={`width-100  ${error && CountryCode.trim() === "" ? "is-invalid" : ""
                    }`}
                  label="Mobile"
                  defaultValue="Mobile"
                  value={Mobile}
                  onChange={(e) => {
                    setMobile(e.target.value);
                  }}
                  onKeyPress={(e) => handleKeyPress(e)}
                  variant="outlined"
                />
                <br />
                <br />
                {error && Mobile === "" && (
                  <div className="invalid-feedback">Please enter Mobile</div>
                )}

                <TextField
                  name="DOB"
                  type="Date"
                  label="Date of Birth"
                  className={`width-100  ${error && DOB.trim() === "" ? "is-invalid" : ""
                    }`}
                  autoComplete="off"
                  id="third_field"
                  placeholder="DOB"
                  aria-label="DOB"
                  value={DOB}
                  onChange={(e) => {
                    setDOB(e.target.value);
                  }}
                />
                {error && DOB.trim() === "" && (
                  <div className="invalid-feedback">Please Select DOB</div>
                )}

                <br />
                <br />
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    className={`form-control-bb ${error && Gender.trim() === "" ? "is-invalid" : ""
                      }`}
                    value={Gender}
                    onChange={(e) => {
                      setGender(e.target.value);
                    }}
                    label="Gender"
                  // onChange={handleChange}
                  >
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                  </Select>
                  {error && Gender.trim() === "" && (
                    <div className="invalid-feedback">Please select gender</div>
                  )}
                </FormControl>
                <br />
                <br />



                <Stack spacing={2} direction="row" className="submit-service">
                  <Button variant="outlined" onClick={fun_Cancel}>Cancel</Button>
                  <Button variant="contained" onClick={fun_SPProfile}> Update </Button>

                </Stack>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditSPProfile;
