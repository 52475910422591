/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "./Header";
import Footer from "./Footer";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Card from "react-bootstrap/Card";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import GradeIcon from "@mui/icons-material/Grade";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import TextField from "@mui/material/TextField";
import { Link, useParams } from "react-router-dom";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import AddAddress from "./Customer/AddAddress";
import DeliveryAddress from "./Customer/DeliveryAddress";
import Badge from "react-bootstrap/Badge";
import { ProductContext } from "./context/AppContext";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import ClearIcon from "@mui/icons-material/Clear";

import AddIcon from "@mui/icons-material/Add";
import RemoveSharpIcon from "@mui/icons-material/RemoveSharp";
import moment from "moment";
import { Blind } from "@mui/icons-material";

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import MainCategory from "./MainCategory";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CustomerLogin from "./Customer/CustomerLogin";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Ragistration from "./Customer/Ragistration";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { StepIcon } from "@mui/material";
import BookingConfirmed from "./Customer/BookingConfirmed";

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { clear } from "@testing-library/user-event/dist/clear";
import { fetchApiWithLoginToken, fetchWrapper } from "./apiUtils";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const steps = ["Book Appointment", "Address", "Appointment Summary"];

function ViewBookingNew() {
  const [activeStep, setActiveStep] = useState();
  const { step } = useParams();
  const [AutoactiveStep, setAutoactiveStep] = useState(0);
  const {
    CardList,
    setCardList,
    handleClickopenAddress,
    handleCloseopenAddress,
    handleClickNewAddress,
    handleCloseNewAddress,
    CandidateActiveAddress,
    openCustomerLogin, handleClickOpenCustomerLogin, handleCloseCustomerLogin, handleClickOpenUserMobile, setAuthkeyTEXT, setLoginType
    , handleClickLoader, handleCloseLoader
  } = useContext(ProductContext);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  let authToken = `${process.env.REACT_APP_BASE_AUTHKEY}`;
  let baseURL = `${process.env.REACT_APP_BASE_URL}`;

  var userLogininfo = JSON.parse(localStorage.getItem("user-Logininfo"));
  const [skipped, setSkipped] = React.useState(new Set());
  const [value, onChange] = useState(new Date());
  const [AppointmentTime, setAppointmentTime] = useState("");
  const [time24, settime24] = useState(0);

  const DeliveryAddressOBJData = JSON.parse(
    localStorage.getItem("deliveryaddress")
  );

  const today = new Date();
  const nextThreeDays = new Date(today.setDate(today.getDate() + 30));
  today.setTime(today.getTime() + 2 * 60 * 60 * 1000);
  const time = today.toLocaleTimeString("en-US", {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
  });
  const currentDate = new Date().toISOString().split("T")[0];
  const handleNext = (i) => {
    window.scrollTo(0, 0);
    if (AppointmentTime === "") {
      alert("Please select appointment time");
      document.getElementById("backto0index").onclick();
      return;
    }
    else {
      if (i === 1) {
        let fdate = moment(value).format("YYYY-MM-DD");
        if (currentDate < fdate) {
          const appointmentobj = {
            AppointmentDate: value,
            AppointmentTime: AppointmentTime,
            time24: time24,
          };
          localStorage.setItem(
            "Appointmentobj",
            JSON.stringify(appointmentobj)
          );
        } else if (currentDate === fdate && time < time24) {
          const appointmentobj = {
            AppointmentDate: value,
            AppointmentTime: AppointmentTime,
            time24: time24,
          };
          localStorage.setItem(
            "Appointmentobj",
            JSON.stringify(appointmentobj)
          );
        } else {
          alert("Please select valid appointment time");
          return;
        }
      }
      if (userLogininfo === null) {
        localStorage.setItem("CardLogin", "CardLogin");
        setLoginType("Customer Login");
        setAuthkeyTEXT("vNA7UP2NdlzIyUTcc5PMqxbuLwFV2qE16i15J4D9dd5uvmES3sOwchZqN04oGjd0NpigKNAxcORxKVn/nxafML8ko9t6Y8m2KpRx6nsUReA=");
        handleClickOpenUserMobile();
        handleClickOpenUserMobile();
      }
      else if (userLogininfo["User Group"] !== "Customer") {
        window.location.href = "/not-found";
      } else {
        document.getElementById("btnNext1").click();
      }
    }
  };

  const [openApply, setOpenApply] = React.useState(false);

  const handleClickOpenApply = () => {
    setOpenApply(true);
  };
  const handleCloseApply = () => {
    setOpenApply(false);
  };
  const [openThankyou, setOpenThankyou] = React.useState(false);

  const handleClickOpenThankyou = () => {
    setOpenThankyou(true);
  };
  const handleCloseThankyou = () => {
    setOpenThankyou(true);
  };

  const [openTermsCondition, setOpenTermsCondition] = React.useState(false);
  const handleClickOpenTermsCondition = () => {
    setOpenTermsCondition(true);
  };
  const handleCloseTermsCondition = () => {
    setOpenTermsCondition(false);
  };
  const [openTaxe, setOpenTaxe] = React.useState(false);
  const handleClickOpenTaxe = () => {
    setOpenTaxe(true);
  };
  const handleCloseTaxe = () => {
    setOpenTaxe(false);
  };

  const [openPrivacyPolicy, setOpenPrivacyPolicy] = React.useState(false);
  const handleClickOpenPrivacyPolicy = () => {
    setOpenPrivacyPolicy(true);
  };
  const handleClosePrivacyPolicy = () => {
    setOpenPrivacyPolicy(false);
  };

  const [openCancellationPolicy, setOpenCancellationPolicy] =
    React.useState(false);
  const handleClickOpenCancellationPolicy = () => {
    setOpenCancellationPolicy(true);
  };

  const handleCloseCancellationPolicy = () => {
    setOpenCancellationPolicy(false);
  };

  useEffect(() => {
    getcompanyLederdata();
    setAutoactiveStep(step);
    setActiveStep(step);
    console.log(CardList, "CardList");
    const CartItemms = JSON.parse(localStorage.getItem("CartItemms")) || [];
    setCardList(CartItemms);
    const AppointmentobjData = JSON.parse(localStorage.getItem("Appointmentobj"));
    if (AppointmentobjData !== null) {
      onChange(AppointmentobjData["AppointmentDate"]);
      let fdate = moment(AppointmentobjData["AppointmentDate"]).format( "YYYY-MM-DD");
      if (currentDate < fdate) {
        setAppointmentTime(AppointmentobjData["AppointmentTime"]);
        settime24(AppointmentobjData["time24"]);
      } else if (currentDate === fdate && time < AppointmentobjData["time24"]) {
        setAppointmentTime(AppointmentobjData["AppointmentTime"]);
        settime24(AppointmentobjData["time24"]);
      } else {
        localStorage.removeItem("Appointmentobj");
        setAppointmentTime("");
        settime24(0);
        if (AutoactiveStep > 0) {
          window.location.href = "/customer/view-booking/0";
        }
      }
    } else {
      if (parseInt(AutoactiveStep) > 0 && parseInt(AutoactiveStep) !== 2) {
        window.location.href = "/customer/view-booking/0";
      }
    }
    fun_CaltotalAmount();
    if (CartItemms !== null && CartItemms?.length > 0) {
      let carddata0 = CartItemms[0]["data"];
      setBacktoCartUrl(`/service/${carddata0["Category"]}/${carddata0["Sub Category"]}`);
    } else {
      setBacktoCartUrl("/");
    }

  }, [step]);
  const [BacktoCartUrl, setBacktoCartUrl] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    const CartItemms = JSON.parse(localStorage.getItem("CartItemms")) || [];
    if ((CartItemms === null || CartItemms === "" || CartItemms?.length <= 0) && parseInt(AutoactiveStep) !== 2) {
      localStorage.removeItem("Appointmentobj");

    }
    if (userLogininfo !== null && userLogininfo !== "" && userLogininfo !== undefined) {
    ListBillingAddress();
    }
  }, [CardList]);
  const CustomStepIcon = (props) => {
    let cactive = parseInt(step) + 1;
    const { active, completed } = props;
    let iconColor = '#757575'; // Default color
    if (active) {
      iconColor = '#1976d2'; // Change color to blue for the active step
    } else if (completed) {
      iconColor = '#1976d2'; // Change color to green for completed steps
    }
    else if (parseInt(cactive) === props.icon) {
      iconColor = '#1976d2'; // Change color to blue for the active step
    }
    return <StepIcon {...props} style={{ color: iconColor }} />;
  };
  const AppointmentData = [
    { d: "08:00", value: "08:00 AM" },
    { d: "08:30", value: "08:30 AM" },
    { d: "09:00", value: "09:00 AM" },
    { d: "09:30", value: "09:30 AM" },
    { d: "10:00", value: "10:00 AM" },
    { d: "10:30", value: "10:30 AM" },
    { d: "11:00", value: "11:00 AM" },
    { d: "11:30", value: "11:30 AM" },
    { d: "12:00", value: "12:00 PM" },
    { d: "12:30", value: "12:30 PM" },
    { d: "13:00", value: "01:00 PM" },
    { d: "13:30", value: "01:30 PM" },
    { d: "14:00", value: "02:00 PM" },
    { d: "14:30", value: "02:30 PM" },
    { d: "15:00", value: "03:00 PM" },
    { d: "15:30", value: "03:30 PM" },
    { d: "16:00", value: "04:00 PM" },
    { d: "16:30", value: "04:30 PM" },
    { d: "17:00", value: "05:00 PM" },
    { d: "17:30", value: "05:30 PM" },
    { d: "18:00", value: "06:00 PM" },
    { d: "18:30", value: "06:30 PM" },
    { d: "19:00", value: "07:00 PM" },
    { d: "19:30", value: "07:30 PM" },
    { d: "20:00", value: "08:00 PM" },
    { d: "20:30", value: "08:30 PM" },
    { d: "21:00", value: "09:00 PM" },
  ];
  const handleSelect = (selectedItem, time24) => {
    setAppointmentTime(selectedItem);
    settime24(time24);
    handleCloseReschedule();
  };

  const [openCustomerRagistration, setOpenCustomerRagistration] =
    React.useState(false);
  const handleClickOpenCustomerRagistration = () => {
    setOpenCustomerRagistration(true);
  };
  const handleCloseCustomerRagistration = () => {
    setOpenCustomerRagistration(false);
  };

  const [openReschedule, setOpenReschedule] = React.useState(false);
  const handleClickOpenReschedule = () => {
    setOpenReschedule(true);
  };
  const handleCloseReschedule = () => {
    setOpenReschedule(false);
  };

  const fun_addtocartItem = (data, i) => {
    let fltCard = CardList.filter((item) => item["ItemID"] === data["ItemID"]);
    if (fltCard?.length > 0) {
      const updatedCard = CardList.map((item) => {
        if (item["ItemID"] === data["ItemID"]) {
          const qutcount = item["Quanity"] + 1;
          let itemtotal = parseFloat( parseFloat(data?.data["MRP"] || 0) * qutcount );
          return {
            ...item, ItemID: data["ItemID"], ItemTotal: itemtotal, Quanity: qutcount,
          };
        }
        return item;
      });
      setCardList(updatedCard);
      localStorage.setItem("CartItemms", JSON.stringify(updatedCard));
    } else {
      const updatedCard = [
        ...CardList,
        {
          data,
          ItemID: data["ItemID"],
          ItemTotal: parseFloat(data["MRP"]),
          Quanity: 1,
        },
      ];
      setCardList(updatedCard);
      localStorage.setItem("CartItemms", JSON.stringify(updatedCard));
    }
    fun_CaltotalAmount();
  };
  const fun_RemovetocartItem = (data, i) => {
    let fltCard = CardList.filter((item) => item["ItemID"] === data["ItemID"]);
    if (fltCard[0]["Quanity"] > 1) {
      const updatedCard = CardList.map((item) => {
        if (item["ItemID"] === data["ItemID"]) {
          const qutcount = item["Quanity"] - 1;
          let itemtotal = parseFloat(
            parseFloat(data?.data["MRP"] || 0) * qutcount
          );
          return {
            ...item,
            ItemID: data["ItemID"],
            ItemTotal: itemtotal,
            Quanity: qutcount,
          };
        }
        return item;
      });
      setCardList(updatedCard);
      localStorage.setItem("CartItemms", JSON.stringify(updatedCard));
    } else {
      let fltCard = CardList.filter(
        (item) => item["ItemID"] !== data["ItemID"]
      );
      //const updatedCard = [...fltCard, { data, ItemID: data["ItemID"], Quanity: 1 }];
      setCardList(fltCard);
      localStorage.setItem("CartItemms", JSON.stringify(fltCard));
    }
    fun_CaltotalAmount();
  };
  const [totalAmont, settotalAmont] = useState(0);
  const [GSTAmount, setGSTAmount] = useState(0);
  const [Discount, setDiscount] = useState(0);
  const [NetPrice, setNetPrice] = useState(0);
  const [Tipforprofessional, setTipforprofessional] = useState(0);

  const fun_CaltotalAmount = () => {
    let totalAmount1 = 0;
    const CartItemmsTot = JSON.parse(localStorage.getItem("CartItemms")) || [];
    CartItemmsTot.forEach((data) => {
      totalAmount1 += parseFloat(data["ItemTotal"] || 0);
    });
    settotalAmont(totalAmount1);
    const GSTAmount = (parseFloat(totalAmount1) * 28) / 100;
    setGSTAmount(GSTAmount);
    const NetPrice = parseFloat(
      totalAmount1 + GSTAmount + Tipforprofessional - Discount
    ).toFixed(2);
    setNetPrice(NetPrice);
  };


  const [loading, setLoading] = useState(false);
  const handleAddAddressTImeSlot = () => {
    const addressLenght = CandidateActiveAddress?.length;
    if (addressLenght > 0) {
      handleClickOpenReschedule();
    } else {
      handleClickopenAddress();
    }
  };
  const [otpList, setOtpList] = useState([]);
  const generateUniqueOtp = () => {
    const generateRandomOtp = () => Math.floor(1000 + Math.random() * 9000);

    let newOtp;
    do {
      newOtp = generateRandomOtp();
    } while (otpList.includes(newOtp));
    setOtpList(prevOtpList => [...prevOtpList, newOtp]);
    return newOtp.toString();
  };
  const [BillingAddressList, setBillingAddressList] = useState([]);
  function ListBillingAddress() {
    let newusername = `{'LoginMobile':${userLogininfo["Login Mobile"]}}`;
    const AllAddPara = {
      pgSize: 100,
      index: 1,
      toEmailID: "designMonu@dialerp.com",
      tabErpID: 1452,
      filters: newusername,
    };
    fetch(baseURL + "/api/dynamic/GetRecords", {
      method: "POST",
      headers: {
        "Authorization-Token": authToken,
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(AllAddPara),
    }).then((result) => {
      result.json().then((resp) => {
        //handleCloseLoader();
        if (resp.hasErrors === false) {
          if (resp.response?.records !== null) {
            if (resp.response?.records.length > 0) {
              setBillingAddressList(resp.response);
            }
          }
        } else {
          alert(resp.errors);
        }
      });
    });
  }

 function fun_AddBooking(Viewtype,id,vchNo) {
     localStorage.removeItem("BookingID");
    let apdate = moment(value).format("DD-MMM-YYYY");
    let carddata0 = CardList[0]["data"];
    //console.log("DeliveryAddressOBJData", DeliveryAddressOBJData);    
    const shipto = {
      "LedgerID":  BillingAddressList?.records?.[0]?.["LedgerID"],
      "LedgerName":  BillingAddressList?.records?.[0]?.["Ledger Name"],
      "ErpName": BillingAddressList?.records?.[0]?.["Ledger Name"],
      "LedgerGroup": BillingAddressList?.records?.[0]?.["Ledger Group"],
      "LedgerGroupID": BillingAddressList?.records?.[0]?.["Ledger GroupID"],
      "AddressStr": BillingAddressList?.records?.[0]?.["Address"],
      "AddressLine1": "",
      "AddressLine2": "",
      "StateCode": BillingAddressList?.records?.[0]?.["Status Code"],
      "City": BillingAddressList?.records?.[0]?.["City"],
      "State": BillingAddressList?.records?.[0]?.["State"],
      "GstType": 0,
      "GroupType": "",
      "Pincode": BillingAddressList?.records?.[0]?.["Pincode"],
      "UserID": 0
    };
    const bookingData = [
      {
        CustMobile: userLogininfo["Login Mobile"],
        CustName: userLogininfo["User Name"],
        CustID: userLogininfo["UserID"],
        CustGroup: userLogininfo["User Group"],
        Category: carddata0["Category"],
        SubCategory: carddata0["Sub Category"],
        AppointmentDt: apdate + " " + time24, //value+' '+ AppointmentTime,
        Status: "Open",
        Stage: "Booking Assigned",
        BookingType: "Fresh",
        BookingTypeID: 1,
        AddressType: "Home",
        Address: BillingAddressList?.records?.[0]?.["Address"]||" ",
        Pincode: BillingAddressList?.records?.[0]?.["Pincode"]|| " ",
        City: BillingAddressList?.records?.[0]["City"],
        State: BillingAddressList?.records?.[0]["State"],
        Country: BillingAddressList?.records?.[0]["Country"],
        ContactName: BillingAddressList?.records?.[0]["Contact Name"],
        ContactMobile: BillingAddressList?.records?.[0]["Contact Mobile"],
        JobEndOTP: generateUniqueOtp(),
        JobStartOTP: generateUniqueOtp(),
        OrderID: id,
        InvoiceNo: vchNo,
        EstimateAmt: totalAmont,
        TaxAmt: GSTAmount,
        TotalAmt: NetPrice,
        PaidtoCompany: 0,
        PaidToProfessional: 0,
        BalAmt: NetPrice,
        CustRemarks: JSON.stringify({
          CardDetails: CardList, TotalAmt: totalAmont, BalAmt: NetPrice,
          TaxAmt: GSTAmount, EstimateAmt: 0, PaidToCompany: 0, PaidToProfessional: 0, Discount: Discount
          , BillTo: BillTo,shipto:shipto
        }),
      },
    ];
    fetchApiWithLoginToken(bookingData, "Dynamic/AddUpdateRecords?LoginType=2&tabErpID=1258")
      .then((resp) => {
        debugger;
        if (resp?.hasErrors === false && resp?.response !== null) {
          localStorage.setItem("BookingID", resp.response.id);
          handleCloseLoader();
          if (Viewtype === "Web")
            document.getElementById("btnNext2").click();
          else
            handleClickOpenThankyou();
        } else {
          handleCloseLoader();
          alert("Something Wrong ?"+resp.errors);
        }
      });
  }
//console.log(BillingAddressList?.[0]?.["LedgerID"],"BillingAddressList");
// console.log(BillingAddressList,"BillingAddressList");
  const BillTo = {
    "LedgerID": BillingAddressList?.records?.[0]["FirmID"],
    "LedgerName":  BillingAddressList?.records?.[0]["Ledger Name"],
    "ErpName":  BillingAddressList?.records?.[0]["User Name"],
    "LedgerGroup":  BillingAddressList?.records?.[0]["Ledger Group"],
    "LedgerGroupID":  BillingAddressList?.records?.[0]["Ledger GroupID"],
    "AddressStr": "",
    "AddressLine1": BillingAddressList?.records?.[0]["Address"],
    "AddressLine2": "",
    "StateCode":  BillingAddressList?.records?.[0]["State Code"],
    "City":  BillingAddressList?.records?.[0]["City"],
    "State":  BillingAddressList?.records?.[0]["State"],
    "GstType": 0,
    "GroupType": "",
    "Pincode":  BillingAddressList?.records?.[0]["Pincode"],
    "UserID":  BillingAddressList?.records?.[0]["UserID"]
  }

  const formattedDate = moment(today).format('YYYY-MM-DD');
  const updatedObjItems = CardList.map((cartItem, index) => {
    // Your function to assign values based on cartItem
    const totalamount = cartItem?.data?.MRP * cartItem?.Quanity;
    debugger
    const newItem = {
      ItemID: 3,
      Item: cartItem?.data?.Item,
      BaseUnit: cartItem?.data?.["Base Unit"],
      BaseUnitID: 0,
      SaleUnit: cartItem?.data?.["Base Unit"],
      SaleUnitID: 0,
      Uom: cartItem?.data?.Uom,
      Size: cartItem?.data?.Size,
      Conversion: cartItem?.data?.Conversion,
      AltUnit1: cartItem?.data?.["Alt Unit1"],
      AltUnit2: cartItem?.data?.["Alt Unit2"],
      AltUnit3: cartItem?.data?.["Alt Unit3"],
      MRP: cartItem?.data?.MRP,
      MSP: cartItem?.data?.["MSP (Taxes Extra)"],
      IGST: cartItem?.data?.["IGST%"],
      CGST: cartItem?.data?.["CGST%"],
      SGST: cartItem?.data?.["SGST%"],
      AutofillTxt: "GALLANT CEMENT PPC LPP BAG 50KG(3)",
      Qty: cartItem?.Quanity,
      DisPer: 1,
      NetRate: totalamount,
      BillAmt: totalamount,
      StockType: 0,
      AvailableStock: 0,
      ObjInventoryLst: null,
      SalePriceAmt: totalamount,
      UomQty: "1 mt",
      ExtraCessAmt: 0,
      CessPer: 0,
      CessAmt: 0,
      NetAmt: totalamount,
      DisAmt: 0,
      IgstAmt: 0,
      TtTaxAmt: GSTAmount,
      TtTaxPer: 28,
      TcsAmt: 0,
      TcsPer: 0,
      TtExtraCessAmt: 0,
      TtCessAmt: 0,
      BillAmount: totalamount,
      ObjTaxLst: [{ TaxName: "IGST", TaxAmt: GSTAmount, TaxtPer: 28 }],
    };
    return newItem;
  });
  const [LedgerDatalist, setLedgerDatalist] = useState("");
  const getcompanyLederdata = () => {
    let newusername = "{UnderLedgerGroupID:1}";
    const checkjobsPra = {
      pgSize: 5000,
      index: 1,
      toEmailID: "designMonu@dialerp.com",
      tabErpID: 1453,
      useAtlusSearch: false,
      filters: newusername,
    };
    let endpointURL = "Dynamic/GetRecords";
    try {
      fetchWrapper(checkjobsPra, endpointURL, authToken)
        .then((resp) => {
          if (resp?.errorCode === "HTTP error") {
            alert("Request not valid");
            return;
          }
          if (resp.hasErrors === false) {
            if (resp.response?.records !== null) {
              if (resp.response?.records.length > 0) {
                setLedgerDatalist(resp.response);
              }
              else {
                alert("No Ledgers Found!");
              }
            }
          } else {
            alert("Invalid Request.");
          }
        })
        .catch((error) => {
          console.error("Failed to fetch user information", error);
        });
    } catch (error) {
      alert(error.message);
    }
  };
  function savebookinggenerateSOD(Viewtype) {
    // console.log("LedgerDatalist",LedgerDatalist);
    const obj = {
      "TaxInclusion": "0",
      "VchDate": formattedDate,
      "SoName": "",
      "Currency": "INR",
      "VchType": 3,
      "ExtraRemarks": "",
      "BillFrom": {
        "LedgerID": LedgerDatalist?.records?.[0]["LedgerID"],
        "LedgerName": LedgerDatalist?.records?.[0]["Ledger Name"],
        "ErpName": "",
        "LedgerGroup": LedgerDatalist?.records?.[0]["Ledger Group"],
        "LedgerGroupID": LedgerDatalist?.records?.[0]["Ledger GroupID"],
        "AddressStr": "",
        "AddressLine1": LedgerDatalist?.records?.[0]["Address"],
        "AddressLine2": "",
        "StateCode": LedgerDatalist?.records?.[0]["State Code"],
        "City": LedgerDatalist?.records?.[0]["City"],
        "State": LedgerDatalist?.records?.[0]["State"],
        "GstType": 0,
        "GroupType": "",
        "Pincode": LedgerDatalist?.records?.[0]["Pincode"],
        "OffPhone": LedgerDatalist?.records?.[0]["Ph (O)"],
        "OffEmail": LedgerDatalist?.records?.[0]["Email (O)"],
        "Gst": LedgerDatalist?.records?.[0]["GST"],
        "UserID": 0
      },
      "ShipFrom": {
        "LedgerID": 0,
        "LedgerName": "",
        "ErpName": "",
        "LedgerGroup": "",
        "LedgerGroupID": 0,
        "AddressStr": "",
        "AddressLine1": "",
        "AddressLine2": "",
        "StateCode": "",
        "City": "",
        "State": "",
        "GstType": 0,
        "GroupType": "",
        "Pincode": 0,
        "OffPhone": "",
        "OffEmail": "",
        "Gst": "",
        "UserID": 0
      },
      "BillTo": BillTo,
      "ShipTo": {
        "LedgerID": 0,
        "LedgerName": "",
        "ErpName": "",
        "LedgerGroup": "",
        "LedgerGroupID": 0,
        "AddressStr": "",
        "AddressLine1": "",
        "AddressLine2": "",
        "StateCode": "",
        "City": "",
        "State": "",
        "GstType": 0,
        "GroupType": "",
        "Pincode": 0,
        "UserID": 0
      },
      "NetAmt": NetPrice,
      "DiscountAmt": Discount,
      "TaxAmt": GSTAmount,
      "TotalAmt": totalAmont,
      "RoundOffAmt": 0,
      "TdsPercent": 0,
      "TdsAmt": 0,
      "TcsPer": 0,
      "TcsAmt": 0,
      "TtCessAmt": 0,
      "TtExtraCessAmt": 0,
      "PayableAmt": NetPrice,
      "TotalQty": CardList?.length,
      "TaxType": "2",
      "ObjTtTaxes": [
        {
          "TaxName": "IGST",
          "TaxtPer": 28,
          "TaxAmt": GSTAmount
        }
      ],
      "ObjItems": updatedObjItems,
      "UserID": 40396,
      "toEmailID": "designMonu@dialerp.com",
      "vchNo":generateUniqueOtp(),
    }



    fetchWrapper(obj, "finance/generateSOD?appname=web&tmpUId=40396&tmpPId=40396", authToken)
      .then((resp) => {
        if (resp.hasErrors === false) {
          if (resp?.response?.id !== undefined && resp?.response?.vchNo !== undefined) {
            fun_AddBooking(Viewtype, resp?.response?.id, resp?.response?.vchNo);
          }
          else {
            alert("Request failed");
            handleCloseLoader();
            return;
          }
        }
        else {
          alert("Something Wrong"+resp.errors);
          handleCloseLoader();
          return;
        }
      });
  }
  function bookingValidation(Viewtype) {
    handleClickLoader();
    localStorage.removeItem("BookingID");
    let apdate = moment(value).format("DD-MMM-YYYY");
    if (CardList?.length === 0 || CardList === undefined) {
      alert("Add something in your cart");
      handleCloseLoader();
      return
    }
    else if (apdate === "" || apdate === undefined) {
      alert("Invalid apointment date.");
      handleCloseLoader();
      return
    }
    else if (DeliveryAddressOBJData?.length === 0 || DeliveryAddressOBJData === undefined) {
      alert("Add address");
      handleCloseLoader();
      return
    }
    else {
      savebookinggenerateSOD(Viewtype);
    }
  }

  return (
    <>
      {/* <div>
      <button onClick={handleClick} disabled={loading}>
        {loading ? 'Loading...' : 'Click Me'}
      </button>
    </div> */}

      <Link to="/customer/view-booking/1" id="btnNext1" hidden></Link>
      <Link to="/customer/view-booking/2" id="btnNext2" hidden></Link>

      <div>
        {/* Web View */}
        <div className="web-view">
          <Header />

          <div className="top-space">
            <Container fluid>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <div className="booking-view">
                    {/* const steps = ["Book Appointment", "Address", "Appointment Summary"]; */}
                    <Stepper activeStep={activeStep}>
                      <Step>
                        <StepLabel StepIconComponent={CustomStepIcon}>Book Appointment</StepLabel>
                      </Step>
                      <Step >
                        <StepLabel StepIconComponent={CustomStepIcon}>Booking Summary</StepLabel>
                      </Step>
                      <Step>
                        <StepLabel StepIconComponent={CustomStepIcon}>Book Confirm</StepLabel>
                      </Step>
                    </Stepper>


                    <React.Fragment>
                      <Typography sx={{ mt: 2, mb: 1 }}>
                        {AutoactiveStep === "0" && (
                          <>
                            <section className="section-step-1">
                              <Container fluid>
                                <Row>
                                  <Col xs={12} sm={12} md={4}>
                                    <h2>
                                      <span className="back-arrow-5" >
                                        <Link to={BacktoCartUrl}>
                                          <KeyboardBackspaceIcon className="back-icon-arrow" />
                                        </Link>
                                      </span>
                                      Appointment Date
                                    </h2>
                                    <Calendar onChange={onChange} value={value} minDate={new Date()} maxDate={nextThreeDays}
                                    />
                                  </Col>
                                  <Col xs={12} sm={12} md={8} className="time-slot-button"
                                  >
                                    <div className="time-slot">
                                      <h2>Appointment Time</h2>
                                      <ul>
                                        {AppointmentData.map((dd, i) => {
                                          if (currentDate < moment(value).format("YYYY-MM-DD")) {
                                            return (
                                              <li key={dd["value"]} onClick={() => handleSelect(dd["value"], dd["d"])} className={AppointmentTime === dd["value"] ? "active-button-bb" : ""} >
                                                {dd["value"]}
                                              </li>
                                            );
                                          }
                                          if (currentDate === moment(value).format("YYYY-MM-DD") && time < dd["d"]
                                          ) {
                                            return (
                                              <li key={dd["value"]} onClick={() => handleSelect(dd["value"], dd["d"])}
                                                className={AppointmentTime === dd["value"] ? "active-button-bb" : ""}
                                              >
                                                {dd["value"]}
                                              </li>
                                            );
                                          }
                                        })}
                                      </ul>
                                    </div>
                                    {/* <Button hidden variant="contained" id="login-first" className='next-button-5"' onClick={() => { handleClickOpenCustomerLogin() }}>Login</Button> */}
                                    <Button variant="contained" id="backto0index" onClick={() => { handleNext(1); }} className="next-button-5" >
                                      Next
                                    </Button>
                                  </Col>
                                </Row>
                              </Container>
                            </section>
                          </>
                        )}
                        {/* {AutoactiveStep === "1" && (
                          <section className="section-step-1">
                            <Container fluid>
                              <Row>
                               
                                <Col xs={12} sm={12} md={7}>
                                <div className="summary-left-fixed">

                                <h2>
                                    <span
                                      className="back-arrow-5"
                                      onClick={() => {
                                        handleBack(0);
                                      }}
                                    >
                                      <KeyboardBackspaceIcon className="back-icon-arrow" />
                                    </span>
                                    Select Address
                                  </h2>
                                  <div className="left-right-radio">
                                    <div className="left-view-booking-1">
                                      <span>
                                        <AccessTimeIcon className="clock-icon location-icon-1" />
                                      </span>
                                      <div className="width-100">
                                        <p>
                                          <b>Slot</b>
                                        </p>
                                        <p>
                                          {moment(value).format(
                                            "ddd, MMM DD -"
                                          )}{" "}
                                          {AppointmentTime}
                                        </p>
                                       
                                      </div>
                                     
                                    </div>
                                  </div>
                                  <DeliveryAddress />
                                  </div>
                                </Col>
                                <Col
                                  xs={12}
                                  sm={12}
                                  md={5}
                                  className="time-slot-button"
                                >
                                  <div className="cart-section-1">
                                    <div className="empty-cart empty-cart-margin-bottom-55 cart-scroll-1">
                                      <h4>Cart</h4>
                                      {CardList?.map((data, maini) => {
                                        return (
                                          <>
                                            <div className="cart-div-22">
                                              <h5 key={maini}>
                                                {data?.data["Item Name"]}
                                              </h5>
                                              <div className="add-button-service">
                                                <Button variant="text" onClick={() => fun_RemovetocartItem( data, maini ) } >
                                                  <RemoveSharpIcon className="add-icon-1" />
                                                </Button>
                                                <span>{data["Quanity"]}</span>
                                                <Button variant="text" onClick={() => fun_addtocartItem( data, maini)} >
                                                  <AddIcon className="add-icon-1" />
                                                </Button>
                                              </div>
                                              <span className="price-span1">
                                                ₹ {parseFloat(data?.data["MRP"] || 0) * data["Quanity"]}
                                              </span>
                                            </div>
                                          </>
                                        );
                                      })}
                                      <p className="add1div">
                                      
                                        Add 1 more service, Get 30% off 2nd one
                                        onwards
                                       
                                      </p>
                                    </div>
                                  </div>
                               
                                  <Button variant="contained" onClick={() => { handleNext(2); }} className="next-button-5" > Next </Button>
                                </Col>
                              </Row>
                            </Container>
                          </section>
                        )} */}
                        {AutoactiveStep === "1" && (
                          <section className="section-step-1 section-step-scroll">
                            <Container fluid>
                              <Row>

                                <Col xs={12} sm={12} md={6}>
                                  <div className="summary-left-fixed">
                                    <div className="time-slot">
                                      <h2>
                                        <span
                                          className="back-arrow-5"

                                        >

                                          <Link to="/customer/view-booking/0"
                                          // onClick={() => {
                                          //   handleBack(0);
                                          // }}
                                          >
                                            <KeyboardBackspaceIcon className="back-icon-arrow" />
                                          </Link>
                                        </span>
                                        Booking Summary
                                      </h2>
                                    </div>
                                    {/* <div className="left-view-booking-4 left-view-booking-41">
                                <h2>
                                <p><b>Slot</b></p>
                                  <AccessTimeIcon className="clock-icon" /> {moment(value).format("DD-MMM-YYYY")}  {AppointmentTime}
                                  <span style={{ paddingTop: "9px" }}>
                                    <Button variant="contained" className="next-button-6" onClick={handleClickOpenReschedule} >
                                      Change
                                    </Button>
                                  </span></h2>
                              </div> */}
                                    <div className="left-right-radio">
                                      <div className="left-view-booking-1">
                                        <span>
                                          <AccessTimeIcon className="clock-icon location-icon-1" />
                                        </span>
                                        <div className="width-100">
                                          <p>
                                            <b>Slot</b>
                                          </p>
                                          <p>
                                            {moment(value).format(
                                              "ddd, MMM DD -"
                                            )}{" "}
                                            {AppointmentTime}
                                          </p>
                                          {/* ddd, MMM DD - hh:mm A */}
                                          {/* DD-MMM-YYYY */}
                                        </div>
                                        <span>
                                          <Button
                                            variant="outlined"
                                            className="next-button-11"
                                            onClick={handleClickOpenReschedule}
                                          >
                                            edit
                                          </Button>
                                        </span>
                                      </div>
                                    </div>
                                    {/* <h2 className="address-new-class">Address</h2> */}
                                    <DeliveryAddress />
                                    <div className="payment-section-1">
                                      <h2 className="address-new-class">
                                        Payment Method
                                      </h2>
                                      {/* <h2 className="address-new-class" onClick={() => { handleClickOpenThankyou() }}>Payment Method</h2> */}
                                      {CandidateActiveAddress?.length > 0 ?
                                        <>{
                                          loading ?
                                            <h2 className="loading-total-amount width-100">
                                              Loading...
                                            </h2>
                                            :
                                            <h2 className="total-amount width-100"
                                              onClick={() => bookingValidation("Web")} >
                                              <span>Pay ₹{NetPrice}</span>
                                            </h2>
                                        }
                                        </> : <h2 className="loading-total-amount width-100">
                                          <span>Pay ₹{NetPrice}</span>
                                        </h2>
                                      }
                                    </div>
                                    <div className="payment-section-2">
                                      <p className="payment-section-condition">
                                        By proceeding, you agree to our
                                        <span>
                                          <Link to="" onClick={() => { handleClickOpenTermsCondition(); }} > Terms & Condition,</Link>
                                        </span>
                                        <span>
                                          <Link to="" onClick={() => { handleClickOpenPrivacyPolicy(); }} > Privacy policy
                                          </Link>
                                          and
                                        </span>
                                        <span>
                                          <Link to="" onClick={() => { handleClickOpenCancellationPolicy(); }}  >
                                            Cancellation Policy
                                          </Link>
                                        </span>{" "}
                                      </p>
                                    </div>
                                    {/* <div className="payment-section-2">
                                  <h2 className="address-new-class">Cancellation policy</h2>
                                  <p>Free cancellations if done more than 3 hrs before the service or if a
                                    professional isn’t assigned. A fee will be charged otherwise.</p>
                                </div> */}
                                  </div>
                                </Col>
                                <Col xs={12} sm={12} md={6} className="view-div-scroll-2">
                                  <div className="time-slot box-shadow">
                                    <Card className="service-booking-cerd-2">
                                      <Card.Body>
                                        <div className="cart-section-1">
                                          <div className="empty-cart">
                                            <h4>Cart</h4>
                                            {CardList?.map((data, maini) => {
                                              return (
                                                <>
                                                  <div className="cart-div-22">
                                                    <h5 key={maini}>
                                                      {data?.data["Item"]}
                                                    </h5>
                                                    <div className="add-button-service">
                                                      <Button
                                                        variant="text"
                                                        onClick={() =>
                                                          fun_RemovetocartItem(
                                                            data,
                                                            maini
                                                          )
                                                        }
                                                      >
                                                        <RemoveSharpIcon className="add-icon-1" />
                                                      </Button>
                                                      <span>
                                                        {data["Quanity"]}
                                                      </span>
                                                      <Button
                                                        variant="text"
                                                        onClick={() =>
                                                          fun_addtocartItem(
                                                            data,
                                                            maini
                                                          )
                                                        }
                                                      >
                                                        <AddIcon className="add-icon-1" />
                                                      </Button>
                                                    </div>
                                                    <span className="price-span1">
                                                      ₹
                                                      {parseFloat(
                                                        data?.data["MRP"] || 0
                                                      ) * data["Quanity"]}
                                                    </span>
                                                  </div>
                                                </>
                                              );
                                            })}
                                            <p className="add1div add1div-55">
                                              {/* <img src='../../assets/images/coin.png'alt="coin" /> */}
                                              Add 1 more service, Get 30% off
                                              2nd one onwards
                                              <span>
                                                <Link

                                                  // onClick={() => {
                                                  //   handlebackurl();
                                                  // }}
                                                  to={BacktoCartUrl}
                                                ><Button className="next-button-7 cs-55" variant="contained">
                                                    <AddOutlinedIcon />Add More Service</Button></Link>
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                        <p>
                                          Item Total<span>₹{totalAmont}</span>
                                        </p>
                                        <p>
                                          Item Discount<span>₹{Discount}</span>
                                        </p>
                                        <p>
                                          {" "}
                                          Tip for Professional
                                          <span>₹{Tipforprofessional}</span>
                                        </p>
                                        <p>
                                          Coupon Discount
                                          <Badge
                                            className="apply-1"
                                            bg="secondary"
                                            onClick={() => {
                                              handleClickOpenApply();
                                            }}
                                          >
                                            {" "}
                                            Apply
                                          </Badge>
                                          <span>₹0</span>
                                        </p>
                                        <p hidden>
                                          Previous cancellations/reschedules
                                          <span>₹0</span>
                                        </p>
                                        <p
                                          onClick={() => {
                                            handleClickOpenTaxe();
                                          }}
                                        >
                                          Taxes and Fee<span>₹{GSTAmount}</span>
                                        </p>
                                        <p className="total-amount-2 total-amount-2-web">
                                          Total<span>₹{NetPrice}</span>
                                        </p>
                                      </Card.Body>
                                    </Card>
                                  </div>
                                </Col>
                              </Row>
                            </Container>
                          </section>
                        )}
                        {AutoactiveStep === "2" &&
                          <BookingConfirmed />
                        }
                        {/* {activeStep + 1} */}
                      </Typography>
                      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}> </Box>
                    </React.Fragment>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* <Footer /> */}
        </div>
        {/* Web View */}

        {/* Mobile View */}
        <div className="mobile-view">
          <div className="">
            <div className="my-orders">
              <h2 className="mobile-header-1">
                <Link to="/">
                  <ArrowBackIcon className="mobile-header-1-left-icon" />
                </Link>
                Summary
                {/* <span className="mobile-search-icon-1">
                <SearchOutlinedIcon />
              </span> */}
              </h2>
            </div>
            <div className="summary-background">
              <div className="top-space-mobile">
                <div className="time-slot">
                  <div className="cart-section-1 cart-section-1-mobile">
                    <div className="empty-cart">
                      <h4>Cart</h4>
                      {CardList?.map((data, maini) => {
                        return (
                          <>
                            <div className="cart-div-22">
                              <h5 key={maini}>{data?.data["Item"]}</h5>
                              <div className="add-button-service">
                                <Button
                                  variant="text"
                                  onClick={() =>
                                    fun_RemovetocartItem(data, maini)
                                  }
                                >
                                  <RemoveSharpIcon className="add-icon-1" />
                                </Button>
                                <span>{data["Quanity"]}</span>
                                <Button
                                  variant="text"
                                  onClick={() => fun_addtocartItem(data, maini)}
                                >
                                  <AddIcon className="add-icon-1" />
                                </Button>
                              </div>
                              <span className="price-span1">
                                ₹
                                {parseFloat(data?.data["MRP"] || 0) *
                                  data["Quanity"]}
                              </span>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="time-slot">
                  <Card className="service-booking-cerd-2 service-booking-cerd-2-mobile">
                    <Card.Body>
                      <h4>Payment Summary</h4>
                      <p>
                        Item Total<span>₹{totalAmont}</span>
                      </p>
                      <p>
                        Item Discount<span>-₹{Discount}</span>
                      </p>
                      <p>
                        {" "}
                        Tip for professional<span>₹{Tipforprofessional}</span>
                      </p>
                      <p>
                        Coupon Discount
                        <Badge
                          className="apply-1"
                          bg="secondary"
                          onClick={() => {
                            handleClickOpenApply();
                          }}
                        >
                          {" "}
                          Apply
                        </Badge>
                        <span>₹0</span>
                      </p>
                      <p hidden>
                        Previous cancellations/reschedules<span>₹0</span>
                      </p>
                      <p
                        onClick={() => {
                          handleClickOpenTaxe();
                        }}
                      >
                        Taxes and Fee<span>₹{GSTAmount}</span>
                      </p>
                      <p className="total-amount-2">
                        Total<span>₹{NetPrice}</span>
                      </p>
                    </Card.Body>
                  </Card>
                </div>
                <div className="payment-section-2 cancellation-mobile-1">
                  <h2>Cancellation policy</h2>
                  <p>
                    Free cancellations if done more than 3 hrs before the
                    service or if a professional isn’t assigned. A fee will be
                    charged otherwise.
                    <Button
                      onClick={handleClickOpenCancellationPolicy}
                      className="leacn-cancellation"
                      variant="text"
                    >
                      Learn
                    </Button>
                  </p>
                </div>

                <div className="summary-footer-mobile">
                  <div className="address-time-slot">
                    {CandidateActiveAddress?.map((status, i) => {
                      if (i === 0) {
                        return (
                          <div className="width-100 div-inline-css-1" key={i}>
                            <p className="mobile-address-show">
                              <HomeOutlinedIcon className="home-icon-1" />
                              <span>
                                <b>{status["Account Type"]} - </b>
                                {status["Address"]},{status["City"]} (
                                {status["State"]}). {status["Country"]}{" "}
                                {status["Pincode"]}
                              </span>

                              <EditOutlinedIcon
                                onClick={handleClickopenAddress}
                                className="edit-icon-1-mobile"
                              />
                            </p>
                          </div>
                        );
                      }
                    })}
                  </div>
                  <div className="address-time-slot ">
                    {AppointmentTime !== "" && AppointmentTime !== null &&
                      CandidateActiveAddress?.map((status, i) => {
                        if (i === 0) {
                          return (
                            <div className="width-100 div- inline-css-1">
                              <p className="mobile-address-show">
                                <AccessTimeIcon className="home-icon-1" />
                                <span>
                                  {moment(value).format(
                                    "ddd, MMM DD -"
                                  )}{" "}
                                  {AppointmentTime}
                                </span>

                                <EditOutlinedIcon
                                  onClick={handleClickOpenReschedule}
                                  className="edit-icon-1-mobile"
                                />
                              </p>
                            </div>
                          );
                        }
                      })}
                  </div>
                  {AppointmentTime !== "" && AppointmentTime !== null && CandidateActiveAddress?.length > 0 ? (
                    <Button onClick={() => {
                      bookingValidation("Mobile");
                    }}>
                      Pay Now
                    </Button>
                  ) : (
                    CandidateActiveAddress?.length <= 0 ?
                      <Button onClick={handleAddAddressTImeSlot}>
                        Add Address
                      </Button> :
                      <Button onClick={handleAddAddressTImeSlot}>
                        Add Slot
                      </Button>
                  )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Mobile View */}
        <Dialog
          open={openApply}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseApply}
          aria-describedby="alert-dialog-slide-description"
          BackdropProps={{
            style: { backgroundColor: 'rgba(0, 0, 0, 0.9)' },
          }}
        >
          <DialogContent className="section-step-1">
            <h2>
              Use Coupon
              <ClearIcon
                className="cross-icon"
                onClick={() => {
                  handleCloseApply();
                }}
              />
            </h2>
            <DialogContentText id="alert-dialog-slide-description">
              <TextField
                id="outlined-basic"
                label="Enter Coupon Code"
                className="width-100"
                variant="outlined"
              />
              <Button
                className="service-button-2"
                onClick={() => {
                  handleCloseApply();
                }}
              >
                Save
              </Button>
            </DialogContentText>
          </DialogContent>
        </Dialog>

        {/* Thankyou */}

        <Dialog
          open={openThankyou}
          onClose={handleCloseThankyou}
          BackdropProps={{
            style: { backgroundColor: 'rgba(0, 0, 0, 0.9)' },
          }}
        >
          <DialogContent className="section-step-1 section-step-12 mobile-thankyou-popup">

            <BookingConfirmed />

          </DialogContent>
        </Dialog>


        {/* Terms & Condition */}
        <Dialog
          open={openTermsCondition}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseTermsCondition}
          aria-describedby="alert-dialog-slide-description"
          BackdropProps={{
            style: { backgroundColor: 'rgba(0, 0, 0, 0.9)' },
          }}
        >
          <DialogContent className="section-step-1 section-step-12">
            <h2>
              Terms & Condition
              <ClearIcon
                className="cross-icon"
                onClick={() => {
                  handleCloseTermsCondition();
                }}
              />
            </h2>
            <hr />
            <DialogContentText
              id="alert-dialog-slide-description"
              className="tnc"
            >
              <h3>Terms and Condition</h3>
              <p>
                The Terms constitute a binding and enforceable legal contract
                between A2Z Work (a company
                incorporated under the Companies Act, 2013 with its registered
                address at H-23, 2nd Floor, Sector 63, Noida Uttar Pradesh -
                201301 and its principal place of business at 608, Padma
                Tower-II, Rajendra Place, New Delhi-110008. ). By using the
                Services, you represent and warrant that you have full legal
                capacity and authority to agree to and bind yourself to these
                Terms. If you represent any other person, you confirm and
                represent that you have the necessary power and authority to
                bind such person to these Terms.
              </p>
              <p>
                By using the Services, you agree that you have read, understood,
                and are bound by, these Terms, as amended from time to time, and
                that you will comply with the requirements listed here. These
                Terms expressly supersede any prior written agreements with you.
                If you do not agree to these Terms, or comply with the
                requirements listed here, please do not use the Services.
              </p>
              <Button className="service-button width-100-percentage">
                <Link
                  to=""
                  onClick={() => {
                    handleCloseTermsCondition();
                  }}
                >
                  Okay, got it
                </Link>
              </Button>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        {/* Terms & Condition */}
        <Dialog
          open={openTaxe}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseTaxe}
          aria-describedby="alert-dialog-slide-description"
          BackdropProps={{
            style: { backgroundColor: 'rgba(0, 0, 0, 0.9)' },
          }}
        >


          <DialogContent className="section-step-1 section-step-12">
            <h2>
              Taxes & Fee
              <ClearIcon
                className="cross-icon"
                onClick={() => {
                  handleCloseTaxe();
                }}
              />
            </h2>
            <hr />
            {/* <hr /> */}
            <DialogContentText
              id="alert-dialog-slide-description"
              className="tnc"
            >
              <h3>What is Taxes and Fee?</h3>
              <p>
                Taxes levied as per Govt. regulations, subject to change basis
                final service value. The fee goes towards training of partners
                and providing support & assistance during the service.
              </p>
              <Button className="service-button width-100-percentage">
                <Link
                  to=""
                  onClick={() => {
                    handleCloseTaxe();
                  }}
                >
                  Okay, got it
                </Link>
              </Button>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        <Dialog
          open={openPrivacyPolicy}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClosePrivacyPolicy}
          aria-describedby="alert-dialog-slide-description"
          BackdropProps={{
            style: { backgroundColor: 'rgba(0, 0, 0, 0.9)' },
          }}
        >
          <DialogContent className="section-step-1 section-step-12">
            <h2>
              Privacy Policy
              <ClearIcon
                className="cross-icon"
                onClick={() => {
                  handleClosePrivacyPolicy();
                }}
              />
            </h2>
            <hr />
            <DialogContentText
              id="alert-dialog-slide-description"
              className="tnc"
            >
              <h3>Privacy Policy</h3>
              <p>
                The Terms constitute a binding and enforceable legal contract
                between A2Z Work (a company
                incorporated under the Companies Act, 2013 with its registered
                address at H-23, 2nd Floor, Sector 63, Noida Uttar Pradesh -
                201301 and its principal place of business at 608, Padma
                Tower-II, Rajendra Place, New Delhi-110008. ). By using the
                Services, you represent and warrant that you have full legal
                capacity and authority to agree to and bind yourself to these
                Terms. If you represent any other person, you confirm and
                represent that you have the necessary power and authority to
                bind such person to these Terms.
              </p>
              <p>
                By using the Services, you agree that you have read, understood,
                and are bound by, these Terms, as amended from time to time, and
                that you will comply with the requirements listed here. These
                Terms expressly supersede any prior written agreements with you.
                If you do not agree to these Terms, or comply with the
                requirements listed here, please do not use the Services.
              </p>
              <Button className="service-button width-100-percentage">
                <Link
                  to=""
                  onClick={() => {
                    handleClosePrivacyPolicy();
                  }}
                >
                  Okay, got it
                </Link>
              </Button>
            </DialogContentText>
          </DialogContent>
        </Dialog>

        <Dialog
          open={openCancellationPolicy}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseCancellationPolicy}
          aria-describedby="alert-dialog-slide-description"
          BackdropProps={{
            style: { backgroundColor: 'rgba(0, 0, 0, 0.9)' },
          }}
        >
          <DialogContent className="section-step-1 section-step-12">
            <h2>
              Cancellation Policy
              <ClearIcon
                className="cross-icon"
                onClick={() => {
                  handleCloseCancellationPolicy();
                }}
              />
            </h2>
            <hr />
            <DialogContentText
              id="alert-dialog-slide-description"
              className="tnc"
            >
              {/* <h3>Cancellation Policy</h3> */}
              <p>
              A2Z Work Company has a fair cancellation policy. Our
                Professionals block time for jobs, and cancellations lead to
                lost revenue for them. We only charge cancellation fees when a
                Professional has been assigned to a job (since they no longer
                get any other jobs for that time). Depending on the service,
                customers have some time after placing the request to cancel the
                job without incurring a fees
              </p>
              <Button className="service-button width-100-percentage">
                <Link
                  to=""
                  onClick={() => {
                    handleCloseCancellationPolicy();
                  }}
                >
                  Okay, got it
                </Link>
              </Button>
            </DialogContentText>
          </DialogContent>
        </Dialog>

        <Dialog
          fullScreen={fullScreen}
          open={openCustomerLogin}
          onClose={handleCloseCustomerLogin}
          aria-labelledby="responsive-dialog-title"
          className="login-popup-1"
          BackdropProps={{
            style: { backgroundColor: 'rgba(0, 0, 0, 0.9)' },
          }}
        >
          <CloseIcon
            className="cross-login-5"
            onClick={() => {
              handleCloseCustomerLogin();
            }}
          />

          <CustomerLogin />
          <p className="account-type-show">
            Don't have an account?
            <Link
              to=""
              onClick={() => {
                handleClickOpenCustomerRagistration();
                handleCloseCustomerLogin();
              }}
            >
              {" "}
              Register here
            </Link>
          </p>
        </Dialog>

        <Dialog
          fullScreen={fullScreen}
          open={openCustomerRagistration}
          onClose={handleCloseCustomerRagistration}
          aria-labelledby="responsive-dialog-title"
          className="login-popup-1"
          BackdropProps={{
            style: { backgroundColor: 'rgba(0, 0, 0, 0.9)' },
          }}
        >
          <CloseIcon
            className="cross-login-5"
            onClick={() => {
              handleCloseCustomerRagistration();
            }}
          />
          <Ragistration />
          <p className="account-type-show">
            Existing User
            <Link
              to=""
              onClick={() => {
                handleClickOpenCustomerLogin();
                handleCloseCustomerRagistration();
              }}
            >
              {" "}
              Login
            </Link>
          </p>
        </Dialog>

        <Dialog
          open={openReschedule}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseReschedule}
          aria-describedby="alert-dialog-slide-description"
          className="reschedule-div-1"
          BackdropProps={{
            style: { backgroundColor: 'rgba(0, 0, 0, 0.9)' },
          }}
        >
          <DialogContent className="reschedule-div-1 reschedule-div-1-mobile">
            <DialogContentText id="alert-dialog-slide-description">
              <h2 className="reason-1 web-view">
                <span>
                  <ClearIcon
                    className="cross-icon"
                    onClick={handleCloseReschedule}
                  />
                </span>
              </h2>

              <section className="section-step-1">
                <Container fluid>
                  <Row>
                    <Col xs={12} sm={12} md={4}>
                      <h2 className="web-view">
                        {/* <Link to={BacktoCartUrl}>
                                    <KeyboardBackspaceIcon className="back-icon-arrow" />
                                  </Link> */}
                        {/* <span className="back-arrow-5" onClick={() => { handlebackurl() }}><KeyboardBackspaceIcon className="back-icon-arrow" /></span> */}
                        Appointment Date
                      </h2>
                      <h2 className="reason-1 mobile-view">
                        Appointment Date
                        <span>
                          <ClearIcon
                            className="cross-icon"
                            onClick={handleCloseReschedule}
                          />
                        </span>
                      </h2>

                      <Calendar
                        onChange={onChange}
                        value={value}
                        minDate={new Date()}
                        maxDate={nextThreeDays}
                      />
                    </Col>
                    <Col xs={12} sm={12} md={8} className="time-slot-button">
                      <div className="time-slot">
                        <h2>Appointment Time</h2>
                        <ul>
                          {AppointmentData.map((dd, i) => {
                            if (
                              currentDate < moment(value).format("YYYY-MM-DD")
                            ) {
                              return (
                                <li key={dd["value"]}
                                  onClick={() => handleSelect(dd["value"], dd["d"])}
                                  className={
                                    AppointmentTime === dd["value"]
                                      ? "active-button-bb"
                                      : ""
                                  }
                                >
                                  {dd["value"]}
                                </li>
                              );
                            }
                            if (currentDate === moment(value).format("YYYY-MM-DD") && time <= dd["d"]) {
                              return (
                                <li key={dd["value"]} onClick={() => handleSelect(dd["value"], dd["d"])} className={AppointmentTime === dd["value"] ? "active-button-bb" : ""}>{dd["value"]}
                                </li>
                              );
                            }
                          })}
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </section>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div >
    </>
  );
}

export default ViewBookingNew;
