/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import Banner from './Banner';
import StarsIcon from '@mui/icons-material/Stars';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import AddIcon from '@mui/icons-material/Add';
import RemoveSharpIcon from '@mui/icons-material/RemoveSharp';
import LocalOfferSharpIcon from '@mui/icons-material/LocalOfferSharp';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import InputAdornment from '@mui/material/InputAdornment';
import RedoIcon from '@mui/icons-material/Redo';
import NearMeIcon from '@mui/icons-material/NearMe';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { red } from '@mui/material/colors';
import { LabelList } from 'recharts';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Service 1',
  'Service 2',
  'Service 3',
  'Service 4',
  'Service 5',
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
function ACServiceRepiar() {

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //Store data in Local Storage Start
  var itemName = "", Price = "", Quantity = "", ItemDescription = "", Servicetype = "", BookingType = ""
  const AddItemData = (itemName, Price, Quantity, ItemDescription, Servicetype) => {
    const obj = {
      itemName: itemName,
      Price: Price,
      Quantity: Quantity,
      ItemDescription: ItemDescription,
      Servicetype: Servicetype,
      //BookingType:"Booking Accepted"
      BookingType: "Professional Assigned"
      //BookingType:"Job completed"
      //BookingType: "Booking Cancelled"

    };
    localStorage.setItem('ACServiceRepairItem', JSON.stringify({ ...obj }));
  };
  //End
  return (
    <div>
      <Header />
      <div className='top-space'>
        <Container fluid>
          <Row>
            <Col xs={12} sm={12} md={6} lg={4}>
              <div className='service-section-1'>
                <div className='banner-section-1'>
                  <h2>AC Service and Repair</h2>
                  <p><StarsIcon className='star-icon2' />4.84 (6.4 M Bookings)</p>
                </div>
                <div className='banner-section-2'>
                  <p className='buy-2'>Select a service</p>
                  <Row>
                    <Col xs={4} md={4}>
                      <div className='banner-div-2'>
                        <img src='../assets/images/ss1.webp' />
                        <p>Service </p>

                      </div>
                    </Col>
                    <Col xs={4} md={4}>
                      <div className='banner-div-2'>
                        <img src='../assets/images/ss2.webp' className='banner-div-2-active' />
                        <p>Repair & gas refill </p>

                      </div>
                    </Col>
                    <Col xs={4} md={4}>
                      <div className='banner-div-2'>
                        <img src='../assets/images/ss3.webp' />
                        <p>Install & uninstall</p>

                      </div>
                    </Col>

                  </Row>
                </div>


                <div className='scroll-div-2'>

                  <div className='left-service-div-1'>
                    <h4>Category <span className='see-all-1' onClick={handleClickOpen}>See All</span></h4>
                    <FormGroup>
                      <FormControlLabel className='text-size' control={<Checkbox defaultChecked />} label="AC Repair and Service" />
                      <FormControlLabel className='text-size' required control={<Checkbox />} label="Air Cooler Repair" />
                      <FormControlLabel className='text-size' required control={<Checkbox />} label="Air Purifier" />
                      <FormControlLabel className='text-size' required control={<Checkbox />} label="Laptop repair(at home)" />

                      <FormControlLabel className='text-size' disabled control={<Checkbox />} label="Refrigerator" />
                      {/* <Button variant='outlined' onClick={handleClickOpen} >More</Button> */}
                    </FormGroup>
                  </div>



                  <div className='left-service-div-1'>
                    <h4>Sub Category</h4>
                    <FormGroup>
                      <FormControlLabel className='text-size' control={<Checkbox defaultChecked />} label="Salon for women" />
                      <FormControlLabel className='text-size' required control={<Checkbox />} label="Spa for women" />
                      <FormControlLabel className='text-size' required control={<Checkbox />} label="Hair studio for women" />
                      <FormControlLabel className='text-size' required control={<Checkbox />} label="Full home cleaning" />
                    </FormGroup>
                  </div>
                  <div className='left-service-div-3'>
                    <FormControl className='width-100' >
                      <InputLabel id="demo-multiple-chip-label">Child Category</InputLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={personName}
                        onChange={handleChange}
                        input={<OutlinedInput id="select-multiple-chip" label="Child Category" />}
                        renderValue={(selected) => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                              <Chip key={value} label={value} />
                            ))}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        {names.map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                            style={getStyles(name, personName, theme)}
                          >
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className='left-service-div-3'>

                    <FormControl variant="outlined" className='width-100'>
                      <InputLabel htmlFor="outlined-adornment-password">Service City</InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        endAdornment={
                          <InputAdornment position="end">
                            <LocationOnIcon />

                          </InputAdornment>
                        }
                        label="Service City"
                      />
                    </FormControl>
                  </div>
                  <div className='left-service-div-3'>

                    <FormControl variant="outlined" className='width-100'>
                      <InputLabel htmlFor="outlined-adornment-password">Service Area</InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        endAdornment={
                          <InputAdornment position="end">
                            <NearMeIcon />

                          </InputAdornment>
                        }
                        label="Service Area"
                      />
                    </FormControl>
                  </div>




                </div>
              </div>
            </Col>

            <Col xs={12} sm={12} md={6} lg={8}>
              <div className='banner-section-1'>
                <img src='../assets/images/servicebanner.webp' className='banner-section-img' />
                <div className='section-service'>

                  <div className='div-service-1'>
                    <Container fluid>
                      <Row>
                        <Col xs={12} sm={12} md={12}>
                          <div className='inner-service-1'>
                            <h2>Service</h2>

                            <Row>
                              <Col xs={12} sm={12} md={12} lg={8}>
                                <p className='service-warrantry'>30 Days Warrantry</p>
                                <h3>Deep clean AC service (split)</h3>
                                <p className='rating-star'><StarsIcon className='star-icon3' />4.84 (6.4 M Bookings)</p>
                                <p>₹699  <span>45 mins</span></p>
                                <ul>
                                  <li>Get 2X deeper dust removal with foamjet technology</li>
                                  <li>Thorough cleaning of both indoor & outdoor AC units</li>
                                </ul>
                                <Button variant="text"><Link to="/ac-service-repair-details">View Details</Link></Button>

                              </Col>
                              <Col xs={12} sm={12} md={12} lg={4}>
                                <div className='service-images-2'>
                                  <img src='../assets/images/s10.webp' width="100%" />
                                  <Button variant="outlined">Add</Button>
                                </div>

                              </Col>
                            </Row>
                          </div>

                          <div className='inner-service-1'>
                            <Row>
                              <Col xs={12} sm={12} md={12} lg={8}>
                                <p className='service-warrantry'>30 Days Warrantry</p>
                                <h3>Deep clean AC service (split)</h3>
                                <p className='rating-star'><StarsIcon className='star-icon3' />4.84 (6.4 M Bookings)</p>
                                <p>₹699  <span>45 mins</span></p>
                                <ul>
                                  <li>Get 2X deeper dust removal with foamjet technology</li>
                                  <li>Thorough cleaning of both indoor & outdoor AC units</li>
                                </ul>
                                <Button variant="text"><Link to="/ac-service-repair-details">View Details</Link></Button>

                              </Col>
                              <Col xs={12} sm={12} md={12} lg={4}>
                                <div className='service-images-5 centered-content'>
                                  <img src='../assets/images/s10.webp' width="100%" />

                                  <div className='add-button-service centered-button' >
                                    <Button variant="text"><RemoveSharpIcon className='add-icon-1' /></Button>
                                    <span>2</span>
                                    <Button variant="text"><AddIcon className='add-icon-1' /></Button>
                                  </div>


                                </div>

                              </Col>
                            </Row>
                          </div>
                          <div className='inner-service-1'>

                            <Row>
                              <Col xs={12} sm={12} md={12} lg={8}>
                                <p className='service-warrantry'>30 Days Warrantry</p>
                                <h3>Deep clean AC service (split)</h3>
                                <p className='rating-star'><StarsIcon className='star-icon3' />4.84 (6.4 M Bookings)</p>
                                <p>₹699  <span>45 mins</span></p>
                                <ul>
                                  <li>Get 2X deeper dust removal with foamjet technology</li>
                                  <li>Thorough cleaning of both indoor & outdoor AC units</li>
                                </ul>
                                <Button variant="text"><Link to="/ac-service-repair-details">View Details</Link></Button>

                              </Col>
                              <Col xs={12} sm={12} md={12} lg={4}>
                                <div className='service-images-2'>
                                  <img src='../assets/images/s10.webp' width="100%" />
                                  <Button variant="outlined">Add</Button>
                                </div>

                              </Col>
                            </Row>
                          </div>
                          <div className='inner-service-1'>

                            <Row>
                              <Col xs={12} sm={12} md={12} lg={8}>
                                <p className='service-warrantry'>30 Days Warrantry</p>
                                <h3>Deep clean AC service (split)</h3>
                                <p className='rating-star'><StarsIcon className='star-icon3' />4.84 (6.4 M Bookings)</p>
                                <p>₹699  <span>45 mins</span></p>
                                <ul>
                                  <li>Get 2X deeper dust removal with foamjet technology</li>
                                  <li>Thorough cleaning of both indoor & outdoor AC units</li>
                                </ul>
                                <Button variant="text"><Link to="/ac-service-repair-details">View Details</Link></Button>

                              </Col>
                              <Col xs={12} sm={12} md={12} lg={4}>
                                <div className='service-images-2'>
                                  <img src='../assets/images/s10.webp' width="100%" />
                                  <Button variant="outlined">Add</Button>
                                </div>

                              </Col>
                            </Row>
                          </div>

                        </Col>

                      </Row>
                    </Container>

                  </div>
                  <div className='div-service-2'>
                    <div className='empty-cart'>
                      <img src='../assets/images/shopping2.png' width="100%" />
                      <p>No items in your cart</p>
                    </div>
                    <div className='empty-cart'>
                      <h4>Cart</h4>
                      <h5>Deep clean AC service (split)</h5>
                      <div className='add-button-service'>
                        <Button variant="text"><RemoveSharpIcon className='add-icon-1' /></Button>
                        <span>2</span>
                        <Button variant="text"><AddIcon className='add-icon-1' /></Button>
                      </div>
                      <span className='price-span1'>₹699</span>
                      <p className='add1div'>Add 1 more service, Get 30% off 2nd one onwards</p>
                      <div className='view-cart-1'><span className='price-span1'>₹699</span> <Link to="/view-booking" onClick={() => AddItemData(itemName = "Haircut For Men", Price = "699", Quantity = "1", ItemDescription = "Get 2X deeper dust removal with foamjet technology", Servicetype = "Salon Prime for kids & men ( Screen 2 )")}>View Cart</Link></div>
                    </div>
                  </div>


                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Footer />

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        className='ac-service-seeall-popup'
      >
        <DialogContent className='more-popup'>
          <DialogContentText id="alert-dialog-slide-description">
            <div className='left-service-div-1 left-service-div-5'>
              <h4>Category <span onClick={handleClose}>X</span></h4>
              <FormGroup>
                <FormControlLabel className='text-size' control={<Checkbox defaultChecked />} label="AC Repair and Service" />
                <FormControlLabel className='text-size' required control={<Checkbox />} label="Air Cooler Repair" />
                <FormControlLabel className='text-size' required control={<Checkbox />} label="Air Purifier" />
                <FormControlLabel className='text-size' required control={<Checkbox />} label="Laptop repair(at home)" />
                <FormControlLabel className='text-size' disabled control={<Checkbox />} label="Refrigerator" />
              </FormGroup>
            </div>
          </DialogContentText>
        </DialogContent>

      </Dialog>
    </div>
  )
}

export default ACServiceRepiar
