import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [0]);
  return (
    <div>
      <Header />
      <div className="top-space refundclass">
        <Container fluid>
          <Row>
            <Col xs={12} sm={12} md={12}>
              <h2>About</h2>
              <h3>Who we are</h3>
              <p>
              Perfect ERP Software is a technology platform running A2ZWorkIndia.com offering a variety of services at home. Customers use our platform to book services such as beauty treatments, haircuts, massage therapy, cleaning, plumbing, carpentry, appliance repair, painting etc. These services are delivered in the comfort of their home and at a time of their choosing. We promise our customers a high quality, standardised and reliable service experience. To fulfill this promise, we work closely with our hand-picked service partners, enabling them with technology, training, products, tools, financing, insurance and brand, helping them succeed and deliver on this promise.
Our Vision: Empower millions of professionals worldwide to deliver services at home like never experienced before
              </p>
              <h3>How We do it</h3>
              <p>
              Perfect ERP Software provides a platform that allows skilled and experienced professionals to connect with users looking for specific services. Once on the platform, our match-making algorithm identifies professionals who are closest to the users’ requirements and available at the requested time and date.
              </p>

            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  );
}

export default About;
