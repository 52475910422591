import React, { useState, useCallback, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ComposedChart,
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Bar,
} from "recharts";
import Form from "react-bootstrap/Form";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Headerinner from "../Common/Headerinner";
import SideBar from "../Common/SideBar";
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}
const rows = [
  createData(1, "DialERP", "Test", 1, "Pending"),
  createData(2, "DialERP", "Test", 1, "Pending"),
  createData(3, "DialERP", "Test", 1, "Pending"),
  createData(4, "DialERP", "Test", 1, "Pending"),
  createData(5, "DialERP", "Test", 1, "Pending"),
];
const data = [
  { name: "Page A", uv: 100, pv: 100, amt: 1500 },
  { name: "Page B", uv: 200, pv: 138, amt: 210 },
  { name: "Page C", uv: 150, pv: 900, amt: 290 },
];
const data55 = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 300 },
  { name: "Group C", value: 300 },
  { name: "Group D", value: 200 },
];
const dataSales = [
  { name: "Page A", uv: 4000, pv: 2400, amt: 2400 },
  { name: "Page B", uv: 3000, pv: 1398, amt: 2210 },
  { name: "Page C", uv: 2000, pv: 9800, amt: 2290 },
  { name: "Page D", uv: 2780, pv: 3908, amt: 2000 },
  { name: "Page E", uv: 1890, pv: 4800, amt: 2181 },
  { name: "Page F", uv: 2390, pv: 3800, amt: 2500 },
  { name: "Page G", uv: 3490, pv: 4300, amt: 2100 },
];

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
function AdminDashboard() {
  const [opacity, setOpacity] = useState({ uv: 1, pv: 1 });
  const handleMouseEnter = useCallback(
    (o) => {
      const { dataKey } = o;
      setOpacity({ ...opacity, [dataKey]: 0.5 });
    },
    [opacity, setOpacity]
  );
  const handleMouseLeave = useCallback(
    (o) => {
      const { dataKey } = o;
      setOpacity({ ...opacity, [dataKey]: 1 });
    },
    [opacity, setOpacity]
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [0]);
  var userLogininfo = JSON.parse(localStorage.getItem("user-Logininfo"));

  if (userLogininfo === null) {
    window.location.href = "/";
  } else {
    if (
      userLogininfo["User Group"] !== "Employee" &&
      userLogininfo["User Group"] !== "Service Provider"
    ) {
      window.location.href = "/not-found";
    }
  }

  return (
    <div className="admin-panel-section">
      <Headerinner />
      <div className="admin-body-section admin-div-1">
        <Container fluid>
          <Row>
            <Col xs={12} sm={12} md={3} lg={2}>
              <SideBar />
            </Col>
            <Col xs={12} sm={12} md={9} lg={10} className="right-side-section">
              {/* <div className="welcome-section-2">
                <Row>
                  <Col xs={12} sm={6} md={3}>
                    <div className="sales-1">
                      <p>Sales</p>
                      <ComposedChart
                        width={500}
                        height={250}
                        data={data}
                        margin={{
                          top: 0,
                          right: 0,
                          bottom: 0,
                          left: 0,
                        }}
                      >
                        <CartesianGrid stroke="#f5f5f5" />
                        <XAxis dataKey="name" scale="band" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="uv" barSize={20} fill="#413ea0" />
                        <Line type="monotone" dataKey="uv" stroke="#ff7300" />
                      </ComposedChart>
                    </div>
                  </Col>
                  <Col xs={12} sm={6} md={3}>
                    <div className="sales-1">
                      <p>Purchase</p>
                      <ComposedChart
                        width={500}
                        height={250}
                        data={data}
                        margin={{
                          top: 0,
                          right: 0,
                          bottom: 0,
                          left: 0,
                        }}
                      >
                        <CartesianGrid stroke="#f5f5f5" />
                        <XAxis dataKey="name" scale="band" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="uv" barSize={20} fill="#413ea0" />
                        <Line type="monotone" dataKey="uv" stroke="#ff7300" />
                      </ComposedChart>
                    </div>
                  </Col>
                  <Col xs={12} sm={6} md={3}>
                    <div className="sales-1">
                      <p>Return</p>
                      <ComposedChart
                        width={500}
                        height={250}
                        data={data}
                        margin={{
                          top: 0,
                          right: 0,
                          bottom: 0,
                          left: 0,
                        }}
                      >
                        <CartesianGrid stroke="#f5f5f5" />
                        <XAxis dataKey="name" scale="band" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="uv" barSize={20} fill="#413ea0" />
                        <Line type="monotone" dataKey="uv" stroke="#ff7300" />
                      </ComposedChart>
                    </div>
                  </Col>
                  <Col xs={12} sm={6} md={3}>
                    <div className="sales-1">
                      <p>Marketing</p>
                      <ComposedChart
                        width={500}
                        height={250}
                        data={data}
                        margin={{
                          top: 0,
                          right: 0,
                          bottom: 0,
                          left: 0,
                        }}
                      >
                        <CartesianGrid stroke="#f5f5f5" />
                        <XAxis dataKey="name" scale="band" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="uv" barSize={20} fill="#413ea0" />
                        <Line type="monotone" dataKey="uv" stroke="#ff7300" />
                      </ComposedChart>
                    </div>
                  </Col>
                </Row>
              </div> */}
              <div className="welcome-section-2">
                <Row>
                  <Col xs={12} sm={8} md={8}>
                    <div className="sales-1">
                      <p>
                        Sales Report{" "}
                        <span>
                          <Form.Select aria-label="Default select example">
                            <option value="Yearly">Yearly</option>
                            <option value="Monthly">Monthly</option>
                            <option value="Weekly">Weekly</option>
                            <option value="Today">Today</option>
                          </Form.Select>
                        </span>
                      </p>
                      <LineChart
                        width={800}
                        height={370}
                        data={dataSales}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                        />
                        <Line
                          type="monotone"
                          dataKey="pv"
                          strokeOpacity={opacity.pv}
                          stroke="#8884d8"
                          activeDot={{ r: 8 }}
                        />
                        <Line
                          type="monotone"
                          dataKey="uv"
                          strokeOpacity={opacity.uv}
                          stroke="#82ca9d"
                        />
                      </LineChart>
                    </div>
                  </Col>

                  <Col xs={12} sm={4} md={4}>
                    <div className="sales-1 sales-2">
                      <p>
                        Source of Purchases
                        <span>
                          <Form.Select aria-label="Default select example">
                            <option value="Yearly">Yearly</option>
                            <option value="Monthly">Monthly</option>
                            <option value="Weekly">Weekly</option>
                            <option value="Today">Today</option>
                          </Form.Select>
                        </span>
                      </p>
                      <PieChart width={400} height={400}>
                        <Pie
                          data={data55}
                          cx={200}
                          cy={200}
                          labelLine={false}
                          label={renderCustomizedLabel}
                          outerRadius={150}
                          fill="#8884d8"
                          dataKey="value"
                        >
                          {data.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>
                      </PieChart>
                    </div>
                  </Col>
                </Row>
              </div>
              {/* <div className="welcome-section-2">
                <Row>
                  <Col xs={12} sm={12} md={12}>
                    <div className="sales-1">
                      <p>
                        Products Of The Month{" "}
                        <span>
                          <Form.Select aria-label="Default select example">
                            <option value="Yearly">Yearly</option>
                            <option value="Monthly">Monthly</option>
                            <option value="Weekly">Weekly</option>
                            <option value="Today">Today</option>
                          </Form.Select>
                        </span>
                      </p>
                      <TableContainer component={Paper}>
                        <Table
                          sx={{ minWidth: 650 }}
                          aria-label="simple table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <b>Service Id</b>
                              </TableCell>
                              <TableCell>
                                <b>Service Name</b>
                              </TableCell>
                              <TableCell>
                                <b>Customer Name </b>
                              </TableCell>
                              <TableCell>
                                <b>Order ID </b>
                              </TableCell>
                              <TableCell>
                                <b>Status</b>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {rows.map((row) => (
                              <TableRow
                                key={row.name}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {row.name}
                                </TableCell>
                                <TableCell align="right">
                                  {row.calories}
                                </TableCell>
                                <TableCell align="right">{row.fat}</TableCell>
                                <TableCell align="right">
                                  {row.carbs}
                                </TableCell>
                                <TableCell align="right">
                                  {row.protein}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </Col>
                </Row>
              </div> */}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default AdminDashboard;
