import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";


import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import PinDropIcon from '@mui/icons-material/PinDrop';
import ListAltIcon from '@mui/icons-material/ListAlt';
import WalletIcon from '@mui/icons-material/Wallet';
import LogoutIcon from '@mui/icons-material/Logout';
import DeliveryAddress from "./DeliveryAddress";
import MyAddress from "./MyAddress";
import MyWallet from "./MyWallet";
import MyOrder from "./MyOrder";


import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const steps = [
  {
    label: 'Assigning professional',
  },
  {
    label: 'Share start Service OTP',
  },
  {
    label: 'Service Delivery',
  },
  {
    label: 'Payment & Invoice',
  },
  {
    label: 'Rate & Review',
  },
];
function OrderTimeDetail() {
   
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleReset = () => {
      setActiveStep(0);
    };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  
  return (
    <div className="my-orders order-time-line-1">
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={
                index === 2 ? (
                  <Typography variant="caption"></Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              <Typography>
              {activeStep == 0 ? (
                <div className="time-line-1">
                   <h2>Service Person on the way</h2>
                   {/* <p>30/10/2023 04:25 PM <span>Your Service has been Done.</span></p> */}
                   <p>31/10/2023 02:25 PM <span>Your Service has been Completed</span></p>
                </div>

) : (
    <></>
  )} 
  {activeStep == 1 ? (
                <div className="time-line-1">
                <h2>Your Service has been completed Service Id - ODI156156465156</h2>
             </div>

) : (
    <></>
  )}
   {activeStep == 2 ? (
                <div className="time-line-1">
                <h2>Your Service has been completed</h2>
             </div>

) : (
    <></>
  )}
              {activeStep == 3 ?
             <div className="time-line-1">
             <h2>Your Service has been completed</h2>
          </div>

: <></>}
              </Typography>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                    className="next-button-7"
                  >
                    {index === steps.length - 1 ? 'Done' : 'Continue'}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {/* {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )} */}
     
    </div>
  );
}

export default OrderTimeDetail
