import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Link } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import CustomerLogin from "./CustomerLogin";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { ProductContext } from "../context/AppContext";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";


function Ragistration() {
  const {
    ContactName,
    setContactName,
    Mobile,
    setMobile,  
    Gender,
    setGender,
    error,  
    DayDOB,
    setDayDOB,
    MonthDOB,
    YearDOB,
    setYearDOB,
    handleKeyPress,
    fun_CustomerRagistration,
    handleSubmit,
    MonthValue,
    RegMobile,
    setRegMobile,
  } = useContext(ProductContext);
 
  return (
    <div>
      <div className="login-18 login-18-Customer-login">
        <div className="form-section">
          <div className="logo-2">
            <Link to="/">
              <img src="../../assets/images/logo1.svg" alt="logo" />
            </Link>
          </div>
          <div className="typing-4">
            <h3>Customer Ragistration</h3>
            <p>Enter your Contact Name, Mobile, Birthday and Gender</p>
          </div>
          <div className="form-group">
            <div className="input-spce-bottom">
              <TextField
                id="outlined-basic"
                className={`width-100 lgoin-input-6  ${error && ContactName.trim() === "" ? "is-invalid" : ""
                  }`}
                label="Contact Name"
                variant="outlined"
                value={ContactName}
                onChange={(e) => {
                  setContactName(e.target.value);
                }}
              />
              {error && ContactName.trim() === "" && (
                <div className="invalid-feedback">
                  Please enter Contact Name
                </div>
              )}
            </div>
            <div className="input-spce-bottom">
              <TextField
                floatingLabelText="number"
                defaultValue="+91 | "
                className={`width-100 lgoin-input-6 ${error && RegMobile.trim() === ""  ? "is-invalid" : ""
                  }`}
                label="Mobile Number"
               // maxLength={10}
                required
                variant="outlined"
               // inputProps={{ maxLength: 10 }}
                //max={10}
                onInput = {(e) =>{
                  e.target.value = (e.target.value).toString().slice(0,10)
              }}
                id="validation-outlined-input"
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      +91
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  setRegMobile(e.target.value);
                }}
                onKeyPress={(e) => handleKeyPress(e)}
              />
              {error && RegMobile === "" && (
                <div className="invalid-feedback">Please enter mobile number</div>
              )}
                {/* {error && Mobile.trim() !== '' && !MobileRegExp.test(Mobile.trim()) && <div className="invalid-feedback">Please enter a valid 10 digits mobile no</div>} */}
            </div>

            <Row>
              <Col xs={4} sm={4} md={4} lg={4}>
                <div className="input-spce-bottom">
                  <TextField
                    id="outlined-basic"
                    className={`width-100 lgoin-input-6  ${error && DayDOB.trim() === "" || error === "Please Enter Valid Day" || error === 'Enter age must be 18.' ? "is-invalid" : ""
                      }`}
                    label="Day"
                    type="text"
                    variant="outlined"
                    value={DayDOB}
                    inputProps={{ maxLength: 2 }}
                    onKeyPress={(e) => handleKeyPress(e)}
                    onChange={(e) => {
                      setDayDOB(e.target.value);
                    }}
                  />
                  {error && DayDOB.trim() === "" && (
                    <div className="invalid-feedback">
                      Please enter valid Day
                    </div>) || error === "Please Enter Valid Day" && (
                      <div className="invalid-feedback">
                        Please Enter Valid Day
                      </div>) || error === "Enter age must be 18." && (
                        <div className="invalid-feedback">
                          Enter age must be 18.
                        </div>)
                  }
                </div>
              </Col>

              <Col xs={4} sm={4} md={4} lg={4}>
                <div className="input-spce-bottom">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-labe">Month</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      className={`width-100 lgoin-input-6 form-control-bb lgoin-input-6 ${error && MonthDOB.trim() === "" ? "is-invalid" : ""
                        }`}
                      label="Month"
                      value={MonthDOB}

                      onChange={handleSubmit}
                    >
                      {MonthValue.map((dd, i) => {
                        return (
                          <MenuItem value={dd["value"]}>{dd["Name"]}</MenuItem>
                        );

                      })}
                    </Select>
                    {error && MonthDOB.trim() === "" && (
                      <div className="invalid-feedback">
                        Please select valid Month
                      </div>)}
                  </FormControl>
                </div>
              </Col>
              <Col xs={4} sm={4} md={4} lg={4}>
                <div className="input-spce-bottom">
                  <TextField
                    id="outlined-basic"
                    className={`width-100 lgoin-input-6  ${error && YearDOB.trim() === "" || error === "Year should be in range 1950 to current year" ? "is-invalid" : ""
                      }`}
                    label="Year"
                    type="text"
                    variant="outlined"
                    value={YearDOB}
                    onKeyPress={(e) => handleKeyPress(e)}
                    inputProps={{ maxLength: 4 }}
                    onChange={(e) => {
                      setYearDOB(e.target.value);
                    }}
                  />
                  {error && YearDOB.trim() === "" && (
                    <div className="invalid-feedback">
                      Please enter valid Year
                    </div>
                  )}
                  {error === "Year should be in range 1950 to current year" &&
                      <div className="invalid-feedback">Year should be in range 1950 to current year</div>}
                {/* {error && YearDOB.trim() !== '' && !yearRegExp.test(YearDOB.trim()) && <div className="invalid-feedback">Please enter a valid 4 digits year</div>} */}

                </div>
              </Col>
            </Row>

            <div className="form-group clearfix">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-labe">Gender</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  className={`width-100 lgoin-input-6 form-control-bb lgoin-input-6 ${error && Gender.trim() === "" ? "is-invalid" : ""
                    }`}
                  label="Gender"
                  value={Gender}
                  onChange={(e) => {
                    setGender(e.target.value);
                  }}
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                </Select>
                {error && Gender.trim() === "" && (
                  <div className="invalid-feedback">Please select gender</div>
                )}
              </FormControl>
            </div>
            <div className="form-group clearfix">
              <button
                type="submit"
                className="btn btn-primary btn-lg btn-theme"
                onClick={fun_CustomerRagistration}
              >
                <b>Register</b>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Ragistration;
